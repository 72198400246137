import { useContext, useEffect, useState } from 'react';
import { Practitioner } from '../../models/practitioner';
import { ServiceContext } from '../../../_core/services/service.context';
import { PractitionerService } from '../../services/practitioner.service';
import useDebounce from '../../component/useDebounce';
import { map } from 'rxjs/operators';

const usePractitioners = (autoRefill: boolean, practitionerService: PractitionerService) => {
  const { query } = useContext(ServiceContext);

  const [searchField, setSearchField] = useState(null as string | null);
  const debouncedSearchField = useDebounce(searchField, 200);

  const [practitioners, setPractitioners] = useState([] as Practitioner[]);

  const [isLoading, setIsLoading] = useState(false);

  const fillPractitioners = () => {
    setIsLoading(true);
    query(practitionerService.getAllPractitioners())
      .subscribe(
        (practitioners: Practitioner[]) => {
          setPractitioners(practitioners);
        },
        (err) => console.log(err),
      )
      .add(() => setIsLoading(false));
  };

  const getSuggestions = () => {
    if (debouncedSearchField && debouncedSearchField.length > 0) {
      setIsLoading(true);
      query(practitionerService.getSuggestions(debouncedSearchField))
        .pipe(map((suggestions: any) => suggestions.map((suggestion: any) => suggestion.suggestion)))
        .subscribe(
          (practitioners: Practitioner[]) => {
            setPractitioners(practitioners);
          },
          (err) => console.log(err),
        )
        .add(() => setIsLoading(false));
    } else {
      setPractitioners([]);
      if (autoRefill) fillPractitioners();
    }
  };

  const refill = () => (autoRefill ? fillPractitioners() : getSuggestions());

  useEffect(getSuggestions, [debouncedSearchField]);

  return {
    practitioners,
    searchField,
    isLoading,
    refill,
    fillPractitioners,
    setSearchField,
  };
};

export default usePractitioners;
