import React from 'react';
import { PAYEE_STATUS } from '../../../../_shared/config/constants';

interface ISetToPayeeButtonProps {
  onSetToPayee: any;
  minified?: boolean;
  disabled?: boolean;
}

export const SetToPayeeButton: React.FC<ISetToPayeeButtonProps> = (props: ISetToPayeeButtonProps) => {
  return (
    <div>
      <button
        className={props.minified ? 'button-validate minified' : 'button-validate'}
        disabled={props.disabled}
        onClick={() => props.onSetToPayee(PAYEE_STATUS)}
      >
        <i className="material-icons">euro_symbol</i>
        {props.minified ? null : <span>Confirmer le paiement</span>}
      </button>
    </div>
  );
};
