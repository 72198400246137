import React from 'react';
import { NON_PAYEE_STATUS, R1_STATUS, R2_STATUS } from '../../../../../_shared/config/constants';
import { SetToR1Button } from '../../../orderPage/orderPanel/setToR1.button';
import { SetToR2Button } from '../../../orderPage/orderPanel/setToR2.button';
import { PrintR1Button } from '../../../orderPage/orderPanel/printR1.button';
import { PrintR2Button } from '../../../orderPage/orderPanel/printR2.button';

interface IOrdersActionsPanelProps {
  editStatus: string | null;
  updateStatus: any;
  printOrder: any;
  disabled?: boolean;
}

const renderOrderButton = ({ editStatus, updateStatus, printOrder, disabled }: IOrdersActionsPanelProps) => {
  switch (editStatus) {
    case NON_PAYEE_STATUS:
      return (
        <>
          <SetToR1Button disabled={disabled} minified={true} onUpdateToR1={() => updateStatus(R1_STATUS)} />
        </>
      );
    case R1_STATUS:
      return (
        <>
          <SetToR2Button disabled={disabled} minified={true} onUpdateToR2={() => updateStatus(R2_STATUS)} />
          <PrintR1Button
            disabled={disabled}
            minified={true}
            onPrintR1={() => {
              printOrder(R1_STATUS);
            }}
          />
        </>
      );
    case R2_STATUS:
      return (
        <>
          <PrintR2Button
            disabled={disabled}
            minified={true}
            onPrintR2={() => {
              printOrder(R2_STATUS);
            }}
          />
        </>
      );
    default:
      return null;
  }
};

export const OrdersActionsPanel: React.FC<IOrdersActionsPanelProps> = (props: IOrdersActionsPanelProps) => {
  return <>{renderOrderButton(props)}</>;
};
