import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { IngredientsPage } from './ingredients.page';
import { IngredientProvider } from '../../_shared/providers/ingredient/ingredient.provider';

export const IngredientRouter: React.FC<any> = ({ match }: any) => {
  return (
    <IngredientProvider>
      <Switch>
        <Route path={match.url} exact component={IngredientsPage} />
      </Switch>
    </IngredientProvider>
  );
};
