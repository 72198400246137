import React from 'react';
import { Ingredient } from '../../models/ingredient';

export interface IIngredientContext {
  ingredientSelected: Ingredient | null;
  ingredientOnEdition: Ingredient | null;
  ingredients: Ingredient[] | null;
  isLoading: boolean;
  filter: string;
  order: any;
  setIngredientSelected: (ingredient: Ingredient | null) => void;
  setIngredientOnEdition: (ingredient: Ingredient | null) => void;
  setIngredientOnEditionAsNew: () => void;
  fetchIngredientOnEdition: () => void;
  deleteIngredient: () => void;
  fillIngredients: () => void;
  setOrder: (order: string) => void;
  setFilter: (filter: any) => void;
}

const IngredientContext = React.createContext<IIngredientContext>({
  ingredientSelected: null,
  ingredientOnEdition: null,
  ingredients: null,
  isLoading: false,
  filter: '',
  order: '',
  fillIngredients: () => '',
  setIngredientSelected: (ingredient: Ingredient | null) => '',
  setIngredientOnEdition: (ingredient: Ingredient | null) => '',
  setIngredientOnEditionAsNew: () => '',
  fetchIngredientOnEdition: () => '',
  deleteIngredient: () => '',
  setFilter: (filter: any) => '',
  setOrder: () => '',
});

const IngredientConsumer = IngredientContext.Consumer;

export { IngredientContext, IngredientConsumer };
