import React from 'react';

interface ITextAreaProps {
  name: string;
  value?: string | number;
  label?: string;
  className?: string;
  autofocus?: boolean;
  prefix?: string;
  suffix?: string;
  min?: number;
  max?: number;
  placeholder?: string;
  inputRef?: any;
  locked?: boolean;
  format?: any;
  size?: number;
  error?: any;
  resize?: boolean;
  decimals?: number;
  onChange: any;
  onPressEnter?: any;
  onCancel?: any;
  dataCy?: string;
}

class NumberInput extends React.Component<ITextAreaProps> {
  state = {
    isEditing: false,
    value: this.props.value,
  };

  formatter = new Intl.NumberFormat('fr-FR', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: this.props.decimals || 2,
  });

  onChange(event: any) {
    const str = event.target.value;
    if (isNaN(str.replace(',', '.'))) {
      return;
    }
    const record = str.toString().charAt(str.length - (2 + (this.props.decimals ? this.props.decimals : 2)));
    if (record === '.') {
      return;
    }

    if (this.props.min && str < this.props.min) {
      return;
    }
    if (this.props.max && str > this.props.max) return;

    this.props.onChange({
      [this.props.name]: Number(event.target.value.replace(',', '.')),
    });
  }

  handleFocus(event: any) {
    event.target.select();
  }

  toCurrency(number: number | string) {
    return this.formatter.format(Number(number));
  }

  toggleEditing() {
    this.setState({ isEditing: !this.state.isEditing });
  }

  render() {
    const value = this.props.value ? this.toCurrency(this.props.value) : undefined;
    const { resize, size } = this.props;
    const relativeSize = resize && value ? (size ? (value.length > size ? value.length : size) : undefined) : size;

    return (
      <div className={`number-input ${this.props.value ? 'active' : ''}`}>
        {this.props.label ? <label>{this.props.label}</label> : null}
        <div className="number-input_row">
          {this.props.suffix ? <div className="number-input_suffix">{this.props.suffix}</div> : null}
          {this.state.isEditing && !this.props.locked ? (
            <input
              type={'text'}
              className={`${this.props.className || ''} ${
                isNaN(Number(this.props.value)) || this.props.error ? 't-red' : ''
              }`}
              autoComplete="off"
              defaultValue={this.state.value as string}
              name={this.props.name}
              onChange={(e: any) => this.onChange(e)}
              onClick={(e: any) => this.handleFocus(e)}
              ref={this.props.inputRef}
              size={resize ? relativeSize : size}
              onFocus={(e: any) => {
                this.handleFocus(e);
              }}
              onKeyDown={(e: any) => {
                if (this.props.onPressEnter && e.key === 'Enter') {
                  this.props.onPressEnter();
                }
              }}
              onBlur={() => this.toggleEditing()}
            />
          ) : (
            <input
              type={'text'}
              className={`${this.props.className || ''} ${
                isNaN(Number(this.props.value)) || this.props.error ? 't-red' : ''
              }`}
              value={this.props.value ? (this.props.format ? this.props.format(Number(value)) : value) : ''}
              name={this.props.name}
              disabled={this.props.locked}
              placeholder={this.props.placeholder}
              autoFocus={this.props.autofocus}
              ref={this.props.inputRef || null}
              size={relativeSize}
              onChange={(e: any) => this.onChange(e)}
              onFocus={() => {
                this.toggleEditing();
              }}
              readOnly
              data-cy={this.props.dataCy}
            />
          )}
          {this.props.prefix ? <div className="number-input_suffix">{this.props.prefix}</div> : null}
          {this.props.onCancel && value ? (
            <div>
              <button tabIndex={1} onClick={() => this.props.onCancel()}>
                <span className="material-icons t-grey">close</span>
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default NumberInput;
