import React from 'react';
import { ARCHIVE_STATUS } from '../../../../_shared/config/constants';

interface ISetToArchiveButtonProps {
  onSetToArchive: any;
  minified?: boolean;
  disabled?: boolean;
}

export const SetToArchiveButton: React.FC<ISetToArchiveButtonProps> = (props: ISetToArchiveButtonProps) => {
  return (
    <div>
      <button
        className={props.minified ? 'button-alert minified' : 'button-alert'}
        disabled={props.disabled}
        onClick={() => props.onSetToArchive(ARCHIVE_STATUS)}
      >
        <span className="material-icons">delete_outline</span>
        {props.minified ? '' : 'Archiver'}
      </button>
    </div>
  );
};
