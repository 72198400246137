import { useContext, useEffect, useState } from 'react';
import { map } from 'rxjs/operators';
import { ServiceContext } from '../../../_core/services/service.context';
import { clientService } from '../../services/client.service';
import { Client } from '../../models/client';

function useClients(autoRefill: boolean) {
  const { query } = useContext(ServiceContext);
  const [clients, setClients] = useState(null as Client[] | null);
  const [searchField, setSearchField] = useState(null as string | null);
  const [isLoading, setIsLoading] = useState(false);

  const fillClients = () => {
    setIsLoading(true);
    query(clientService.getAllClients())
      .subscribe(
        (clients: any[]) => setClients(clients),
        (err) => console.log(err),
      )
      .add(() => setIsLoading(false));
  };

  const unfillClients = () => {
    setClients([]);
  };

  const getSuggestions = () => {
    if (searchField && searchField.length > 0) {
      setIsLoading(true);
      query(clientService.getSuggestions(searchField))
        .pipe(map((suggestions: any) => suggestions.map((suggestion: any) => suggestion.suggestion)))
        .subscribe(
          (clients: Client[]) => setClients(clients),
          (err: any) => console.log(err),
        )
        .add(() => setIsLoading(false));
    } else {
      setClients([]);
      if (autoRefill) fillClients();
    }
  };

  const reInit = () => {
    getSuggestions();
  };

  useEffect(getSuggestions, [searchField]);
  useEffect(reInit, [autoRefill]);

  return {
    searchField,
    isLoading,
    clients,
    setSearchField,
    fillClients,
    unfillClients,
    reInit,
  };
}

export default useClients;
