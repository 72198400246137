import React, { useContext, useEffect, useState } from 'react';
import { orderService } from '../../../_shared/services/order.service';
import { ServiceContext } from '../../../_core/services/service.context';
import { SpinLoader } from '../../../_shared/component/loader/loader';
import moment from 'moment';

import './clientOrder.list.css';
import { useEuroFormatter } from '../../../_shared/services/formatter.service';
import { HeadersList } from '../../../_shared/component/tableHeadersList/Headers.list';

const headersList = [
  {
    name: 'code',
    title: 'code',
  },
  {
    name: 'date',
    title: 'date',
  },
  {
    name: 'praticien',
    title: 'praticien',
  },
  {
    name: 'prixCalcul',
    title: 'prix',
  },
  {
    name: 'reste',
    title: 'reste',
  },
  {
    name: 'status',
    title: 'status',
    locked: true,
  },
];

interface IClientOrderListProps {
  clientId: string;
  onSelect: any;
  onWarning?: any;
  onWarningRecent?: any;
}

function useClientOrder(clientId: string | null) {
  const { query } = useContext(ServiceContext);
  const [state, setState] = useState({
    orders: [],
    total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [orderSort, setOrderSort] = useState({
    sort: 'date',
    direction: 'desc',
  });

  const printUnpaidOrders = (clientId: string) => {
    if (query) {
      query(orderService.printClientOrder(clientId)).subscribe(
        (res: any) => {
          const file = new Blob([res], {
            type: 'application/pdf',
          });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        },
        (err) => console.log(err),
      );
    }
  };

  useEffect(() => {
    if (clientId) {
      setIsLoading(true);
      setState({
        orders: [],
        total: 0,
      });
      query(orderService.getAllOrder({ cid: [clientId] }, offset, orderSort.sort, orderSort.direction))
        .subscribe(
          (res) => setState(res),
          (err: any) => console.log(err),
        )
        .add(() => setIsLoading(false));
    } else {
      setState({ orders: [], total: 0 });
    }
  }, [clientId, orderSort, offset]);

  const updateSort = (sort: any) => {
    if (orderSort && orderSort.sort === sort) {
      setOrderSort({
        sort,
        direction: orderSort.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setOrderSort({ sort, direction: 'desc' });
    }
  };

  return {
    ...state,
    sort: orderSort,
    offset,
    isLoading,
    setOffset,
    updateSort,
    printUnpaidOrders,
  };
}

const warningHigh = 'high';
const warningMedium = 'medium';
const warningRecent = 'recent';

export const ClientOrderList: React.FC<IClientOrderListProps> = ({
  clientId,
  onSelect,
  onWarning,
  onWarningRecent,
}: IClientOrderListProps) => {
  const { orders, isLoading, sort, updateSort, printUnpaidOrders } = useClientOrder(clientId);

  useEffect(() => {
    if (onWarning !== undefined) onWarning(orders && orders.filter((o: any) => o.warning === warningHigh).length > 0);

    if (onWarningRecent !== undefined)
      onWarningRecent(orders && orders.filter((o: any) => o.warning == warningRecent).length > 0);
  }, [orders]);

  const formatToEuro = useEuroFormatter();

  const unpaidOrdersNumber = orders.filter((order: any) => order.warning === warningHigh).length;

  const recentOrdersNumber = orders.filter((order: any) => order.warning == warningRecent).length;

  return (
    <div className={'client-order-list'}>
      <div className="p-medium t-green light br-bottom row gapped">
        <span className="material-icons">restore</span>
        <span>HISTORIQUE DES COMMANDES</span>
      </div>
      {orders && orders.length > 0 ? (
        <>
          {recentOrdersNumber || unpaidOrdersNumber ? (
            <div className="row gapped t-red ">
              <i className="material-icons">warning</i>
              <div className="full-width">
                {recentOrdersNumber > 0 ? <div>Commande récentes : {recentOrdersNumber}</div> : null}
                {unpaidOrdersNumber > 0 ? (
                  <div className="row spaced ">
                    <div>Commande en attente de paiement : {unpaidOrdersNumber}</div>
                    <button type={'button'} className="t-red" onClick={() => printUnpaidOrders(clientId)}>
                      <i className="material-icons">print</i>
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}

          <table className="br-top br-bottom">
            <thead>
              <tr>
                <HeadersList headersList={headersList} sort={sort} onUpdateSort={updateSort} />
              </tr>
            </thead>
            <tbody>
              {orders.map((order: any, index: number) => (
                <tr
                  key={index}
                  onClick={() => onSelect(order.id)}
                  className={
                    order.warning == warningHigh || order.warning === warningRecent
                      ? 'b-red t-white'
                      : order.warning == warningMedium
                      ? 'b-yellow'
                      : ''
                  }
                >
                  <td>{order.codeFull}</td>
                  <td>{moment(order.date).locale('fr').format('DD MMMM YYYY')}</td>
                  <td>{order.praticienCode || '--'}</td>
                  <td>{formatToEuro(order.prixCalcul)}</td>
                  <td>{formatToEuro(order.reste)}</td>
                  <td>{order.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <div className="row center p-medium full-width">
          {isLoading ? (
            <SpinLoader size={'3em'} thickness={'0.2em'} color={'#000000'} />
          ) : (
            <div className="t-grey">Aucune commande passée</div>
          )}
        </div>
      )}
    </div>
  );
};
