import React from 'react';
import './checkbox.css';

interface ICheckboxProps {
  name: string;
  value: boolean;
  onChange: any;
}

const Checkbox: React.FC<ICheckboxProps> = (props: ICheckboxProps) => {
  return (
    <div className="row center full-height">
      <input name={props.name} type="checkbox" checked={props.value} onChange={() => props.onChange(!props.value)} />
    </div>
  );
};

export default Checkbox;
