import * as React from 'react';
import { useDecimalFormatter } from '../../../../../../../_shared/services/formatter.service';
import useCompositionOnEdition from '../../../../../../../_shared/providers/draft/useCompositionOnEdition';

const ProportionField: React.FC = () => {
  const { composition } = useCompositionOnEdition();
  const formatToDecimal = useDecimalFormatter();
  return composition.forme && composition.forme.code !== 'LB' ? (
    <div className="t-grey p-medium">
      <div className="t-small">Proportions:</div>
      <div style={{ minWidth: '5em' }}>{formatToDecimal(composition.proportionTotal)}</div>
    </div>
  ) : null;
};

export default ProportionField;
