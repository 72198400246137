import { useReducer } from 'react';

interface ListParamsState<F, S = string> {
  filters: Partial<F>;
  offset: number;
  sort: S;
  direction: 'asc' | 'desc';
}

const INIT_PARAMS = 'INIT_PARAMS';
const SET_PARAMS = 'SET_PARAMS';
const SET_FILTERS = 'SET_FILTERS';
const SET_OFFSET = 'SET_OFFSET';
const SET_SORT = 'SET_SORT';

const ListParamsReducer = (state: ListParamsState<any>, action: any) => {
  switch (action.type) {
    case INIT_PARAMS:
      return { ...action.initialParams };
    case SET_PARAMS:
      return { ...state, ...action.updates };
    case SET_FILTERS:
      if (!action.updates) {
        return { ...state, filters: {}, offset: 0 };
      } else {
        const updatedFilters: any = { ...state.filters, ...action.updates };
        Object.keys(updatedFilters).forEach(
          (key: string) => updatedFilters[key] === undefined && delete updatedFilters[key],
        );
        return { ...state, filters: updatedFilters, offset: 0 };
      }
    case SET_OFFSET:
      return { ...state, offset: action.offset };
    case SET_SORT:
      if (state.sort === action.sort) {
        return {
          ...state,
          direction: state.direction === 'asc' ? 'desc' : 'asc',
        };
      } else {
        return {
          ...state,
          sort: action.sort,
          direction: 'desc',
        };
      }
    default:
      return { ...state };
  }
};

const useListParams = <F, S = string>(initialState: Partial<ListParamsState<F, S>>) => {
  const [params, dispatch]: [ListParamsState<any>, any] = useReducer(ListParamsReducer, initialState);

  const initParams = (initialParams: ListParamsState<F, S>) => {
    dispatch({ type: INIT_PARAMS, initialParams });
  };

  const updateParams = (updates: Partial<ListParamsState<F, S>>) => {
    dispatch({ type: SET_PARAMS, updates });
  };

  const updateFilters = (updates: Partial<F>) => {
    dispatch({ type: SET_FILTERS, updates });
  };

  const updateOrderSort = (sort: S) => {
    dispatch({ type: SET_SORT, sort });
  };

  const updateOffset = (offset: number) => {
    dispatch({ type: SET_OFFSET, offset: offset });
  };

  return {
    params,
    initParams,
    updateParams,
    updateFilters,
    updateOrderSort,
    updateOffset,
  };
};

export default useListParams;
