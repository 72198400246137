import React, { forwardRef, Ref, useRef, useState } from 'react';

interface ISearchBarProps {
  filter: string;
  placeholder: string;
  classname?: string;
  autofocus?: boolean;
  outfocus?: boolean;
  onChange: any;
  onFilterRemove: any;
  ref?: any;
  dataCy?: string;
}

export const SearchBar: React.FC<ISearchBarProps> = forwardRef((props: ISearchBarProps, ref: any) => {
  const htmlref = ref || useRef(null);
  return (
    <div className={`search-bar row rounded gapped p-small br-bottom ${props.classname}`}>
      <i className="material-icons">search</i>
      <input
        autoFocus={props.autofocus}
        value={props.filter}
        ref={htmlref}
        className="search-bar-input fill-width"
        onChange={(e) => props.onChange(e.target.value)}
        placeholder={props.placeholder}
        data-cy={props.dataCy}
      />
      {props.filter.length > 0 ? (
        <button
          className="button-lgrey circle"
          onClick={() => {
            props.onFilterRemove();
            htmlref?.current?.focus();
          }}
        >
          <span className="material-icons">close</span>
        </button>
      ) : null}
    </div>
  );
});
