import React, { useContext, useEffect, useState } from 'react';
import { codePrixService, getAllCodePrix } from '../../_shared/services/codeprix.service';
import { combineLatest } from 'rxjs';
import { getAllForme } from '../../_shared/services/forme.service';
import { SpinLoader } from '../../_shared/component/loader/loader';
import TextInput from '../../_shared/component/textInput/textInput.component';
import NumberInput from '../../_shared/component/numberInput';
import useEscape from '../../_shared/component/useEscape';
import { ServiceContext } from '../../_core/services/service.context';

import './prices.page.css';
import { NavLink } from 'react-router-dom';

export const CodePrixPage: React.FC<any> = () => {
  const { codesPrix, formes, isLoading, codePrixSelected, setCodePrixSelected, fetchCodePrix, deleteCodePrix } =
    useCodePrixCrud();

  const [onDelete, setOnDelete] = useState(null as null | any);

  useEscape(() => {
    setOnDelete(null);
    setCodePrixSelected(null);
  });

  const handleCodePrixSelected = (codePrix: any) => {
    if (!codePrixSelected || codePrixSelected.id !== codePrix.id) setCodePrixSelected(codePrix);
  };

  const AddNewCodePrix = () => {
    if (formes) {
      const newCodePrix = {
        code: '',
        forme_prix: {} as any,
      };
      formes.map((forme: any) => {
        newCodePrix.forme_prix[forme.id] = 0;
      });
      setCodePrixSelected(newCodePrix);
    }
  };

  return (
    <div className="page">
      <div className="crud-header row gapped">
        <div className="title">
          <span className="material-icons">euro</span>
          <span>PRIX</span>
        </div>

        {codePrixSelected ? (
          <>
            <button
              className="button-alert transparent"
              type={'button'}
              autoFocus={true}
              onClick={() => setCodePrixSelected(null)}
            >
              Annuler
            </button>
            <div className="light t-grey p-spaced row">
              Valider avec &nbsp;<b className="t-green">Entrée</b>| Annuler avec &nbsp;<b>Esc</b>
            </div>
          </>
        ) : (
          <>
            <button
              disabled={codePrixSelected}
              className="button-validate transparent"
              type={'button'}
              autoFocus={true}
              onClick={() => AddNewCodePrix()}
            >
              + Ajouter un code prix
            </button>
            <div className="light t-grey row">
              <span>Sélectionner une ligne pour l'éditer</span>
            </div>
          </>
        )}
      </div>
      <div>
        {isLoading ? (
          <div className="column center full-height">
            <SpinLoader size={'8em'} thickness={'4px'} color={'dimgrey'} />
          </div>
        ) : formes && codesPrix ? (
          <CodePrixList
            formes={formes}
            codesPrix={codesPrix}
            codePrixSelected={codePrixSelected}
            onSelectCodePrix={handleCodePrixSelected}
            onUpdateSelectedCodePrix={setCodePrixSelected}
            onFetchCodePrix={fetchCodePrix}
            onDeleteCodePrix={setOnDelete}
          />
        ) : null}
      </div>
      {onDelete ? (
        <div className="absolute overlay full-height full-width column center">
          <div className="b-white bordered rounded p-medium column center">
            <div className="p-medium">Voulez-vous vraiment supprimer ce code prix ?</div>
            <div className="t-red">Ne pas supprimer si le code prix a déjà été affecté à un ingrédient.</div>
            <div className="row gapped center p-medium">
              <button
                type="button"
                onClick={() => {
                  deleteCodePrix(onDelete);
                  setOnDelete(null);
                }}
                className="button-validate"
              >
                Oui
              </button>
              <button
                type="button"
                onClick={() => {
                  setOnDelete(null);
                }}
                className="button-alert"
                autoFocus={true}
              >
                Non
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

interface ICodePrixListProps {
  formes: any[];
  codesPrix: any[];
  codePrixSelected: any;
  onSelectCodePrix: any;
  onUpdateSelectedCodePrix: any;
  onFetchCodePrix: any;
  onDeleteCodePrix: any;
}

export const CodePrixList: React.FC<ICodePrixListProps> = ({
  formes,
  codesPrix,
  codePrixSelected,
  onSelectCodePrix,
  onUpdateSelectedCodePrix,
  onFetchCodePrix,
  onDeleteCodePrix,
}: ICodePrixListProps) => {
  return (
    <>
      <table className={`prices-list list ${codePrixSelected ? '' : 'fixed'}`}>
        <thead>
          <tr>
            {codePrixSelected ? <th className="fitContent" /> : null}
            <th>Code</th>
            {formes.map((forme: any, index) => (
              <th key={index}>{forme.label}</th>
            ))}
            {codePrixSelected ? <th className="fitContent" /> : null}
          </tr>
        </thead>
        <tbody className="table-body">
          {codesPrix
            ? codesPrix.map((codePrix: any, index: number) => {
                const isSelected = codePrixSelected && codePrix.id === codePrixSelected.id;
                return (
                  <tr
                    key={index}
                    tabIndex={0}
                    className={isSelected ? 'bordered selected bold' : ''}
                    onClick={() => onSelectCodePrix(codePrix)}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        onSelectCodePrix(codePrix);
                      }
                    }}
                  >
                    {codePrixSelected ? (
                      isSelected ? (
                        <td className="fitContent b-green t-white">
                          <span className="material-icons t-medium">edit</span>
                        </td>
                      ) : (
                        <td className="fitContent" />
                      )
                    ) : null}
                    <td className="bold">
                      {isSelected ? (
                        <TextInput
                          value={codePrixSelected.code}
                          name={'code'}
                          onChange={(e: any) =>
                            onUpdateSelectedCodePrix({
                              ...codePrixSelected,
                              ...e,
                            })
                          }
                          isEditable={true}
                          isAutofocus={true}
                          labelHide={true}
                          onPressEnter={() => onFetchCodePrix(codePrixSelected)}
                        />
                      ) : (
                        codePrix.code
                      )}
                    </td>
                    {formes.map((forme: any, index) => (
                      <td key={index}>
                        {isSelected ? (
                          <NumberInput
                            name={forme.id}
                            value={codePrixSelected.forme_prix[forme.id]}
                            onChange={(e: any) => {
                              onUpdateSelectedCodePrix({
                                ...codePrixSelected,
                                forme_prix: {
                                  ...codePrixSelected.forme_prix,
                                  ...e,
                                },
                              });
                            }}
                            decimals={3}
                            onPressEnter={() => onFetchCodePrix(codePrixSelected)}
                          />
                        ) : codePrix.forme_prix[forme.id] ? (
                          codePrix.forme_prix[forme.id]
                        ) : (
                          '--'
                        )}
                      </td>
                    ))}
                    {codePrixSelected ? (
                      codePrixSelected.id === codePrix.id ? (
                        <td>
                          <div className="row">
                            <button className="button-validate" onClick={() => onFetchCodePrix(codePrixSelected)}>
                              <span className="material-icons">done</span>
                            </button>
                            <button className="button-error" onClick={() => onUpdateSelectedCodePrix(null)}>
                              <span className="material-icons">close</span>
                            </button>
                            <button className="deleteButton" onClick={() => onDeleteCodePrix(codePrixSelected.id)}>
                              <span className="material-icons">delete_outline</span>
                            </button>
                          </div>
                        </td>
                      ) : (
                        <td />
                      )
                    ) : null}
                  </tr>
                );
              })
            : null}
          {codePrixSelected && !codePrixSelected.id ? (
            <tr className="bordered">
              <td className="fitContent b-green t-white">
                <span className="material-icons t-medium">edit</span>
              </td>
              <td>
                <TextInput
                  value={codePrixSelected.code}
                  name={'code'}
                  onChange={(e: any) => onUpdateSelectedCodePrix({ ...codePrixSelected, ...e })}
                  isEditable={true}
                  isAutofocus={true}
                  labelHide={true}
                  onPressEnter={() => onFetchCodePrix(codePrixSelected)}
                />
              </td>
              {formes.map((forme: any, index) => (
                <td key={index}>
                  <NumberInput
                    name={forme.id}
                    value={codePrixSelected.forme_prix[forme.id]}
                    onChange={(e: any) => {
                      onUpdateSelectedCodePrix({
                        ...codePrixSelected,
                        forme_prix: {
                          ...codePrixSelected.forme_prix,
                          ...e,
                        },
                      });
                    }}
                    decimals={3}
                    onPressEnter={() => onFetchCodePrix(codePrixSelected)}
                  />
                </td>
              ))}
              <td>
                <div className="row">
                  <button className="button-validate" onClick={() => onFetchCodePrix(codePrixSelected)}>
                    <span className="material-icons">done</span>
                  </button>
                  <button className="button-error" onClick={() => onUpdateSelectedCodePrix(null)}>
                    <span className="material-icons">remove</span>
                  </button>
                </div>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </>
  );
};

const useCodePrixCrud = () => {
  const { query } = useContext(ServiceContext);
  const [codesPrix, setCodePrix] = useState(null as null | any[]);
  const [formes, setFormes] = useState(null as null | any[]);
  const [isLoading, setIsLoading] = useState(false);
  const [codePrixSelected, setCodePrixSelected] = useState(null as null | any);

  const fillCodesPrix = () => {
    setIsLoading(true);
    combineLatest(query(getAllCodePrix()), query(getAllForme())).subscribe(
      ([codesPrix, formes]: any) => {
        codesPrix.forEach((codePrix: any) => {
          const forme_prix: any = {};
          codePrix.forme_prix.map((forme: any) => {
            forme_prix[forme.forme.id] = forme.prix;
          });
          codePrix.forme_prix = forme_prix;
        });
        setCodePrix(codesPrix);
        setFormes(formes);
        setIsLoading(false);
        setCodePrixSelected(null);
      },
      () => setIsLoading(false),
    );
  };

  const fetchCodePrix = (codePrix: any) => {
    const code = {
      ...codePrix,
      forme_prix: Object.keys(codePrix.forme_prix).map((value: any) => {
        return { forme: value, prix: codePrix.forme_prix[value] };
      }),
    };

    query(codePrixService.fetchCodePrix(code), {
      notify: code.id ? 'Le code prix a été modifié.' : 'Le code prix a été créé.',
    }).subscribe(
      () => fillCodesPrix(),
      (err) => console.log(err),
    );
  };

  const deleteCodePrix = (id: string) => {
    query(codePrixService.deleteCodePrix(id), {
      notify: 'Le code prix a été supprimé.',
    }).subscribe(
      () => fillCodesPrix(),
      (err) => console.log(err),
    );
  };

  useEffect(() => {
    fillCodesPrix();
  }, []);

  return {
    codesPrix,
    codePrixSelected,
    setCodePrixSelected,
    fetchCodePrix,
    deleteCodePrix,
    formes,
    isLoading,
  };
};
