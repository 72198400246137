import * as qs from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ServiceContext } from '../../_core/services/service.context';
import { SpinLoader } from '../../_shared/component/loader/loader';
import { SearchBar } from '../../_shared/component/searchBar/searchBar';
import useEscape from '../../_shared/component/useEscape';
import { Client } from '../../_shared/models/client';
import useClients from '../../_shared/providers/client/useClients';
import { useOrderById } from '../../_shared/providers/order/useOrderById';
import { clientService } from '../../_shared/services/client.service';
import './clients.page.css';
import { ClientsList } from './clientsList/clientsList';

export const ClientsPage: React.FC<any> = () => {
  const { order, setOrderId } = useOrderById();
  const [isFilled, setIsFilled] = useState(false);

  const { searchField, clients, isLoading, setSearchField } = useClients(isFilled);

  const [state, setState] = useState({
    client: null as null | Client,
    adresse: null as null | number,
  });

  const setClient = (client: Client | null, adresse: number | null) => {
    setState({ client, adresse });
  };

  const { query } = useContext(ServiceContext);
  const location = useLocation();
  const history = useHistory();

  const initParams = () => {
    const params = qs.parse(location.search);
    if (params.searchField) setSearchField(params.searchField as string);
    if (params.cid) {
      query(clientService.getClientById(params.cid as string)).subscribe(
        (client: Client) => {
          setClient(
            client,
            client.adresses.findIndex((a) => a.default),
          );
        },
        (err) => console.log(err),
      );
    }
  };

  const updateParams = () => {
    const params: any = {};
    if (state.client) params.cid = state.client.id;
    if (searchField) params.searchField = searchField;
    history.replace({
      pathname: location.pathname,
      search: qs.stringify(params),
    });
  };

  useEffect(initParams, []);
  useEffect(updateParams, [state.client, searchField]);

  useEffect(() => {
    if (state.client) setOrderId(undefined);
  }, [state.client]);

  useEscape(() => {
    order ? setOrderId(undefined) : setClient(null, null);
  });

  const redirectTo = (clientId: string) => history.push(`/clients/${clientId}`);

  return (
    <div className="page" style={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}>
      <div className="crud-header row gapped">
        <div className="title">
          <span className="material-icons">person</span>
          <span>CLIENTS</span>
        </div>
        <SearchBar
          autofocus
          filter={searchField || ''}
          placeholder={'Rechercher un client'}
          onChange={setSearchField}
          onFilterRemove={() => setSearchField(null)}
        />
        <button className="button-validate transparent " onClick={() => redirectTo('new')}>
          <i className="material-icons">add</i>
          Nouveau Client
        </button>
        <button className="button-lgrey bordered" onClick={() => setIsFilled(!isFilled)}>
          <i className="material-icons">view_headline</i>
          {isFilled ? 'Masquer tout les clients' : 'Afficher tout les clients'}
        </button>
      </div>
      <div className="align full-height p-relative">
        <div className="full-height full-width row relative">
          {isLoading ? (
            <div className="row center p-medium full-width">
              <SpinLoader size={'8em'} thickness={'4px'} color={'dimgrey'} />
            </div>
          ) : clients && clients.length ? (
            <ClientsList
              isLoading={isLoading}
              clients={clients}
              handleOrder={() => ''}
              handleSelectClient={(client: Client) => redirectTo(client.id as string)}
            />
          ) : (
            <div className="full-height full-width row center">
              {searchField && searchField.length ? (
                'Aucun client trouvé'
              ) : (
                <div className="row">
                  <span className="material-icons t-xlarge">search</span>
                  Veuillez renseigner un champ de recherche
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
