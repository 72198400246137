import useCompositionOnEdition from './useCompositionOnEdition';

export const useUpdateIngredient = (ingredientState: any[]) => {
  const { updateComposition } = useCompositionOnEdition();

  return (update: any, index: number) => {
    ingredientState[index] = { ...ingredientState[index], ...update };
    updateComposition({ ingredients: ingredientState });
  };
};
