import {
  BROUILLON_STATUS,
  DEPOT_STATUS,
  DEVIS_STATUS,
  ENVOIE_STATUS,
  EXPEDIEE_STATUS,
  VALIDEE_STATUS,
} from '../../../../../_shared/config/constants';
import React from 'react';
import { SetToEnvoieButton } from '../../../orderPage/orderPanel/setToEnvoie.button';
import { SetToExpedieeButton } from '../../../orderPage/orderPanel/setToExpediee.button';
import { PrintOrderButton } from '../../../orderPage/orderPanel/printOrder.button';
import { SetToArchiveButton } from '../../../orderPage/orderPanel/setToArchive.button';

interface IOrderButtonPanelProps {
  editStatus: string;
  updateStatus: any;
  onShipping: any;
  printOrder: any;
  disabled: boolean;
}

const renderOrderButton = ({ editStatus, updateStatus, onShipping, printOrder, disabled }: IOrderButtonPanelProps) => {
  switch (editStatus) {
    case BROUILLON_STATUS:
      return <SetToArchiveButton disabled={disabled} onSetToArchive={updateStatus} minified={true} />;
    case VALIDEE_STATUS:
      return (
        <>
          <SetToEnvoieButton disabled={disabled} minified={true} onSetToEnvoie={() => updateStatus(ENVOIE_STATUS)} />
          <PrintOrderButton
            disabled={disabled}
            minified={true}
            onPrintOrder={() => {
              printOrder();
            }}
          />
        </>
      );
    case ENVOIE_STATUS:
      return (
        <>
          {/*<SetToExpedieeButton disabled={disabled} minified={true} onSetToExpediee={() => onShipping()} />*/}
          <PrintOrderButton
            disabled={disabled}
            minified={true}
            onPrintOrder={() => {
              printOrder();
            }}
          />
        </>
      );
    case EXPEDIEE_STATUS:
      return (
        <>
          <PrintOrderButton
            disabled={disabled}
            minified={true}
            onPrintOrder={() => {
              printOrder();
            }}
          />
        </>
      );
    case DEPOT_STATUS:
      return (
        <>
          <PrintOrderButton
            disabled={disabled}
            minified={true}
            onPrintOrder={() => {
              printOrder();
            }}
          />
        </>
      );
    case DEVIS_STATUS:
      return (
        <>
          <PrintOrderButton
            disabled={disabled}
            minified={true}
            onPrintOrder={() => {
              printOrder();
            }}
          />
        </>
      );
  }
};

export const DraftActionsPanel: React.FC<IOrderButtonPanelProps> = (props: IOrderButtonPanelProps) => {
  return <div className="row gapped">{renderOrderButton(props)}</div>;
};
