import React, { useState } from 'react';
import { IngredientListSuggester } from '../compositionIngredient.list';
import TextInput from '../../../../../../_shared/component/textInput/textInput.component';
import PickerTypePanel from '../pickerTypePanel/pickerType.panel';
import EmptyRows from '../EmptyRows/empty.rows';
import Row from '../../../../../../_shared/component/row/row';

interface IIngredientPickerProps {
  pattern: any[];
  formeId: string;
  ingredientsId: any;
  onAddIngredient: any;
  onPickerTypeChange: any;
}
const IngredientPicker: React.FC<IIngredientPickerProps> = (props: IIngredientPickerProps) => {
  const [suggestion, setSuggestion] = useState(null as any | null);

  return (
    <>
      <tr className="suggestion-row bordered">
        <td className="t-grey br-top br-bottom ">
          <i className="material-icons t-large p-small t-green">spa</i>
        </td>
        <td colSpan={2} className="b-white">
          {suggestion ? (
            <Row align="end">
              <b>{suggestion.code}</b>
              &nbsp; {suggestion.pinyin}
              <button onClick={() => setSuggestion(null)}>
                <span className="material-icons t-grey">close</span>
              </button>
            </Row>
          ) : (
            <IngredientListSuggester
              formeId={props.formeId}
              ingredients={props.ingredientsId}
              onSelect={(e: any) => {
                setSuggestion(e);
              }}
              onCancel={() => {
                setSuggestion(null);
              }}
            />
          )}
        </td>
        <td className={'b-white'}>
          {suggestion ? (
            <TextInput
              onChange={(e: any) => {
                setSuggestion({ ...suggestion, ...e });
              }}
              name={'proportion'}
              number={true}
              decimals={2}
              labelHide={true}
              className={'full-width'}
              isEditable={!!suggestion}
              isAutofocus={true}
              onPressEnter={() => {
                props.onAddIngredient(suggestion);
                setSuggestion(null);
              }}
              value={suggestion ? suggestion.proportion : undefined}
            />
          ) : (
            ''
          )}
        </td>
        <td className="search-row" colSpan={props.pattern.length + 4}>
          <PickerTypePanel pickerType={'ingredient'} onPickerTypeChange={props.onPickerTypeChange} />
        </td>
      </tr>
      <EmptyRows
        rowsNumber={35 - props.ingredientsId.length}
        startIndex={props.ingredientsId.length}
        pattern={props.pattern}
      />
    </>
  );
};

export default IngredientPicker;
