import React from 'react';
import { NON_PAYEE_STATUS } from '../../../../_shared/config/constants';

interface ICancelPaiementButtonProps {
  onSetToNonPayee: any;
  minified?: boolean;
  disabled?: boolean;
}

export const CancelPaiementButton: React.FC<ICancelPaiementButtonProps> = (props: ICancelPaiementButtonProps) => {
  return (
    <div>
      <button
        className={props.minified ? 'button-alert minified' : 'button-alert'}
        disabled={props.disabled}
        onClick={() => props.onSetToNonPayee(NON_PAYEE_STATUS)}
      >
        <i className="material-icons">euro_symbol</i>
        {props.minified ? '' : 'Annuler le paiement'}
      </button>
    </div>
  );
};
