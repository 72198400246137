import { VALIDEE_STATUS } from './constants';

export const ON_EDITION_PATH = '/nouvelleCommande';
export const DRAFTS_PATH = '/enCours';
export const ORDERS_PATH = '/commandes';
export const CLIENTS_PATH = '/clients';
export const PRACTITIONERS_PATH = '/praticiens';
export const INGREDIENTS_PATH = '/ingredients';
export const PRICES_PATH = '/prix';
export const SCAN_PATH = '/scanner';
export const STATS_PATH = '/stats';

export const DEFAULT_PATH = `${DRAFTS_PATH}?status=${VALIDEE_STATUS}`;
