import { Composition } from '../models/composition';
import { GE_FLACON_MAX, MO_FLACON_MAX, PC_FLACON_MAX, WEIGHT_DECIMALS } from './constants';

const roundPoids = (poids: number) => {
  const factor = 10 * WEIGHT_DECIMALS;
  return Math.round((poids + Number.EPSILON) * factor) / factor;
};

const computeIngredient = (ingredient: any, total_proportion: number, total_poids: number) => {
  ingredient.percent = ingredient.proportion / total_proportion;
  const poids = total_poids * ingredient.percent;
  ingredient.poids = roundPoids(poids);
  ingredient.prix_reel = poids * ingredient.prix;
};

const standard = (flaconMax: number, currentComposition: Composition, composition: Composition) => {
  if (!composition) return null;

  composition.isPoidsSet = !currentComposition
    ? composition.poids.toFixed(2) !==
      composition.ingredients.reduce((acc: number, ing: any) => acc + ing.proportion, 0).toFixed(2)
    : Number(composition.poids) > 0 &&
      (composition.isPoidsSet ||
        (Number(currentComposition.poids) !== Number(composition.poids) && !composition.isPoidsSet));

  composition.isContenantSet = !currentComposition
    ? composition.contenant !== Math.ceil(Number(parseFloat(composition.poids.toString()).toFixed(2)) / flaconMax)
    : Number(composition.contenant) > 0 &&
      (composition.isContenantSet ||
        (Number(currentComposition.contenant) !== Number(composition.contenant) && !composition.isContenantSet));

  if (composition && !composition.isPoidsSet) {
    composition.poids = roundPoids(
      composition.ingredients.reduce((curr: number, ing: any) => Number(curr) + Number(ing.proportion), 0),
    );
  }

  if (composition && !composition.isContenantSet) {
    composition.contenant = Math.ceil(Number(parseFloat(composition.poids.toString()).toFixed(2)) / flaconMax);
  }

  const total_proportion = composition.ingredients.reduce(
    (curr: number, ing: any) => Number(curr) + Number(ing.proportion),
    0,
  );

  composition.proportionTotal = total_proportion;

  composition.ingredients.forEach((ingredient: any) => {
    computeIngredient(ingredient, total_proportion, composition.poids);
  });

  composition.prix = composition.ingredients.reduce((curr: number, ing: any) => curr + ing.prix_reel, 0);

  composition.valid =
    composition.prix > 0 &&
    composition.poids > 0 &&
    composition.contenant > 0 &&
    composition.ingredients.length > 0 &&
    composition.ingredients.filter((i: any) => i.proportion <= 0).length <= 0 &&
    // case unrecognized ingredient
    composition.ingredients.filter((i: any) => !i.pinyin).length <= 0;

  return composition;
};

const double = (currentComposition: Composition, composition: Composition) => {
  if (!composition) return null;

  if (!currentComposition) {
    composition.isPoidsSet =
      composition.poids.toFixed(2) !==
      composition.ingredients.reduce((acc: number, ing: any) => acc + ing.proportion, 0).toFixed(2);

    composition.isContenantSet =
      composition.contenant > 0 &&
      composition.contenant !==
        Math.ceil(Number(parseFloat(composition.poids.toString()).toFixed(2)) / Number(composition.poids_contenant));

    composition.isPoidsContenantSet =
      !!composition.poids_contenant &&
      Number(composition.poids) / Number(composition.contenant) !== composition.poids_contenant;
  } else {
    composition.isPoidsSet =
      Number(composition.poids) > 0 &&
      (composition.isPoidsSet ||
        (Number(currentComposition.poids) !== Number(composition.poids) && !composition.isPoidsSet));

    composition.isContenantSet =
      Number(composition.contenant) > 0 &&
      (composition.isContenantSet ||
        (Number(currentComposition.contenant) !== Number(composition.contenant) && !composition.isContenantSet));

    composition.isPoidsContenantSet =
      Number(composition.poids_contenant) > 0 &&
      (composition.isPoidsContenantSet || currentComposition.poids_contenant !== Number(composition.poids_contenant));
  }

  if (!composition.isPoidsContenantSet) {
    if (!composition.isPoidsSet) {
      composition.poids = roundPoids(
        composition.ingredients.reduce((acc: number, ing: any) => acc + ing.proportion, 0),
      );
    }
    if (!composition.isContenantSet) {
      composition.contenant = Math.ceil(Number(composition.poids) / Number(composition.poids_contenant)) || 0;
    }
    composition.poids_contenant = roundPoids(Number(composition.poids) / Number(composition.contenant)) || 0;
  } else {
    if (!composition.isPoidsSet) {
      composition.poids = !composition.isContenantSet
        ? roundPoids(composition.ingredients.reduce((acc: number, ing: any) => acc + ing.proportion, 0))
        : roundPoids(composition.contenant * composition.poids_contenant);
    } else if (composition.isContenantSet) {
      composition.isContenantSet = false;
    }
    composition.contenant =
      Math.ceil(Number(parseFloat(composition.poids.toString()).toFixed(2)) / Number(composition.poids_contenant)) || 0;
  }

  const total_proportion = composition.ingredients.reduce((curr: number, i: any) => curr + Number(i.proportion), 0);

  composition.ingredients.forEach((ingredient: any) => {
    computeIngredient(ingredient, total_proportion, composition.poids);
  });

  composition.proportionTotal = total_proportion;

  composition.prix = composition.ingredients.reduce((curr: number, ing: any) => curr + ing.prix_reel, 0);

  composition.valid =
    composition.prix &&
    composition.poids &&
    composition.poids_contenant &&
    composition.contenant &&
    composition.ingredients.length &&
    composition.ingredients.filter((i: any) => i.proportion <= 0).length <= 0 &&
    // case unrecognized ingredient
    composition.ingredients.filter((i: any) => !i.pinyin).length <= 0;

  return composition;
};

export const FORMES_PIPES = (forme: string, currentComposition: Composition, composition: Composition) => {
  switch (forme) {
    case 'PC':
      return standard(PC_FLACON_MAX, currentComposition, composition);
    case 'MO':
      return standard(MO_FLACON_MAX, currentComposition, composition);
    case 'GE':
      return standard(GE_FLACON_MAX, currentComposition, composition);
    case 'IF':
      return double(currentComposition, composition);
    case 'CC':
      return double(currentComposition, composition);
    case 'VR':
      return double(currentComposition, composition);
    case 'VR8':
      return double(currentComposition, composition);
    case 'LB':
      composition.ingredients.map((ingredient: any) => {
        ingredient.prix_reel = Number(ingredient.proportion) * Number(ingredient.prix);
      });

      const totalProportion = composition.ingredients.reduce((curr: number, i: any) => curr + Number(i.proportion), 0);
      composition.totalProportion = totalProportion;
      composition.poids = totalProportion;

      composition.prix = composition.ingredients.reduce((curr: number, ing: any) => curr + ing.prix_reel, 0);

      composition.valid =
        composition.ingredients.length > 0 &&
        composition.ingredients.filter((i: any) => i.proportion <= 0 && i.contenant <= 0).length <= 0;
      return composition;
    case 'DV':
      if (!composition) return null;

      const total_proportion = composition.ingredients.reduce((curr: number, i: any) => curr + Number(i.proportion), 0);

      composition.ingredients.map((ingredient: any) => {
        ingredient.percent = Math.round(((100 * Number(ingredient.proportion)) / total_proportion) * 100) / 100;
        ingredient.poids = Math.round(Number(composition.poids) * Number(ingredient.percent)) / 100;
        ingredient.prix_reel = Math.round(ingredient.poids * ingredient.prix * 100) / 100;
      });

      composition.prix =
        Math.round(
          composition.ingredients.reduce((curr: number, ing: any) => curr + ing.prix_reel, 0) *
            composition.contenant *
            100,
        ) / 100;
      return composition;
    default:
      throw new Error('Forme code does not exist');
  }
};
