import React from 'react';
import { VALIDEE_STATUS } from '../../../../_shared/config/constants';

interface ISetToValideeButtonProps {
  onSetToValidee: any;
  minified?: boolean;
  disabled?: boolean;
}

export const SetToValideeButton: React.FC<ISetToValideeButtonProps> = (props: ISetToValideeButtonProps) => {
  return (
    <div>
      <button
        className={props.minified ? 'button-validate minified' : 'button-validate'}
        disabled={props.disabled}
        onClick={() => props.onSetToValidee(VALIDEE_STATUS)}
      >
        <i className="material-icons">done</i>
        {props.minified ? '' : 'Valider la commande'}
      </button>
    </div>
  );
};
