import React from 'react';

enum rowPosition {
  'start' = 'flex-start',
  'center' = 'center',
  'end' = 'flex-end',
  'spaced' = 'space-between',
}

interface RowProps {
  className?: string;
  align?: 'start' | 'center' | 'end' | 'spaced';
  gapped?: boolean;
  reference?: any;
  onClick?: any;
  children: any;
}

const Row: React.FC<RowProps> = (props: RowProps) => {
  return (
    <div
      ref={props.reference}
      className={`${props.className || ''} row-component`}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: rowPosition[props.align || 'start'],
      }}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

export default Row;
