import { useContext } from 'react';
import { ServiceContext } from '../../_core/services/service.context';

const useCopyInClipboard = () => {
  const { pushAlert } = useContext(ServiceContext);

  const copy = (value: string, callback?: any) => {
    if (typeof value === 'string') {
      navigator.clipboard.writeText(value);
      pushAlert({ type: 'info', message: 'Copié dans le presse-papier' });
      if (callback) callback(value);
    }
  };
  return copy;
};

export default useCopyInClipboard;
