import * as React from 'react';
import { Observable } from 'rxjs';
import { useContext } from 'react';

export interface Alert {
  message: string;
  type: 'error' | 'valid' | 'info';
  component?: any;
  permanent?: boolean;
}

interface IServiceContext {
  query: (query: Observable<any>, options?: Partial<QueryOptions>) => Observable<any>;
  pushAlert: (alert: Alert) => void;
}

const ServiceContext = React.createContext<IServiceContext>({
  query: () => new Observable(),
  pushAlert: () => {},
});

interface QueryOptions {
  notify: string;
  onError: string;
  noAlert: boolean;
}

const useQuery = () => {
  const { query } = useContext(ServiceContext);

  return query;
};

export { ServiceContext, useQuery };
