import { Composition } from '../../models/composition';
import { Practitioner } from '../../models/practitioner';
import { Client } from '../../models/client';
import { Paiement } from './draftEditor.provider';

export const INIT_DRAFT = 'INIT_DRAFT';
export const SET_COMPOSITION_SELECTED_AS_NEW = 'SET_COMPOSITION_SELECTED_AS_NEW';
export const SET_COMPOSITION_SELECTED = 'SET_COMPOSITION_SELECTED';
export const UPDATE_COMPOSITION_SELECTED = 'UPDATE_COMPOSITION_SELECTED';
export const UPDATE_COMPOSITION_FORME = 'UPDATE_COMPOSITION_FORME';
export const DELETE_COMPOSITION_SELECTED = 'DELETE_COMPOSITION_SELECTED';
export const SET_COMMENTAIRE = 'SET_COMMENTAIRE';
export const SET_COMMENTAIRE_PAIEMENT = 'SET_COMMENTAIRE_PAIEMENT';
export const SET_CLIENT = 'SET_CLIENT';
export const SET_CLIENT_WARNING = 'SET_CLIENT_WARNING';
export const SET_ADRESSE_SELECTED = 'SET_ADRESSE_SELECTED';
export const SET_PRACTITIONER = 'SET_PRACTITIONER';
export const SET_PICKER_TYPE = 'SET_PICKER_TYPE';
export const SET_SELECTED = 'SET_SELECTED';
export const SET_FRAIS_PORT = 'SET_FRAIS_PORT';
export const SET_SHIPMENT_TYPE = 'SET_SHIPMENT_TYPE';
export const SET_PAIEMENT = 'SET_PAIEMENT';
export const SET_LAST_SAVE = 'SET_LAST_SAVE';

export const initDraft = (draft: any) => {
  return {
    type: INIT_DRAFT,
    draft,
  };
};

export const setCompositionSelectedAsNew = (forme: any) => {
  return {
    type: SET_COMPOSITION_SELECTED_AS_NEW,
    forme,
  };
};

export const setCompositionSelected = (index: number) => {
  return {
    type: SET_COMPOSITION_SELECTED,
    index,
  };
};

export const updateCompositionSelected = (updates: Partial<Composition>) => {
  return {
    type: UPDATE_COMPOSITION_SELECTED,
    updates,
  };
};

export const updateCompositionForme = (forme: any) => {
  return {
    type: UPDATE_COMPOSITION_FORME,
    forme,
  };
};

export const deleteCompositionSelected = () => {
  return {
    type: DELETE_COMPOSITION_SELECTED,
  };
};

export const setCommentaire = (commentaire: any) => {
  return {
    type: SET_COMMENTAIRE,
    commentaire,
  };
};

export const setCommentairePaiement = (commentairePaiement: string) => ({
  type: SET_COMMENTAIRE_PAIEMENT,
  commentairePaiement,
});

export const setClient = (client: Client | null, adresseSelected: number | undefined = undefined) => {
  return {
    type: SET_CLIENT,
    client,
    adresseSelected,
  };
};

export const setClientWarning = (clientWarning: boolean) => {
  return {
    type: SET_CLIENT_WARNING,
    clientWarning,
  };
};

export const setAdresseSelected = (adresseSelected: number) => {
  return {
    type: SET_ADRESSE_SELECTED,
    adresseSelected,
  };
};

export const setPractitioner = (practitioner: Practitioner | null) => {
  return {
    type: SET_PRACTITIONER,
    practitioner,
  };
};

export const setPickerType = (pickerType: string) => {
  return {
    type: SET_PICKER_TYPE,
    pickerType,
  };
};

export const setSelected = (selected: 'client' | 'practitioner' | null) => {
  return {
    type: SET_SELECTED,
    selected,
  };
};

export const setFraisPort = (fraisPort: number) => {
  return {
    type: SET_FRAIS_PORT,
    fraisPort,
  };
};

export const setShipmentType = (shipmentType: string) => {
  return {
    type: SET_SHIPMENT_TYPE,
    shipmentType,
  };
};

export const setPaiement = (paiement?: Paiement) => {
  return {
    type: SET_PAIEMENT,
    paiement,
  };
};

export const setLastSave = (lastSave: Date) => {
  return {
    type: SET_LAST_SAVE,
    lastSave,
  };
};
