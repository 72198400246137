import React from 'react';

interface ICopyOrderButtonProps {
  onCopyOrder: any;
  minified?: boolean;
  disabled?: boolean;
}

export const CopyOrderButton: React.FC<ICopyOrderButtonProps> = (props: ICopyOrderButtonProps) => {
  return (
    <div>
      <button
        className={props.minified ? 'button-lgrey minified' : 'row gapped button-lgrey light'}
        disabled={props.disabled}
        onClick={() => props.onCopyOrder()}
      >
        <i className="material-icons">content_copy</i>
        <div>{props.minified ? '' : 'Renouveler'}</div>
      </button>
    </div>
  );
};
