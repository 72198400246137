import React, { useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import useDebounce from '../../../_shared/component/useDebounce';
import useSave from '../../../_shared/providers/order/useSave';

import { DraftEditorContext } from '../../../_shared/providers/draft/draftEditor.provider';
import { ServiceContext } from '../../../_core/services/service.context';
import { orderService } from '../../../_shared/services/order.service';

import { CompositionEditorProvider } from '../../../_shared/providers/draft/compositionEditor.provider';

import { CompositionEditor } from './components/compositionEditor/composition.editor';
import { DraftInfoPanel } from './components/draftInfos/draftInfo.panel';

import { initDraft } from '../../../_shared/providers/draft/draftEditor.actions';
import { BROUILLON_STATUS } from '../../../_shared/config/constants';

import './draftEditor.page.scss';
import { DraftsStatusPanel } from '../draftsPage/draftFilterPanel/draftsStatus.panel';
import { DRAFTS_PATH } from '../../../_shared/config/paths';

const useDraftById = (id: string | null) => {
  const history = useHistory();
  const { dispatch } = useContext(DraftEditorContext);
  const { query } = useContext(ServiceContext);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!id) {
      setIsLoading(true);
      query(orderService.createNewDraft(), {
        notify: 'Nouveau brouillon créé',
      })
        .subscribe(
          (state: any) => history.replace(`/nouvelleCommande/${state.id}`),
          () => history.goBack(),
        )
        .add(() => setIsLoading(false));
    } else {
      query(orderService.getDraftById(id), {
        onError: 'Commande en cours non trouvée',
      })
        .subscribe(
          (draft: any) => {
            if (draft.editStatus !== BROUILLON_STATUS) {
              throw new Error("La commande n'est pas en status brouillon");
            } else {
              dispatch(initDraft(draft));
            }
          },
          () => history.goBack(),
        )
        .add(() => setIsLoading(false));
    }
  }, []);

  return { isLoading };
};

const useAutoSave = () => {
  const { save } = useSave();
  const {
    compositions,
    client,
    practitioner,
    commentaire,
    commentairePaiement,
    adresseSelected,
    fraisPort,
    paiement,
    shipment,
  } = useContext(DraftEditorContext);

  const updated = [client, practitioner, adresseSelected, shipment, paiement];
  const debounced = useDebounce([compositions, commentaire, fraisPort, commentairePaiement], 500);

  useEffect(save, debounced);
  useEffect(save, updated);
};

export const DraftEditorPage: React.FC<any> = ({ match }: any) => {
  useAutoSave();
  const { isLoading } = useDraftById(match.params.id);
  const history = useHistory();
  return (
    <div className="orders-layout">
      <DraftsStatusPanel
        status={BROUILLON_STATUS}
        onHandleStatus={(status: string) => history.push(`${DRAFTS_PATH}?type=${status}`)}
      />
      <div className="draft-editor page b-grey">
        <DraftInfoPanel />
        <CompositionEditorProvider>
          <CompositionEditor />
        </CompositionEditorProvider>
      </div>
    </div>
  );
};
