import React from 'react';

import Row from '../../../../../../_shared/component/row/row';

import FormSelector from '../component/formSelector/form.selector';
import ProportionField from '../component/proportionField/proportion.field';
import DeleteCompositionButton from '../component/deleteCompositionButton/deleteComposition.button';
import DestinaireInput from '../component/destinataireInput/destinataire.input';
import LibelleInput from '../component/libelleInput/libelle.input';
import WeightPanel from '../component/weightPanel/weight.panel';
import CommentaireInput from '../component/commentaireInput/commentaire.input';

import './composition.header.css';
import useCompositionOnEdition from '../../../../../../_shared/providers/draft/useCompositionOnEdition';
import { IngredientPricePanel } from '../component/ingredientPricePanel/ingedientPrice.panel';

export const CompositionHeader: React.FC = () => {
  const { composition } = useCompositionOnEdition();
  return (
    <>
      <div className="composition-header br-bottom">
        <div className="p-spaced">
          <Row className="wrap full-width">
            <FormSelector />
            <ProportionField />
          </Row>
          {composition.forme.code !== 'LB' ? (
            <div className="row spaced">
              <Row>
                <span className="material-icons t-grey">donut_large</span>
                <div className="p-medium" />
                <WeightPanel />
              </Row>
              <IngredientPricePanel />
            </div>
          ) : null}
        </div>
        <DeleteCompositionButton />
      </div>
      <div className="p-medium">
        <Row className="p-spaced fit-height">
          <LibelleInput />
          <DestinaireInput />
        </Row>
        <CommentaireInput />
      </div>
    </>
  );
};
