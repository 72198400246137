import React from 'react';
import { TO_PRATICIEN } from '../../../../_shared/config/constants';

interface ISetToSendPraticienButtonProps {
  onSetToSendPraticien: any;
  minified?: boolean;
  disabled?: boolean;
}

export const SetToSendPraticienButton: React.FC<ISetToSendPraticienButtonProps> = (
  props: ISetToSendPraticienButtonProps,
) => {
  return (
    <div>
      <button
        className={props.minified ? 'button-onWait minified' : 'button-onWait'}
        disabled={props.disabled}
        onClick={() => props.onSetToSendPraticien(TO_PRATICIEN)}
      >
        <i className="material-icons">forward</i>
        {props.minified ? null : <span>Compte praticien</span>}
      </button>
    </div>
  );
};
