import React, { useEffect, useState } from 'react';
import { TextArea } from '../../../../_shared/component/textArea/textArea.component';

interface IOrderCommentProps {
  label?: string;
  placeholder?: string;
  commentaire: string;
  className?: string;
  lockable?: boolean;
  minRow?: number;
  onChange: (comment: string) => void;
}

export const OrderComment: React.FC<IOrderCommentProps> = (props: IOrderCommentProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [comment, setComment] = useState(props.commentaire || '');

  useEffect(() => {
    if (props.lockable) {
      setComment(props.commentaire);
      setIsEditing(false);
    }
  }, [props.commentaire]);

  const commentaire = props.lockable ? comment : props.commentaire;
  return (
    <div className="column">
      {commentaire || props.lockable ? (
        <div>
          {props.lockable ? null : (
            <div className="p-spaced">
              {props.label ? (
                <label className="upperCase">{props.label}</label>
              ) : (
                <label className="upperCase">Commentaire de la commande</label>
              )}
            </div>
          )}
          <div style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
            <TextArea
              value={commentaire}
              placeholder={props.placeholder}
              locked={(!props.lockable && typeof props.lockable !== 'undefined') || (props.lockable && !isEditing)}
              className="b-white bordered"
              minRows={props.minRow || 4}
              name={'commentaire'}
              onChange={(e: any) => (props.lockable ? setComment(e.commentaire) : props.onChange(e.commentaire))}
            />
            {props.lockable ? (
              <div className="column center gapped">
                {isEditing ? (
                  <>
                    <button
                      className="button-validate circle transparent"
                      disabled={comment === props.commentaire}
                      onClick={() => {
                        props.onChange(comment);
                      }}
                    >
                      <span className="material-icons">done</span>
                    </button>
                    <button
                      className="button-alert circle transparent"
                      onClick={() => {
                        setIsEditing(false);
                        setComment(props.commentaire || '');
                      }}
                    >
                      <span className="material-icons">close</span>
                    </button>
                  </>
                ) : (
                  <button onClick={() => setIsEditing(true)} className="button-lgrey circle">
                    <span className="material-icons">edit</span>
                  </button>
                )}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};
