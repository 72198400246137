import React from 'react';
import { R2_STATUS } from '../../../../_shared/config/constants';

interface IPrintR2ButtonProps {
  onPrintR2: any;
  minified: boolean;
  disabled?: boolean;
}

export const PrintR2Button: React.FC<IPrintR2ButtonProps> = (props: IPrintR2ButtonProps) => {
  return (
    <div className="row gapped">
      <button
        disabled={props.disabled}
        className={props.minified ? 'button-print minified' : 'button-print'}
        onClick={() => props.onPrintR2(R2_STATUS)}
      >
        <i className="material-icons">print</i>
        {props.minified ? '' : 'Imprimer relance 2'}
      </button>
      <div>{props.minified ? 'R2' : ''}</div>
    </div>
  );
};
