import React, { useContext, useEffect, useState } from 'react';
import { ServiceContext } from '../../../../../_core/services/service.context';
import { CrudButtonPanel } from '../../../../../_shared/component/crudButtonPanel/crudButtonPanel';
import { TUextInput } from '../../../../../_shared/component/textInput/textInput.component';
import useEscape from '../../../../../_shared/component/useEscape';
import { Client } from '../../../../../_shared/models/client';
import useClientOnEdition from '../../../../../_shared/providers/client/useClientOnEdition';
import useClients from '../../../../../_shared/providers/client/useClients';
import {
  initDraft,
  setAdresseSelected,
  setClient,
  setClientWarning,
  setSelected,
} from '../../../../../_shared/providers/draft/draftEditor.actions';
import { DraftEditorContext } from '../../../../../_shared/providers/draft/draftEditor.provider';
import { useOrderById } from '../../../../../_shared/providers/order/useOrderById';
import { orderService } from '../../../../../_shared/services/order.service';
import { CLientDraftsList } from '../../../../clients/clientDraftsHistory/clientDrafts.list';
import ClientForm from '../../../../clients/clientForm/client.form';
import { ClientOrderList } from '../../../../clients/clientOrderHistory/clientOrder.list';
import { OrderCard } from '../../../components/orderCard/order.card';
import './client.picker.scss';
import { ClientSuggestion } from './clientSuggestion/client.suggestion';
import { useDraftById } from '../../../../../_shared/providers/draft/useDraftById';

const useOrderCopy = () => {
  const { query } = useContext(ServiceContext);
  const { dispatch } = useContext(DraftEditorContext);

  const copyOrder = (orderId: string, draftId?: string | null) => {
    if (query) {
      query(orderService.copyOrder(orderId, draftId), {
        notify: 'Commande copiée',
      }).subscribe(
        (res: any) => {
          dispatch(initDraft(res));
        },
        (err) => console.log(err),
      );
    }
  };

  return copyOrder;
};

export const ClientPicker: React.FC<any> = () => {
  const [draftWarning, setDraftWarning] = useState<boolean>(false);
  const [orderWarning, setOrderWarning] = useState<boolean>(false);
  const [recentWarning, setRecentWarning] = useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);
  const { order, setOrderId } = useOrderById();
  const { draft, setDraftId } = useDraftById();

  const { client, adresseSelected, id, dispatch } = useContext(DraftEditorContext);

  const { clients, searchField, setSearchField } = useClients(false);

  const {
    clientOnEdition,
    adresseOnEdition,
    updateClientOnEdition,
    setClientOnEdition,
    setAdresseOnEdition,
    setAdresseOnEditionAsNew,
    fetchClientOnEdition,
    setClientOnEditionAsNew,
  } = useClientOnEdition();

  useEffect(() => {
    setOrderId(undefined);
    setDraftId(undefined);
    if (!client) {
      setOrderWarning(false);
      setDraftWarning(false);
      setRecentWarning(false);
    }
  }, [client]);

  const copyOrder = useOrderCopy();

  useEscape(() => (draftWarning || orderWarning ? setShowModal(true) : dispatch(setSelected(null))));

  const pickedClient = clientOnEdition || client;
  const clientAdresse = adresseOnEdition === undefined ? adresseSelected : adresseOnEdition;
  const isNew = pickedClient && !pickedClient.id;

  const handleAddressSelected = (address: number) => {
    if (adresseOnEdition !== undefined) {
      setAdresseOnEdition(address);
    } else {
      dispatch(setAdresseSelected(address));
    }
  };

  useEffect(() => {
    if (client) dispatch(setClientWarning(draftWarning || orderWarning || recentWarning));
  }, [draftWarning, orderWarning, recentWarning]);

  const pickedOrder = order || draft;

  return (
    <div className="picker relative">
      {showModal ? (
        <div className="absolute overlay column center full-height full-width relative">
          <div className="b-white rounded p-medium">
            <div className="t-large light">
              <div>
                <div className="center-align br-bottom">Attention</div>
                {draftWarning ? (
                  <div className="p-spaced row gapped">
                    <span className="material-icons t-orange">warning</span>
                    <span>Commandes en préparations</span>
                  </div>
                ) : null}
                {recentWarning ? (
                  <div className="p-spaced row gapped">
                    <span className="material-icons t-orange">warning</span>
                    <span>Commandes récentes</span>
                  </div>
                ) : null}
                {orderWarning ? (
                  <div className="p-spaced row gapped">
                    <span className="material-icons t-red">warning</span>
                    <span>Commandes en attente de paiement</span>
                  </div>
                ) : null}
              </div>
            </div>
            <button
              className="validate-button full-width"
              autoFocus={true}
              onClick={() => dispatch(setSelected(null))}
              data-cy="ClientPicker-modal-OK"
            >
              OK
            </button>
          </div>
        </div>
      ) : null}

      <div className={`client picker_container ${pickedClient && !isNew ? ' picked' : ''}`}>
        {!pickedClient ? (
          <div className="picker_header row spaced">
            <div className="picker_header_search b-white light">
              <TUextInput
                value={searchField}
                name={'searchField'}
                labelHide
                autofocus
                suffix={<span className="material-icons">search</span>}
                className="search-field"
                placeholder={'Rechercher un client'}
                onCancel={() => {
                  setSearchField('');
                }}
                onChange={(e: any) => setSearchField(e.searchField)}
                dataCy="ClientPicker-search"
              />
            </div>
            <button className={'t-large add-button'} type={'button'} onClick={setClientOnEditionAsNew}>
              <i className="material-icons">add</i>
            </button>
            <button className={'t-large close-button'} type={'button'} onClick={() => dispatch(setSelected(null))}>
              <i className="material-icons">close</i>
            </button>
          </div>
        ) : (
          <div className="row spaced full-height">
            <button
              type={'button'}
              className="return-button"
              onClick={() => {
                dispatch(setClient(null));
                setClientOnEdition(undefined, undefined);
              }}
            >
              <i className="material-icons">search</i>
              Retour à la recherche
            </button>
            <button className={'t-large close-button'} type={'button'} onClick={() => dispatch(setSelected(null))}>
              <i className="material-icons">close</i>
            </button>
          </div>
        )}
        {pickedClient ? (
          <div className="picker_content">
            {pickedOrder ? (
              <div className="order-selected">
                <div className="row medium spaced p-medium">
                  <button
                    className="returnButton b-white"
                    type={'button'}
                    onClick={() => {
                      setOrderId(undefined);
                      setDraftId(undefined);
                    }}
                  >
                    <i className="material-icons">chevron_left</i> Retour au client
                  </button>
                  <button className=" button-validate " onClick={() => copyOrder(order.id, id)}>
                    Copier la commande
                  </button>
                </div>
                <div className="scrollable-y">
                  <OrderCard order={pickedOrder} />
                </div>
              </div>
            ) : (
              <div className="client-selected">
                <div className="client-selected_content p-medium">
                  <div>
                    <div className="card">
                      <ClientForm
                        client={pickedClient}
                        adresseSelected={clientAdresse}
                        isOnEdition={!!clientOnEdition}
                        onChange={updateClientOnEdition}
                        onNewAddress={setAdresseOnEditionAsNew}
                        onAddressSelect={handleAddressSelected}
                      />
                      <div className="row p-small">
                        {!clientOnEdition ? (
                          <button
                            type={'button'}
                            className="button-validate"
                            autoFocus={!!pickedClient.id}
                            onClick={() =>
                              draftWarning || orderWarning || recentWarning
                                ? setShowModal(true)
                                : dispatch(setSelected(null))
                            }
                            data-cy="ClientPicker-Valider"
                          >
                            <span className="material-icons">done</span>
                            Valider
                          </button>
                        ) : null}
                        <CrudButtonPanel
                          isOnEdition={!!clientOnEdition}
                          onEdition={() => setClientOnEdition(client, adresseSelected)}
                          onValidate={() =>
                            fetchClientOnEdition((client: Client, address: number) => {
                              dispatch(setClient(client, address));
                              setClientOnEdition(undefined, undefined);
                            })
                          }
                          onCancelEdition={() => {
                            setClientOnEdition(undefined, undefined);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {pickedClient.id ? (
                    <div>
                      <div className="card">
                        <CLientDraftsList
                          clientId={pickedClient.id}
                          orderId={id}
                          onSelect={(id: string) => setDraftId(id)}
                          onWarning={setDraftWarning}
                        />
                      </div>
                      <div className="card">
                        <ClientOrderList
                          clientId={pickedClient.id}
                          onSelect={(id: string) => setOrderId(id)}
                          onWarning={setOrderWarning}
                          onWarningRecent={setRecentWarning}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="p-medium scrollable-y">
            <div className="column">
              <div>
                {clients
                  ? clients.map((client: Client, index: number) => (
                      <button
                        key={index}
                        className="selectable br-bottom p-medium fill-width l-align"
                        onClick={() => {
                          dispatch(setClient(client));
                        }}
                        data-cy={`ClientPicker-${client.prenom}-${client.nom}`}
                      >
                        <ClientSuggestion client={client} />
                      </button>
                    ))
                  : 'Aucun client trouvé'}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
