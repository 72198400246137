import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceContext } from '../../../../_core/services/service.context';
import { Client } from '../../../../_shared/models/client';
import { Practitioner } from '../../../../_shared/models/practitioner';
import { clientService } from '../../../../_shared/services/client.service';
import { practitionerService } from '../../../../_shared/services/practitioner.service';
import ClientSuggester from '../../../clients/clientSuggester/client.suggester';
import PractitionerSuggester from '../../../practitioner/practitionerSuggester/practitioner.suggester';
import NumberInput from '../../../../_shared/component/numberInput';
import DateInput from '../../../../_shared/component/dateInput/dateInput';
import moment from 'moment';
import TextSelector from '../../../../_shared/component/textSelector/textSelector';
import { SHIPMENT_TYPES } from '../../../../_shared/config/constants';
import { SearchBar } from '../../../../_shared/component/searchBar/searchBar';
import { formatToDateFilter } from '../../../../_shared/services/formatter.service';

interface IDraftsFiltersProps {
  filters: any;
  onFiltersChange: (filter: any) => void;
  onRemoveFilters: any;
}

const DraftsFilters: React.FC<IDraftsFiltersProps> = ({
  filters,
  onFiltersChange,
  onRemoveFilters,
}: IDraftsFiltersProps) => {
  const { query } = useContext(ServiceContext);
  const [clients, setClients] = useState<Client[]>([]);
  const [practitioners, setPractitioners] = useState<Practitioner[]>([]);
  const practInputRef = useRef<HTMLInputElement>();
  const clientInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (filters.cid) {
      query(clientService.getAllClients(filters.cid)).subscribe(
        (clients: Client[]) => {
          setClients(clients);
        },
        (err) => console.log(err),
      );
    }

    if (filters.pid) {
      query(practitionerService.getAllPractitioners(filters.pid)).subscribe(
        (practitioners: Practitioner[]) => setPractitioners(practitioners),
        (err) => console.log(err),
      );
    }
  }, []);

  useEffect(() => {
    setClients(clients.filter((c) => filters.cid && filters.cid.findIndex((cid: string) => cid === c.id) >= 0));
  }, [filters.cid]);

  useEffect(() => {
    setPractitioners(
      practitioners.filter((p) => filters.pid && filters.pid.findIndex((pid: string) => pid === p.id) >= 0),
    );
  }, [filters.pid]);

  const addClient = (client: Client) => {
    if (clients.findIndex((c) => c._id === client._id) < 0) {
      const updatedClients = [...clients, client];
      setClients(updatedClients);
      onFiltersChange({ cid: updatedClients.map((c) => c._id) });
      clientInputRef?.current?.focus();
    }
  };

  const removeClient = (index: number) => {
    const clis = [...clients];
    clis.splice(index, 1);
    setClients(clis);
    onFiltersChange({ cid: clis.map((p) => p.id) });
  };

  const addPractitioner = (practitioner: Practitioner) => {
    if (practitioners.findIndex((p) => p._id === practitioner._id) < 0) {
      const updatedPractitioners = [...practitioners, practitioner];
      setPractitioners(updatedPractitioners);
      onFiltersChange({ pid: updatedPractitioners.map((p) => p.id) });
      practInputRef?.current?.focus();
    }
  };

  const removePractitioner = (index: number) => {
    const pracs = [...practitioners];
    pracs.splice(index, 1);
    setPractitioners(pracs);
    onFiltersChange({ pid: pracs.map((p) => p.id) });
  };

  const now = formatToDateFilter(moment().clone());

  return (
    <div className="orders-filters row gapped b-lgrey p-spaced full-width flex-start">
      <div className="light t-grey row p-medium">
        <span className="material-icons">list</span>
        <span>FILTRES</span>
      </div>

      <div className="full-width">
        <div className="search-bar">
          <SearchBar
            filter={filters.code || ''}
            placeholder={'Rechercher par code'}
            onChange={(value: string) => onFiltersChange({ code: value })}
            onFilterRemove={() => onFiltersChange({ code: undefined })}
          />
        </div>
        <div className="row gapped wrap br-bottom ">
          <div className="row gapped p-medium">
            <label className={`row gapped ${filters.cid?.length ? 't-green' : ''}`}>
              <span className="material-icons t-large">person</span>
              <span className="no-wrap">Filtrer par client:</span>
            </label>
            <div className="row gapped wrap">
              {clients.map((client, index) => (
                <div key={'client-filter-' + index} className="row no-wrap ">
                  <div>
                    <b>{client.num}</b> {client.nom} {client.prenom}
                  </div>
                  <button className="delete-filters" onClick={() => removeClient(index)}>
                    <span className="material-icons t-grey">close</span>
                  </button>
                </div>
              ))}
              <ClientSuggester ref={clientInputRef} clear onClientSelect={addClient} />
            </div>
            {filters.cid?.length > 0 ? (
              <button className="circle-button" onClick={() => onFiltersChange({ cid: [] })}>
                <span className="material-icons">replay</span>
              </button>
            ) : null}
          </div>

          <div className="row gapped p-medium">
            <label className={`row gapped ${filters.pid?.length ? 't-green' : ''}`}>
              <span className="material-icons t-large">medical_services</span>
              <span>Filtrer par praticien(s):</span>
            </label>
            <div className="row gapped wrap">
              {practitioners.map((practitioner, index) => (
                <div key={'practitioner-filter-' + index} className="row">
                  <div>
                    <b>{practitioner.code}</b> {practitioner.nom} {practitioner.prenom}
                  </div>
                  <button className="delete-filters" onClick={() => removePractitioner(index)}>
                    <span className="material-icons t-grey">close</span>
                  </button>
                </div>
              ))}
              <PractitionerSuggester ref={practInputRef} clear onPractitionerSelect={addPractitioner} />
              {filters.pid?.length > 0 ? (
                <button className="circle-button" onClick={() => onFiltersChange({ pid: [] })}>
                  <span className="material-icons">replay</span>
                </button>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row gapped wrap p-medium">
          <div className="row gapped p-spaced">
            <NumberInput
              value={filters.prixMin}
              name={'prixMin'}
              label={'Prix min.'}
              size={10}
              placeholder="0"
              min={0}
              max={filters.prixMax}
              prefix={'€'}
              onChange={onFiltersChange}
              onCancel={() => onFiltersChange({ prixMin: undefined })}
            />
            <NumberInput
              value={filters.prixMax}
              name={'prixMax'}
              label={'Prix max.'}
              size={10}
              placeholder="0"
              min={filters.prixMin}
              className={filters.prixMax ? 'active' : ''}
              prefix={'€'}
              onChange={onFiltersChange}
              onCancel={() => onFiltersChange({ prixMax: undefined })}
            />
          </div>

          <div className="row gapped p-spaced">
            <DateInput
              value={filters.dateStart}
              label={'Date de création min.'}
              name={'dateStart'}
              onChange={onFiltersChange}
              className={filters.dateStart ? 'active' : ''}
              max={filters.dateEnd || now}
              onCancel={() => onFiltersChange({ dateStart: undefined })}
            />
            <DateInput
              value={filters.dateEnd}
              label={'Date de création max.'}
              name={'dateEnd'}
              onChange={onFiltersChange}
              className={filters.dateEnd ? 'active' : ''}
              min={filters.dateStart}
              max={now}
              onCancel={() => onFiltersChange({ dateEnd: undefined })}
            />
          </div>

          <div className="row gapped p-spaced">
            <TextSelector
              value={filters.shipment}
              name={'shipment'}
              isEditable={true}
              label={"Type d'envoie"}
              options={[undefined, ...SHIPMENT_TYPES]}
              onChange={onFiltersChange}
            />
          </div>
        </div>
      </div>
      <div className="p-medium">
        <button className="button-grey delete-filters" type={'button'} onClick={() => onRemoveFilters()}>
          <span className="material-icons large">replay</span>
        </button>
      </div>
    </div>
  );
};

export default DraftsFilters;
