import TextInput from '../../../../../../_shared/component/textInput/textInput.component';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceContext } from '../../../../../../_core/services/service.context';
import { useAddIngredients } from '../../../../../../_shared/providers/draft/useAddIngredients';
import { formuleService } from '../../../../../../_shared/services/formule.service';
import NumberInput from '../../../../../../_shared/component/numberInput';
import useOutsideClick from '../../../../../../_shared/component/useClickOutside';
import { useIngredientsReducerHook } from '../ingredients.reducer';
import useEscape from '../../../../../../_shared/component/useEscape';
import PickerTypePanel from '../pickerTypePanel/pickerType.panel';
import Row from '../../../../../../_shared/component/row/row';
import EmptyRows from '../EmptyRows/empty.rows';
import SuggestionRow from '../suggestionRow/suggestion.row';
import ParentComponent from '../../../../../../_shared/component/parentComponent/parent.component';

interface IFormulePickerProps {
  pattern: any;
  formeId: string;
  ingredientIds: any[];
  onPickerTypeChange: any;
}

const FormulePicker: React.FC<IFormulePickerProps> = (props: IFormulePickerProps) => {
  const [suggestionFilter, setSuggestionFilter] = useState('');
  const [lineSelected, setLineSelected] = useState(null as null | number);

  const { formule, formuleSuggestions, selectFormule, addFormule } = useFormuleSuggestions(
    suggestionFilter,
    props.formeId,
  );

  const {
    suggestions,
    poids,
    isPoidsSet,
    updateSuggestion,
    replaceSuggestion,
    deleteSuggestion,
    replaceSuggestions,
    setPoids,
  } = useIngredientsReducerHook();

  const suggestListRef = useRef(null);

  useOutsideClick(suggestListRef, () => setSuggestionFilter(''));
  useEscape(() => {
    setSuggestionFilter('');
    selectFormule(null, () => replaceSuggestions([]));
  });

  const poidsInputRef = useRef(null as null | any);

  const onSelectFormule = (formuleId: string | null) => {
    if (formuleId) {
      selectFormule(formuleId, (formule: any) => {
        replaceSuggestions(formule.ingredients);
        poidsInputRef.current.select();
      });
    } else {
      selectFormule(null, () => replaceSuggestions([]));
    }
  };

  const onResetFormule = () => {
    if (formule) {
      replaceSuggestions(formule.ingredients);
      setTimeout(() => poidsInputRef.current.select());
    }
  };

  return (
    <>
      <tr className="formule-picker-row bordered">
        <td className="t-grey br-top br-bottom ">
          <i className="material-icons t-large p-small t-green">post_add</i>
        </td>
        <td colSpan={2} className="b-white">
          {formule ? (
            <Row align="end">
              {formule.name}
              <button onClick={() => onSelectFormule(null)}>
                <span className="material-icons t-grey">close</span>
              </button>
            </Row>
          ) : (
            <div>
              <TextInput
                onChange={(e: any) => setSuggestionFilter(e['ingredient-search'])}
                label={'Rechercher'}
                name={'ingredient-search'}
                labelHide={true}
                className={'full-width'}
                isEditable={true}
                isAutofocus={true}
                value={suggestionFilter}
              />
              {formuleSuggestions.length > 0 ? (
                <div className="relative full-width b-white" ref={suggestListRef}>
                  <ParentComponent onBlur={() => setSuggestionFilter('')} className="absolute full-width">
                    {formuleSuggestions.map((suggestion: any, i: number) => (
                      <button
                        key={i}
                        className={'selectable p-medium full-width l-align '}
                        onClick={() => {
                          onSelectFormule(suggestion.suggestion.id);
                          setSuggestionFilter('');
                        }}
                      >
                        {suggestion.suggestion.title}
                      </button>
                    ))}
                  </ParentComponent>
                </div>
              ) : null}
            </div>
          )}
        </td>
        <td className={formule ? 'b-white' : 'b-grey'}>
          {formule ? (
            <Row>
              <NumberInput
                name={'poids'}
                value={poids}
                className={'full-width'}
                onChange={(e: any) => setPoids(e.poids)}
                inputRef={poidsInputRef}
                error={poids <= 0}
                onPressEnter={() => {
                  addFormule(suggestions, poids);
                  onSelectFormule(null);
                }}
              />
              {isPoidsSet ? (
                <button className="button-white circle" type={'button'} tabIndex={1} onClick={() => setPoids(0)}>
                  <span className="material-icons t-grey">close</span>
                </button>
              ) : null}
            </Row>
          ) : null}
        </td>
        <td className="search-row" colSpan={props.pattern.length + 4}>
          <Row>
            <button
              className="addButton"
              disabled={!formule || poids <= 0}
              onClick={() => {
                addFormule(suggestions, poids);
                onSelectFormule(null);
              }}
            >
              <span className="material-icons">done</span>
            </button>
            <button className="row gapped light" disabled={!formule} onClick={onResetFormule}>
              <span className="material-icons">replay</span>
            </button>
            <PickerTypePanel pickerType={'formule'} onPickerTypeChange={props.onPickerTypeChange} />
          </Row>
        </td>
      </tr>
      {suggestions.length > 0
        ? suggestions.map((ingredient: any, index: number) => {
            const valid = ingredient.id && props.ingredientIds.findIndex((i: any) => i.id === ingredient.id) < 0;
            return (
              <SuggestionRow
                key={`suggestion-row-${index}`}
                ingredient={ingredient}
                isValid={valid}
                index={index}
                formeId={props.formeId}
                pattern={props.pattern}
                ingredientIds={props.ingredientIds}
                lineSelected={lineSelected}
                setLineSelected={setLineSelected}
                replaceSuggestion={replaceSuggestion}
                updateSuggestion={updateSuggestion}
                deleteSuggestion={deleteSuggestion}
              />
            );
          })
        : null}
      <EmptyRows
        rowsNumber={35 - (props.ingredientIds.length + suggestions.length)}
        startIndex={props.ingredientIds.length + suggestions.length}
        pattern={props.pattern}
      />
    </>
  );
};

function useFormuleSuggestions(filter: string, formeId: string) {
  const { query } = useContext(ServiceContext);

  const [formuleSuggestions, setSuggestions] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const [formule, setFormule] = useState(null as any | null);
  const addIngredients = useAddIngredients();

  const selectFormule = (formuleId: string | null, callback: any) => {
    if (formuleId && query) {
      query(formuleService.getFormule(formuleId, formeId)).subscribe(
        (formule) => {
          setFormule(formule);
          callback(formule);
        },
        (err) => console.log(err),
      );
    } else {
      setFormule(null);
      callback(null);
    }
  };

  const addFormule = (ingredients: any[], poids: number) => {
    addIngredients(ingredients, poids);
  };

  useEffect(() => {
    if (filter && filter.length > 0) {
      setIsLoading(true);
      query(formuleService.getSuggestions(filter))
        .subscribe(
          (suggestions) => {
            setSuggestions(suggestions);
          },
          (err) => console.log(err),
        )
        .add(() => setIsLoading(false));
    } else {
      setSuggestions([]);
    }
  }, [filter]);

  return {
    formuleSuggestions,
    formule,
    selectFormule,
    addFormule,
    isLoading,
  };
}

export default FormulePicker;
