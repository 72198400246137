import React from 'react';

import { OrderStatusCard } from '../orderStatusCard/orderStatus.card';
import { PractitionerCard } from '../practitionerCard/practitioner.card';
import { ClientCard } from '../clientCard/client.card';
import { AdresseCard } from '../adresseCard/adresse.card';
import { CompositionDetail } from '../compositionCard/composition.detail';
import { useHistory } from 'react-router-dom';
import './order.card.scss';

interface IOrderCardProps {
  order: any;
  onRemoveOrder?: any;
}

export const OrderCard: React.FC<IOrderCardProps> = ({ order, onRemoveOrder, children }: any) => {
  const history = useHistory();

  return (
    <div className="order-card b-grey">
      <div className="order-card_header">
        <div className="info-card-content card row">
          <OrderStatusCard
            status={order.editStatus}
            orderCode={order.code}
            shipmentType={order.shipment.type}
            dates={order.dates}
            practitionerCode={order.practitioner ? order.practitioner.code : null}
          />
        </div>
        <div className=" p-spaced full-width">
          <div className="row spaced p-small light">
            <span className="t-grey">INFORMATIONS</span>
            {onRemoveOrder ? (
              <button type="button" className="button-alert transparent" tabIndex={0} onClick={onRemoveOrder}>
                <span className="material-icons t-xlarge">delete</span>
                <div>Archiver</div>
              </button>
            ) : null}
          </div>
          <div className="order-info">
            {order.practitioner ? (
              <div className="detail-card card">
                <div className="t-green light p-spaced br-bottom">PRATICIEN </div>
                <div className="row spaced full-width border-box">
                  <div className="row full-width">
                    <div className="circle p-medium column center t-grey b-lgrey bordered">
                      <i className="material-icons t-green">medical_services</i>
                    </div>
                    <PractitionerCard
                      practitioner={order.practitioner}
                      remised={
                        order.practitioner && order.tauxRemise > 0 && order.practitioner.remise === order.tauxRemise
                      }
                    />
                  </div>
                  <button
                    className="button-validate circle"
                    onClick={() =>
                      order.practitioner
                        ? history.push({
                            pathname: '/praticiens',
                            search: `?pid=${order.practitioner._id}`,
                          })
                        : null
                    }
                  >
                    <span className="material-icons">search</span>
                  </button>
                </div>
              </div>
            ) : null}
            <div className="detail-card card">
              <div className="t-green light p-spaced br-bottom">CLIENT</div>
              <div className="row spaced border-box">
                <div className="row full-width">
                  <div className="circle p-medium column center t-grey b-lgrey bordered">
                    <i className="material-icons">person</i>
                  </div>
                  <ClientCard
                    client={order.client}
                    isRemised={
                      order.client &&
                      order.tauxRemise > 0 &&
                      (order.practitioner ? order.practitioner.remise !== order.tauxRemise : order.tauxRemise)
                    }
                    remise={order.client.tauxRemise || order.tauxRemise}
                  />
                </div>
                {order.clientWarning ? (
                  <i className="material-icons t-red" style={{ fontSize: '3rem' }}>
                    error
                  </i>
                ) : null}
                {order.client ? (
                  <button
                    className="button-validate circle"
                    onClick={() => (order.client ? history.push(`/clients/${order.client._id}`) : null)}
                  >
                    <span className="material-icons">search</span>
                  </button>
                ) : null}
              </div>
            </div>
            <div className="detail-card card">
              <div className="t-green light p-spaced br-bottom">LIVRAISON</div>
              <div className="row">
                <div className="circle p-medium column center t-grey b-lgrey bordered">
                  <i className="material-icons">local_shipping</i>
                </div>
                {order.client && order.client.adresses[order.adresseSelected] ? (
                  <AdresseCard adresseSelected={order.client.adresses[order.adresseSelected]} />
                ) : (
                  <div>Aucune adresse renseignée</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="order-card_content full-height" style={{ overflow: 'auto' }}>
        <div className="masonry">
          {order.compositions.map((composition: any, index: number) => (
            <CompositionDetail key={index} index={index} composition={composition} />
          ))}
        </div>
        <div
          style={{
            position: 'sticky',
            paddingTop: '2rem',
            display: 'flex',
            justifyContent: 'center',
            bottom: '2rem',
            zIndex: 5,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
