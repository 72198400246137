import { useContext, useState } from 'react';
import { Client } from '../../models/client';
import { clientService } from '../../services/client.service';
import { ServiceContext } from '../../../_core/services/service.context';

const useClient = () => {
  const [clientState, setClientState] = useState<{
    client: Client;
    adresse: number;
  }>();

  const { query } = useContext(ServiceContext);

  const getById = (id: string) => {
    query(clientService.getClientById(id)).subscribe(
      (client: any) => {
        setClientState({ client, adresse: client.adresseSelected });
      },
      (err) => console.log(err),
    );
  };

  return {
    client: clientState?.client,
    adresse: clientState?.adresse || 0,
    setClientState,
    getById,
  };
};

export default useClient;
