import { useContext, useState } from 'react';
import { Practitioner } from '../../models/practitioner';
import { ServiceContext } from '../../../_core/services/service.context';
import { PractitionerService } from '../../services/practitioner.service';

const usePractitionerOnEdition = (practitionerService: PractitionerService) => {
  const { query } = useContext(ServiceContext);
  const [practitioner, setPractitioner] = useState(null as Practitioner | null);

  const updatePractitionerOnEdition = (updates: Partial<Practitioner>) => {
    if (practitioner) setPractitioner({ ...practitioner, ...updates });
  };

  const setPractitionerOnEditionAsNew = () => {
    const newPractitioner = practitionerService.getNewPractitioner();
    setPractitioner(newPractitioner);
  };

  const fetchPractitionerOnEdition = (callback?: any) => {
    if (practitioner) {
      const isNew = !practitioner.id;
      query(
        isNew
          ? practitionerService.createPractitioner(practitioner)
          : practitionerService.updatePractitioner(practitioner),
        {
          notify: isNew
            ? `Le praticien ${practitioner.code} a été créé`
            : `Le praticien ${practitioner.code} a été mis à jour`,
        },
      ).subscribe(
        (res: Practitioner) => {
          if (isNew) {
            setPractitioner(res);
          }

          if (callback) callback(isNew ? res : practitioner);
        },
        (err) => console.log(err),
      );
    } else {
      throw Error('No practitioner on edition');
    }
  };

  const deletePractitionerOnEdition = (callback?: any) => {
    if (practitioner) {
      if (practitioner.id) {
        query(practitionerService.deletePractitioner(practitioner.id), {
          notify: `Le praticien ${practitioner.code} a été supprimé`,
        }).subscribe(
          () => {
            setPractitioner(null);
            if (callback) callback(null);
          },
          (err) => console.log(err),
        );
      } else {
        throw Error('Practitioner have no ID');
      }
    } else {
      throw Error('No practitioner on edition');
    }
  };

  return {
    practitionerOnEdition: practitioner,
    setPractitionerOnEdition: setPractitioner,
    setPractitionerOnEditionAsNew,
    fetchPractitionerOnEdition,
    updatePractitionerOnEdition,
    deletePractitionerOnEdition,
  };
};

export default usePractitionerOnEdition;
