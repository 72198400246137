import React, { useEffect, useState } from 'react';
import useCompositionOnEdition from '../../../../../../../_shared/providers/draft/useCompositionOnEdition';
import Row from '../../../../../../../_shared/component/row/row';
import NumberInput from '../../../../../../../_shared/component/numberInput';
import { WEIGHT_PATTERNS } from '../../../weightPicker/weight.patterns';

const WeightPanel: React.FC = () => {
  const { composition, updateComposition }: any = useCompositionOnEdition();
  const [pattern, setPattern] = useState(composition.forme ? WEIGHT_PATTERNS[composition.forme.code] : null);

  useEffect(() => {
    setPattern(composition.forme ? WEIGHT_PATTERNS[composition.forme.code] : null);
  }, [composition.forme]);

  return composition && pattern ? (
    <div className="weight-panel" style={{ gridTemplateColumns: `repeat(${pattern.length}, auto)` }}>
      {pattern.map((element: any, index: number) => (
        <React.Fragment key={index}>
          {typeof element === 'string' ? (
            <>
              <div></div>
              <div className="t-grey t-large operator">{element}</div>
            </>
          ) : (
            <>
              <label>{element.label.toUpperCase()}</label>
              <Row gapped={true} className={`calcInput ${element.locked ? 'b-grey' : ''}`}>
                {composition[element.varName] ? (
                  <div style={{ lineHeight: 0 }}>
                    <span className="material-icons t-medium t-grey">lock_open</span>
                  </div>
                ) : null}
                <NumberInput
                  name={element.name}
                  value={composition[element.value]}
                  onChange={updateComposition}
                  size={element.size}
                  resize={true}
                  decimals={element.decimals || 2}
                  error={composition[element.value] <= 0}
                  locked={element.locked}
                  dataCy={`WeightPanel-${element.name}`}
                />
                <div className="light t-small">{element.prefix}</div>
                {composition[element.varName] ? (
                  <button
                    className="deleteButton"
                    type={'button'}
                    tabIndex={1}
                    onClick={() => updateComposition({ [element.name]: 0 })}
                  >
                    <span className="material-icons t-grey">close</span>
                  </button>
                ) : null}
              </Row>
            </>
          )}
        </React.Fragment>
      ))}
    </div>
  ) : null;
};

export default WeightPanel;
