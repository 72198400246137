import React from 'react';

import { TUextInput } from '../../../../../../../_shared/component/textInput/textInput.component';
import useCompositionOnEdition from '../../../../../../../_shared/providers/draft/useCompositionOnEdition';
import Row from '../../../../../../../_shared/component/row/row';

const LibelleInput: React.FC = () => {
  const { composition, updateComposition } = useCompositionOnEdition();

  return (
    <Row className="libelle-input">
      <TUextInput
        name={'libelle'}
        label={'Libellé'}
        suffix={<span className="material-icons t-large t-lgrey">label</span>}
        value={composition.libelle || ''}
        className="p-small "
        size={40}
        resize={true}
        onChange={updateComposition}
        dataCy="LibelleInput"
      />
    </Row>
  );
};

export default LibelleInput;
