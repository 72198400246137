import { from, throwError } from 'rxjs';
import { Address } from '../models/client';
import { flatMap, map } from 'rxjs/operators';
import { BASE_URL } from '../config/constants';

const GEO_API_URL = (window as any)._env_.GEO_API_URL;

export class AddressService {
  searchAddress(address: Address) {
    const street = address.lines[0].split(' ').join('+');
    const code = address.cp;
    const city = address.ville.split(' ').join('+');

    let query = street;
    if (code) query += '+' + code;
    if (city) query += '+' + street;

    return from(fetch(`${GEO_API_URL}/?q=${query}`)).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
      map((res: any) => {
        const addresses = res.features.map((feature: any) => {
          const props = feature.properties;
          return {
            label: props.label,
            name: props.name,
            ville: props.city,
            cp: props.postcode,
          };
        });
        return addresses;
      }),
    );
  }

  getVillesSuggestions(searchField: string) {
    return from(
      fetch(`${BASE_URL}/suggest/ville?query=${searchField}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }
}

export const addressService = new AddressService();
