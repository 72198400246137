import React from 'react';

interface ISetToR1ButtonProps {
  onPrintR1: any;
  minified: boolean;
  disabled?: boolean;
}

export const PrintR1Button: React.FC<ISetToR1ButtonProps> = (props: ISetToR1ButtonProps) => {
  return (
    <div className="row gapped">
      <button
        disabled={props.disabled}
        className={props.minified ? 'button-print minified' : 'button-print'}
        onClick={() => props.onPrintR1('R1')}
      >
        <i className="material-icons">print</i>
        {props.minified ? null : <span>Imprimer relance 1</span>}
      </button>
      <div className="t-lgrey">{props.minified ? 'R1' : ''}</div>
    </div>
  );
};
