import React from 'react';
import { Practitioner } from '../../../_shared/models/practitioner';

interface IPractitionersListProps {
  isLoading: boolean;
  practitioners: Practitioner[] | null;
  handleSelectPractitioner: any;
  isEditing: boolean;
}

export const PractitionersList: React.FC<IPractitionersListProps> = (props: IPractitionersListProps) => {
  return (
    <div className={'full-width' + (props.isEditing ? ' locked' : '')}>
      <table className="list">
        <thead className="table-head">
          <tr>
            <th>CODE</th>
            <th>NOM</th>
            <th>PRENOM</th>
            <th>ADRESSE</th>
            <th>CP</th>
            <th>VILLE</th>
            <th>MOBILE</th>
            <th>FIXE</th>
          </tr>
        </thead>
        <tbody className="table-body">
          {props.practitioners && props.practitioners.length ? (
            props.practitioners.map((practitioner: Practitioner, index) => (
              <tr key={index} onClick={() => props.handleSelectPractitioner(practitioner)}>
                <td className="bold">{practitioner.code.toUpperCase()}</td>
                <td className="upperCase">{practitioner.nom}</td>
                <td>{practitioner.prenom}</td>
                <td>{practitioner.adresse[0] || '--'}</td>
                <td>{practitioner.cp}</td>
                <td>{practitioner.ville}</td>
                <td>{practitioner.mobile}</td>
                <td>{practitioner.fixe}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="center-align" colSpan={8}>
                Aucun praticien
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PractitionersList;
