import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { DraftsPage } from './draftsPage/drafts.page';

import './orders.layout.scss';
import { DraftPage } from './orderPage/draft.page';

export const EnCoursRouter: React.FC<any> = ({ match }: any) => {
  return (
    <Switch>
      <Route path={`${match.url}`} exact={true} component={DraftsPage} />
      <Route path={`${match.url}/:id`} exact={true} component={DraftPage} />
    </Switch>
  );
};
