interface IEnumerable {
  [key: string]: any;
}

export const WEIGHT_PATTERNS: IEnumerable = {
  PC: [
    {
      value: 'poids',
      name: 'poids',
      className: 'poids',
      prefix: 'g',
      size: 4,
      decimals: 1,
      label: 'Poids',
      varName: 'isPoidsSet',
    },
    '/',
    {
      value: 'contenant',
      name: 'contenant',
      className: '',
      prefix: 'flacons',
      label: 'Nombre de flacons',
      decimals: 0,
      size: 2,
      varName: 'isContenantSet',
    },
  ],
  MO: [
    {
      value: 'poids',
      name: 'poids',
      className: 'poids',
      prefix: 'g',
      size: 4,
      decimals: 1,
      label: 'Poids',
      varName: 'isPoidsSet',
    },
    '/',
    {
      value: 'contenant',
      name: 'contenant',
      className: '',
      size: 2,
      prefix: 'flacons',
      decimals: 0,
      label: 'Nombre de flacons',
      varName: 'isContenantSet',
    },
  ],
  GE: [
    {
      value: 'poids',
      name: 'poids',
      className: 'poids',
      prefix: 'g',
      size: 4,
      decimals: 1,
      label: 'Poids',
      varName: 'isPoidsSet',
    },
    '/',
    {
      value: 'contenant',
      name: 'contenant',
      className: '',
      size: 2,
      decimals: 0,
      prefix: 'flacons',
      label: 'Nombre de flacons',
      varName: 'isContenantSet',
    },
  ],
  IF: [
    {
      value: 'poids_contenant',
      name: 'poids_contenant',
      className: 'poids',
      prefix: 'g',
      size: 4,
      decimals: 1,
      label: 'Poids par sachet',
      varName: 'isPoidsContenantSet',
    },
    'x',
    {
      value: 'contenant',
      name: 'contenant',
      className: '',
      prefix: 'sachets',
      size: 2,
      decimals: 0,
      label: 'Nombre de sachets',
      varName: 'isContenantSet',
    },
    '=',
    {
      value: 'poids',
      name: 'poids',
      className: 'poids',
      prefix: 'g',
      size: 4,
      decimals: 1,
      label: 'Poids total',
      varName: 'isPoidsSet',
    },
  ],
  CC: [
    {
      value: 'poids_contenant',
      name: 'poids_contenant',
      className: 'poids',
      prefix: 'g',
      size: 4,
      decimals: 1,
      label: 'Poids par sachet',
      varName: 'isPoidsContenantSet',
    },
    '=',
    {
      value: 'poids',
      name: 'poids',
      className: 'poids',
      prefix: 'g',
      size: 4,
      decimals: 1,
      label: 'Poids total',
      varName: 'isPoidsSet',
    },
    '/',
    {
      value: 'contenant',
      name: 'contenant',
      className: '',
      prefix: 'sachets',
      size: 2,
      decimals: 0,
      label: 'Nombre de sachets',
      varName: 'isContenantSet',
    },
  ],
  VR: [
    {
      value: 'poids_contenant',
      name: 'poids_contenant',
      className: 'poids',
      prefix: 'g',
      size: 4,
      decimals: 1,
      label: 'Poids par sachet',
      varName: 'isPoidsContenantSet',
    },
    'x',
    {
      value: 'contenant',
      name: 'contenant',
      className: '',
      prefix: 'sachets',
      size: 2,
      decimals: 0,
      label: 'Nombre de sachets',
      varName: 'isContenantSet',
    },
    '=',
    {
      value: 'poids',
      name: 'poids',
      className: 'poids',
      prefix: 'g',
      size: 4,
      decimals: 1,
      label: 'Poids total',
      varName: 'isPoidsSet',
    },
  ],
  VR8: [
    {
      value: 'poids_contenant',
      name: 'poids_contenant',
      className: 'poids',
      prefix: 'g',
      size: 4,
      decimals: 1,
      label: 'Poids par sachet',
      varName: 'isPoidsContenantSet',
    },
    'x',
    {
      value: 'contenant',
      name: 'contenant',
      className: '',
      prefix: 'sachets',
      size: 2,
      decimals: 0,
      label: 'Nombre de sachets',
      varName: 'isContenantSet',
    },
    '=',
    {
      value: 'poids',
      name: 'poids',
      className: 'poids',
      prefix: 'g',
      size: 4,
      decimals: 1,
      label: 'Poids total',
      varName: 'isPoidsSet',
    },
  ],
  DV: [
    {
      value: 'contenant',
      name: 'contenant',
      className: '',
      prefix: 'sachets',
      size: 3,
      decimals: 0,
      label: 'Nombre de sachets',
      varName: 'isContenantSet',
    },
  ],
};
