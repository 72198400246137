import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { OrdersPage } from './ordersPage/orders.page';
import { OrderPage } from './orderPage/order.page';

export const OrderRouter: React.FC<any> = ({ match }: any) => {
  return (
    <Switch>
      <Route path={`${match.url}`} exact component={OrdersPage} />
      <Route path={`${match.url}/:id`} exact component={OrderPage} />
    </Switch>
  );
};
