import React from 'react';
import { useEuroFormatter, usePercentFormatter } from '../../../../../_shared/services/formatter.service';
import { usePrixTotal } from '../../../../../_shared/providers/draft/usePrixTotal';

const DraftPricePanel: React.FC<any> = () => {
  const formatToEuro = useEuroFormatter();
  const formatToPercent = usePercentFormatter();
  const { prixTotal, remise, remiseInEuro } = usePrixTotal();

  return (
    <div className="br-top">
      <div className="light row">
        <div className="circle p-medium t-medium column center t-grey b-lgrey bordered">
          <i className="material-icons">euro</i>
        </div>
        <div>PRIX TOTAL</div>
      </div>
      <div className="row wrap gapped">
        <div className="row gapped wrap">
          <div className="column">
            <label className="t-small">TAUX</label>
            <label className="t-small">DE REMISE</label>
          </div>
          <div className="t-large t-green">{formatToPercent(remise / 100)}</div>
        </div>
        <div className="row gapped wrap">
          <div className="column">
            <label className="t-small">REMISE SUR</label>
            <label className="t-small">COMMANDE</label>
          </div>
          <div className="t-large t-green">{formatToEuro(remiseInEuro)}</div>
        </div>
        <div className="row gapped">
          <div className="column">
            <label className="t-small">PRIX TOTAL</label>
            <label className="t-small">DE LA COMMANDE</label>
          </div>
          <div className="t-xlarge p-small t-grey">{formatToEuro(prixTotal)}</div>
        </div>
      </div>
    </div>
  );
};

export default DraftPricePanel;
