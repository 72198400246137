import React from 'react';

export const AdresseCard: React.FC<any> = ({ adresseSelected }: any) => {
  return (
    <>
      {adresseSelected ? (
        <div>
          <div>
            {adresseSelected.lines
              .filter((l: string) => l.length > 0)
              .map((line: string, index: number) =>
                index === 0 ? (
                  <span key={index} className={'bold t-medium'}>
                    {line.toUpperCase()}
                  </span>
                ) : (
                  <span key={index} className="t-medium">
                    , {line}
                  </span>
                ),
              )}
          </div>
          <div className="t-medium">
            {adresseSelected.cp} &nbsp;
            {adresseSelected.ville}, &nbsp;
            <span className="capitalize">{adresseSelected.pays}</span>
          </div>
        </div>
      ) : (
        'Aucune adresse renseignée'
      )}
    </>
  );
};
