import * as React from 'react';
import { IFormFieldProps } from '../ingredientForm';

interface IPinyinInputProps extends IFormFieldProps<string> {
  pinyin: string;
}

const PinyinInput: React.FunctionComponent<IPinyinInputProps> = (props) => {
  const { pinyin, onChange } = props;

  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleOnChange = (e: any) => {
    onChange({
      target: { name: 'pinyin', value: e.target.value.toLowerCase() },
    });
  };

  return (
    <div>
      <label className="required">
        PINYIN <span style={{ color: 'red' }}>{props.isExist ? 'Existe déjà' : ''}</span>
      </label>
      <input
        className={(props.isExist ? 'onError ' : '') + 'firstUpper'}
        type="text"
        name="pinyin"
        disabled={!props.isEditable}
        value={capitalize(pinyin)}
        placeholder="Pinyin"
        autoComplete="off"
        onChange={(e) => {
          handleOnChange(e);
        }}
      />
    </div>
  );
};

export default PinyinInput;
