import { useQuery } from '../../../_core/services/service.context';
import { useState } from 'react';
import { orderService } from '../../services/order.service';

const usePaiementOrder = () => {
  const query = useQuery();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const createPaiementOrder = (id: string, editStatus: string, type: 'URL' | 'MAIL', callBack?: any) => {
    setIsLoading(true);
    query(orderService.createPaiementOrder(id, editStatus, type), {
      notify:
        type === 'URL' ? 'Le lien de paiement a bien été généré' : 'Le lien de paiement a bien été envoyé par mail',
    })
      .subscribe(
        (url) => {
          if (callBack) callBack(url);
        },
        (err) => console.log(err),
      )
      .add(() => setIsLoading(false));
  };

  return { isLoading, createPaiementOrder };
};

export default usePaiementOrder;
