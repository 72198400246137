import { useContext, useState } from 'react';

import * as qs from 'query-string';

import { ServiceContext } from '../../_core/services/service.context';
import { practitionerService } from '../../_shared/services/practitioner.service';
import { Practitioner } from '../../_shared/models/practitioner';
import { useHistory, useLocation } from 'react-router-dom';
import usePractitionerOnEdition from '../../_shared/providers/practitioner/usePractitionerOnEdition';
import usePractitioners from '../../_shared/providers/practitioner/usePractitioners';

const usePractitionerPage = () => {
  const { query, pushAlert } = useContext(ServiceContext);
  const location = useLocation();
  const history = useHistory();

  const [practitioner, setPractitioner] = useState(null as null | Practitioner);

  const {
    practitionerOnEdition,
    updatePractitionerOnEdition,
    fetchPractitionerOnEdition,
    setPractitionerOnEdition,
    deletePractitionerOnEdition,
    setPractitionerOnEditionAsNew,
  } = usePractitionerOnEdition(practitionerService);

  const { searchField, setSearchField, practitioners, refill, isLoading } = usePractitioners(true, practitionerService);

  const init = () => {
    const params = qs.parse(location.search);
    setSearchField(params.searchField ? (params.searchField as string) : null);
    if (params.pid) {
      query(practitionerService.getPractitionerById(params.pid as string)).subscribe(
        (practitioner: Practitioner) => {
          setPractitioner(practitioner);
        },
        (err) => console.log(err),
      );
    }
  };

  const updateQueryParams = () => {
    const params: any = {};
    if (practitioner) params.pid = practitioner.id;
    if (searchField) params.searchField = searchField;
    history.replace({
      pathname: location.pathname,
      search: qs.stringify(params),
    });
  };

  /**
   * Calculate the point balance of a practitioner according to its point events and order points.
   * @param practitioner
   */
  const calculateBalance = (practitioner: Practitioner): number => {
    return (
      (practitioner.points?.orderPoints || 0) +
      (practitioner.points?.events.reduce((acc, current) => acc + current.amount, 0) || 0)
    );
  };

  /**
   * Get the points of a practitioner if necessary and set it as selected.
   * @param practitionerToSelect {Practitioner}
   */
  const selectPractitioner = (practitionerToSelect: Practitioner) => {
    if (!practitionerToSelect.id) return;
    // Don't call the API if points were already retrieved
    if (practitionerToSelect.points) {
      setPractitioner(practitionerToSelect);
      return;
    }
    query(practitionerService.getPoints(practitionerToSelect.id)).subscribe(
      (res: Practitioner['points']) => {
        practitionerToSelect.points = res;
        setPractitioner(practitionerToSelect);
      },
      (error) => {
        console.error('😣', error);
      },
    );
  };

  /**
   * Add a new point event to a practitioner.
   * @param practitionerId {string}
   * @param newPointEvent { { amount: number, date: string } }
   */
  const addPointEvent = (practitionerId: string, newPointEvent: { amount: number; date: string }) => {
    if (!practitioner) return;
    const { amount } = newPointEvent;
    if (amount === 0) return;
    query(practitionerService.addPointEvent(practitionerId, newPointEvent)).subscribe(
      (res: PointEventResponse) => {
        practitioner.points = res;
        setPractitioner(practitioner);
        pushAlert({
          type: 'valid',
          message: `${Math.abs(amount)} points ${amount > 0 ? 'ajoutés' : 'retirés'} à ${practitioner.nom} ${
            practitioner.prenom
          }`,
        });
      },
      (error) => {
        console.error('😣', error);
      },
    );
  };

  /**
   * Delete a practitioner point event.
   * @param practitionerId {string} the id of the practitioner
   * @param pointEventId {string} the id of the point event
   */
  const deletePointEvent = (practitionerId: string, pointEventId: string) => {
    if (!practitioner) return;
    query(practitionerService.deletePointEvent(practitionerId, pointEventId)).subscribe(
      (res: PointEventResponse) => {
        practitioner.points = res;
        setPractitioner(practitioner);
        pushAlert({
          type: 'valid',
          message: `Les points ont été supprimés.`,
        });
      },
      (error) => {
        console.error('😣', error);
        pushAlert({
          type: 'error',
          message: `Erreur lors de la suppression des points. Réessayez plus tard.`,
        });
      },
    );
  };

  /**
   * Re-init state after fetch practitioner.
   */
  const reInit = (res: any) => {
    refill();
    setPractitioner(res);
    setPractitionerOnEdition(null);
  };

  return {
    searchField,
    practitioner,
    practitionerOnEdition,
    practitioners,
    isLoading,
    init,
    updateQueryParams,
    refill,
    setSearchField,
    setPractitioner,
    setPractitionerOnEdition,
    updatePractitionerOnEdition,
    setPractitionerOnEditionAsNew,
    deletePractitionerOnEdition: () => deletePractitionerOnEdition((res: any) => reInit(res)),
    fetchPractitionerOnEdition: () => fetchPractitionerOnEdition((res: any) => reInit(res)),
    selectPractitioner,
    addPointEvent,
    deletePointEvent,
  };
};

export default usePractitionerPage;
