import React from 'react';
import { Switch } from 'react-router';

import { Header } from '../header/header';
import { AuthProvider } from './auth.provider';
import { ServiceProvider } from '../services/service.provider';
import { ClientRouter } from '../../_features/clients/clients.route';
import { IngredientRouter } from '../../_features/ingredients/ingredients.route';
import { PractitionerRouter } from '../../_features/practitioner/practitioner.route';
import { OrderRouter } from '../../_features/orders/orders.route';
import { EnCoursRouter } from '../../_features/orders/enCours.route';
import { CodePrixPage } from '../../_features/prices/prices.page';
import { StatsPage } from '../../_features/orders/statsPage/stats.page';
import { Redirect, Route } from 'react-router-dom';

import {
  CLIENTS_PATH,
  DEFAULT_PATH,
  DRAFTS_PATH,
  ON_EDITION_PATH,
  INGREDIENTS_PATH,
  ORDERS_PATH,
  PRACTITIONERS_PATH,
  PRICES_PATH,
  STATS_PATH,
  SCAN_PATH,
} from '../../_shared/config/paths';

import { OnEditionRouter } from '../../_features/orders/onEdition.router';
import { PaiementScanPage } from '../../_features/orders/paiementScanPage/paiementScan.page';

export const AppRouter: React.FC = () => {
  return (
    <Switch>
      <Route path={ON_EDITION_PATH} component={OnEditionRouter} />
      <Route path={ORDERS_PATH} component={OrderRouter} />
      <Route path={DRAFTS_PATH} component={EnCoursRouter} />
      <Route path={CLIENTS_PATH} component={ClientRouter} />
      <Route path={INGREDIENTS_PATH} component={IngredientRouter} />
      <Route path={PRACTITIONERS_PATH} component={PractitionerRouter} />
      <Route path={PRICES_PATH} component={CodePrixPage} />
      <Route path={SCAN_PATH} component={PaiementScanPage} />
      <Route path={STATS_PATH} component={StatsPage} />
      <Route>
        <Redirect to={DEFAULT_PATH} />
      </Route>
    </Switch>
  );
};

export const AppLayout: React.FC = (props: any) => {
  return (
    <div className="full-height" style={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}>
      <Header />
      {props.children}
    </div>
  );
};

export const AppProvider: React.FC = (props: any) => {
  return (
    <AuthProvider>
      <ServiceProvider>{props.children}</ServiceProvider>
    </AuthProvider>
  );
};
