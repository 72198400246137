import React, { useEffect, useRef, useState } from 'react';
import { SpinLoader } from '../../../../../../_shared/component/loader/loader';
import { useIngredientsReducerHook } from '../ingredients.reducer';
import NumberInput from '../../../../../../_shared/component/numberInput';
import { useAddIngredients } from '../../../../../../_shared/providers/draft/useAddIngredients';
import PickerTypePanel from '../pickerTypePanel/pickerType.panel';
import SuggestionRow from '../suggestionRow/suggestion.row';
import EmptyRows from '../EmptyRows/empty.rows';
import Row from '../../../../../../_shared/component/row/row';

interface IParserPickerProps {
  pattern: any;
  formeId: string;
  suggestions: any[];
  ingredientIds: any[];
  onPickerTypeChange: any;
  onParserFocus: any;
}

const ParserPicker: React.FC<IParserPickerProps> = (props: IParserPickerProps) => {
  const {
    suggestions,
    poids,
    isPoidsSet,
    replaceSuggestion,
    replaceSuggestions,
    deleteSuggestion,
    setPoids,
    updateSuggestion,
  } = useIngredientsReducerHook();

  const [lineSelected, setLineSelected] = useState(null as null | number);

  const proportionRef = useRef<HTMLDivElement>(null as null | any);
  const addIngredients = useAddIngredients();

  useEffect(() => {
    props.onParserFocus();
  }, []);

  useEffect(() => {
    replaceSuggestions(props.suggestions);
    setLineSelected(null);
    setTimeout(() => {
      if (proportionRef && proportionRef.current) proportionRef.current.focus();
    });
  }, [props.suggestions]);

  const onResetParsing = () => {
    if (suggestions) {
      replaceSuggestions(suggestions);
      setTimeout(() => {
        if (proportionRef && proportionRef.current) proportionRef.current.focus();
      });
    }
  };

  const onParserValidation = () => {
    addIngredients(
      suggestions.filter((i: any) => !!i.id && i.pinyin),
      poids,
    );
    replaceSuggestions([]);
  };

  return (
    <>
      <tr className="formule-picker-row bordered">
        <td className="t-grey br-top br-bottom ">
          <i className="material-icons t-large p-small t-green">notes</i>
        </td>
        <td
          colSpan={2}
          className={suggestions && suggestions.length > 0 ? 'b-white' : 'b-grey selectable'}
          onClick={() => props.onParserFocus()}
        >
          {suggestions && suggestions.length > 0 ? (
            <Row align="end">Résultat parsé :</Row>
          ) : (
            <Row align="end" className="gapped ">
              <SpinLoader size={'1em'} thickness={'1px'} color={'lightgrey'} />
              <div className={'light'}>En attente d'un résultat</div>
            </Row>
          )}
        </td>
        <td className={suggestions && suggestions.length > 0 ? 'b-white' : 'b-grey'}>
          {suggestions && suggestions.length > 0 ? (
            <Row>
              <NumberInput
                name={'poids'}
                value={poids}
                onChange={(e: any) => setPoids(e.poids)}
                inputRef={proportionRef}
                error={poids <= 0}
                onPressEnter={() => {
                  replaceSuggestions([]);
                  addIngredients(
                    suggestions.filter((i: any) => !!i.id && i.pinyin),
                    poids,
                  );
                  props.onPickerTypeChange('ingredient');
                }}
              />
              {isPoidsSet ? (
                <button className="deleteButton" type={'button'} tabIndex={1} onClick={() => setPoids(0)}>
                  <span className="material-icons t-grey">close</span>
                </button>
              ) : null}
            </Row>
          ) : null}
        </td>
        <td className="search-row" colSpan={props.pattern.length + 4}>
          <Row>
            <button
              className="addButton"
              disabled={!suggestions || suggestions.length <= 0 || poids <= 0}
              onClick={onParserValidation}
              data-cy="ParserPicker-done"
            >
              <span className="material-icons">done</span>
            </button>
            <button
              className="row gapped light"
              disabled={!suggestions || suggestions.length <= 0 || poids <= 0}
              onClick={onResetParsing}
            >
              <span className="material-icons">replay</span>
            </button>
            <button onClick={props.onParserFocus}>
              <span className="material-icons">open_in_new</span>
            </button>
            <PickerTypePanel pickerType={'parser'} onPickerTypeChange={props.onPickerTypeChange} />
          </Row>
        </td>
      </tr>
      {suggestions && suggestions.length > 0
        ? suggestions.map((ingredient: any, index: number) => {
            const valid = ingredient.id && props.ingredientIds.findIndex((i: any) => i.id === ingredient.id) < 0;
            return (
              <SuggestionRow
                key={`suggestion-row-${index}`}
                ingredient={ingredient}
                isValid={valid}
                index={index}
                formeId={props.formeId}
                pattern={props.pattern}
                ingredientIds={props.ingredientIds}
                lineSelected={lineSelected}
                setLineSelected={setLineSelected}
                replaceSuggestion={replaceSuggestion}
                updateSuggestion={updateSuggestion}
                deleteSuggestion={deleteSuggestion}
              />
            );
          })
        : null}
      <EmptyRows
        rowsNumber={35 - props.ingredientIds.length + suggestions.length}
        startIndex={props.ingredientIds.length + suggestions.length}
        pattern={props.pattern}
      />
    </>
  );
};

export default ParserPicker;
