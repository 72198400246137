import React from 'react';
import { FormeContext, IFormeContext } from './forme.context';
import { ServiceContext } from '../../../_core/services/service.context';
import { getAllForme } from '../../services/forme.service';
import { combineLatest } from 'rxjs';
import { getAllCodePrix } from '../../services/codeprix.service';

export class FormeProvider extends React.Component<any, any> {
  static contextType = ServiceContext;

  state: IFormeContext = {
    formes: [],
  };

  componentWillMount(): void {
    this.fillFormes();
  }

  fillFormes = () => {
    combineLatest(getAllCodePrix(), getAllForme()).subscribe(
      ([prixRes, formesRes]: any) => {
        const formes: any[] = [];
        formesRes.map((forme: any) => {
          formes.push({
            ...forme,
            prix: {},
          });
        });
        prixRes.map((prix: any) => {
          prix.forme_prix.map((formePrix: any) => {
            const formeIndex = formes.findIndex((forme: any) => forme.id === formePrix.forme.id);
            if (formeIndex >= 0) formes[formeIndex].prix[prix.id] = formePrix.prix;
          });
        });
        this.setState({ formes: formes });
      },
      (err: any) => console.log(err),
    );
  };

  render() {
    return <FormeContext.Provider value={this.state}>{this.props.children}</FormeContext.Provider>;
  }
}
