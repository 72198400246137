import React, { useContext, useRef } from 'react';
import PractitionerForm from '../../../../practitioner/pracitionnerForm/practitionner.form';
import { Practitioner } from '../../../../../_shared/models/practitioner';
import { PractitionerSuggestion } from './practitionerSuggestion/practitioner.suggestion';
import { CrudButtonPanel } from '../../../../../_shared/component/crudButtonPanel/crudButtonPanel';
import useEscape from '../../../../../_shared/component/useEscape';

import './practitioner.picker.css';
import { DraftEditorContext } from '../../../../../_shared/providers/draft/draftEditor.provider';
import usePractitionerOnEdition from '../../../../../_shared/providers/practitioner/usePractitionerOnEdition';
import { practitionerService } from '../../../../../_shared/services/practitioner.service';
import { setPractitioner, setSelected } from '../../../../../_shared/providers/draft/draftEditor.actions';
import { TUextInput } from '../../../../../_shared/component/textInput/textInput.component';
import usePractitioners from '../../../../../_shared/providers/practitioner/usePractitioners';

export const PractitionerPicker: React.FC<any> = () => {
  const ref: any = useRef();

  const { searchField, setSearchField, practitioners } = usePractitioners(false, practitionerService);

  const {
    practitionerOnEdition,
    setPractitionerOnEdition,
    updatePractitionerOnEdition,
    setPractitionerOnEditionAsNew,
    fetchPractitionerOnEdition,
  } = usePractitionerOnEdition(practitionerService);

  const { practitioner, dispatch } = useContext(DraftEditorContext);

  useEscape(() => dispatch(setSelected(null)));

  const pickedPractitioner = practitionerOnEdition || practitioner;
  return (
    <div className="picker">
      <div className="picker_container practitioner" ref={ref}>
        {!pickedPractitioner ? (
          <div className="picker_header row spaced">
            <div className="picker_header_search b-white light">
              <TUextInput
                value={searchField}
                name={'searchField'}
                labelHide={true}
                autofocus={true}
                suffix={<span className="material-icons">search</span>}
                className="search-field bold"
                placeholder={'Rechercher un praticien'}
                onCancel={() => {
                  setSearchField('');
                }}
                onChange={(e: any) => setSearchField(e.searchField)}
              />
            </div>
            <button className={'t-large add-button'} type={'button'} onClick={setPractitionerOnEditionAsNew}>
              <i className="material-icons">add</i>
            </button>
            <button className={'t-large close-button'} type={'button'} onClick={() => dispatch(setSelected(null))}>
              <i className="material-icons">close</i>
            </button>
          </div>
        ) : (
          <div className="row spaced full-height">
            <button
              type={'button'}
              className="return-button"
              onClick={() => {
                dispatch(setPractitioner(null));
                setPractitionerOnEdition(null);
              }}
            >
              <i className="material-icons">search</i>
              Retour à la recherche
            </button>
            <button className={'t-large close-button'} type={'button'} onClick={() => dispatch(setSelected(null))}>
              <i className="material-icons">close</i>
            </button>
          </div>
        )}
        <div className="picker_content">
          {pickedPractitioner ? (
            <div className="card scrollable-y">
              <PractitionerForm
                practitionerSelected={pickedPractitioner}
                isOnEdition={!!practitionerOnEdition}
                onChange={updatePractitionerOnEdition}
              />
              <div className="row p-small">
                {!practitionerOnEdition ? (
                  <button
                    type={'button'}
                    className="validate-button"
                    disabled={!!practitionerOnEdition}
                    autoFocus={true}
                    onClick={() => dispatch(setSelected(null))}
                  >
                    Valider
                  </button>
                ) : null}
                <CrudButtonPanel
                  isOnEdition={!!practitionerOnEdition}
                  onEdition={() => setPractitionerOnEdition(practitioner)}
                  onValidate={() =>
                    fetchPractitionerOnEdition((practitioner: any) => {
                      dispatch(setPractitioner(practitioner));
                      setPractitionerOnEdition(null);
                    })
                  }
                  onCancelEdition={() => setPractitionerOnEdition(null)}
                />
              </div>
            </div>
          ) : (
            <div className="picker_content card scrollable-y">
              {practitioners
                ? practitioners.map((practitioner: Practitioner, index: number) => (
                    <button
                      key={index}
                      className="selectable br-bottom p-medium fill-width l-align"
                      onClick={() => dispatch(setPractitioner(practitioner))}
                    >
                      <PractitionerSuggestion practitioner={practitioner} />
                    </button>
                  ))
                : searchField && searchField.length > 0
                ? 'Aucun praticien trouvé'
                : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
