import React from 'react';

export interface IFormeContext {
  formes: any[];
}

const FormeContext = React.createContext<IFormeContext>({
  formes: [],
});

const FormeConsumer = FormeContext.Consumer;

export { FormeConsumer, FormeContext };
