import { from, Observable, of, throwError } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { BASE_URL } from '../config/constants';
import { Address, Client } from '../models/client';

export class ClientService {
  getNewAddress(def = false): Address {
    return {
      label: '',
      lines: ['', '', '', ''],
      cp: '',
      ville: '',
      pays: 'France',
      default: def,
    };
  }

  getNewClient(): Client {
    return {
      nom: '',
      prenom: '',
      adresses: [this.getNewAddress(true)],
      adresseSelected: 0,
      remise: 0,
    };
  }

  getClientById(id: string): Observable<Client> {
    return from(
      fetch(`${BASE_URL}/client/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  getAllClients(cids: Client['id'][] = []): Observable<Client[] | any> {
    return from(
      fetch(`${BASE_URL}/client${cids.length > 0 ? '?cid=' + cids : ''}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
      map((clients: Client[]) => {
        clients.map((client: any) => {
          client.adresses = client.adresses.map((address: any) => {
            return {
              ...address,
              lines: [...address.lines, ...Array(4 - (address.lines ? address.lines.length : 0)).fill('')],
            };
          });
        });
        return clients;
      }),
    );
  }

  createClient(client: Client): Observable<Client> {
    const body: any = Object.assign({}, { ...client });

    return from(
      fetch(`${BASE_URL}/client?refresh=true`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(body),
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  updateClient(client: Client): Observable<any> {
    const body: any = {
      prenom: client.prenom,
      nom: client.nom,
      adresses: client.adresses,
      mail: client.mail,
      mobile: client.mobile,
      fixe: client.fixe,
      tel3: client.tel3,
      tel4: client.tel4,
      note: client.note,
      remise: client.remise || 0,
    };

    return from(
      fetch(`${BASE_URL}/client/${client.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(body),
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return of(true);
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  deleteClient(clientId: string): Observable<any> {
    return from(
      fetch(`${BASE_URL}/client/${clientId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return of(true);
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  getIsPropsExist(type: string, value: string | number) {
    const body = { type, value };
    return from(
      fetch(`${BASE_URL}/search/client`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(body),
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
      map((res) => {
        return res.exists;
      }),
    );
  }

  getSuggestions(field: string) {
    return from(
      fetch(`${BASE_URL}/suggest/client?limit=50&query=${field}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
      map((suggestions: any[]) => {
        suggestions.map((item: Client) => {
          item.suggestion.adresses = item.suggestion.adresses.map((address: any) => {
            return {
              ...address,
              lines: [...address.lines, ...Array(4 - address.lines.length).fill('')],
            };
          });
        });
        return suggestions;
      }),
    );
  }
}

export const clientService: ClientService = new ClientService();
