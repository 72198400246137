import React, { forwardRef, Ref } from 'react';
import { SearchBar } from '../../../_shared/component/searchBar/searchBar';
import { PractitionerSuggestion } from '../../orders/draftEditorPage/components/practitionerPicker/practitionerSuggestion/practitioner.suggestion';
import { Practitioner } from '../../../_shared/models/practitioner';
import { practitionerService } from '../../../_shared/services/practitioner.service';
import ParentComponent from '../../../_shared/component/parentComponent/parent.component';
import usePractitioners from '../../../_shared/providers/practitioner/usePractitioners';
import { SpinLoader } from '../../../_shared/component/loader/loader';
import useOutsideClick from '../../../_shared/component/useClickOutside';

interface IPractitionerSuggesterProps {
  autofocus?: boolean;
  clear?: boolean;
  onPractitionerSelect: (practitioner: Practitioner) => void;
  ref?: any;
}

const PractitionerSuggester: React.FC<IPractitionerSuggesterProps> = forwardRef(
  (props: IPractitionerSuggesterProps, ref: Ref<any>) => {
    const { practitioners, searchField, setSearchField, isLoading } = usePractitioners(false, practitionerService);

    useOutsideClick(ref, () => setSearchField(null));

    const selectPractitioner = (practitioner: Practitioner) => {
      props.onPractitionerSelect(practitioner);
      if (props.clear) setSearchField(null);
    };

    return (
      <ParentComponent onBlur={() => setSearchField(null)} className="practitioner-suggester">
        <div>
          <SearchBar
            filter={searchField || ''}
            placeholder={'Rechercher un praticien'}
            onChange={setSearchField}
            ref={ref}
            onFilterRemove={() => setSearchField(null)}
            dataCy="PractitionerSuggester-search"
          />
        </div>
        {practitioners && searchField && searchField.length ? (
          <div className="relative full-width">
            <div className="absolute full-width">
              <div className="b-white bordered rounded">
                {practitioners.length > 0 ? (
                  practitioners.map((practitioner: any, index: number) => (
                    <button
                      className={`selectable p-medium full-width l-align ${
                        index !== practitioners.length - 1 ? ' br-bottom' : ''
                      }`}
                      key={index}
                      onClick={() => selectPractitioner(practitioner)}
                      data-cy={`PractitionerSuggested-${practitioner.prenom}-${practitioner.nom}`}
                    >
                      <PractitionerSuggestion practitioner={practitioner} />
                    </button>
                  ))
                ) : isLoading ? (
                  <SpinLoader size={'1rem'} thickness={'0.2rem'} />
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </ParentComponent>
    );
  },
);

export default PractitionerSuggester;
