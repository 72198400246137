import React from 'react';
import { IngredientsList } from './ingredientsList/ingredientsList';
import IngredientForm from './ingredientForm/ingredientForm';
import { Ingredient } from '../../_shared/models/ingredient';
import { CSSTransition } from 'react-transition-group';
import { IngredientConsumer, IngredientContext } from '../../_shared/providers/ingredient/ingredient.context';
import { SearchBar } from '../../_shared/component/searchBar/searchBar';
import './ingredients.page.scss';

export class IngredientsPage extends React.Component<any, any> {
  static contextType = IngredientContext;

  componentDidMount(): void {
    this.context.fillIngredients();
  }

  render() {
    return (
      <IngredientConsumer>
        {({
          ingredients,
          ingredientOnEdition,
          ingredientSelected,
          order,
          filter,
          isLoading,
          setIngredientOnEdition,
          setIngredientOnEditionAsNew,
          setIngredientSelected,
          deleteIngredient,
          fetchIngredientOnEdition,
          setOrder,
          setFilter,
        }) => (
          <div className="page ingredients-page">
            <div className="crud-header row gapped">
              <div className="title">
                <span className="material-icons">spa</span>
                <span>INGREDIENTS</span>
              </div>
              <SearchBar
                filter={filter}
                placeholder={'Recherche un ingrédient'}
                onChange={setFilter}
                onFilterRemove={() => setFilter('')}
                autofocus
              />
              <button
                className="button-validate transparent"
                disabled={!!ingredientOnEdition}
                onClick={setIngredientOnEditionAsNew}
              >
                <i className="material-icons">add</i>
                Nouvel Ingredient
              </button>
            </div>
            <div className="ingredients-page_content full-height">
              <CSSTransition
                in={!!ingredientSelected || !!ingredientOnEdition}
                timeout={0}
                classNames="list-transition"
                unmountOnExit
              >
                <div className="p-medium">
                  <div className="row bordered rounded b-white p-small">
                    <IngredientForm
                      ingredientSelected={ingredientOnEdition ? ingredientOnEdition : ingredientSelected}
                      isEditing={!!ingredientOnEdition}
                      onChange={setIngredientOnEdition}
                      handleDelete={deleteIngredient}
                    />
                    {!!ingredientOnEdition ? (
                      <div className="row gapped">
                        <button className="addButton" type="button" onClick={fetchIngredientOnEdition}>
                          <i className="material-icons">done</i>
                          Valider
                        </button>
                        <button className="cancelButton" type="button" onClick={() => setIngredientOnEdition(null)}>
                          <i className="material-icons">undo</i>
                          Annuler
                        </button>
                        {ingredientOnEdition.id ? (
                          <button className="cancelButton" type="button" onClick={deleteIngredient}>
                            <i className="material-icons">delete_forever</i>
                            Supprimer
                          </button>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      <div className="row gapped">
                        <button
                          className="cancelButton"
                          type="button"
                          onClick={() => setIngredientOnEdition(ingredientSelected)}
                        >
                          <i className="material-icons">create</i>
                          Modifier
                        </button>
                        <button className="cancelButton" type="button" onClick={() => setIngredientSelected(null)}>
                          <i className="material-icons">chevron_left</i>
                          Retour
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </CSSTransition>
              <IngredientsList
                ingredients={ingredients}
                isLoading={isLoading}
                ingredientSelected={ingredientSelected}
                isEditing={!!ingredientOnEdition}
                handleOrder={(e: string) => setOrder(e)}
                order={order}
                onSelect={(ingredient: Ingredient) => setIngredientSelected(ingredient)}
              />
            </div>
          </div>
        )}
      </IngredientConsumer>
    );
  }
}
