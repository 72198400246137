import React, { useContext, useState } from 'react';
import moment from 'moment';
import { SpinLoader } from '../../../../../_shared/component/loader/loader';
import { DraftEditorContext } from '../../../../../_shared/providers/draft/draftEditor.provider';
import useSave from '../../../../../_shared/providers/order/useSave';

export const SavePanel: React.FC<any> = () => {
  const { isSaving, save } = useSave();
  const { lastSave } = useContext(DraftEditorContext);
  return (
    <div className="row end t-medium gapped t-grey">
      {isSaving ? <SpinLoader thickness={'0.2em'} size={'1em'} color={'#89bc7a'} /> : ''}
      <div>
        <span className="light">Dernière sauvegarde:</span>
        {moment(lastSave).locale('fr').format('HH:mm:ss')}
      </div>
      <button onClick={save} className="p-small circle-button">
        <i className="material-icons t-grey">save</i>
      </button>
    </div>
  );
};
