import { from, Observable, of, throwError } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { BASE_URL } from '../config/constants';
import { Ingredient } from '../models/ingredient';

class IngredientService {
  getAllIngredients(): Observable<Ingredient[] | any> {
    return from(
      fetch(`${BASE_URL}/ingredient`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  createIngredient(ingredient: Ingredient): Observable<Ingredient> {
    const body: any = Object.assign({}, { ...ingredient });
    body.codeprix = body.codeprix.id;
    body.code = ingredient.code && ingredient.code.length > 0 ? ingredient.code : undefined;

    return from(
      fetch(`${BASE_URL}/ingredient?refresh=true`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(body),
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  updateIngredient(ingredient: Ingredient): Observable<any> {
    const body: any = {
      code: ingredient.code && ingredient.code.length > 0 ? ingredient.code : undefined,
      chinois: ingredient.chinois,
      latin: ingredient.latin,
      codeprix: ingredient.codeprix.id,
      synonymes: ingredient.synonymes,
      reglementation: ingredient.reglementation,
      pinyin: ingredient.pinyin,
    };

    return from(
      fetch(`${BASE_URL}/ingredient/${ingredient.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(body),
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return of(true);
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  deleteIngredient(ingredientId: string): Observable<any> {
    return from(
      fetch(`${BASE_URL}/ingredient/${ingredientId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return of(true);
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  getIsPropsExist(type: string, value: string | number) {
    if ((type === 'pinyin' || type === 'synonymes') && typeof value === 'string') {
      value = value.toLowerCase();
    }
    const body = { type, value };
    return from(
      fetch(`${BASE_URL}/search/ingredient`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(body),
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  getSuggestions(field: string, formeId: string | undefined) {
    return from(
      fetch(`${BASE_URL}/suggest/ingredient?query=${field}${formeId ? `&fid=${formeId}` : ''}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return [];
        }
      }),
    );
  }

  parse(text: string, isBullet: boolean, formeId: string) {
    return from(
      fetch(`${BASE_URL}/parser${'?bullet=' + isBullet}${'&fid=' + formeId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          content: text,
        }),
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
      map((res: any) => {
        // we log the ingredients that were returned #50
        console.log('================');
        console.log('Parsing result');
        const logs: any[] = [];
        res.forEach((ing: any) => {
          logs.push([ing.match, ing.ing.id ? ing.ing.pinyin : ing.ing, ing.weight]);
        });
        console.log(logs.join(' || '));
        console.log('================');

        const ingredients: any = [];
        res.map((data: any) => {
          const ing =
            data.ing && data.ing.id
              ? {
                  id: data.ing.id,
                  code: data.ing.code,
                  pinyin: data.ing.pinyin,
                  proportion: data.weight,
                  codeprix: data.ing.codeprix,
                  percent: '0',
                  poids_reel: 0,
                  prix: data.ing.prix,
                  prix_total: 0,
                }
              : {
                  id: null,
                  pinyin: data.match,
                  proportion: data.weight,
                };
          console.log(ing);
          ingredients.push(ing);
        });
        return ingredients;
      }),
    );
  }
}

export const ingredientService: IngredientService = new IngredientService();
