import React, { useContext, useState } from 'react';
import { AuthContext } from '../routing/auth.context';
import TextInput from '../../_shared/component/textInput/textInput.component';
import { SpinLoader } from '../../_shared/component/loader/loader';
import { version } from '../../../package.json';
import './login.scss';

export const LoginPage: React.FC = () => {
  const { login, isLoading, authError } = useContext(AuthContext);
  const [state, setState] = useState({
    user: '',
    password: '',
  });

  const onStateUpdate = (e: any) => {
    setState({
      ...state,
      ...e,
    });
  };

  const onLogin = (e: any) => {
    e.preventDefault();
    login(state.user, state.password);
  };

  return (
    <div className="login-page page aligned">
      <form className="login-form card ">
        <div className="p-medium t-xlarge">Connexion</div>
        <TextInput
          value={state.user}
          isEditable={true}
          label={'login'}
          name={'user'}
          className={'p-small t-large'}
          onChange={onStateUpdate}
          dataCy="login"
        />
        <TextInput
          value={state.password}
          isEditable={true}
          name={'password'}
          label={'mot de passe'}
          className={'p-small t-large'}
          type={'password'}
          onChange={onStateUpdate}
          dataCy="password"
        />
        <button
          className={'login_button'}
          disabled={state.user.trim().length < 1 || state.password.trim().length < 1}
          onClick={onLogin}
          data-cy="login-submit"
        >
          {isLoading ? <SpinLoader size={'1em'} thickness={'2px'} /> : ''}
          Connexion
        </button>
      </form>
      {authError ? <div className="errorMessage">{authError}</div> : ''}
      <div className="row t-grey">{version}</div>
    </div>
  );
};
