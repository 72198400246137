import { from, Observable, of, throwError } from 'rxjs';
import { flatMap, map, switchMap } from 'rxjs/operators';
import moment from 'moment';
import {
  BASE_URL,
  BROUILLON_STATUS,
  DEVIS_STATUS,
  DRAFT_STATUS,
  ENVOIE_STATUS,
  VALIDEE_STATUS,
  ORDER_STATUS,
  ORDERS_LIMIT,
  EXPORT_LIMIT,
  DEPOT_STATUS,
} from '../config/constants';
import { Client } from '../models/client';
import { Practitioner } from '../models/practitioner';
import { OrderFilters } from '../types/OrderFilter';

moment.locale('fr');

export const isDraftStatus = (status: string) => {
  switch (status) {
    case BROUILLON_STATUS:
      return true;
    case VALIDEE_STATUS:
      return true;
    case ENVOIE_STATUS:
      return true;
    case DEPOT_STATUS:
      return true;
    case DEVIS_STATUS:
      return true;
    default:
      return false;
  }
};

export class OrderService {
  isDraftStatus = (status: string) => !!DRAFT_STATUS[status];
  isOrderStatus = (status: string) => !!ORDER_STATUS[status];

  createNewDraft() {
    return from(
      fetch(`${BASE_URL}/draft`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  saveDraft(draft: any) {
    return from(
      fetch(`${BASE_URL}/draft/${draft.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(draft),
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  getAllDraftsByStatus(filters: any, offset: number, sort: string, direction: string) {
    const filtersQuery = filters
      ? Object.keys(filters).reduce((curr: string, acc: string) => curr + `&${acc}=${filters[acc]}`, '')
      : '';

    // in case we get "depot" status, we want all the orders, so that we can select all and create bordereau
    const limit = filters.status == DEPOT_STATUS ? 10000 : ORDERS_LIMIT;

    return from(
      fetch(
        `${BASE_URL}/draft?${filters ? filtersQuery : ''}&limit=${limit}&offset=${offset}${
          sort ? '&sort=' + sort + ',' + direction : ''
        }`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        },
      ),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  getDraftById(id: string) {
    return from(
      fetch(`${BASE_URL}/draft/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
      map((res: any) => {
        return this.getOrderMap(res);
      }),
    );
  }

  updateDraftStatus(id: string, status: string) {
    return from(
      fetch(`${BASE_URL}/draft/${id}/lifecycle?status=${status}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return of(true);
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  updateDraftsStatus(ids: string[], status: string) {
    return from(
      fetch(`${BASE_URL}/drafts/lifecycle?status=${status}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          draftIds: ids,
        }),
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return of(true);
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  printOrder(id: string, orderType: string, noTVA: boolean, comment: string): Observable<any> {
    return from(
      fetch(`${BASE_URL}/print/${id}?stage=${orderType}&noTVA=${noTVA}&comment=${comment}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Response-Type': 'blob',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return from(response.blob());
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  printClientOrder(clientId: string): Observable<any> {
    return from(
      fetch(`${BASE_URL}/print/?stage=facture_non_payees`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Response-Type': 'blob',
        },
        credentials: 'include',
        body: JSON.stringify({ cid: clientId }),
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return from(response.blob());
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  printMultiOrders(ids: string[], orderType: string) {
    return from(
      fetch(`${BASE_URL}/print?stage=${orderType}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Response-Type': 'blob',
        },
        credentials: 'include',
        body: JSON.stringify({
          ids: ids,
        }),
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return from(response.blob());
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  getAllOrder(filters: Partial<OrderFilters>, offset: number, sort: any, direction: string) {
    const filtersQuery = filters
      ? Object.keys(filters).reduce((curr: string, acc: string) => curr + `&${acc}=${(filters as any)[acc]}`, '')
      : '';

    return from(
      fetch(
        `${BASE_URL}/order${filters ? `?${filtersQuery}` : ''}&limit=${ORDERS_LIMIT}&offset=${offset}${
          sort ? '&sort=' + sort + ',' + direction : ''
        }`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        },
      ),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  getOrderById(id: string) {
    return from(
      fetch(`${BASE_URL}/order/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
      map((res: any) => {
        return this.getOrderMap(res);
      }),
    );
  }

  getOrderByNumber(number: string) {
    return from(
      fetch(`${BASE_URL}/search/order?code=${number}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
      map((res: any) => {
        return this.getOrderMap(res);
      }),
    );
  }

  updateOrderStatus(ids: string[], status: string) {
    return from(
      fetch(`${BASE_URL}/orders/lifecycle/?status=${status}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          ids: ids,
        }),
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return of(true);
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  createPaiementOrder(id: string, editStatus: string, type: 'URL' | 'SMS' | 'MAIL' = 'URL') {
    return from(
      fetch(`${BASE_URL}/${isDraftStatus(editStatus) ? 'draft' : 'order'}/${id}/paiementOrder?type=${type}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
      map((res: any) => {
        console.log(res);
        return res.url;
      }),
    );
  }

  copyOrder(orderId: string, draftId?: string | null) {
    const CopyOrder$ = (orderId: string, draftId?: string) =>
      from(
        fetch(`${BASE_URL}/draft/${draftId}?renew=${orderId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        }),
      ).pipe(
        flatMap((response: Response) => {
          if (response.ok) {
            return response.json();
          } else {
            return throwError({
              status: response.status,
              message: response.text(),
            });
          }
        }),
        map((res: any) => {
          return this.getOrderMap(res);
        }),
      );

    if (draftId) {
      return CopyOrder$(orderId, draftId);
    } else {
      return this.createNewDraft().pipe(
        switchMap((id) => {
          return CopyOrder$(orderId, id);
        }),
      );
    }
  }

  exportStats(filters: any) {
    const filtersQuery = Object.keys(filters)
      .filter((k) => !!filters[k])
      .map((key) => `${key}=${filters[key]}`)
      .join('&');

    const date = moment().local().format('YYYY-MM-DD');

    let fileName = `stats-${date}.xlsx`;

    return from(
      fetch(`${BASE_URL}/stats${filters ? `?${filtersQuery}` : ''}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          const content = response.headers.get('content-disposition');
          if (content !== null) {
            fileName = (content as any).match(/filename="(.+)"/)[1];
          }
          return response.blob();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
      map((response) => {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a') as any;
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }),
    );
  }

  exportOrders(filters: Partial<OrderFilters>) {
    const filtersQuery = filters
      ? Object.keys(filters).reduce((curr: string, acc: string) => curr + `&${acc}=${(filters as any)[acc]}`, '')
      : '';

    const date = moment().local().format('YYYY-MM-DD');

    let fileName = `export-${date}.csv`;

    return from(
      fetch(`${BASE_URL}/order${filters ? `?${filtersQuery}` : ''}&limit=${EXPORT_LIMIT}`, {
        method: 'GET',
        headers: {
          Accept: 'text/csv',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          const content = response.headers.get('content-disposition');
          if (content !== null) {
            fileName = (content as any).match(/filename="(.+)"/)[1];
          }
          return response.blob();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
      map((response) => {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a') as any;
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }),
    );
  }

  updateOrderComment(id: string, commentaire?: string, commentairePaiement?: string) {
    return from(
      fetch(`${BASE_URL}/order/${id}/comment`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          commentaire,
          commentairePaiement,
        }),
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return of(true);
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  addPaiement(id: string, amount: number, date: string, toPraticien: boolean) {
    return from(
      fetch(`${BASE_URL}/order/${id}/paiement?amount=${amount}&date=${date}&toPraticien=${toPraticien}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
      map((res: any) => {
        return this.getOrderMap(res);
      }),
    );
  }

  private getOrderMap(res: any) {
    return {
      id: res.id,
      code: res.code,
      editStatus: res.editStatus,
      commentaire: res.commentaire,
      commentairePaiement: res.commentairePaiement,
      compositionSelected: 0,
      practitioner: res.praticien,
      client: res.client ? res.client.object : null,
      clientWarning: res.clientWarning,
      events: res.events as Event[],
      dates: {
        createdAt: res.createdAt,
        validationDate: res.validationDate,
        paiementDate: res.paiementDate,
        relanceDate: res.relanceDate,
      },
      createdAt: res.createdAt,
      adresseSelected: res.client ? res.client.adresseSelected : 0,
      compositions:
        res.preparations.map((prep: any) => {
          return {
            ...prep,
            isPoidsSet: true,
            isContenantSet: true,
            isPoidsContenantSet: true,
            libelle: prep.libelle,
            poids_contenant: prep.poidsContenant,
            contenants: prep.poidsContenant,
            poids: prep.poids,
            ingredients: prep.ingredients.map((ing: any) => {
              return {
                id: ing.ingredient ? ing.ingredient.id : '',
                proportion: ing.proportion,
                prix: ing.prix,
                percent: ing.pourcentage,
                poids: ing.poids,
                code: ing.ingredient ? ing.ingredient.code : '',
                codeprix: ing.ingredient ? ing.ingredient.codeprix : '',
                pinyin: ing.ingredient ? ing.ingredient.pinyin : '',
                aPart: ing.aPart ? ing.aPart : false,
                designation: ing.designation,
              };
            }),
          };
        }) || [],
      prix: res.prix,
      prixTotal: res.prixCalcul,
      reste: res.reste,
      poids: res.poids,
      fraisPort: typeof res.fraisPort !== 'undefined' ? res.fraisPort : res.editStatus === BROUILLON_STATUS ? 6 : 0,
      shipment: res.shipment || { type: 'colissimo' },
      printLatin: res.printLatin || false,
      isPrepaid: res.isPrepaid || false,
      paiement: res.paiement,
      remise: res.remise || 0,
      tauxRemise: res.tauxRemise || 0,
      paiementURL: res.paiementURL,
    };
  }
}

export interface DraftFilters {
  code: string;
  status: string;
  prixMin: string;
  prixMax: string;
  dateEnd: string;
  dateStart: string;
  cid: Client['id'][];
  pid: Practitioner['id'][];
  shipment: string;
}

export const orderService: OrderService = new OrderService();
