import * as React from 'react';
import { IFormFieldProps } from '../ingredientForm';

interface ICodeInputProps extends IFormFieldProps<string> {
  code: string;
}

const handleOnChange = (e: any) => {
  return { target: { value: e.target.value.toUpperCase(), name: 'code' } };
};

const CodeInput: React.FunctionComponent<ICodeInputProps> = (props: ICodeInputProps) => {
  return (
    <div>
      <label>
        CODE <span style={{ color: 'red' }}>{props.isExist ? 'Existe déjà' : ''}</span>
      </label>

      <input
        className={props.isExist ? 'onError' : ''}
        autoFocus
        type="text"
        name="code"
        disabled={!props.isEditable}
        value={props.code}
        autoComplete="off"
        placeholder="Code"
        onChange={(e) => props.onChange(handleOnChange(e))}
      />
    </div>
  );
};

export default CodeInput;
