import { FORME_COLORS, FORME_LABELS } from '../../../../../_shared/config/constants';
import React, { useContext } from 'react';
import { FormeContext } from '../../../../../_shared/providers/forme/forme.context';

interface IFormesListProps {
  onSelect: any;
  formes?: any[];
}

export const FormesList: React.FC<IFormesListProps> = (props: IFormesListProps) => {
  const { formes } = useContext(FormeContext);

  const formesUsed = props.formes || formes;

  return (
    <>
      {formesUsed.map((forme: any, index: number) => (
        <button
          className="row gapped selectable"
          key={index}
          autoFocus={index === 0}
          onClick={() => {
            props.onSelect(forme);
          }}
          data-cy={`FormesList-${forme.code}`}
        >
          <div className="circle" style={{ backgroundColor: FORME_COLORS[forme.code] }} />
          <div>{FORME_LABELS[forme.code] || forme.label}</div>
        </button>
      ))}
    </>
  );
};
