import * as React from 'react';
import { Practitioner } from '../../../_shared/models/practitioner';
import { TUextInput } from '../../../_shared/component/textInput/textInput.component';
import { TextArea } from '../../../_shared/component/textArea/textArea.component';
import Checkbox from '../../../_shared/component/checkBox/checkbox';
import './practitioner.form.css';
import { AddressForm } from '../../clients/clientForm/components/addressTab.component';
import { Address } from '../../../_shared/models/client';
import NumberInput from '../../../_shared/component/numberInput';
import PointEventList from '../PointEventList';
import { useState } from 'react';

interface IPractitionerForm {
  practitionerSelected: Practitioner;
  isOnEdition: boolean;
  onChange: (updates: Partial<Practitioner>) => void;
  onNewPEventAdded?: (amount: number) => void;
  onPEventDeleted?: (pointEventId: string) => void;
}

const PractitionerForm: React.FC<IPractitionerForm> = ({
  practitionerSelected,
  isOnEdition,
  onChange,
  onNewPEventAdded,
  onPEventDeleted,
}: IPractitionerForm) => {
  const [showPEventList, setShowPEventList] = useState<boolean>(false);

  const handleAdresseChange = (updates: Partial<Address>) => {
    let toUpdate: Partial<Practitioner> = {};
    const update = (a: any) => {
      toUpdate = { ...toUpdate, ...a };
    };
    if (updates.lines) {
      update({ adresse: updates.lines });
    }
    if (updates.cp) {
      update({ cp: updates.cp });
    }
    if (updates.ville) {
      update({ ville: updates.ville });
    }
    if (updates.pays) {
      update({ pays: updates.pays });
    }
    onChange(toUpdate);
  };

  /**
   * Called when a new point event is added.
   * @param amount {number} the amount of the new point event
   */
  const handleNewPEventAdded = (amount: number) => {
    if (!onNewPEventAdded) return;
    onNewPEventAdded(amount);
  };

  /**
   * Called when a new point event is deleted.
   * @param pointEventId {string} the id of point event
   */
  const handlePEventDeleted = (pointEventId: string) => {
    if (!onPEventDeleted) return;
    onPEventDeleted(pointEventId);
  };

  const adresse: any = {
    label: '',
    lines: practitionerSelected.adresse,
    cp: practitionerSelected.cp,
    ville: practitionerSelected.ville,
    pays: practitionerSelected.pays || 'FRANCE',
  };

  const points = practitionerSelected.points?.balance ?? 0;

  return (
    <div className="practitioner-form">
      <form>
        <div className="form-section">
          {isOnEdition ? (
            <>
              <div className="form-section_label row t-grey light">
                <span className="material-icons">person</span>
                <span>INFORMATIONS PERSONNELLES</span>
              </div>
              <div className="form-section_content">
                <TUextInput
                  value={practitionerSelected.code}
                  name={'code'}
                  className={'upperCase p-small'}
                  resize={true}
                  autofocus={true}
                  suffix={''}
                  error={
                    practitionerSelected.code.length > 4 ? 'Le code doit être composé de 4 caractère max' : undefined
                  }
                  disabled={!isOnEdition}
                  onChange={onChange}
                />
                <TUextInput
                  value={practitionerSelected.nom}
                  className="upperCase bold"
                  name={'nom'}
                  disabled={!isOnEdition}
                  onChange={onChange}
                />
                <TUextInput
                  value={practitionerSelected.prenom}
                  name={'prenom'}
                  className="capitalize"
                  disabled={!isOnEdition}
                  onChange={onChange}
                />
              </div>
            </>
          ) : (
            <div className="row gapped t-xlarge">
              <b className="upperCase ">{practitionerSelected.code}</b>{' '}
              <b className={'upperCase'}>{practitionerSelected.nom}</b> <span>{practitionerSelected.prenom}</span>
            </div>
          )}
        </div>

        <div className="form-section">
          <div className="form-section_label row t-grey light">
            <span className="material-icons">home</span>
            <span>ADRESSE</span>
          </div>
          <div className="form-section_content">
            <AddressForm address={adresse} onChange={handleAdresseChange} isEditing={isOnEdition} />
          </div>
        </div>

        <div className="form-section">
          <div className="form-section_label row p-medium light">
            <span className="material-icons">add_ic_call</span>
            <span>CONTACT</span>
          </div>
          {isOnEdition ? (
            <div className="form-section_content">
              <div className="row gapped">
                <TUextInput
                  value={practitionerSelected.mobile}
                  name={'mobile'}
                  suffix={<span className="material-icons">stay_current_portrait</span>}
                  size={15}
                  resize={true}
                  disabled={!isOnEdition}
                  isHidden={!practitionerSelected.mobile && !isOnEdition}
                  onChange={onChange}
                />
              </div>
              <div className="form-section row wrap gapped">
                <TUextInput
                  value={practitionerSelected.fixe}
                  name={'fixe'}
                  suffix={<span className="material-icons">call</span>}
                  size={15}
                  resize={true}
                  disabled={!isOnEdition}
                  isHidden={!practitionerSelected.fixe && !isOnEdition}
                  onChange={onChange}
                />
                <TUextInput
                  value={practitionerSelected.tel3}
                  name={'tel3'}
                  suffix={<span className="material-icons">call</span>}
                  size={15}
                  resize={true}
                  disabled={!isOnEdition}
                  isHidden={!practitionerSelected.tel3 && !isOnEdition}
                  onChange={onChange}
                />
                <TUextInput
                  value={practitionerSelected.tel4}
                  name={'tel4'}
                  suffix={<span className="material-icons">call</span>}
                  size={15}
                  resize={true}
                  disabled={!isOnEdition}
                  isHidden={!practitionerSelected.tel4 && !isOnEdition}
                  onChange={onChange}
                />
              </div>
              <div>
                <TUextInput
                  value={practitionerSelected.mail}
                  name={'mail'}
                  suffix={<span className="material-icons">alternate_email</span>}
                  isHidden={!practitionerSelected.mail && !isOnEdition}
                  disabled={!isOnEdition}
                  onChange={onChange}
                />
              </div>
            </div>
          ) : (
            <div className="form-section_content">
              {practitionerSelected.mobile ? (
                <div className="row gapped light t-grey">
                  <span className="material-icons t-large">stay_current_portrait</span>
                  <span>{practitionerSelected.mobile}</span>
                </div>
              ) : null}
              {practitionerSelected.fixe ? (
                <div className="row gapped light t-grey">
                  <span className="material-icons t-large">call</span>
                  <span>{practitionerSelected.fixe}</span>
                </div>
              ) : null}
              {practitionerSelected.tel3 ? (
                <div className="row gapped light t-grey">
                  <span className="material-icons t-large">call</span>
                  <span>{practitionerSelected.tel3}</span>
                </div>
              ) : null}
              {practitionerSelected.tel4 ? (
                <div className="row gapped light t-grey">
                  <span className="material-icons t-large">call</span>
                  <span>{practitionerSelected.tel4}</span>
                </div>
              ) : null}
              {practitionerSelected.mail ? (
                <div className="row gapped light t-grey">
                  <span className="material-icons t-large">alternate_email</span>
                  <span>{practitionerSelected.mail}</span>
                </div>
              ) : null}
            </div>
          )}
        </div>

        <div className="form-section">
          <div className="form-section_label row p-medium light">
            <span className="material-icons">work_outline</span>
            <span>INFOS</span>
          </div>
          <div className="form-section_content">
            <div className="row">
              <label>REMISE</label>
              <NumberInput
                name={'remise'}
                value={practitionerSelected.remise || 0}
                locked={!isOnEdition}
                size={5}
                resize={true}
                className="t-large light"
                prefix={'%'}
                onChange={onChange}
              />
            </div>
            <div className="row">
              <label>JOINDRE LES PRESCRIPTIONS</label>
              {isOnEdition ? (
                <Checkbox
                  name={'prescription'}
                  value={practitionerSelected.prescription || false}
                  onChange={(value: boolean) => onChange({ prescription: value })}
                />
              ) : practitionerSelected.prescription ? (
                'Oui'
              ) : (
                'Non'
              )}
            </div>
            <div className="row">
              <label>DEVIS PRÉALABLE</label>
              {isOnEdition ? (
                <Checkbox
                  name={'devisPrealable'}
                  value={practitionerSelected.devisPrealable || false}
                  onChange={(value: boolean) => onChange({ devisPrealable: value })}
                />
              ) : practitionerSelected.devisPrealable ? (
                'Oui'
              ) : (
                'Non'
              )}
            </div>
            <div className="row">
              <label>RENOUVELLEMENT REFUSÉ</label>
              {isOnEdition ? (
                <Checkbox
                  name={'renouvellementRefuse'}
                  value={practitionerSelected.renouvellementRefuse || false}
                  onChange={(value: boolean) => onChange({ renouvellementRefuse: value })}
                />
              ) : practitionerSelected.renouvellementRefuse ? (
                'Oui'
              ) : (
                'Non'
              )}
            </div>
            <div className="row">
              <label>PRÉPAYÉ REQUIS</label>
              {isOnEdition ? (
                <Checkbox
                  name={'prepayeRequis'}
                  value={practitionerSelected.prepayeRequis || false}
                  onChange={(value: boolean) => onChange({ prepayeRequis: value })}
                />
              ) : practitionerSelected.prepayeRequis ? (
                'Oui'
              ) : (
                'Non'
              )}
            </div>
          </div>
          {isOnEdition || practitionerSelected.note ? (
            <div>
              <label>NOTES</label>
              <TextArea
                name={'note'}
                value={practitionerSelected.note || ''}
                className="full-width"
                locked={!isOnEdition}
                minRows={3}
                onChange={onChange}
              />
            </div>
          ) : null}
        </div>

        {/* Practitioner points */}
        <div className="form-section">
          <div className="form-section_label row p-medium light">
            <span className="material-icons">stars</span>
            <span>POINTS</span>
          </div>
          <div className="form-section_content">
            <button type="button" className="button-lgrey light" onClick={() => setShowPEventList(!showPEventList)}>
              {/* Point history icon */}
              <span className="material-icons t-grey t-xlarge">history</span>
              {/* Amount */}
              <b className="mr-1">{points}</b> point{points > 0 ? 's' : ''}
            </button>
          </div>
          {showPEventList && (
            <PointEventList
              points={practitionerSelected.points}
              onNewPEventAdded={handleNewPEventAdded}
              onPEventDeleted={handlePEventDeleted}
              className="mt-2"
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default PractitionerForm;
