import React from 'react';
import './loader.css';

interface ISpinLoaderProps {
  size: string;
  thickness: string;
  color?: string;
  className?: string;
}

export const SpinLoader: React.FC<ISpinLoaderProps> = (props: ISpinLoaderProps) => {
  return (
    <div
      className={`lds-ring ${props.className ? props.className : ''}`}
      style={{ width: props.size, height: props.size }}
    >
      {Array.from(Array(4).keys()).map((i) => (
        <div
          key={i}
          style={{
            borderWidth: `${props.thickness}`,
            width: `calc(${props.size} - ${props.thickness})`,
            height: `calc(${props.size} - ${props.thickness})`,
            margin: `${props.thickness}`,
            borderTopColor: props.color || 'lightgrey',
          }}
        />
      ))}
    </div>
  );
};
