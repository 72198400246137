import Row from '../../../../../_shared/component/row/row';
import { PractitionerCard } from '../../../components/practitionerCard/practitioner.card';
import PractitionerSuggester from '../../../../practitioner/practitionerSuggester/practitioner.suggester';
import React, { useContext } from 'react';
import { DraftEditorContext } from '../../../../../_shared/providers/draft/draftEditor.provider';
import { setPractitioner, setSelected } from '../../../../../_shared/providers/draft/draftEditor.actions';
import { Practitioner } from '../../../../../_shared/models/practitioner';
import { useRemise } from '../../../../../_shared/providers/draft/useRemise';

const PractitionerInfo: React.FC<any> = ({ remised }: any) => {
  const { practitioner, dispatch } = useContext(DraftEditorContext);
  const remise = useRemise();
  return (
    <button className=" wrap l-align br-bottom start p-small full-width l-align" tabIndex={0} autoFocus={true}>
      <div className={`row full-width`}>
        {practitioner ? (
          <Row className="full-width">
            <div className="circle p-medium column center t-grey b-lgrey bordered">
              <i className="material-icons t-green">medical_services</i>
            </div>
            <Row className="full-width" align="spaced">
              <PractitionerCard practitioner={practitioner} remised={remise > 0 && remise === practitioner.remise} />
              <div className="column">
                <span className="material-icons t-grey" onClick={() => dispatch(setSelected('practitioner'))}>
                  search
                </span>
                <span className="material-icons t-grey" onClick={() => dispatch(setPractitioner(null))}>
                  close
                </span>
              </div>
            </Row>
          </Row>
        ) : (
          <div className="row full-width">
            <div className="circle p-medium column center t-grey b-lgrey bordered">
              <i className="material-icons">medical_services</i>
            </div>
            <div className="full-width">
              <div className="light t-green">PRATICIEN</div>
              <PractitionerSuggester
                onPractitionerSelect={(practitioner: Practitioner) => dispatch(setPractitioner(practitioner))}
              />
            </div>
            <div className="row gapped " onClick={() => dispatch(setSelected('practitioner'))}>
              <div className="flex-text t-green">+</div>
            </div>
          </div>
        )}
      </div>
    </button>
  );
};

export default PractitionerInfo;
