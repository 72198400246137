import React from 'react';

interface IPrintOrderButtonProps {
  onPrintOrder: any;
  minified?: boolean;
  disabled?: boolean;
}

export const PrintOrderButton: React.FC<IPrintOrderButtonProps> = (props: IPrintOrderButtonProps) => {
  return (
    <div>
      <button
        className={props.minified ? 'button-print minified' : 'button-print'}
        disabled={props.disabled}
        onClick={() => props.onPrintOrder('prepa')}
      >
        <i className="material-icons">print</i>
        {props.minified ? '' : 'Imprimer'}
      </button>
    </div>
  );
};
