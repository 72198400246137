import { useContext, useState } from 'react';
import { ServiceContext } from '../../../_core/services/service.context';
import { DraftEditorContext } from '../draft/draftEditor.provider';
import { BROUILLON_STATUS } from '../../config/constants';
import { orderService } from '../../services/order.service';
import { setLastSave } from '../draft/draftEditor.actions';

const useSave = () => {
  const { query } = useContext(ServiceContext);

  const {
    id,
    code,
    compositions,
    client,
    practitioner,
    commentaire,
    commentairePaiement,
    adresseSelected,
    fraisPort,
    paiement,
    shipment,
    dispatch,
  } = useContext(DraftEditorContext);

  const [isSaving, setIsSaving] = useState(false);

  const save = () => {
    if (!id) return;
    const draft: any = {
      id: id,
      code: code,
      editStatus: BROUILLON_STATUS,
      commentaire: commentaire,
      commentairePaiement: commentairePaiement,
      client: {
        object: client ? client.id : null,
        adresseSelected: adresseSelected,
      },
      preparations: compositions.map((prep: any, index: number) => {
        return {
          index: index,
          destinataire: prep.destinataire,
          commentaire: prep.commentaire,
          poids: prep.poids,
          contenant: prep.contenant,
          libelle: prep.libelle,
          poidsContenant: prep.poids_contenant,
          forme: prep.forme.id,
          ingredients: prep.ingredients.map((ing: any, ind: number) => {
            return {
              ingredient: ing.id,
              designation: ing.designation,
              proportion: ing.proportion,
              prix: ing.prix,
              aPart: ing.aPart ? ing.aPart : false,
            };
          }),
        };
      }),
      fraisPort: fraisPort,
      shipment: shipment || { type: 'colissimo' },
      paiement: paiement,
    };
    if (practitioner) draft.praticien = practitioner.id;

    setIsSaving(true);

    query(orderService.saveDraft(draft))
      .subscribe(
        () => {
          dispatch(setLastSave(new Date()));
        },
        (err: any) => console.log(err),
      )
      .add(() => {
        setIsSaving(false);
      });
  };

  return { isSaving, save };
};

export default useSave;
