import React from 'react';

export interface IAuthContext {
  login: any;
  logout: any;
  isAuth: boolean;
  isLoading: boolean;
  authError: any | null;
}

const AuthContext = React.createContext<Partial<IAuthContext>>({});

const AuthConsumer = AuthContext.Consumer;

export { AuthContext, AuthConsumer };
