import React, { useEffect, useState } from 'react';
import { CrudButtonPanel } from '../../_shared/component/crudButtonPanel/crudButtonPanel';
import { Client } from '../../_shared/models/client';
import useClient from '../../_shared/providers/client/useClient';
import useClientOnEdition from '../../_shared/providers/client/useClientOnEdition';
import { useDraftById } from '../../_shared/providers/draft/useDraftById';
import { useOrderById } from '../../_shared/providers/order/useOrderById';
import { OrderDetail } from '../orders/orderPage/order.detail';
import './client.page.scss';
import { CLientDraftsList } from './clientDraftsHistory/clientDrafts.list';
import ClientForm from './clientForm/client.form';
import { ClientOrderList } from './clientOrderHistory/clientOrder.list';
import { useHistory } from 'react-router-dom';
import Modal from '../../_shared/component/modal/modal';

export const ClientPage: React.FC<any> = ({ match }: any) => {
  const history = useHistory();

  const { client, adresse: adresseSelected, setClientState, getById } = useClient();

  const {
    clientOnEdition,
    adresseOnEdition,
    updateClientOnEdition,
    setClientOnEdition,
    setClientOnEditionAsNew,
    setAdresseOnEdition,
    setAdresseOnEditionAsNew,
    fetchClientOnEdition,
    deleteClientOnEdition,
  } = useClientOnEdition();

  const {
    order,
    orderId,
    setOrderId,
    isLoading: orderLoading,
    updateStatus: updateStatusOrder,
    updateComment,
  } = useOrderById();

  const { draft, setDraftId, updateStatus: updateStatusDraft, isLoading: draftLoading } = useDraftById();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [refreshLists, setRefreshLists] = useState<boolean>(false);

  const refresh = () => {
    setRefreshLists(true);
    setTimeout(() => setRefreshLists(false), 100);
  };

  const updateStatus = (status: string) => {
    orderId ? updateStatusOrder(status, refresh) : updateStatusDraft(status, refresh);
  };

  const selectById = (id: string, isDraft = false) => {
    if (isDraft) {
      setDraftId(id);
      setOrderId(undefined);
    } else {
      setOrderId(id);
      setDraftId(undefined);
    }
  };

  useEffect(() => {
    const id = match.params.id;
    if (id) {
      if (id == 'new') {
        setClientOnEditionAsNew();
      } else {
        getById(match.params.id);
      }
    }
  }, []);

  const handleAddressSelected = (adresse: number) => {
    if (adresseOnEdition !== undefined) {
      setAdresseOnEdition(adresse);
    } else {
      setClientState({ client: client as Client, adresse });
    }
  };

  const pickedClient = clientOnEdition || client;
  const selected = order || draft;
  const isLoading = orderLoading || draftLoading;
  const clientAdresse = adresseOnEdition === undefined ? adresseSelected : adresseOnEdition;

  return !pickedClient ? (
    <div>Client non sélectionné</div>
  ) : (
    <div className="page client-page b-grey">
      <Modal on={showDeleteModal} onCancel={() => setShowDeleteModal(false)}>
        <div className="p-medium card column gapped">
          <div className="row center">
            <span className="material-icons t-grey" style={{ fontSize: '4rem' }}>
              delete_forever
            </span>
          </div>
          <div className="br-bottom">Êtes-vous sûr de vouloir supprimer le client {client?.num}</div>
          <div className="row center gapped">
            <button className="button-validate" onClick={() => deleteClientOnEdition(() => history.goBack())}>
              Oui
            </button>
            <button className="button-alert" onClick={() => setShowDeleteModal(false)}>
              Non
            </button>
          </div>
        </div>
      </Modal>
      <div className="full-height scrollable-y">
        <div className="client-page_info p-medium ">
          <div className="card">
            <ClientForm
              client={pickedClient}
              adresseSelected={clientAdresse}
              isOnEdition={!!clientOnEdition}
              onChange={updateClientOnEdition}
              onAddressSelect={handleAddressSelected}
              onNewAddress={setAdresseOnEditionAsNew}
            />
            <div className="row spaced p-small">
              <CrudButtonPanel
                isOnEdition={!!clientOnEdition}
                onEdition={() => setClientOnEdition(client, client?.adresseSelected)}
                onValidate={() =>
                  fetchClientOnEdition((client: Client) => {
                    setClientState({ client, adresse: clientAdresse || 0 });
                    setClientOnEdition(undefined, undefined);
                  })
                }
                onCancelEdition={() => (pickedClient.id ? setClientOnEdition(undefined, undefined) : history.goBack())}
              />
              {!!clientOnEdition ? (
                <button onClick={() => setShowDeleteModal(true)} className="cancelButton">
                  <span className="material-icons">delete_forever</span>
                  Supprimer
                </button>
              ) : null}
            </div>
          </div>

          {!refreshLists ? (
            <>
              <div className="card">
                <CLientDraftsList clientId={pickedClient.id} onSelect={(id: string) => selectById(id, true)} />
              </div>
              <div className="card">
                <ClientOrderList
                  clientId={pickedClient.id as string}
                  onSelect={(id: string) => selectById(id)}
                  onWarning={undefined}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className="client-page_order br-left">
        {selected ? (
          <OrderDetail
            order={selected}
            isLoading={isLoading}
            onCommentChange={updateComment}
            onUpdateStatus={updateStatus}
          />
        ) : (
          <div className="full-width full-height column center">
            <span className="material-icons t-lgrey" style={{ fontSize: '4rem' }}>
              open_in_new
            </span>
            <span className="t-grey">Sélectionner une commande pour la visualiser</span>
          </div>
        )}
      </div>
    </div>
  );
};
