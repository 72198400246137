import { useContext } from 'react';
import { ServiceContext } from '../../../_core/services/service.context';
import { orderService } from '../../services/order.service';

const openPdf = (data: any) => {
  const file = new Blob([data], {
    type: 'application/pdf',
  });
  const fileURL = URL.createObjectURL(file);
  const win: any = window.open(fileURL);
  if (win) {
    win.focus();
    win.print();
  }
};

export const usePrint = () => {
  const { query } = useContext(ServiceContext);
  const print = (id: string, status: string, noTVA = false, comment = '') => {
    if (query) {
      query(orderService.printOrder(id, status, noTVA, comment)).subscribe(
        (res: any) => {
          openPdf(res);
        },
        (err: any) => console.log(err),
      );
    }
  };

  return print;
};
