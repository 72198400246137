import { useEffect, useState } from 'react';
import { useQuery } from '../../../_core/services/service.context';
import { usePrintMulti } from './useMultiPrint';
import { DraftFilters, orderService } from '../../services/order.service';
import { BROUILLON_STATUS, Enumerable, ENVOIE_STATUS, VALIDEE_STATUS } from '../../config/constants';
import useListParams from '../useListParams';

export const useDrafts = () => {
  const query = useQuery();
  const printMulti = usePrintMulti();

  const [state, setState] = useState({
    drafts: [],
    total: 0,
  });

  const [isLoading, setIsLoading] = useState(false);

  const {
    params,
    initParams,
    updateOffset,
    updateParams,
    updateFilters: setFilters,
    updateOrderSort,
  } = useListParams<DraftFilters>({
    offset: 0,
    sort: 'validationDate',
    direction: 'desc',
  });

  const { filters, offset, sort, direction } = params;

  const init = (initialParams: any) => {
    let orderSort = { sort, direction };
    if (params.filters && params.filters.status) {
      if (params.filters.status === BROUILLON_STATUS && orderSort.sort === 'validationDate') {
        orderSort = { ...orderSort, sort: 'date' };
      } else if (orderSort.sort === 'date') {
        orderSort = { ...orderSort, sort: 'validationDate' };
      }
    }

    const parms = {
      sort: initialParams.sort || orderSort.sort,
      direction: initialParams.direction || orderSort.direction,
      offset: initialParams.offset || params.offset,
      filters: {
        status: initialParams.type || VALIDEE_STATUS,
      },
    };

    const filters: Enumerable<'string' | 'array'> = {
      code: 'string',
      dateStart: 'string',
      dateEnd: 'string',
      prixMin: 'string',
      prixMax: 'string',
      cid: 'array',
      pid: 'array',
      status: 'string',
      shipment: 'string',
    };

    Object.keys(filters).forEach((f: string) => {
      if (initialParams[f]) {
        if (filters[f] === 'array') {
          (parms.filters as any)[f] = Array.isArray(initialParams[f]) ? [...initialParams[f]] : [initialParams[f]];
        } else {
          (parms.filters as any)[f] = initialParams[f];
        }
      }
    });

    initParams(parms);
  };

  const updateFilters = (updates: Partial<DraftFilters>) => {
    if (updates.status === BROUILLON_STATUS && sort === 'validationDate') {
      updateParams({
        filters: { ...filters, status: updates.status },
        sort: 'date',
      });
    } else if (sort === 'date') {
      updateParams({
        filters: { ...filters, status: updates.status },
        sort: 'validationDate',
      });
    } else {
      setFilters(updates);
    }
  };

  const removeFilters = () => {
    updateParams({ filters: { status: filters.status } });
  };

  const getDrafts = () => {
    if (filters?.status) {
      setIsLoading(true);

      query(orderService.getAllDraftsByStatus(filters, offset, sort, direction))
        .subscribe(
          (draftsState: any) => setState(draftsState),
          () =>
            setState({
              drafts: [],
              total: 0,
            }),
        )
        .add(() => setIsLoading(false));
    }
  };

  const updateDraftsStatus = (ids: string[], draftStatus: string) => {
    if (query) {
      query(orderService.updateDraftsStatus(ids, draftStatus)).subscribe(
        () => {
          if (draftStatus === ENVOIE_STATUS) {
            printMulti(ids, 'prepa');
          }
          getDrafts();
        },
        (err) => console.log(err),
      );
    }
  };

  useEffect(getDrafts, [params]);

  return {
    drafts: state.drafts,
    total: state.total,
    params,
    isLoading,
    init,
    updateOrderSort,
    updateOffset,
    updateFilters,
    removeFilters,
    updateDraftsStatus,
  };
};

export default useDrafts;
