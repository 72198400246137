import React, { useEffect, useRef, useState } from 'react';
import TextInput from '../../../../../_shared/component/textInput/textInput.component';

import NumberInput from '../../../../../_shared/component/numberInput';
import { SearchRow } from './searchRow/search.row';
import Checkbox from '../../../../../_shared/component/checkBox/checkbox';

import { useEuroFormatter } from '../../../../../_shared/services/formatter.service';
import { useUpdateIngredient } from '../../../../../_shared/providers/draft/useUpdateIngredient';
import { useRemoveIngredient } from '../../../../../_shared/providers/draft/useRemoveIngredient';
import { useAddIngredient } from '../../../../../_shared/providers/draft/useAddIngredient';
import { useIngredientSuggestions } from '../../../../../_shared/providers/ingredient/useIngredientSuggestions';
import useEscape from '../../../../../_shared/component/useEscape';
import useOutsideClick from '../../../../../_shared/component/useClickOutside';

import { INGREDIENT_LIST_PATTERNS } from './compositionIngredientsList.pattern';
import ParentComponent from '../../../../../_shared/component/parentComponent/parent.component';

import './compositionIngredient.list.scss';

interface ICompositionIngredientListProps {
  forme: any;
  ingredients: any[];
  ingredientSuggestions: any[];
  pickerType: string;
  onChangePickerType: any;
  onParserFocus: any;
}

function isIngredientValid(ingredient: any) {
  return ingredient.pinyin && ingredient.id && ingredient.proportion > 0 && ingredient.prix > 0;
}

function useIngredientListPattern(formeCode: string): any {
  const [pattern, setPattern] = useState(INGREDIENT_LIST_PATTERNS[formeCode]);

  useEffect(() => {
    setPattern(INGREDIENT_LIST_PATTERNS[formeCode]);
  }, [formeCode]);

  return pattern;
}

export const CompositionIngredientList: React.FC<ICompositionIngredientListProps> = (props: any) => {
  const [lineSelected, setLineSelected] = useState(null as null | number);
  const formatToEuro = useEuroFormatter();
  const pattern = useIngredientListPattern(props.forme.code);
  const updateIngredient = useUpdateIngredient(props.ingredients);
  const addIngredient = useAddIngredient(props.ingredients);
  const removeIngredient = useRemoveIngredient(props.ingredients);
  const ingredients = props.ingredients;

  return (
    <div className="composition-ingredient-list ">
      <table className="t-medium b-white">
        <thead>
          <tr>
            <th className="fit-content" />
            <th className="fit-content">Code</th>
            <th>Pinyin</th>
            {pattern.map((element: any, index: number) => (
              <th className="fit-content" key={index}>
                {element.label}
              </th>
            ))}
            <th className="fit-content">Prix / g</th>
            <th className="fit-content">Prix</th>
            <th className="fit-content">À part</th>
            <th className="fit-content">
              <i className="material-icons">delete_forever</i>
            </th>
          </tr>
        </thead>
        <tbody>
          {ingredients.map((ingredient: any, index: number) => (
            <tr key={index} className={`bordered ${isIngredientValid(ingredient) ? '' : 't-red'}`}>
              <td className="center-align">
                {isIngredientValid(ingredient) ? (
                  index + 1
                ) : (
                  <i className="material-icons t-medium t-red">error_outline</i>
                )}
              </td>
              {lineSelected === index ? (
                <td colSpan={2} className={'b-white'}>
                  <IngredientListSuggester
                    formeId={props.forme.id}
                    ingredients={props.ingredients}
                    onSelect={(e: any) => {
                      updateIngredient({ ...e, proportion: ingredient.proportion }, index);
                      setLineSelected(null);
                    }}
                    onCancel={() => {
                      setLineSelected(null);
                    }}
                  />
                </td>
              ) : (
                <>
                  <td className="unlockable l-align" onClick={() => setLineSelected(index)}>
                    {ingredient.code}
                  </td>
                  <td className="unlockable l-align no-wrap" onClick={() => setLineSelected(index)}>
                    {ingredient.pinyin
                      ? ingredient.pinyin
                          .toLowerCase()
                          .split(' ')
                          .map((x: string) => x[0].toUpperCase() + x.slice(1))
                          .join(' ')
                      : // case unrecognized ingredient
                        '> Ingrédient non reconnu'}
                  </td>
                </>
              )}
              {pattern.map((element: any, i: number) => (
                <td key={i} className={element.isEditable ? 'fit-content' : 'locked no-wrap fit-content'}>
                  {element.isEditable ? (
                    <NumberInput
                      name={element.name}
                      value={ingredient[element.value]}
                      size={ingredient[element.value].toString().length}
                      error={ingredient[element.value] <= 0}
                      onChange={(e: any) => updateIngredient(e, index)}
                    />
                  ) : (
                    `${element.formatter(ingredient[element.value])}`
                  )}
                </td>
              ))}
              <td className="fit-content">
                <NumberInput
                  name={'prix'}
                  value={ingredient.prix}
                  decimals={3}
                  size={5}
                  error={ingredient.prix <= 0}
                  onChange={(e: any) => updateIngredient(e, index)}
                />
              </td>
              <td className="fit-content locked no-wrap">
                <div>{formatToEuro(ingredient.prix_reel)}</div>
              </td>
              <td className="fit-content">
                <Checkbox
                  name={'aPart'}
                  value={ingredient.aPart}
                  onChange={(e: any) => updateIngredient({ aPart: e }, index)}
                />
              </td>
              <td className="fit-content locked">
                {ingredient.id ? (
                  <button className="deleteButton" tabIndex={-1} type="button" onClick={() => removeIngredient(index)}>
                    <i className="material-icons">close</i>
                  </button>
                ) : (
                  ''
                )}
              </td>
            </tr>
          ))}
          <SearchRow
            formeId={props.forme.id}
            ingredientsId={props.ingredients.reduce((curr: string[], i: any) => [...curr, i], [])}
            pickerType={props.pickerType}
            onAddIngredient={addIngredient}
            onPickerTypeChange={props.onChangePickerType}
            onParserFocus={props.onParserFocus}
            parserSuggestions={props.ingredientSuggestions}
            pattern={pattern}
          />
        </tbody>
      </table>
    </div>
  );
};

interface IIngredientListSuggesterProps {
  formeId: string;
  ingredients: any[];
  onSelect: any;
  onCancel: any;
}

export const IngredientListSuggester: React.FC<IIngredientListSuggesterProps> = (
  props: IIngredientListSuggesterProps,
) => {
  const [suggestionFilter, setSuggestionFilter] = useState('');
  const { suggestions } = useIngredientSuggestions(suggestionFilter, props.formeId);

  const ref = useRef(null);

  useEscape(() => {
    props.onCancel();
    setSuggestionFilter('');
  });

  useOutsideClick(ref, () => {
    props.onCancel();
    setSuggestionFilter('');
  });

  return (
    <>
      <div ref={ref}>
        <TextInput
          onChange={(e: any) => setSuggestionFilter(e['ingredient-search'])}
          label={'Rechercher'}
          name={'ingredient-search'}
          labelHide={true}
          className={'full-width'}
          isEditable={true}
          isAutofocus={true}
          value={suggestionFilter}
        />
        {suggestions.length > 0 ? (
          <ParentComponent onBlur={props.onCancel} className="relative full-width b-white">
            <div className="absolute full-width">
              {suggestions.map((suggestion: any, i) => (
                <button
                  key={i}
                  className={`selectable p-medium full-width l-align ${
                    props.ingredients.findIndex((ingredient: any) => ingredient.id === suggestion.suggestion.id) >= 0
                      ? 't-red'
                      : ''
                  }`}
                  onClick={() => {
                    props.onSelect(suggestion.suggestion);
                    setSuggestionFilter('');
                  }}
                >
                  {suggestion.suggestion.pinyin}
                  &nbsp;
                  <span className="light t-lgrey">{suggestion.match}</span>
                </button>
              ))}
            </div>
          </ParentComponent>
        ) : null}
      </div>
    </>
  );
};
