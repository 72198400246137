import React, { useContext, useRef, useState } from 'react';

import Row from '../../../../../../../_shared/component/row/row';

import { FormesList } from '../../../formesList/formes.list';

import useCompositionOnEdition from '../../../../../../../_shared/providers/draft/useCompositionOnEdition';
import useOutsideClick from '../../../../../../../_shared/component/useClickOutside';

import { FormeContext } from '../../../../../../../_shared/providers/forme/forme.context';
import { FORME_COLORS } from '../../../../../../../_shared/config/constants';
import { DraftEditorContext } from '../../../../../../../_shared/providers/draft/draftEditor.provider';
import { updateCompositionForme } from '../../../../../../../_shared/providers/draft/draftEditor.actions';

const FormSelector: React.FC<any> = () => {
  const { composition } = useCompositionOnEdition();
  const { dispatch } = useContext(DraftEditorContext);
  const { formes } = useContext(FormeContext);

  const [showFormesPanel, setShowFormesPanel] = useState(false);

  const formesPanelRef = useRef(null);
  useOutsideClick(formesPanelRef, () => setShowFormesPanel(false));

  return (
    <Row
      reference={formesPanelRef}
      className="selectable rounded t-xlarge"
      onClick={() => setShowFormesPanel(!showFormesPanel)}
    >
      <div
        className="circle"
        style={{
          backgroundColor: composition.forme ? FORME_COLORS[composition.forme.code] : 'white',
        }}
      />
      <div>{composition.forme.label.toUpperCase()}</div>
      {composition.forme.code !== 'LB' ? (
        <>
          <span onClick={() => setShowFormesPanel(!showFormesPanel)} className="material-icons selectable rounded">
            unfold_more
          </span>

          {showFormesPanel ? (
            <div className="relative">
              <div className="absolute">
                <div className="b-white fit-width no-wrap bordered rounded p-medium">
                  <FormesList
                    onSelect={(formes: any) => {
                      dispatch(updateCompositionForme(formes));
                      setShowFormesPanel(false);
                    }}
                    formes={formes.filter((f) => f.code !== 'LB')}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </Row>
  );
};

export default FormSelector;
