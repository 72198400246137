import useCompositionOnEdition from './useCompositionOnEdition';

export const useAddIngredient = (ingredientsState: any[]) => {
  const { updateComposition } = useCompositionOnEdition();
  return (ingredient: any) => {
    const ingredientIndex: number = ingredientsState.findIndex((i: any) => ingredient.id && i.id === ingredient.id);
    if (ingredientIndex >= 0) {
      ingredientsState[ingredientIndex].proportion += Number(ingredient.proportion);
    } else {
      ingredientsState = [...ingredientsState, { ...ingredient }];
    }
    updateComposition({ ingredients: ingredientsState });
  };
};
