import React from 'react';
import { OrdersList } from './ordersList/orders.list';
import { ORDERS_LIMIT } from '../../../_shared/config/constants';
import { OrdersFilters } from '../components/ordersFilters/orders.filters';
import { usePrintMulti } from '../../../_shared/providers/order/useMultiPrint';
import { usePrint } from '../../../_shared/providers/order/usePrint';
import { useOrders } from '../../../_shared/providers/order/useOrders';
import { SpinLoader } from '../../../_shared/component/loader/loader';
import PaginationPanel from '../../../_shared/component/listPagination/ListPagination';
import useQueryParams from '../../../_shared/providers/useQueryParams';
import { OrdersTypeFilterPanel } from './ordersTypeFilterPanel/ordersTypeFilter.panel';

export const OrdersPage: React.FC<any> = () => {
  const { params, orders, total, isLoading, init, updateOffset, updateFilters, updateOrderSort, updateOrdersState } =
    useOrders();

  const { offset, filters, sort, direction } = params;

  useQueryParams(params, (query: any) => init(query));

  const print = usePrint();
  const printMulti = usePrintMulti();

  return (
    <div className="orders-layout">
      <OrdersTypeFilterPanel
        status={filters && filters.status && filters?.status.length === 1 ? filters.status[0] : undefined}
        disabled={false}
        onHandleStatus={(status: string[]) => updateFilters({ status: status ? status : [] })}
      />
      <div className="page orders-page" style={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}>
        {filters ? <OrdersFilters filters={filters} onFiltersChange={updateFilters} /> : null}
        <div className="full-height" style={{ display: 'grid', gridTemplateRows: 'auto 0fr' }}>
          <div className="full-height scrollable-y relative">
            {!isLoading && orders.length <= 0 ? (
              <div className="column center full-height">
                <div>
                  <span className="material-icons">list_alt</span>
                </div>
                <div>Aucune commande trouvée</div>
              </div>
            ) : (
              <OrdersList
                ordersStatus={filters?.status || []}
                orders={orders}
                onPrint={print}
                sort={sort}
                direction={direction}
                onUpdateSort={updateOrderSort}
                onPrintMulti={printMulti}
                onUpdateOrdersStatus={(ids, status) => updateOrdersState(ids, status)}
              />
            )}
            {isLoading ? (
              <div className="absolute column center full-height full-width">
                <SpinLoader size={'20vmax'} thickness={'0.2rem'} color={'dimgrey'} />
              </div>
            ) : null}
          </div>
          <div className=" row center br-top b-white">
            {orders && orders.length > 0 ? (
              <>
                <PaginationPanel
                  onPageSelect={(page: any) => {
                    updateOffset((page - 1) * ORDERS_LIMIT);
                  }}
                  offset={offset}
                  itemByPage={ORDERS_LIMIT}
                  totalItem={total}
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
