import React, { useEffect } from 'react';
import { PractitionersList } from './practitionersList/practitionersList';
import PractitionerForm from './pracitionnerForm/practitionner.form';
import { CSSTransition } from 'react-transition-group';
import { SearchBar } from '../../_shared/component/searchBar/searchBar';
import { CrudButtonPanel } from '../../_shared/component/crudButtonPanel/crudButtonPanel';
import usePractitionerPage from './usePractitionerPage';
import useEscape from '../../_shared/component/useEscape';
import { SpinLoader } from '../../_shared/component/loader/loader';
import './practitioners.page.scss';
import { formatToDate } from '../../_shared/services/formatter.service';

export const PractitionersPage: React.FC<any> = () => {
  const {
    searchField,
    practitioner,
    practitionerOnEdition,
    practitioners,
    isLoading,
    init,
    updateQueryParams,
    setSearchField,
    setPractitioner,
    setPractitionerOnEdition,
    updatePractitionerOnEdition,
    setPractitionerOnEditionAsNew,
    deletePractitionerOnEdition,
    fetchPractitionerOnEdition,
    selectPractitioner,
    addPointEvent,
    deletePointEvent,
  } = usePractitionerPage();

  const practitionerSelected = practitionerOnEdition || practitioner;
  const isEditing = !!practitionerOnEdition;

  const handleNewPEventAdded = (amount: number) => {
    if (!practitionerSelected?.id) return;
    const newPointEvent = {
      amount,
      date: formatToDate(new Date().toDateString()),
    };
    addPointEvent(practitionerSelected.id, newPointEvent);
  };

  const handlePEventDeleted = (pointEventId: string) => {
    if (!practitionerSelected?.id) return;
    deletePointEvent(practitionerSelected.id, pointEventId);
  };

  useEffect(init, []);

  useEffect(updateQueryParams, [practitioner, searchField]);

  useEscape(() => (practitioner ? setPractitioner(null) : null));

  return (
    <div className="page practitioners-page">
      <div className="crud-header row gapped">
        <div className="title">
          <span className="material-icons">medical_services</span>
          <span>PRATICIENS</span>
        </div>
        <SearchBar
          autofocus
          filter={searchField || ''}
          placeholder={'Rechercher un praticien'}
          onChange={setSearchField}
          onFilterRemove={() => setSearchField(null)}
        />
        <button
          className="button-validate transparent"
          disabled={!!practitionerOnEdition}
          onClick={setPractitionerOnEditionAsNew}
        >
          <i className="material-icons">add</i>
          Nouveau Praticien
        </button>
      </div>
      <div className="full-height relative">
        {isLoading ? (
          <div className="row center p-medium full-height full-width">
            <SpinLoader size={'14rem'} thickness={'0.2rem'} color={'dimgrey'} />
          </div>
        ) : (
          <div className="full-height scrollable-y">
            <PractitionersList
              isEditing={!!practitionerOnEdition}
              isLoading={isLoading}
              practitioners={practitioners}
              handleSelectPractitioner={selectPractitioner}
            />
          </div>
        )}
        <CSSTransition
          in={!!practitioner || !!practitionerOnEdition}
          timeout={0}
          classNames="left-transition"
          unmountOnExit
        >
          <div className="full-height" style={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}>
            <div>
              <div className="row gapped p-small br-bottom">
                <button
                  className="returnButton"
                  onClick={() => {
                    setPractitioner(null);
                    setPractitionerOnEdition(null);
                  }}
                >
                  <i className="material-icons">chevron_right</i>
                  Fermer
                </button>
                <div className="t-grey light">
                  {isEditing && practitionerSelected
                    ? practitionerSelected.id
                      ? 'MODIFICATION'
                      : 'NOUVEAU PRATICIEN'
                    : null}
                </div>
              </div>
            </div>
            <div className="scrollable-y full-height">
              {practitionerSelected ? (
                <div className="p-medium">
                  <PractitionerForm
                    practitionerSelected={practitionerSelected}
                    isOnEdition={!!practitionerOnEdition}
                    onChange={updatePractitionerOnEdition}
                    onNewPEventAdded={handleNewPEventAdded}
                    onPEventDeleted={handlePEventDeleted}
                  />
                  <div className="row gapped br-top">
                    <CrudButtonPanel
                      isOnEdition={!!practitionerOnEdition}
                      onEdition={() => setPractitionerOnEdition(practitioner)}
                      onValidate={() => fetchPractitionerOnEdition()}
                      onCancelEdition={() => setPractitionerOnEdition(null)}
                    />
                    {practitionerOnEdition && practitionerOnEdition.id ? (
                      <button className="cancelButton" onClick={() => deletePractitionerOnEdition()}>
                        <i className="material-icons">delete_forever</i>
                        Supprimer
                      </button>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};
