import { from, Observable, of, throwError } from 'rxjs';
import { flatMap, mergeMap } from 'rxjs/operators';
import { BASE_URL } from '../config/constants';
import { Practitioner } from '../models/practitioner';

export class PractitionerService {
  getNewPractitioner(): Practitioner {
    return {
      code: '',
      nom: '',
      prenom: '',
      adresse: [],
      cp: '',
      ville: '',
      pays: 'France',
      mobile: '',
      fixe: '',
      mail: '',
      note: '',
      remise: 0,
    };
  }

  getPractitionerById(id: string): Observable<Practitioner> {
    return from(
      fetch(`${BASE_URL}/praticien/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  getAllPractitioners(pids: Practitioner['id'][] = []): Observable<Practitioner[] | any> {
    return from(
      fetch(`${BASE_URL}/praticien${pids.length > 0 ? '?pid=' + pids : ''}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  createPractitioner(practitioner: Practitioner): Observable<Practitioner> {
    const body: any = {
      ...practitioner,
      code: practitioner.code.toUpperCase(),
    };

    for (const arg in body) {
      if (body.hasOwnProperty(arg) && body[arg].length <= 0) {
        delete body[arg];
      }
    }

    return from(
      fetch(`${BASE_URL}/praticien?refresh=true`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(body),
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  updatePractitioner(practitioner: Practitioner): Observable<boolean> {
    const body: any = {
      ...practitioner,
      code: practitioner.code.toUpperCase(),
    };

    for (const arg in body) {
      if (body.hasOwnProperty(arg) && body[arg].length <= 0) {
        delete body[arg];
      }
    }

    return from(
      fetch(`${BASE_URL}/praticien/${practitioner.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(body),
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return of(true);
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  deletePractitioner(practitionerId: string): Observable<any> {
    return from(
      fetch(`${BASE_URL}/praticien/${practitionerId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return of(true);
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  getIsPropsExist(type: string, value: string) {
    const body = { type, value: value.toUpperCase() };
    return from(
      fetch(`${BASE_URL}/search/praticien`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(body),
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  getSuggestions(field: string) {
    return from(
      fetch(`${BASE_URL}/suggest/praticien?limit=50&query=${field}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return [];
        }
      }),
    );
  }

  getPractitionersByCodes(codes: string[]) {
    return from(
      fetch(`${BASE_URL}/parser/praticien`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ codes }),
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }

  /**
   * Call the API to get the points of a practitioner and its events.
   * @param practitionerId { string }
   */
  getPoints(practitionerId: string): Observable<Practitioner['points']> {
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
    };
    const credentials: 'include' = 'include';
    const options = { method, headers, credentials };
    return from(fetch(`${BASE_URL}/praticien/${practitionerId}/points`, options)).pipe(
      mergeMap((response: Response) => {
        if (!response.ok) {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
        return response.json();
      }),
    );
  }

  /**
   * Call the API to add a new point event to a practitioner.
   * @param practitionerId {string}
   * @param newPointEvent { { amount: number } }
   */
  addPointEvent(practitionerId: string, newPointEvent: { amount: number }): Observable<PointEventResponse> {
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
    };
    const credentials: 'include' = 'include';
    const body = JSON.stringify(newPointEvent);
    const options = { method, headers, credentials, body };
    return from(fetch(`${BASE_URL}/praticien/${practitionerId}/points`, options)).pipe(
      mergeMap((response: Response) => {
        if (!response.ok) {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
        return response.json();
      }),
    );
  }

  /**
   * Call the API to delete an existing point event of a practitioner.
   * @param practitionerId {string} the id of the practitioner
   * @param pointEventId {string} the id of the point event
   */
  deletePointEvent(practitionerId: string, pointEventId: string): Observable<PointEventResponse> {
    const method = 'DELETE';
    const headers = {
      'Content-Type': 'application/json',
    };
    const credentials: 'include' = 'include';
    const options = { method, headers, credentials };
    return from(fetch(`${BASE_URL}/praticien/${practitionerId}/points/${pointEventId}`, options)).pipe(
      mergeMap((response: Response) => {
        if (!response.ok) {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
        return response.json();
      }),
    );
  }
}

export const practitionerService: PractitionerService = new PractitionerService();
