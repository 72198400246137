import React from 'react';

export interface ListHeader {
  title: string;
  name: string;
  className?: string;
  locked?: boolean;
}

interface SortItem {
  [key: string]: string;
}

interface IHeadersListProps {
  headersList: ListHeader[];
  sort: SortItem;
  onUpdateSort: (sort: string) => void;
}

export const HeadersList: React.FC<IHeadersListProps> = (props: IHeadersListProps) => {
  return (
    <>
      {props.headersList.map((header: ListHeader, index: number) => {
        const selected = props.sort.sort === header.name;
        return (
          <th
            className={`${header.className || ''} ${
              !header.locked ? (selected ? 'selected selectable' : 'selectable') : ''
            }`}
            onClick={() => (header.locked ? null : props.onUpdateSort(header.name))}
            key={index}
          >
            <div className="row spaced">
              <div>{header.title}</div>
              {selected ? (
                props.sort.direction === 'desc' ? (
                  <span className="material-icons">keyboard_arrow_up</span>
                ) : (
                  <span className="material-icons">keyboard_arrow_down</span>
                )
              ) : !header.locked ? (
                <span className="material-icons t-lgrey">keyboard_arrow_down</span>
              ) : null}
            </div>
          </th>
        );
      })}
    </>
  );
};
