import * as React from 'react';

interface ITextSelectorProps {
  isEditable?: boolean;
  isAutofocus?: boolean;
  value: any | string | undefined;
  name: string;
  options: any[];
  label?: string;
  required?: boolean;
  className?: string;
  labelHide?: boolean;
  defaultChoice?: string;
  onChange: any;
}

const TextSelector: React.FunctionComponent<ITextSelectorProps> = (props: ITextSelectorProps) => {
  const handleOnChange = (e: any) => {
    return {
      [props.name]: e.target.value,
    };
  };

  if (props.isEditable || props.value) {
    return (
      <div className={`${props.className || ''} text-selector`}>
        {!props.labelHide ? (
          <label className={props.isEditable && props.required ? 'required' : ''}>
            {props.label ? props.label : props.name}
          </label>
        ) : null}
        <div className="row gapped">
          {props.isEditable ? (
            <select
              autoFocus={props.isAutofocus}
              disabled={!props.isEditable}
              value={props.value}
              onChange={(e) => {
                props.onChange(handleOnChange(e));
              }}
            >
              {!props.value || props.value.length <= 0 ? (
                <option disabled={!!props.defaultChoice} value={props.defaultChoice || ''}>
                  {' '}
                  {props.defaultChoice || null}{' '}
                </option>
              ) : (
                ''
              )}

              {props.options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          ) : (
            <div className={'text-selector-text'}>{props.value}</div>
          )}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default TextSelector;
