import React, { useEffect, useState } from 'react';

const DEFAULT_ROW_NUMBER = 2;

interface ITextAreaProps {
  name: string;
  placeholder?: string;
  value: string;
  className?: string;
  autofocus?: boolean;
  inputRef?: any;
  onChange: any;
  locked?: boolean;
  format?: any;
  rows?: number;
  minRows?: number;
  cols?: number;
  onFocusChange?: any;
  reference?: any;
  dataCy?: string;
}

export const TextArea: React.FC<ITextAreaProps> = (props: ITextAreaProps) => {
  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    if (props.onFocusChange) {
      props.onFocusChange(isEditing);
    }
  }, [isEditing]);

  const valueRowNumber = props.value ? props.value.split('\n').length : DEFAULT_ROW_NUMBER;

  const rowNumber = props.rows
    ? props.rows
    : props.value
    ? props.minRows
      ? valueRowNumber > props.minRows
        ? valueRowNumber
        : props.minRows
      : valueRowNumber
    : props.minRows;

  const onChange = (e: any) => {
    props.onChange({ [props.name]: e.target.value });
  };

  return isEditing && !props.locked ? (
    <textarea
      name={props.name}
      placeholder={props.placeholder}
      className={props.className}
      autoFocus={true}
      rows={rowNumber}
      cols={props.cols}
      defaultValue={props.value as string}
      disabled={props.locked}
      onChange={(e: any) => onChange(e)}
      onBlur={() => {
        setIsEditing(false);
      }}
      data-cy={props.dataCy}
    />
  ) : (
    <textarea
      name={props.name}
      placeholder={props.placeholder}
      className={props.className}
      rows={rowNumber}
      cols={props.cols}
      value={props.value || ''}
      disabled={props.locked}
      autoFocus={props.autofocus}
      onFocus={() => setIsEditing(true)}
      onClick={() => setIsEditing(true)}
      ref={props.reference}
      readOnly
      data-cy={props.dataCy}
    />
  );
};
