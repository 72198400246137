import React, { useContext, useState } from 'react';
import { FormesList } from '../formesList/formes.list';
import { DraftEditorContext } from '../../../../../_shared/providers/draft/draftEditor.provider';
import { setCompositionSelectedAsNew } from '../../../../../_shared/providers/draft/draftEditor.actions';

export const AddCompositionPanel: React.FC<any> = () => {
  const [showList, setShowlist] = useState(false);
  const { dispatch } = useContext(DraftEditorContext);

  return (
    <div className="formes-picker">
      {showList ? (
        <div className="formes-list">
          <div className="light row p-medium center">
            <div>+ Ajouter une préparation</div>
          </div>
          <FormesList
            onSelect={(forme: any) => {
              dispatch(setCompositionSelectedAsNew(forme));
              setShowlist(false);
            }}
          />
          <button className="row center" onClick={() => setShowlist(false)}>
            <span className="material-icons">expand_less</span>
          </button>
        </div>
      ) : (
        <div className="row end p-small">
          <button className="addButton" onClick={() => setShowlist(true)}>
            <i className="material-icons">add</i>
            Ajouter une préparation
          </button>
        </div>
      )}
    </div>
  );
};
