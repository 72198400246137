import { Composition } from '../../models/composition';
import { FORMES_PIPES } from '../../config/forme.pipes';

import {
  DELETE_COMPOSITION_SELECTED,
  INIT_DRAFT,
  SET_ADRESSE_SELECTED,
  SET_CLIENT,
  SET_CLIENT_WARNING,
  SET_COMMENTAIRE,
  SET_COMMENTAIRE_PAIEMENT,
  SET_COMPOSITION_SELECTED,
  SET_COMPOSITION_SELECTED_AS_NEW,
  SET_FRAIS_PORT,
  SET_LAST_SAVE,
  SET_PAIEMENT,
  SET_PRACTITIONER,
  SET_SELECTED,
  SET_SHIPMENT_TYPE,
  UPDATE_COMPOSITION_FORME,
  UPDATE_COMPOSITION_SELECTED,
} from './draftEditor.actions';
import { IDraftEditorContextState, Paiement } from './draftEditor.provider';
import { Client } from '../../models/client';
import { Practitioner } from '../../models/practitioner';

const initDraft = (state: IDraftEditorContextState, draft: any) => {
  if (draft.compositions) {
    draft.compositions.forEach((composition: Composition) => {
      composition = FORMES_PIPES(composition.forme.code, composition, composition) || composition;
      composition.isPoidsSet =
        composition.ingredients.reduce((acc: number, curr: any) => acc + curr.proportion, 0) !== composition.poids;
    });
  }

  return { ...state, ...draft };
};

const setCompositionSelectedAsNew = (state: IDraftEditorContextState, forme: string) => {
  const client = state.client;
  const newComposition: Composition = {
    destinataire: client ? `${client.prenom} ${client.nom}` : '',
    commentaire: '',
    poids: 0,
    contenant: 0,
    poids_contenant: 0,
    proportionTotal: 0,
    prix: 0,
    forme: forme,
    ingredients: [],
    parser: '',
    valid: false,
  };

  const compositions: Composition[] = [...state.compositions, newComposition];
  return {
    ...state,
    compositionSelected: compositions.length - 1,
    compositions,
  };
};

const setCompositionSelected = (state: IDraftEditorContextState, index: number) => ({
  ...state,
  compositionSelected: index,
});

const updateCompositionSelected = (state: IDraftEditorContextState, updates: Partial<Composition>) => {
  const currentState: Composition = state.compositions[state.compositionSelected];
  const composition: Composition = { ...currentState, ...updates };
  const compositionUpdated = FORMES_PIPES(composition.forme.code, currentState, composition);
  const compositions = [...state.compositions];

  if (compositionUpdated) {
    compositions[state.compositionSelected] = compositionUpdated;
  }

  return { ...state, compositions };
};

const updateCompositionSelectedForme = (state: IDraftEditorContextState, forme: any) => {
  if (forme.code === 'LB') return;
  const currentState = state.compositions[state.compositionSelected];

  currentState.ingredients.forEach((ingredient: any) => {
    ingredient.prix = forme.prix[ingredient.codeprix.id];
  });

  return updateCompositionSelected(state, { forme });
};

const deleteCompositionSelected = (state: IDraftEditorContextState) => {
  const compositions: Composition[] = [...state.compositions];
  const compositionSelected = state.compositionSelected;
  compositions.splice(compositionSelected, 1);
  return {
    ...state,
    compositions,
    compositionSelected: compositionSelected > 0 ? compositionSelected - 1 : 0,
  };
};

const setCommentaire = (state: IDraftEditorContextState, commentaire: string) => ({ ...state, commentaire });

const setCommentairePaiement = (state: IDraftEditorContextState, commentairePaiement: string) => ({
  ...state,
  commentairePaiement,
});

const setClient = (state: IDraftEditorContextState, client: Client | undefined, address: number | undefined) => {
  const compositions = [...state.compositions];

  const adresseSelected = address !== undefined ? address : client ? client.adresses.findIndex((a) => a.default) : 0;

  return {
    ...state,
    client,
    clientWarning: false,
    adresseSelected,
    compositions: compositions
      ? compositions.map((composition: Composition) => {
          if (!composition.destinataire) {
            // set destinataire only if it's not already set #404
            composition.destinataire = client ? `${client.prenom} ${client.nom}` : '';
          }
          return composition;
        })
      : [],
  };
};

const setAdresseSelected = (state: IDraftEditorContextState, adresseSelected: number) => ({
  ...state,
  adresseSelected,
});

const setPractitioner = (state: IDraftEditorContextState, practitioner: Practitioner) => ({ ...state, practitioner });

const setFraisPort = (state: IDraftEditorContextState, fraisPort: number) => ({
  ...state,
  fraisPort,
});

const setShipmentType = (state: IDraftEditorContextState, shipmentType: string) => {
  let shipping = 0;
  switch (shipmentType) {
    case 'colissimo':
      shipping = 6;
      break;
    case 'chronopost':
      shipping = 16;
      break;
    case 'coursier':
      shipping = 8;
      break;
    default:
      shipping = 0;
      break;
  }

  return {
    ...state,
    shipment: {
      type: shipmentType,
    },
    fraisPort: shipping,
  };
};

const setSelected = (state: IDraftEditorContextState, selected: 'client' | 'practitioner' | null) => ({
  ...state,
  selected,
});

const setPaiement = (state: IDraftEditorContextState, paiement: undefined | Paiement) => ({
  ...state,
  paiement,
});

const setLastSave = (state: IDraftEditorContextState, lastSave: Date) => ({
  ...state,
  lastSave,
});

function draftEditorReducer(state: any, action: any) {
  switch (action.type) {
    case INIT_DRAFT:
      return initDraft(state, action.draft);
    case SET_COMPOSITION_SELECTED_AS_NEW:
      return setCompositionSelectedAsNew(state, action.forme);
    case SET_COMPOSITION_SELECTED:
      return setCompositionSelected(state, action.index);
    case UPDATE_COMPOSITION_SELECTED:
      return updateCompositionSelected(state, action.updates);
    case UPDATE_COMPOSITION_FORME:
      return updateCompositionSelectedForme(state, action.forme);
    case DELETE_COMPOSITION_SELECTED:
      return deleteCompositionSelected(state);
    case SET_COMMENTAIRE:
      return setCommentaire(state, action.commentaire);
    case SET_COMMENTAIRE_PAIEMENT:
      return setCommentairePaiement(state, action.commentairePaiement);
    case SET_CLIENT:
      return setClient(state, action.client, action.adresseSelected);
    case SET_CLIENT_WARNING:
      return { ...state, clientWarning: action.clientWarning };
    case SET_ADRESSE_SELECTED:
      return setAdresseSelected(state, action.adresseSelected);
    case SET_PRACTITIONER:
      return setPractitioner(state, action.practitioner);
    case SET_FRAIS_PORT:
      return setFraisPort(state, action.fraisPort);
    case SET_SHIPMENT_TYPE:
      return setShipmentType(state, action.shipmentType);
    case SET_SELECTED:
      return setSelected(state, action.selected);
    case SET_PAIEMENT:
      return setPaiement(state, action.paiement);
    case SET_LAST_SAVE:
      return setLastSave(state, action.lastSave);
    default:
      throw new Error('Draft Editor Action ' + action.type + ' does not exist');
  }
}

export default draftEditorReducer;
