import React, { useEffect } from 'react';
import { useDraftById } from '../../../_shared/providers/draft/useDraftById';
import { OrderDetail } from './order.detail';
import { DRAFTS_PATH } from '../../../_shared/config/paths';
import { useHistory } from 'react-router-dom';
import { DraftsStatusPanel } from '../draftsPage/draftFilterPanel/draftsStatus.panel';

export const DraftPage: React.FC<any> = ({ match }: any) => {
  const { draft, isLoading, isPaiementOrderLoading, updateStatus, generatePaiementURL, setDraftId } = useDraftById();

  const history = useHistory();

  useEffect(() => {
    if (match.params) setDraftId(match.params.id);
  }, []);

  return (
    <div className="orders-layout">
      <DraftsStatusPanel
        status={draft?.editStatus}
        onHandleStatus={(status: string) => history.push(`${DRAFTS_PATH}?type=${status}`)}
      />
      <div className="draft-page page">
        <OrderDetail
          order={draft}
          isLoading={isLoading}
          isPaiementOrderLoading={isPaiementOrderLoading}
          onUpdateStatus={updateStatus}
          onGeneratePaiementOrder={generatePaiementURL}
        />
      </div>
    </div>
  );
};
