import React from 'react';
import {
  useDecimalFormatter,
  useEuroFormatter,
  usePercentFormatter,
  usePoidsFormatter,
} from '../../../../_shared/services/formatter.service';
import { FORME_COLORS } from '../../../../_shared/config/constants';
import { WEIGHT_PATTERNS } from '../../draftEditorPage/components/weightPicker/weight.patterns';

interface ICompositionDetailProps {
  index: number;
  composition: any;
}

export const CompositionDetail: React.FC<ICompositionDetailProps> = (props: ICompositionDetailProps) => {
  const composition = props.composition;

  const formatToEuro = useEuroFormatter();
  const formatToPercent = usePercentFormatter();
  const formatToPoids = usePoidsFormatter();
  const formatToDecimals = useDecimalFormatter();

  return (
    <div className="bordered  rounded padding b-white fit-height">
      <div
        className="p-small"
        style={{
          display: 'grid',
          height: '6em',
          gridTemplateColumns: 'auto auto',
        }}
      >
        <div>
          <div className="t-grey bold">{props.index + 1}.</div>
          <div className="row ">
            <div>
              <div
                className="circle"
                style={{
                  backgroundColor: composition.forme ? FORME_COLORS[composition.forme.code] : 'white',
                }}
              />
            </div>
            <div>
              <div className="t-medium bold">{composition.libelle}</div>
              <div className="t-medium">
                {typeof composition.forme === 'object' ? composition.forme.label.toUpperCase() : ''}
              </div>
              <div className="t-medium">{composition.destinataire}</div>
            </div>
          </div>
        </div>
        <div className="column t-medium gapped t-end">
          <div>
            Prix: <span className={'t-xlarge'}>{formatToEuro(composition.prix)}</span>
          </div>
          {typeof composition.forme === 'object' && composition.forme.code !== 'LB'
            ? WEIGHT_PATTERNS[composition.forme.code].map((element: any, index: number) => (
                <React.Fragment key={index}>
                  {typeof element === 'string' ? null : (
                    <div className="">
                      {element.label}:{' '}
                      <span className="bold">
                        {element.decimals > 0
                          ? formatToDecimals(composition[element.value])
                          : composition[element.value]}
                        &nbsp; {element.prefix}
                      </span>
                    </div>
                  )}
                </React.Fragment>
              ))
            : null}
        </div>
      </div>
      <table className="composition-ingredient-list t-medium list">
        <thead>
          <tr>
            <th className="fit-content" />
            {composition.forme && composition.forme.code === 'LB' ? (
              <>
                <th>Designation</th>
                <th>Quantité</th>
                <th>Prix unitaire</th>
                <th>Prix</th>
              </>
            ) : (
              <>
                <th>Pinyin</th>
                <th>Prop</th>
                <th>%</th>
                <th>Poids</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {Array.from(Array(composition.ingredients.length < 20 ? 20 : composition.ingredients.length).keys()).map(
            (index) =>
              composition.ingredients[index] ? (
                composition.forme.code === 'LB' ? (
                  <tr key={index}>
                    <td className="locked t-grey fit-content ">{index + 1}</td>
                    <td className="locked no-wrap">{composition.ingredients[index].designation}</td>
                    <td className="locked no-wrap">{composition.ingredients[index].proportion}</td>
                    <td className="locked no-wrap">{composition.ingredients[index].prix}</td>
                    <td className="locked no-wrap">{composition.ingredients[index].pinyin}</td>
                  </tr>
                ) : (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="locked no-wrap">{composition.ingredients[index].pinyin}</td>
                    <td className="locked no-wrap">{formatToDecimals(composition.ingredients[index].proportion)}</td>
                    <td className="locked no-wrap">{formatToPercent(composition.ingredients[index].percent)}</td>
                    <td className="locked no-wrap">{formatToPoids(composition.ingredients[index].poids)}</td>
                  </tr>
                )
              ) : (
                <tr key={index}>
                  <td className="locked"></td>
                  <td className="locked"></td>
                  <td className="locked"></td>
                  <td className="locked"></td>
                  <td className="locked"></td>
                </tr>
              ),
          )}
        </tbody>
      </table>
    </div>
  );
};
