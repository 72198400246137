import { useReducer } from 'react';

enum actions {
  UPDATE_INGREDIENT = 'UPDATE_INGREDIENT',
  DELETE_INGREDIENT = 'DELETE_INGREDIENT',
  REPLACE_INGREDIENT = 'REPLACE_INGREDIENT',
  MODIFY_POIDS = 'MODIFY_WEIGHT',
  REPLACE_STATE = 'REPLACE_STATE',
}

interface IngredientSuggestionsReducerState {
  poids: number;
  isPoidsSet: boolean;
  ingredients: any[];
}

const IngredientSuggestionsReducer = (
  state: IngredientSuggestionsReducerState,
  action: { type: string; payload: any },
) => {
  switch (action.type) {
    case actions.UPDATE_INGREDIENT: {
      const ingredientsState = [...state.ingredients];
      ingredientsState[action.payload.index] = {
        ...ingredientsState[action.payload.index],
        proportion: Number(action.payload.proportion),
      };
      const poids = state.isPoidsSet
        ? state.poids
        : ingredientsState.reduce((acc: number, ing: any) => acc + ing.proportion, 0).toFixed(2);
      return {
        ...state,
        ingredients: ingredientsState,
        poids,
      } as IngredientSuggestionsReducerState;
    }
    case actions.DELETE_INGREDIENT: {
      const ingredientsState = [...state.ingredients];
      ingredientsState.splice(action.payload.index, 1);
      return {
        ...state,
        ingredients: ingredientsState,
        poids: state.isPoidsSet
          ? state.poids
          : ingredientsState
              .filter((ingredient: any) => !!ingredient.id)
              .reduce((acc: number, curr: any) => acc + curr.proportion, 0)
              .toFixed(2),
      };
    }
    case actions.REPLACE_INGREDIENT: {
      const ingredientsState = [...state.ingredients];
      const ingredient = { ...ingredientsState[action.payload.index] };
      ingredientsState[action.payload.index] = {
        ...action.payload.ingredient,
        proportion: isNaN(Number(ingredient.proportion)) ? 0 : Number(ingredient.proportion),
      };
      return {
        ...state,
        ingredients: ingredientsState,
        poids: state.isPoidsSet
          ? state.poids
          : ingredientsState
              .filter((ingredient: any) => !!ingredient.id)
              .reduce((acc: number, curr: any) => acc + curr.proportion, 0)
              .toFixed(2),
      } as IngredientSuggestionsReducerState;
    }
    case actions.MODIFY_POIDS: {
      const poids = Number(action.payload.poids);
      return {
        ...state,
        poids:
          isNaN(poids) || poids <= 0
            ? state.ingredients
                .filter((ingredient: any) => !!ingredient.id)
                .reduce((acc: number, curr: any) => acc + curr.proportion, 0)
                .toFixed(2)
            : poids,
        isPoidsSet: !isNaN(poids) && poids > 0,
      } as IngredientSuggestionsReducerState;
    }
    case actions.REPLACE_STATE: {
      const ingredients = [...action.payload.ingredients];
      return {
        ingredients: [...ingredients],
        poids:
          ingredients.length > 0
            ? action.payload.ingredients
                .filter((ingredient: any) => !!ingredient.id)
                .reduce((acc: number, curr: any) => acc + curr.proportion, 0)
                .toFixed(2)
            : 0,
      } as IngredientSuggestionsReducerState;
    }
    default:
      return state as IngredientSuggestionsReducerState;
  }
};

export const useIngredientsReducerHook = () => {
  const initialState: IngredientSuggestionsReducerState = {
    poids: 0,
    ingredients: [],
    isPoidsSet: false,
  };
  const [state, dispatch] = useReducer(IngredientSuggestionsReducer, initialState);

  const poids = state.poids;
  const suggestions = state.ingredients;
  const isPoidsSet = state.isPoidsSet;

  const replaceSuggestions = (ingredients: any[]) => {
    dispatch({
      type: actions.REPLACE_STATE,
      payload: { ingredients },
    });
  };

  const updateSuggestion = (proportion: any, index: number) => {
    dispatch({
      type: actions.UPDATE_INGREDIENT,
      payload: { index, proportion },
    });
  };

  const deleteSuggestion = (index: number) => {
    dispatch({
      type: actions.DELETE_INGREDIENT,
      payload: { index },
    });
  };

  const replaceSuggestion = (ingredient: any, index: number) => {
    dispatch({
      type: actions.REPLACE_INGREDIENT,
      payload: { index, ingredient },
    });
  };

  const setPoids = (poids: number) => {
    dispatch({
      type: actions.MODIFY_POIDS,
      payload: { poids },
    });
  };

  return {
    poids,
    suggestions,
    isPoidsSet,
    replaceSuggestions,
    updateSuggestion,
    deleteSuggestion,
    replaceSuggestion,
    setPoids,
  };
};
