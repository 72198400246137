import React from 'react';

export const PRINT_FACTURE = 'facture';
export const PRINT_ETIQUETTE = 'etiquette';
export const PRINT_SHIPMENT = 'ship';

interface IPrintPanelProps {
  onPrintOrder: any;
  printTypes: string[];
  disabled?: boolean;
}

function renderPrintButton(printType: string, onPrintOrder: any, index: number) {
  switch (printType) {
    case 'infos':
      return (
        <button key={index} className="button-lgrey" onClick={() => onPrintOrder('prepa')}>
          <i className="material-icons">toc</i>
          <div className="no-wrap">Facture & Étiquettes</div>
        </button>
      );
    case 'latin':
      return (
        <button key={index} className="button-lgrey" onClick={() => onPrintOrder('latin')}>
          <i className="material-icons">toc</i>
          <div className="no-wrap">Latin</div>
        </button>
      );
    case 'devis':
      return (
        <button key={index} className="button-lgrey" onClick={() => onPrintOrder('prepa')}>
          <i className="material-icons">print</i>
          <div className="no-wrap">Devis</div>
        </button>
      );
    case 'facture':
      return (
        <button key={index} className="button-lgrey" onClick={() => onPrintOrder(PRINT_FACTURE)}>
          <i className="material-icons">toc</i>
          <div>Facture</div>
        </button>
      );
    case 'etiquette':
      return (
        <button key={index} className="button-lgrey" onClick={() => onPrintOrder(PRINT_ETIQUETTE)}>
          <i className="material-icons">toc</i>
          <div>Étiquettes</div>
        </button>
      );
    case 'ship':
      return (
        <button key={index} className="button-lgrey" onClick={() => onPrintOrder(PRINT_SHIPMENT)}>
          <i className="material-icons">send</i>
          <div>Envoi</div>
        </button>
      );
    case 'R1':
      return (
        <button key={index} className="button-lgrey" onClick={() => onPrintOrder('r1')}>
          <i className="material-icons">error_outline</i>
          <span>R1</span>
        </button>
      );
    case 'R2':
      return (
        <button key={index} className="button-lgrey" onClick={() => onPrintOrder('r2')}>
          <i className="material-icons">warning</i>
          <span>R2</span>
        </button>
      );
    default:
      return null;
  }
}

export const PrintPanel: React.FC<IPrintPanelProps> = (props: IPrintPanelProps) => {
  return (
    <div className="row gapped p-medium br-top light">
      <span className="material-icons t-lgrey t-large">print</span>
      <span className="t-lgrey">Imprimer</span>
      {props.printTypes.map((type, index: number) => renderPrintButton(type, props.onPrintOrder, index))}
    </div>
  );
};
