import { useContext, useEffect, useState } from 'react';
import { ServiceContext } from '../../../_core/services/service.context';
import { DEPOT_STATUS, ENVOIE_STATUS, EXPEDIEE_STATUS } from '../../config/constants';
import { orderService } from '../../services/order.service';
import { usePrint } from '../order/usePrint';
import { useHistory } from 'react-router-dom';
import usePaiementOrder from '../paiementOrder/usePaiementOrder';

export const useDraftById = () => {
  const [draftId, setDraftId] = useState<string>();
  const [draft, setDraft] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { query } = useContext(ServiceContext);
  const print = usePrint();

  const { createPaiementOrder, isLoading: isPaiementOrderLoading } = usePaiementOrder();

  const loadDraft = () => {
    if (draftId) {
      setIsLoading(true);
      query(orderService.getDraftById(draftId))
        .subscribe(
          (res: any) => {
            setDraft(res);
          },
          (err) => console.log(err),
        )
        .add(() => setIsLoading(false));
    } else {
      setDraft(null);
    }
  };

  const updateStatus = (status: string, callback?: any) => {
    if (draft && query) {
      query(orderService.updateDraftsStatus([draft.id], status), {
        onError: 'Commande en cours non trouvée',
      }).subscribe(
        () => {
          if (status === ENVOIE_STATUS) {
            print(draft.id, 'prepa');
          }
          if (status == EXPEDIEE_STATUS) {
            if (draft.shipment.type === 'colissimo' && draft.editStatus != DEPOT_STATUS) {
              print(draft.id, 'ship');
              // we move to depot here so we stay in "draft" mode
              loadDraft();
            } else {
              // for other cases, we move to "commande"
              history.replace({
                pathname: '/commandes/' + draft.id,
              });
            }
          } else {
            loadDraft();
          }
          if (callback) callback();
        },
        (err) => console.log(err),
      );
    }
  };

  const generatePaiementURL = (type: 'URL' | 'MAIL') => {
    createPaiementOrder(draft.id, draft.editStatus, type, loadDraft);
  };

  useEffect(loadDraft, [draftId]);

  return {
    draftId,
    draft,
    isLoading,
    isPaiementOrderLoading,
    updateStatus,
    generatePaiementURL,
    setDraftId,
  };
};
