import React, { useContext } from 'react';
import TextSelector from '../../../../../_shared/component/textSelector/textSelector';
import { DraftEditorContext } from '../../../../../_shared/providers/draft/draftEditor.provider';
import NumberInput from '../../../../../_shared/component/numberInput';
import Row from '../../../../../_shared/component/row/row';
import { setFraisPort, setShipmentType } from '../../../../../_shared/providers/draft/draftEditor.actions';
import { SHIPMENT_TYPES } from '../../../../../_shared/config/constants';

export const ShippingPanel: React.FC<any> = () => {
  const { shipment, fraisPort, dispatch } = useContext(DraftEditorContext);

  return (
    <div className="shipping-panel">
      <Row className="light">
        <div className="circle p-medium t-medium column center t-grey b-lgrey bordered">
          <i className="material-icons">send</i>
        </div>
        <div>LIVRAISON</div>
      </Row>
      <Row className=" gapped p-small">
        <TextSelector
          value={shipment.type}
          labelHide={true}
          name={'shipment'}
          options={SHIPMENT_TYPES}
          isEditable={true}
          className="t-large p-small t-grey shipping-input"
          onChange={(value: any) => dispatch(setShipmentType(value['shipment']))}
        />
        {shipment.type !== 'boutique' ? (
          <Row>
            <div className="column">
              <label className="t-small">FRAIS</label>
              <label className="t-small">DE LIVRAISON</label>
            </div>
            <div className="row gapped ">
              <NumberInput
                name={'fraisPort'}
                className="t-grey light r-align"
                value={fraisPort}
                size={4}
                resize={true}
                onChange={({ fraisPort }: any) => dispatch(setFraisPort(fraisPort))}
              />
            </div>
            <div className="light t-large">€</div>
          </Row>
        ) : null}
      </Row>
    </div>
  );
};
