import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { AuthConsumer } from '../routing/auth.context';
import './header.scss';
import {
  CLIENTS_PATH,
  DRAFTS_PATH,
  INGREDIENTS_PATH,
  ORDERS_PATH,
  PRACTITIONERS_PATH,
  PRICES_PATH,
  STATS_PATH,
} from '../../_shared/config/paths';
import { NON_PAYEE_STATUS, VALIDEE_STATUS } from '../../_shared/config/constants';

export const Header: React.FC = () => {
  return (
    <AuthConsumer>
      {({ isAuth, logout }) =>
        isAuth ? (
          <div className="header row spaced">
            <nav className="panelButton row gapped">
              <NavLink to="/nouvelleCommande" className="button" activeClassName="active" data-cy="Header-new-order">
                + Creer une commande
              </NavLink>
              <NavLink
                className="button"
                activeClassName="active"
                to={{
                  pathname: DRAFTS_PATH,
                  search: `status=${VALIDEE_STATUS}`,
                }}
              >
                <span className="material-icons">access_time</span>
                <span> En cours </span>
              </NavLink>
              <NavLink
                className="button"
                activeClassName="active"
                to={{
                  pathname: ORDERS_PATH,
                  search: `status=${NON_PAYEE_STATUS}`,
                }}
              >
                <span className="material-icons">format_list_bulleted</span>
                <span>Commandes</span>
              </NavLink>
              <NavLink className="button" activeClassName="active" to={PRACTITIONERS_PATH} data-cy="Header-praticiens">
                <span className="material-icons">medical_services</span>
                <span>Praticiens</span>
              </NavLink>
              <NavLink className="button" activeClassName="active" to={CLIENTS_PATH}>
                <span className="material-icons">person</span>
                <span>Clients</span>
              </NavLink>
              <NavLink className="button" activeClassName="active" to={INGREDIENTS_PATH}>
                <span className="material-icons">spa</span>
                <span>Ingrédients</span>
              </NavLink>
              <NavLink className="button" activeClassName="active" to={PRICES_PATH}>
                <span className="material-icons">euro</span>
                <span>Prix</span>
              </NavLink>
              <NavLink className="button" activeClassName="active" to={STATS_PATH}>
                <span className="material-icons">leaderboard</span>
                <span>Stats</span>
              </NavLink>
            </nav>
            <button className={'logout_button'} onClick={logout}>
              <i className="material-icons">power_settings_new</i>
              Déconnexion
            </button>
          </div>
        ) : null
      }
    </AuthConsumer>
  );
};
