import { useContext, useEffect, useState } from 'react';
import { ServiceContext } from '../../../_core/services/service.context';
import { usePrint } from './usePrint';
import { orderService } from '../../services/order.service';
import { R1_STATUS, R2_STATUS } from '../../config/constants';
import usePaiementOrder from '../paiementOrder/usePaiementOrder';

export const useOrderById = () => {
  const [orderId, setOrderId] = useState<string>();
  const [order, setOrder] = useState(null as null | any);
  const [isLoading, setIsLoading] = useState(false);
  const { query, pushAlert } = useContext(ServiceContext);
  const print = usePrint();

  const { createPaiementOrder, isLoading: isPaiementOrderLoading } = usePaiementOrder();

  const loadOrder = () => {
    if (orderId) {
      setIsLoading(true);
      query(orderService.getOrderById(orderId))
        .subscribe(
          (res: any) => {
            setOrder(res);
          },
          (err) => console.log(err),
        )
        .add(() => setIsLoading(false));
    } else {
      setOrder(null);
    }
  };

  const updateStatus = (status: string, callback?: any) => {
    if (orderId) {
      query(orderService.updateOrderStatus([orderId], status)).subscribe(
        () => {
          if (status === R1_STATUS || status === R2_STATUS) {
            print(orderId, status);
          }
          //setOrder({ ...order, editStatus: status });
          loadOrder();
          if (callback) callback();
        },
        (err) => console.log(err),
      );
    }
  };

  const updateComment = (comment?: string, paymentComment?: string) => {
    if (order) {
      query(orderService.updateOrderComment(order.id, comment, paymentComment), {
        notify: 'Commentaire mis à jour',
        onError: 'Erreur avec la mise à jour du commentaire',
      }).subscribe(
        () => {
          setOrder({
            ...order,
            commentaire: comment || order.commentaire,
            commentairePaiement: paymentComment || order.commentairePaiement,
          });
        },
        (err) => console.log(err),
      );
    }
  };

  const addPaiement = (amount: number, date: string, praticien: boolean) => {
    if (order) {
      query(orderService.addPaiement(order.id, amount, date, praticien), {
        notify: `Le paiement a bien été ${amount >= 0 ? 'effectué' : 'annulé'}`,
        noAlert: true,
      }).subscribe(
        (order: any) => setOrder(order),
        (err: any) => {
          pushAlert({
            type: 'error',
            message: err.message,
            permanent: true,
          });
        },
      );
    }
  };

  const generatePaiementURL = (type: 'URL' | 'MAIL') => {
    createPaiementOrder(order.id, order.editStatus, type, loadOrder);
  };

  useEffect(loadOrder, [orderId]);
  return {
    orderId,
    order,
    isLoading,
    isPaiementOrderLoading,
    setOrderId,
    print,
    updateStatus,
    updateComment,
    addPaiement,
    generatePaiementURL,
  };
};
