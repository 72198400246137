import React from 'react';
import { R2_STATUS } from '../../../../_shared/config/constants';

interface ISetToR2ButtonProps {
  onUpdateToR2: any;
  minified?: boolean;
  disabled?: boolean;
}

export const SetToR2Button: React.FC<ISetToR2ButtonProps> = (props: ISetToR2ButtonProps) => {
  return (
    <div className="row gapped">
      <button
        disabled={props.disabled}
        className={props.minified ? 'button-alert minified' : 'button-alert'}
        onClick={() => props.onUpdateToR2(R2_STATUS)}
      >
        <i className="material-icons">warning</i>
        {props.minified ? '' : 'Passer en relance 2'}
      </button>
      <div>{props.minified ? 'R2' : ''}</div>
    </div>
  );
};
