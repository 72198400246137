import React, { useContext } from 'react';
import { DraftEditorContext } from '../../../../../_shared/providers/draft/draftEditor.provider';
import { setCommentaire } from '../../../../../_shared/providers/draft/draftEditor.actions';
import { TextArea } from '../../../../../_shared/component/textArea/textArea.component';

export const DraftComment: React.FC = () => {
  const { commentaire, dispatch } = useContext(DraftEditorContext);

  return (
    <div className="draft-comment column">
      <div className="draft-comment_label">
        <label className="t-small">COMMENTAIRE DE LA COMMANDE</label>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
        <TextArea
          value={commentaire}
          minRows={4}
          name={'commentaire'}
          onChange={(e: any) => dispatch(setCommentaire(e.commentaire))}
          dataCy="DraftComment"
        />
      </div>
    </div>
  );
};
