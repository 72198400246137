import React, { useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { orderSelectionReducer } from '../../../../_shared/providers/order/orderSelectionReducer';
import { OrdersActionsPanel } from './paiementActionsPanel/paiementActions.panel';
import Checkbox from '../../../../_shared/component/checkBox/checkbox';
import { formatToDate, useEuroFormatter } from '../../../../_shared/services/formatter.service';
import { HeadersList } from '../../../../_shared/component/tableHeadersList/Headers.list';
import { STATUS_PROPS } from '../../../../_shared/config/constants';
import { ORDERS_PATH } from '../../../../_shared/config/paths';

const orderListHeaders = [
  {
    title: 'code',
    name: 'code',
  },
  {
    title: 'client',
    name: 'client',
  },
  {
    title: 'praticien',
    name: 'praticien',
  },
  {
    title: "date d'expedition",
    name: 'date',
  },
  {
    title: 'date de paiement',
    name: 'paiementDate',
  },
  {
    title: 'prix',
    name: 'prixCalcul',
  },
  {
    title: 'Reste à payer',
    name: 'reste',
  },
  {
    title: 'commentaire',
    name: 'commentaire',
    className: 'fit-content',
    locked: true,
  },
  {
    title: 'status',
    name: 'status',
    className: 'fit-content',
    locked: true,
  },
];

interface IOrdersListProps {
  ordersStatus: string[];
  orders: any[];
  sort: string;
  direction: 'asc' | 'desc';
  onUpdateSort: (sort: string) => void;
  onPrint: (id: string, status: string) => void;
  onPrintMulti: (ids: string[], status: string) => void;
  onUpdateOrdersStatus: (ids: string[], status: string) => void;
}

export const OrdersList: React.FC<IOrdersListProps> = (props: IOrdersListProps) => {
  const history = useHistory();
  const formatToEuro = useEuroFormatter();

  const [orderSelectedState, dispatchOrderSelected] = useReducer(orderSelectionReducer, {
    ordersSelected: [],
  });

  useEffect(() => {
    dispatchOrderSelected({ type: 'remove_allOrders' });
  }, [props.orders]);

  const orders = props.orders;
  const ordersSelected = orderSelectedState.ordersSelected;
  const redirectTo = (orderId: string) => history.push(`${ORDERS_PATH}/${orderId}`);

  return (
    <>
      <table className="full-width list">
        <thead>
          <tr>
            <th className="fit-content">
              <div className="row">
                {props.ordersStatus?.length === 1 ? (
                  <>
                    <Checkbox
                      name={'all'}
                      value={ordersSelected.length > 0}
                      onChange={() =>
                        ordersSelected.length === orders.length
                          ? dispatchOrderSelected({
                              type: 'remove_allOrders',
                            })
                          : dispatchOrderSelected({
                              type: 'add_orders',
                              payload: orders.map((o: any) => o.id),
                            })
                      }
                    />
                    <OrdersActionsPanel
                      editStatus={props.ordersStatus[0]}
                      updateStatus={(status: string) =>
                        props.onUpdateOrdersStatus(orderSelectedState.ordersSelected, status)
                      }
                      printOrder={(status: string) => props.onPrintMulti(ordersSelected, status)}
                      disabled={ordersSelected.length <= 0}
                    />
                  </>
                ) : null}
              </div>
            </th>
            <HeadersList
              headersList={orderListHeaders}
              sort={{ sort: props.sort, direction: props.direction }}
              onUpdateSort={props.onUpdateSort}
            />
          </tr>
        </thead>
        <tbody className="table-body">
          {orders.map((order: any, index: number) => (
            <tr key={index}>
              <td className="fit-content">
                {props.ordersStatus?.length === 1 ? (
                  <div className="row">
                    <Checkbox
                      name={'select'}
                      value={ordersSelected.findIndex((o: string) => o === order.id) >= 0}
                      onChange={(value: boolean) => {
                        dispatchOrderSelected({
                          type: value ? 'add_order' : 'remove_order',
                          payload: order.id,
                        });
                      }}
                    />

                    <OrdersActionsPanel
                      editStatus={order.status as string}
                      updateStatus={(status: string) => props.onUpdateOrdersStatus([order.id], status)}
                      printOrder={(status: string) => props.onPrint(order.id, status)}
                      disabled={false}
                    />
                  </div>
                ) : null}
              </td>
              <td className="small" onClick={() => redirectTo(order.id)}>
                {order.codeFull}
              </td>
              <td className="medium" onClick={() => redirectTo(order.id)}>
                {order.client}
              </td>
              <td className="xsmall" onClick={() => redirectTo(order.id)}>
                {order.praticienCode || '--'}
              </td>
              <td className="small no-wrap" onClick={() => redirectTo(order.id)}>
                {formatToDate(order.date)}
              </td>
              <td className="small no-wrap" onClick={() => redirectTo(order.id)}>
                {formatToDate(order.paiementDate)}
              </td>
              <td className="xsmall r-align" onClick={() => redirectTo(order.id)}>
                {formatToEuro(order.prixCalcul)}
              </td>
              <td className="xsmall r-align" onClick={() => redirectTo(order.id)}>
                {formatToEuro(order.reste)}
              </td>
              <td className="large" onClick={() => redirectTo(order.id)}>
                {order.commentaire}
              </td>
              <td className="no-wrap">{STATUS_PROPS[order.status]?.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
