import * as React from 'react';
import OrderEvent from '../../../../_shared/models/event';
import moment from 'moment';

import './events.panel.scss';
import Modal from '../../../../_shared/component/modal/modal';
import NumberInput from '../../../../_shared/component/numberInput';
import { useState } from 'react';
import { STATUS_PROPS } from '../../../../_shared/config/constants';
import useCopyInClipboard from '../../../../_shared/component/useCopyInClipboard';
import DateInput from '../../../../_shared/component/dateInput/dateInput';
import Checkbox from '../../../../_shared/component/checkBox/checkbox';

const formatDate = (date: any) =>
  moment(date || new Date())
    .locale('fr')
    .format('DD MMMM YYYY : HH:mm');

const euroFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
});

const formatToEuro = (value: any) => (isNaN(value) ? '---' : euroFormatter.format(value));

const getEventRender = (event: OrderEvent, onAddPaiement: any, onCopyToCP: any) => {
  switch (event.type) {
    case 'CREATE':
      return (
        <tr className="event">
          <td>
            <div className="row gapped t-green">
              <span className="material-icons t-lgrey">arrow_forward</span>
              <span>Création</span>
            </div>
          </td>
          <td>{formatDate(event.date)}</td>
          <td>{event.user}</td>
          <td />
          <td />
        </tr>
      );
    case 'PAIEMENT_ORDER':
      return (
        <tr className="event">
          <td>
            <div className="row gapped">
              <span className="material-icons">link</span>
              <span>Lien de paiement</span>
            </div>
          </td>
          <td>{formatDate(event.date)}</td>
          <td>{event.user}</td>
          <td>{event.payload.orderType}</td>
          <td onClick={() => onCopyToCP(event.payload.url)}>
            <span className="material-icons">content_copy</span>
          </td>
        </tr>
      );
    case 'STATUS':
      const status = event.payload.status;
      const statusProps = STATUS_PROPS[status];
      return (
        <tr className="event">
          <td>
            <div className="row gapped" style={{ color: statusProps.color }}>
              <span className="material-icons t-lgrey">arrow_forward</span>
              <span className="material-icons">{statusProps.icon}</span>
              <span>{statusProps.name}</span>
            </div>
          </td>
          <td>{formatDate(event.date)}</td>
          <td>{event.user}</td>
          <td />
          <td />
        </tr>
      );
    case 'PAIEMENT':
      const { amount } = event.payload;
      const isPositive = event.payload.amount >= 0;
      return (
        <tr className="event">
          <td>
            <div className={`${isPositive ? ' t-green' : ' t-red'} row gapped`}>
              <span className="material-icons">euro_symbol</span>
              <span>{isPositive ? 'Paiement' : 'Annulation paiement'}</span>
            </div>
          </td>
          <td>{formatDate(event.date)}</td>
          <td>{event.user}</td>
          <td className={`${isPositive ? 't-green' : 't-red'}`}>
            {isPositive ? '+' : null}
            {formatToEuro(event.payload.amount)}
          </td>
          {isPositive ? (
            <td>
              <button className="button-lgrey circle" onClick={() => onAddPaiement(-amount)}>
                <span className="material-icons">close</span>
              </button>
            </td>
          ) : (
            <td />
          )}
        </tr>
      );
  }
};

const EventList: React.FC<any> = ({ events, onAddPaiement }: any) => {
  const copy = useCopyInClipboard();

  return (
    <div className="events-panel scrollable-y">
      <table>
        <tbody className="t-grey">
          {events.map((event: OrderEvent) => getEventRender(event, onAddPaiement, copy))}
        </tbody>
      </table>
    </div>
  );
};

interface IEventModal {
  events: OrderEvent[];
  isDisplayed: boolean;
  onHide: any;
  onAddPaiement: any;
}

const HistoryModal: React.FC<IEventModal> = ({ events, isDisplayed, onHide, onAddPaiement }: IEventModal) => {
  return events && events.length ? (
    <Modal on={isDisplayed} onCancel={onHide}>
      <div className="events-panel p-medium card column gapped">
        <div className="p-medium br-bottom">HISTORIQUE</div>
        <EventList events={events} onAddPaiement={onAddPaiement} />
      </div>
    </Modal>
  ) : null;
};

interface IPaiementModal extends IEventModal {
  maxAmount: number;
}

const PaiementModal: React.FC<IPaiementModal> = ({
  events,
  isDisplayed,
  onHide,
  onAddPaiement,
  maxAmount,
}: IPaiementModal) => {
  const [amount, setAmount] = useState<number>(0);
  const [toPraticien, setToPraticien] = useState<boolean>(false);
  const [date, setDate] = useState<string>(moment().format('YYYY-MM-DD'));

  const handleOnChangePraticien = (e: boolean) => {
    setToPraticien(e);
    if (e) {
      setAmount(maxAmount);
    } else {
      setAmount(0);
    }
  };

  return (
    <Modal on={isDisplayed} onCancel={onHide}>
      <div className="p-medium card column gapped">
        {events?.length ? (
          <div className="column gapped">
            <div className="br-bottom light">PAIEMENTS: </div>
            <EventList events={events} onAddPaiement={onAddPaiement} />
          </div>
        ) : (
          <div className="row center">
            <span className="light t-grey">Aucun paiement pour l'instant</span>
          </div>
        )}
        <div className="row gapped br-top p-medium light">
          <span className="material-icons t-green">add</span>
          <span>Ajouter un paiement :</span>
          <span className="t-lgrey">(Max {maxAmount} €)</span>
        </div>

        <div className="row flex-start gapped spaced p-medium light">
          <DateInput value={date} label={'Date'} name={'date'} onChange={(d: any) => setDate(d.date)} />

          <div>
            <label>Paiement praticien</label>
            <Checkbox name="toPraticien" value={toPraticien} onChange={handleOnChangePraticien} />
          </div>

          <div>
            <label>Montant</label>
            <div className="row gapped-l flex-end">
              <NumberInput
                name={'amount'}
                value={amount}
                locked={toPraticien}
                size={4}
                resize={true}
                autofocus={true}
                className="t-grey light r-align t-large bordered rounded"
                onChange={(e: any) => setAmount(e.amount)}
                onPressEnter={() =>
                  amount <= maxAmount && amount > 0 ? onAddPaiement(amount, date, toPraticien) : null
                }
              />
              <button
                className="button-validate circle"
                disabled={amount <= 0 || amount > maxAmount}
                onClick={() => onAddPaiement(amount, date, toPraticien)}
              >
                <span className="material-icons">check</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { EventList, HistoryModal, PaiementModal };
