import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { switchMap } from 'rxjs/operators';

import { ServiceContext } from '../../../_core/services/service.context';
import { orderService } from '../../services/order.service';

import { DRAFTS_PATH } from '../../config/paths';

export const useCopyOrder = () => {
  const history = useHistory();
  const { query } = useContext(ServiceContext);
  const copyOrder = (orderId: string) => {
    if (query) {
      query(orderService.createNewDraft())
        .pipe(
          switchMap((draft: any) => {
            return query(orderService.copyOrder(orderId, draft.id), {
              notify: 'Commande renouvellée',
            });
          }),
        )
        .subscribe(
          (draft: any) => {
            history.push(`${DRAFTS_PATH}/${draft.id}`);
          },
          (err: any) => console.log(err),
        );
    }
  };
  return copyOrder;
};
