import React, { useContext, useEffect, useState } from 'react';
import { SavePanel } from '../savePanel/save.panel';
import { DraftEditorContext } from '../../../../../_shared/providers/draft/draftEditor.provider';
import { DEVIS_STATUS, VALIDEE_STATUS } from '../../../../../_shared/config/constants';

const useIsDraftValid = () => {
  const [isDraftValid, setIsDraftValid] = useState(false);
  const { client, compositions } = useContext(DraftEditorContext);

  useEffect(() => {
    let valid = true;
    compositions.map((composition) => {
      if (!composition.valid) {
        valid = false;
        return;
      }
    });
    setIsDraftValid(!!client && compositions.length > 0 && valid);
  }, [compositions, client]);
  return isDraftValid;
};

const DraftConfirmationPanel: React.FC<any> = () => {
  const { setDraftAs, clientWarning, practitioner, editStatus, paiement } = useContext(DraftEditorContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalMessages, setModalMessages] = useState<string[]>([]);
  const isDraftValid = useIsDraftValid();

  useEffect(() => {
    const messages = [];
    if (clientWarning) {
      messages.push('Le client sélectionné contient des alertes');
    }
    if (practitioner?.devisPrealable && editStatus != DEVIS_STATUS) {
      messages.push('Le praticien demande un devis préalable.');
    }
    if (practitioner?.prepayeRequis && paiement?.type !== 'prepaye') {
      messages.push('Le praticien demande un paiement préalable.');
    }
    setModalMessages(messages);
  }, [clientWarning, practitioner, editStatus, paiement]);

  const onValidate = () => {
    if (showModal) {
      setDraftAs(VALIDEE_STATUS);
    } else if (modalMessages.length > 0) {
      setShowModal(true);
    } else {
      setDraftAs(VALIDEE_STATUS);
    }
  };

  return (
    <div className="draft-confirmation-panel">
      <SavePanel />
      <div className="row end gapped ">
        <button
          className="button-print"
          type="button"
          disabled={!isDraftValid}
          onClick={() => setDraftAs(DEVIS_STATUS)}
        >
          <i className="material-icons">list</i>
          Passer en devis
        </button>
        <button
          className="button-validate"
          type="button"
          disabled={!isDraftValid}
          onClick={onValidate}
          data-cy="DraftConfirmationPanel-Valider"
        >
          <i className="material-icons">done</i>
          Valider la commande
        </button>
      </div>
      {showModal ? (
        <div className="absolute full-height full-width row center overlay">
          <div className="column center p-medium b-white gapped rounded">
            <span className="material-icons t-red">error</span>
            <div className="p-medium">
              {modalMessages.map((message: string, i: number) => (
                <div key={i}>{message}</div>
              ))}
            </div>
            <div className="br-top t-grey p-small">Êtes-vous sûr de vouloir valider la commande ?</div>
            <div className="row gapped ">
              <button
                onClick={onValidate}
                className="button-validate t-large"
                data-cy="DraftConfirmationPanel-modal-oui"
              >
                oui
              </button>
              <button onClick={() => setShowModal(false)} className="button-alert t-large">
                non
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DraftConfirmationPanel;
