import React, { FC, PropsWithChildren, ReactElement } from 'react';
import styles from './PointRow.module.scss';
import { formatToDate } from '../../../_shared/services/formatter.service';

interface IPointRow {
  type?: 'pointEvent' | 'other';
  icon?: string;
  amount: number;
  date?: string;
  className?: string;
  children: any;
  onDeletedClicked?: () => void;
}

/**
 * Represents point informations as a row for a listing purpose.
 * @param type {"pointEvent" | "other"} whether the point is an event or not
 * @param icon {string} the icon use to prefix the row
 * @param amount {number} the amount of point
 * @param date {string} the date for the event
 * @param className {string} custom className
 * @param children {string[]}
 * @param onDeleteClicked callback called when the delete icon is clicked
 */
const PointRow: FC<PropsWithChildren<IPointRow>> = ({
  type = 'pointEvent',
  icon = 'arrow_forward',
  amount,
  date,
  className,
  children,
  onDeletedClicked: onDeleteClicked,
}: IPointRow): ReactElement => {
  // Different style according to the type
  const amountClassNames = type === 'pointEvent' ? styles.pointEventAmount : styles.otherAmount;

  return (
    <div className={`${styles.pointRow} p-2 ${className}`}>
      {/* Icon, arrow_forward by default */}
      <span className={`${styles.materialIcons} material-icons t-lgrey`}>{icon}</span>
      {/* Amount */}
      <span className={`${amountClassNames} ${amount >= 0 ? styles.positive : styles.negative} mx-2`}>
        {amount}
      </span>{' '}
      {/* If no children, display date and clear icon */}
      {!children && (
        <>
          {/* Date */}
          {date && <span className="push-left">{formatToDate(date)}</span>}
          {/* Clear icon on type point event */}
          {type === 'pointEvent' && (
            <i className={`${styles.clearIcon} material-icons push-left`} onClick={onDeleteClicked}>
              clear
            </i>
          )}
        </>
      )}
      {children}
    </div>
  );
};

export default PointRow;
