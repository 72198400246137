import React from 'react';
import { useEuroFormatter, usePoidsFormatter } from '../../../../_shared/services/formatter.service';
import { isDraftStatus } from '../../../../_shared/services/order.service';

interface IOrderInfoProps {
  order: any;
}

export const OrderInfo: React.FC<IOrderInfoProps> = (props: IOrderInfoProps) => {
  const formatToPoids = usePoidsFormatter();
  const formatToEuro = useEuroFormatter();

  const order = props.order;
  return (
    <div className="row gapped full-width">
      <div className="row gapped full-width p-medium b-grey rounded">
        <div className="p-small column">
          <div className="t-small">FRAIS</div>
          <div className="t-small">DE PORT</div>
          <div className="t-xlarge p-small t-grey">{formatToEuro(order.fraisPort || 0)}</div>
        </div>
        <div className="p-small column">
          <div className="t-small">PRIX TOTAL</div>
          <div className="t-small">PRÉPARATIONS</div>
          <div className="t-xlarge p-small t-grey">{formatToEuro(order.prix)}</div>
        </div>
        <div className="p-small column">
          <div className="t-small">POIDS TOTAL</div>
          <div className="t-small">DE LA COMMANDE</div>
          <div className="t-xlarge p-small bold t-grey">{formatToPoids(order.poids)}</div>
        </div>
        <div className="p-small column">
          <div className="t-small">PRIX TOTAL</div>
          <div className="t-small">DE LA COMMANDE</div>
          <div className="t-xlarge p-small bold t-red">{formatToEuro(order.prixTotal)}</div>
        </div>
      </div>
      {!isDraftStatus(order.editStatus) && order.reste && order.reste > 0 ? (
        <div className="p-medium column rounded bordered t-red">
          <div className="t-small">RESTE</div>
          <div className="t-small">À PAYER</div>
          <div className="t-xlarge p-small bold">{formatToEuro(order.reste)}</div>
        </div>
      ) : null}
    </div>
  );
};
