import React, { useContext, useState } from 'react';
import ParentComponent from '../../../../../../../_shared/component/parentComponent/parent.component';
import Row from '../../../../../../../_shared/component/row/row';
import useEscape from '../../../../../../../_shared/component/useEscape';
import { DraftEditorContext } from '../../../../../../../_shared/providers/draft/draftEditor.provider';
import { deleteCompositionSelected } from '../../../../../../../_shared/providers/draft/draftEditor.actions';

const DeleteCompositionButton: React.FC = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { dispatch } = useContext(DraftEditorContext);

  useEscape(() => setIsDeleting(false));

  return (
    <ParentComponent onBlur={() => setIsDeleting(false)} className="delete-composition-button">
      {isDeleting ? (
        <div className="t-grey rounded bordered">
          <div>Etes-vous sûr ?</div>
          <Row align="spaced">
            <button
              className="t-green"
              onClick={() => {
                dispatch(deleteCompositionSelected());
                setIsDeleting(false);
              }}
            >
              Oui
            </button>
            <button className="t-red" autoFocus={true} onClick={() => setIsDeleting(false)}>
              Non
            </button>
          </Row>
        </div>
      ) : null}

      <button tabIndex={1} onClick={() => setIsDeleting(true)}>
        <i className="material-icons">close</i>
      </button>
    </ParentComponent>
  );
};

export default DeleteCompositionButton;
