import React from 'react';
import { Practitioner } from '../../../../_shared/models/practitioner';

interface IPractitionerCardProps {
  practitioner: Practitioner | null;
  remised?: boolean;
}

export const PractitionerCard: React.FC<IPractitionerCardProps> = ({
  practitioner,
  remised,
}: IPractitionerCardProps) => {
  return (
    <>
      {practitioner ? (
        <div className="row spaced full-width">
          <div className="row gapped">
            <div className="bold t-xlarge">{practitioner.code}</div>
            <div className="t-medium">
              <div>{practitioner.prenom}</div>
              <div>{practitioner.nom}</div>
            </div>
          </div>
          <div className="column end p-medium">
            {remised ? (
              <div className="row gapped t-green">
                <span className="material-icons t-large">local_offer</span>
                <span className="t-large">{practitioner.remise} %</span>
              </div>
            ) : null}
            {practitioner.prescription ? (
              <div className="t-red t-medium bold r-align">Joindre les prescriptions</div>
            ) : null}
            {practitioner.prepayeRequis ? <div className="t-red t-medium bold r-align">Prépayé requis</div> : null}
            {practitioner.note ? <div className="t-red t-medium bold r-align">Note : {practitioner.note}</div> : null}
          </div>
        </div>
      ) : (
        <div className="row gapped">
          <div className="t-small">Sans praticien</div>
        </div>
      )}
    </>
  );
};
