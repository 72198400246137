import React, { useEffect, useRef, useState } from 'react';
import TextInput from '../../../../_shared/component/textInput/textInput.component';
import useEscape from '../../../../_shared/component/useEscape';
import Modal from '../../../../_shared/component/modal/modal';

interface IArchiveConfirmationModalProps {
  orderCode: string;
  isDisplayed: boolean;
  onHideModal: any;
  onValidate: any;
}

export const ArchiveConfirmationModal: React.FC<IArchiveConfirmationModalProps> = (
  props: IArchiveConfirmationModalProps,
) => {
  const [code, setCode] = useState('');

  return (
    <Modal on={props.isDisplayed} onCancel={props.onHideModal}>
      <div className="card">
        <div className="column center br-bottom">
          <div className="t-large t-red">
            <span className="material-icons">delete</span>
          </div>
          <div className="light br-bottom p-medium">
            Être vous sûr de confirmer l'archivage de la commande <b>{props.orderCode}</b> ?
          </div>
          <div className="light t-grey p-medium">Veuillez rentrer le code de la commande afin de l'archiver.</div>
          <TextInput
            value={code}
            name={'code'}
            className="p-medium"
            onChange={(e: any) => setCode(e.code)}
            isEditable={true}
            labelHide={true}
          />
        </div>
        <div className="row center gapped p-medium">
          <button
            type={'button'}
            disabled={!!props.orderCode && code !== props.orderCode.toString()}
            className="button-validate"
            onClick={() => {
              props.onValidate();
              props.onHideModal();
            }}
          >
            Oui
          </button>
          <button type={'button'} className="button-alert" onClick={() => props.onHideModal()}>
            Non
          </button>
        </div>
      </div>
    </Modal>
  );
};
