import * as React from 'react';
import { IFormFieldProps } from '../ingredientForm';

interface IChineseInputProps extends IFormFieldProps<string> {
  chinese: string | undefined;
}

const ChineseInput: React.FunctionComponent<IChineseInputProps> = (props: IChineseInputProps) => {
  return (
    <div>
      <label>
        CHINOIS <span style={{ color: 'red' }}>{props.isExist ? 'Existe déjà' : ''}</span>
      </label>
      <input
        className={props.isExist ? 'onError' : ''}
        type="text"
        name="chinois"
        disabled={!props.isEditable}
        value={props.chinese}
        autoComplete="off"
        placeholder="Chinois"
        onChange={(e) => props.onChange(e)}
      />
    </div>
  );
};

export default ChineseInput;
