import React, { useContext, useRef, useState } from 'react';
import { Composition } from '../../../../../_shared/models/composition';
import { useEuroFormatter, usePoidsFormatter } from '../../../../../_shared/services/formatter.service';
import { usePrixTTC } from '../../../../../_shared/providers/draft/usePrixTTC';
import { usePoidsTotal } from '../../../../../_shared/providers/order/usePoidsTotal';
import { FormesList } from '../formesList/formes.list';
import useEscape from '../../../../../_shared/component/useEscape';
import useOutsideClick from '../../../../../_shared/component/useClickOutside';
import CompositionRow from './composition.row';
import ParentComponent from '../../../../../_shared/component/parentComponent/parent.component';
import { DraftEditorContext } from '../../../../../_shared/providers/draft/draftEditor.provider';
import {
  setCompositionSelected,
  setCompositionSelectedAsNew,
} from '../../../../../_shared/providers/draft/draftEditor.actions';
import Row from '../../../../../_shared/component/row/row';

export const CompositionList: React.FC<any> = () => {
  const [showList, setShowlist] = useState(false);
  const listRef = useRef(null);
  useEscape(() => setShowlist(false));
  useOutsideClick(listRef, () => setShowlist(false));

  const { compositions, compositionSelected, dispatch } = useContext(DraftEditorContext);

  const formatToEuro = useEuroFormatter();
  const formatToPoids = usePoidsFormatter();
  const prixTTC = usePrixTTC();
  const poidsTotal = usePoidsTotal(compositions);

  return (
    <div className="composition-list">
      <>
        <Row align="spaced" className="composition-list_header bordered p-small">
          <Row className="light">
            <div className="circle p-medium column center t-grey b-lgrey bordered">
              <i className="material-icons t-large">list</i>
            </div>
            <div className="t-green">LISTE DES PRÉPARATIONS</div>
          </Row>
          <div className="relative p-medium" ref={listRef}>
            <button
              className="button-validate minified"
              onClick={() => setShowlist(!showList)}
              data-cy="CompositionList-add"
            >
              <i className="material-icons">add</i>
            </button>
            {showList ? (
              <ParentComponent className="formes-list fixed b-white fit-width" onBlur={() => setShowlist(false)}>
                <div className="light row p-medium center">
                  <div>+ Ajouter une préparation</div>
                </div>
                <FormesList
                  onSelect={(formeId: string) => {
                    dispatch(setCompositionSelectedAsNew(formeId));
                    setShowlist(false);
                  }}
                />
                <button className="row center" onClick={() => setShowlist(false)}>
                  <span className="material-icons">expand_less</span>
                </button>
              </ParentComponent>
            ) : null}
          </div>
        </Row>
        <div className="composition-list_content p-small">
          <table>
            <tbody>
              {compositions.map((composition: Composition, index: number) => (
                <CompositionRow
                  composition={composition}
                  index={index}
                  key={`composition-row_${index}`}
                  selected={index === compositionSelected}
                  onCompositionSelected={() => dispatch(setCompositionSelected(index))}
                />
              ))}
              <tr>
                <td className="t-large p-medium light">TOTAL:</td>
                <td className="no-wrap">{formatToEuro(prixTTC)}</td>
                <td className="no-wrap">{formatToPoids(poidsTotal)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    </div>
  );
};
