import React from 'react';
import { EXPEDIEE_STATUS } from '../../../../_shared/config/constants';

interface ISetToExpedieeButtonProps {
  onSetToExpediee: any;
  minified?: boolean;
  disabled?: boolean;
  label?: string;
}

export const SetToExpedieeButton: React.FC<ISetToExpedieeButtonProps> = (props: ISetToExpedieeButtonProps) => {
  return (
    <div>
      <button
        className={props.minified ? 'button-validate minified' : 'button-validate bordered'}
        disabled={props.disabled}
        onClick={() => props.onSetToExpediee(EXPEDIEE_STATUS)}
        data-cy="SetToExpediee"
      >
        <i className="material-icons">send</i>
        {props.minified ? null : <span>{props.label ? props.label : 'Expédier'}</span>}
      </button>
    </div>
  );
};
