import { from, Observable, throwError } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { BASE_URL } from '../config/constants';

export class FormuleService {
  getFormule(formuleId: string, formeId: string): Observable<any> {
    return from(
      fetch(`${BASE_URL}/formule/${formuleId}?fid=${formeId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
      map((res: any) => {
        const ingredients: any = res.ingredients
          // TODO: we should not filter silently here, otherwise ingredients will be missing
          .filter(
            (ingredient: any) =>
              ingredient.weight &&
              ingredient.ing &&
              ingredient.ing.id &&
              ingredient.ing.pinyin &&
              ingredient.ing.codeprix,
          )
          .map((data: any) => {
            if (data && data.ing) {
              return {
                id: data.ing.id,
                code: data.ing.code,
                pinyin: data.ing.pinyin,
                proportion: data.weight,
                codeprix: data.ing.codeprix,
                percent: '0',
                poids_reel: 0,
                prix: data.ing.prix || 0.056,
                prix_total: 0,
              };
            }
          });
        return {
          name: res.title,
          ingredients,
        };
      }),
    );
  }

  getSuggestions(field: string) {
    return from(
      fetch(`${BASE_URL}/suggest/formule?query=${field}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  }
}

export const formuleService = new FormuleService();
