import React from 'react';
import Modal from '../../../../_shared/component/modal/modal';

export const ShippingConfirmationModal: React.FC<any> = (props: any) => {
  return props.showModal ? (
    <Modal on={props.showModal} onCancel={props.onHideModal}>
      <div className="p-medium b-white card">
        <div className="column center br-bottom p-medium">
          <div className="t-large t-green">
            <span className="material-icons">send</span>
          </div>
          <div className="light">Êtes vous sûr de confirmer l'expédition ?</div>
        </div>
        <div className="row center gapped p-medium">
          <button
            type={'button'}
            autoFocus={true}
            className="button-validate"
            onClick={() => {
              props.onValidate();
              props.onHideModal(false);
            }}
            data-cy="ShippingConfirmationModal-oui"
          >
            Oui
          </button>
          <button type={'button'} className="button-alert" onClick={() => props.onHideModal(false)}>
            Non
          </button>
        </div>
      </div>
    </Modal>
  ) : null;
};
