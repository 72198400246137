import React from 'react';
import { R1_STATUS } from '../../../../_shared/config/constants';

interface ISetToR1ButtonProps {
  onUpdateToR1: any;
  minified?: boolean;
  disabled?: boolean;
}

export const SetToR1Button: React.FC<ISetToR1ButtonProps> = (props: ISetToR1ButtonProps) => {
  return (
    <div className="row gapped">
      <button
        disabled={props.disabled}
        className={props.minified ? 'button-onWait minified' : 'button-onWait'}
        onClick={() => props.onUpdateToR1(R1_STATUS)}
      >
        <i className="material-icons">error_outline</i>
        {props.minified ? '' : 'Passer en relance 1'}
      </button>
      <div>{props.minified ? 'R1' : ''}</div>
    </div>
  );
};
