import { from, Observable, of, throwError } from 'rxjs';
import { catchError, first, flatMap } from 'rxjs/operators';
import { BASE_URL } from '../../_shared/config/constants';

class AuthService {
  login(login: string, password: string): Observable<Response> | Observable<any> {
    const body = {
      login: login,
      password: password,
    };

    const options: any = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(body),
    };

    return from(fetch(`${BASE_URL}/login`, options)).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return of(response);
        } else {
          return throwError({
            service: 'LOGIN',
            status: 401,
            message: response.body,
          });
        }
      }),
      catchError((err) => {
        return throwError({
          service: 'LOGIN',
          status: err.status || 500,
          message: err.message,
        });
      }),
      first(),
    );
  }

  logout(): Observable<Response> | Observable<any> {
    const options: any = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };

    return from(fetch(`${BASE_URL}/logout`, options)).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return of(response);
        } else {
          return throwError({
            service: 'LOGIN',
            status: response.status || 401,
            message: response.body,
          });
        }
      }),
      catchError((err) => {
        return throwError({
          service: 'LOGIN',
          status: err.status || 500,
          message: err.message,
        });
      }),
      first(),
    );
  }
}

export const authService = new AuthService();
