import React, { useContext } from 'react';
import { Composition } from '../../models/composition';
import { DraftEditorContext } from './draftEditor.provider';
import { updateCompositionSelected } from './draftEditor.actions';

interface ICompositionEditorState {
  composition: Composition | null;
  updateComposition: (updates: Partial<Composition>) => void;
}

const initialState: ICompositionEditorState = {
  composition: null,
  updateComposition: () => null,
};

export const CompositionEditorContext = React.createContext<ICompositionEditorState>(initialState);

export const CompositionEditorProvider: React.FC = (props: any) => {
  const { compositions, compositionSelected, dispatch } = useContext(DraftEditorContext);

  const composition = compositions[compositionSelected];

  const updateComposition = (value: Partial<Composition>) => {
    dispatch(updateCompositionSelected(value));
  };

  return (
    <CompositionEditorContext.Provider value={{ composition, updateComposition }}>
      {props.children}
    </CompositionEditorContext.Provider>
  );
};
