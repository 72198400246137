import React, { useState } from 'react';
import NumberInput from '../../../../../_shared/component/numberInput';
import { useUpdateIngredient } from '../../../../../_shared/providers/draft/useUpdateIngredient';
import { useAddIngredient } from '../../../../../_shared/providers/draft/useAddIngredient';
import { useRemoveIngredient } from '../../../../../_shared/providers/draft/useRemoveIngredient';
import TextInput from '../../../../../_shared/component/textInput/textInput.component';
import { useEuroFormatter } from '../../../../../_shared/services/formatter.service';
import { useFocus } from '../../../../../_shared/component/useFocus';

interface ICompositionProductListProps {
  forme: any;
  products: any[];
}

export const CompositionProductList: React.FC<ICompositionProductListProps> = (props: ICompositionProductListProps) => {
  const [lineSelected, setLineSelected] = useState(null as number | null);
  const [newProduct, setNewProduct] = useState({} as any);

  const updateIngredient = useUpdateIngredient(props.products);
  const addIngredient = useAddIngredient(props.products);
  const removeIngredient = useRemoveIngredient(props.products);

  const products = props.products;
  const formatToEuro = useEuroFormatter();

  const updateNewProduct = (update: any) => {
    setNewProduct({ ...newProduct, ...update });
  };

  const { htmlElRef, setFocus } = useFocus();

  const addNewProduct = () => {
    if (newProduct.designation && newProduct.proportion && newProduct.prix) {
      addIngredient({ ...newProduct, contenant: 1 });
      setNewProduct({});
      setFocus();
    }
  };

  return (
    <div className="composition-ingredient-list p-medium">
      <table className="t-medium b-white">
        <thead>
          <tr>
            <th className="fit-content" />
            <th>Designation</th>
            <th className="fit-content">Qté</th>
            <th className="fit-content">Prix/unitaire</th>
            <th>Prix total</th>
            <th>
              <i className="material-icons">delete_forever</i>
            </th>
          </tr>
        </thead>
        <tbody>
          {products.map((product: any, index: number) => {
            const selected = index === lineSelected;
            return (
              <tr key={index} className={selected ? 'selected' : 'selectable'}>
                <td className="center-align">{index + 1}</td>
                <td>
                  <TextInput
                    onChange={(e: any) => updateIngredient(e, index)}
                    name={'designation'}
                    labelHide={true}
                    className={'full-width'}
                    isEditable={true}
                    value={product.designation || ''}
                  />
                </td>
                <td className="fit-content">
                  <NumberInput
                    name={'proportion'}
                    value={product.proportion || 0}
                    className="r-align"
                    size={6}
                    onChange={(e: any) => updateIngredient(e, index)}
                  />
                </td>
                <td className="fit-content">
                  <NumberInput
                    name={'prix'}
                    value={product.prix || 0}
                    className="r-align"
                    size={6}
                    format={() => formatToEuro(product.prix.toString())}
                    onChange={(e: any) => updateIngredient(e, index)}
                  />
                </td>
                <td className="locked">{formatToEuro(product.prix_reel)}</td>
                <td className="locked">
                  <button className="deleteButton" tabIndex={-1} type="button" onClick={() => removeIngredient(index)}>
                    <i className="material-icons">close</i>
                  </button>
                </td>
              </tr>
            );
          })}
          <tr className="bordered b-white">
            <td>
              <span className="material-icons t-large p-small t-green">edit</span>
            </td>
            <td>
              <TextInput
                label={'Ajouter un produit'}
                name={'designation'}
                labelHide={true}
                className={'full-width'}
                isEditable={true}
                isAutofocus={true}
                ref={htmlElRef}
                value={newProduct.designation || ''}
                onChange={updateNewProduct}
                onPressEnter={addNewProduct}
              />
            </td>
            <td>
              <NumberInput
                name={'proportion'}
                value={newProduct.proportion}
                className="r-align"
                size={6}
                onChange={updateNewProduct}
                onPressEnter={addNewProduct}
              />
            </td>
            <td>
              <NumberInput
                name={'prix'}
                value={newProduct.prix}
                className="full-width r-align"
                onChange={updateNewProduct}
                onPressEnter={addNewProduct}
                format={() => formatToEuro(newProduct.prix ? newProduct.prix.toString() : 0)}
              />
            </td>
            <td className="locked">{formatToEuro(newProduct.proportion * newProduct.prix || 0)}</td>
            <td className="t-green">
              <button
                disabled={!newProduct.proportion || !newProduct.prix || !newProduct.designation}
                onClick={addNewProduct}
              >
                <span className="material-icons">done</span>
              </button>
            </td>
          </tr>
          {props.products.length < 35
            ? Array.from(Array(35 - props.products.length).keys()).map((index) => (
                <tr key={index}>
                  <td className="locked t-grey center-align">{props.products.length + index + 1}</td>
                  <td className="locked" />
                  <td className="locked" />
                  <td className="locked" />
                  <td className="locked" />
                  <td className="locked" />
                </tr>
              ))
            : ''}
        </tbody>
      </table>
    </div>
  );
};
