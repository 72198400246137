export const orderSelectionReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'add_order':
      return {
        ordersSelected: [...state.ordersSelected, action.payload],
      };
    case 'add_orders':
      return { ordersSelected: [...action.payload] };
    case 'remove_order':
      return {
        ordersSelected: [...state.ordersSelected.filter((order: string) => order !== action.payload)],
      };
    case 'remove_allOrders':
      return {
        ordersSelected: [],
      };
    default:
      throw new Error();
  }
};
