import { useEffect, useState } from 'react';

export const usePoidsTotal = (compositions: any[]) => {
  const [poids, setPoids] = useState(0);
  useEffect(() => {
    const poidsTotal = compositions.reduce((c, r) => c + Number(r.poids), 0);
    setPoids(poidsTotal);
  }, [compositions]);
  return poids;
};
