import React from 'react';
import { FORME_COLORS } from '../../../../../_shared/config/constants';
import { useEuroFormatter, usePoidsFormatter } from '../../../../../_shared/services/formatter.service';

const CompositionRow: React.FC<any> = ({ index, composition, selected, onCompositionSelected }: any) => {
  const formatToEuro = useEuroFormatter();
  const formatToPoids = usePoidsFormatter();
  return (
    <tr
      className={`composition-card ${selected ? 'selected' : 'selectable'}`}
      key={index}
      tabIndex={0}
      onKeyDown={(e: any) => {
        if (e.key === 'Enter') {
          onCompositionSelected(index);
        }
      }}
      onClick={() => onCompositionSelected(index)}
    >
      <td className="row">
        <div className="t-grey">{index + 1}</div>
        {composition.valid ? (
          <div
            className="circle t-large"
            style={{
              backgroundColor: composition.forme ? FORME_COLORS[composition.forme.code] : 'white',
            }}
          />
        ) : (
          <div className="circle t-large">
            <span className="material-icons t-large t-red">error_outline</span>
          </div>
        )}
        <div>
          <div className="light">{composition.forme.label.toUpperCase()}</div>
          <div className="t-small light">{composition.libelle}</div>
          <div className="t-small light">{composition.destinataire}</div>
        </div>
      </td>
      <td className="no-wrap">{formatToEuro(composition.prix)}</td>
      <td className="no-wrap">{formatToPoids(composition.poids)}</td>
    </tr>
  );
};

export default CompositionRow;
