import React, { useContext, useMemo, useReducer } from 'react';
import { Composition } from '../../models/composition';
import { Practitioner } from '../../models/practitioner';
import { Client } from '../../models/client';
import { BROUILLON_STATUS, DEVIS_STATUS, VALIDEE_STATUS } from '../../config/constants';

import draftEditorReducer from './draftEditor.reducer';
import { orderService } from '../../services/order.service';
import { ServiceContext } from '../../../_core/services/service.context';
import { useHistory } from 'react-router-dom';
import Compose from '../../objects/Compose/Compose';
import { IngredientProvider } from '../ingredient/ingredient.provider';
import { FormeProvider } from '../forme/forme.provider';

export interface IDraftEditorContextState {
  id: string | null;
  code: string;
  dates: any;
  editStatus: string;
  commentaire: string;
  compositions: Composition[];
  compositionSelected: number;
  compositionOnEdition: Composition | null;
  practitioner: Practitioner | null;
  client: Client | undefined;
  clientWarning: boolean;
  adresseSelected: number;
  fraisPort: number;
  shipment: any;
  paiement?: Paiement;
  commentairePaiement?: string | null;
  selected: string | null;
  lastSave: Date;
}

export const DraftEditorInitialState: IDraftEditorContextState = {
  id: null,
  code: '',
  dates: {},
  editStatus: BROUILLON_STATUS,
  commentaire: '',
  practitioner: null,
  client: undefined,
  clientWarning: false,
  adresseSelected: 0,
  fraisPort: 0,
  shipment: { type: 'colissimo' },
  compositions: [],
  compositionSelected: 0,
  compositionOnEdition: null,
  paiement: { type: 'standard', amount: 0 },
  commentairePaiement: '',
  selected: null,
  lastSave: new Date(),
};

export interface Paiement {
  type: 'partiel' | 'praticien' | 'prepaye' | 'standard';
  amount?: number;
}

interface IDispatch {
  dispatch: any;
  setDraftAs: any;
}

type DraftEditorType = IDraftEditorContextState & IDispatch;

export const DraftEditorContext = React.createContext<DraftEditorType>({
  ...DraftEditorInitialState,
  dispatch: () => null,
  setDraftAs: () => null,
});

export const DraftEditorProvider: React.FC = (props: any) => {
  const { query } = useContext(ServiceContext);
  const history = useHistory();
  const [state, dispatch] = useReducer(draftEditorReducer, {
    ...DraftEditorInitialState,
  });

  const setDraftAs = (draftStatus: string) => {
    if (draftStatus !== VALIDEE_STATUS && draftStatus !== DEVIS_STATUS) return;
    if (state.id) {
      const id = state.id || '';
      query(orderService.updateDraftStatus(id, draftStatus)).subscribe(
        () => {
          history.replace(`/enCours/${id}`);
          //window.location.reload(false);
        },
        (err: any) => {
          console.log(err);
        },
      );
    }
  };

  const contextValue = useMemo(() => {
    return { ...(state as IDraftEditorContextState), dispatch, setDraftAs };
  }, [state, dispatch]);

  return (
    <DraftEditorContext.Provider value={contextValue}>
      <Compose components={[IngredientProvider, FormeProvider]}>{props.children}</Compose>
    </DraftEditorContext.Provider>
  );
};
