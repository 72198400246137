import moment from 'moment';
import React, { useContext } from 'react';
import { ServiceContext } from '../../../../_core/services/service.context';
import { EXPORT_LIMIT } from '../../../../_shared/config/constants';
import { formatToDateFilter } from '../../../../_shared/services/formatter.service';
import { orderService } from '../../../../_shared/services/order.service';
import { OrderFilters } from '../../../../_shared/types/OrderFilter';

interface IExportOrdersButtonProps {
  filters: OrderFilters;
  icon: string;
  title: string;
}

const ExportOrdersButton: React.FC<IExportOrdersButtonProps> = (props: IExportOrdersButtonProps) => {
  const { query } = useContext(ServiceContext);
  const onExport = () => {
    query(
      orderService.exportOrders({
        ...props.filters,
      }),
    ).subscribe((err: any) => console.log(err));
  };

  return (
    <button className="button-grey delete-filters" type={'button'} onClick={() => onExport()} title={props.title}>
      <span className="material-icons large">{props.icon}</span>
    </button>
  );
};

const lastMonthFilter = (): OrderFilters => {
  const lastDayOfPreviousMonth = moment().clone().startOf('month').subtract(1, 'days');

  const firstDayOfPreviousMonth = moment().clone().startOf('month').subtract(1, 'days').startOf('month');

  const dateStart = formatToDateFilter(firstDayOfPreviousMonth);
  const dateEnd = formatToDateFilter(lastDayOfPreviousMonth);

  return {
    dateStart,
    dateEnd,
  };
};

export const ExportOrdersOneMonthButton: React.FC = () => {
  return (
    <ExportOrdersButton
      filters={lastMonthFilter()}
      title={'Exporter toutes les commandes du mois précédent'}
      icon={'exposure_neg_1'}
    />
  );
};

export const ExportOrdersFilteredButton: React.FC<{
  filters: OrderFilters;
}> = ({ filters }: { filters: OrderFilters }) => {
  return (
    <ExportOrdersButton
      filters={filters}
      icon={'file_download'}
      title={`Exporter les commandes filtrées (max ${EXPORT_LIMIT})`}
    />
  );
};
