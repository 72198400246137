export const BASE_URL = (window as any)._env_
  ? (window as any)._env_.REACT_APP_BASE_URL
  : process.env.REACT_APP_BASE_URL;

export const BROUILLON_STATUS = 'brouillon';
export const ARCHIVE_STATUS = 'archive';
export const VALIDEE_STATUS = 'validee';
export const DEVIS_STATUS = 'devis';
export const ENVOIE_STATUS = 'envoie';
export const DEPOT_STATUS = 'depot';
export const EXPEDIEE_STATUS = 'expediee';
export const NON_PAYEE_STATUS = 'non_payee';
export const TO_PRATICIEN = 'to_praticien';

export const R1_STATUS = 'r1';
export const R2_STATUS = 'r2';
export const PAYEE_STATUS = 'payee';

export const COLISSIMO = 'colissimo';
export const SHIPMENT_TYPES = [COLISSIMO, 'boutique', 'chronopost', 'coursier'];

export const ADDRESS_MAX_LENGTH = 35;

export const ORDERS_LIMIT = 100;

export const EXPORT_LIMIT = 50000;

export const WEIGHT_DECIMALS = 1;

export interface Enumerable<T> {
  [key: string]: T;
}

export const FORME_COLORS: Enumerable<string> = {
  PC: '#408f80',
  MO: '#6BA2DB',
  GE: '#BDA558',
  IF: 'rgb(131,157,188)',
  CC: '#bd7e9d',
  VR: 'rgba(238,133,76,0.73)',
  VR8: 'rgba(50,151,106,0.73)',
  LB: '#e5e5e5',
  DV: '#61649e',
};

export const PC_FLACON_MAX = 710;
export const MO_FLACON_MAX = 540;
export const GE_FLACON_MAX = 400;

export const FORME_LABELS: Enumerable<string> = {
  CC: '粗打粉',
  VR8: '草分开',
};

export const STATUS_PROPS: Enumerable<{
  color: string;
  icon: string;
  name: string;
}> = {
  [BROUILLON_STATUS]: {
    color: '#568b6b',
    icon: 'edit',
    name: 'Brouillon',
  },
  [DEVIS_STATUS]: {
    color: '#696969',
    icon: 'list',
    name: 'Devis',
  },
  [VALIDEE_STATUS]: {
    color: '#568b6b',
    icon: 'flip_camera_android',
    name: 'Validée',
  },
  [ENVOIE_STATUS]: {
    color: '#c5ae36',
    icon: 'mail_outline',
    name: 'Envoyée',
  },
  [DEPOT_STATUS]: {
    color: '#c5ae36',
    icon: 'content_paste_go',
    name: 'À déposer',
  },
  [EXPEDIEE_STATUS]: {
    color: '#4985c5',
    icon: 'send',
    name: 'Expédiée',
  },
  [NON_PAYEE_STATUS]: {
    color: '#9bb44a',
    icon: 'query_builder',
    name: 'Non payée',
  },
  [R1_STATUS]: {
    color: '#feb96f',
    icon: 'error_outline',
    name: 'R1',
  },
  [R2_STATUS]: {
    color: '#fe5f54',
    icon: 'warning',
    name: 'R2',
  },
  [PAYEE_STATUS]: {
    color: 'green',
    icon: 'done_outline',
    name: 'Payée',
  },
};

export const DRAFT_STATUS: Enumerable<string> = {
  [BROUILLON_STATUS]: BROUILLON_STATUS,
  [DEVIS_STATUS]: DEVIS_STATUS,
  [VALIDEE_STATUS]: VALIDEE_STATUS,
  [ENVOIE_STATUS]: ENVOIE_STATUS,
  [EXPEDIEE_STATUS]: EXPEDIEE_STATUS,
  [DEPOT_STATUS]: DEPOT_STATUS,
};

export const ORDER_STATUS: Enumerable<string> = {
  [NON_PAYEE_STATUS]: NON_PAYEE_STATUS,
  [R1_STATUS]: R1_STATUS,
  [R2_STATUS]: R2_STATUS,
  [PAYEE_STATUS]: PAYEE_STATUS,
};
