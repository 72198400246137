import { useContext, useEffect, useState } from 'react';
import { DraftEditorContext } from './draftEditor.provider';

export const useRemise = () => {
  const { client, practitioner } = useContext(DraftEditorContext);
  const [remise, setRemise] = useState(0);

  useEffect(() => {
    if (client && practitioner && client.remise && practitioner.remise) {
      setRemise(client.remise > practitioner.remise ? client.remise : practitioner.remise);
    } else if (client && client.remise) {
      setRemise(client.remise);
    } else if (practitioner && practitioner.remise) {
      setRemise(practitioner.remise);
    } else {
      setRemise(0);
    }
  }, [client, practitioner]);

  return remise;
};
