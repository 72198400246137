import * as React from 'react';

import { Address, Client } from '../../../_shared/models/client';
import { TUextInput } from '../../../_shared/component/textInput/textInput.component';
import NumberInput from '../../../_shared/component/numberInput';
import { TextArea } from '../../../_shared/component/textArea/textArea.component';
import AddressTabs from './components/addressTab.component';

import './client.form.scss';

interface IClientFormeProps {
  client: Client;
  adresseSelected: number | undefined;
  isOnEdition: boolean;
  onChange: any;
  onNewAddress: any;
  onAddressSelect: any;
}

const ClientForm: React.FC<IClientFormeProps> = ({
  client,
  adresseSelected,
  isOnEdition,
  onChange,
  onNewAddress,
  onAddressSelect,
}: IClientFormeProps) => {
  return (
    <div className="client-form">
      <form autoComplete="off" className="p-medium" style={{ backgroundColor: isOnEdition ? 'white' : 'transparent' }}>
        <div className="form-section">
          {isOnEdition ? (
            <>
              <div className="form-section_label row t-grey light">
                <span className="material-icons">person</span>
                <span>INFORMATION PERSONNELLE</span>
              </div>
              <div className="form-section_content p-medium">
                {client.num ? <div className="row">N°{client.num.toString()}</div> : null}
                <TUextInput
                  value={client.nom}
                  name={'nom'}
                  autofocus={true}
                  className="upperCase bold"
                  onChange={onChange}
                />
                <TUextInput value={client.prenom} name={'prenom'} className="capitalize" onChange={onChange} />
              </div>
            </>
          ) : (
            <div className="form-section_content">
              {client.num ? <div className="row">N°{client.num.toString()}</div> : null}
              <div className="t-xlarge">
                <b className="upperCase">{client.nom}</b> {client.prenom}
              </div>
            </div>
          )}
        </div>
        <div className="form-section ">
          <div className="form-section_label row t-grey light">
            <span className="material-icons">home</span>
            <span>ADRESSES</span>
          </div>
          <div className="form-section_content p-medium">
            <AddressTabs
              addressSelected={adresseSelected}
              addresses={client.adresses}
              isEditing={isOnEdition}
              onAdresseSelect={onAddressSelect}
              onNewAddress={onNewAddress}
              onChange={(a: Address[]) => {
                onChange({ adresses: a });
              }}
            />
          </div>
        </div>
        <div className="form-section">
          <div className="form-section_label row t-grey light">
            <span className="material-icons">add_ic_call</span>
            <span>CONTACT</span>
          </div>
          {isOnEdition ? (
            <div className="form-section_content">
              <div className="row gapped">
                <TUextInput
                  value={client.mobile}
                  name={'mobile'}
                  suffix={<span className="material-icons">stay_current_portrait</span>}
                  size={15}
                  resize={true}
                  disabled={!isOnEdition}
                  isHidden={!client.mobile && !isOnEdition}
                  onChange={onChange}
                />
              </div>
              <div className="row wrap gapped">
                <TUextInput
                  value={client.fixe}
                  name={'fixe'}
                  suffix={<span className="material-icons">call</span>}
                  size={15}
                  resize={true}
                  disabled={!isOnEdition}
                  isHidden={!client.fixe && !isOnEdition}
                  onChange={onChange}
                />
                <TUextInput
                  value={client.tel3}
                  name={'tel3'}
                  suffix={<span className="material-icons">call</span>}
                  size={15}
                  resize={true}
                  disabled={!isOnEdition}
                  isHidden={!client.tel3 && !isOnEdition}
                  onChange={onChange}
                />
                <TUextInput
                  value={client.tel4}
                  name={'tel4'}
                  suffix={<span className="material-icons">call</span>}
                  size={15}
                  resize={true}
                  disabled={!isOnEdition}
                  isHidden={!client.tel4 && !isOnEdition}
                  onChange={onChange}
                />
              </div>
              <TUextInput
                value={client.mail}
                name={'mail'}
                suffix={<span className="material-icons">alternate_email</span>}
                isHidden={!client.mail && !isOnEdition}
                disabled={!isOnEdition}
                onChange={onChange}
              />
            </div>
          ) : (
            <div className="form-section_content">
              {client.mobile ? (
                <div className="row gapped light t-grey">
                  <span className="material-icons t-large">stay_current_portrait</span>
                  <span>{client.mobile}</span>
                </div>
              ) : null}
              {client.fixe ? (
                <div className="row gapped light t-grey">
                  <span className="material-icons t-large">call</span>
                  <span>{client.fixe}</span>
                </div>
              ) : null}
              {client.tel3 ? (
                <div className="row gapped light t-grey">
                  <span className="material-icons t-large">call</span>
                  <span>{client.tel3}</span>
                </div>
              ) : null}
              {client.tel4 ? (
                <div className="row gapped light t-grey">
                  <span className="material-icons t-large">call</span>
                  <span>{client.tel4}</span>
                </div>
              ) : null}
              {client.mail ? (
                <div className="row gapped light t-grey">
                  <span className="material-icons t-large">alternate_email</span>
                  <span>{client.mail}</span>
                </div>
              ) : null}
            </div>
          )}
        </div>
        <div className="form-section">
          <div className="form-section_label row p-medium light">
            <span className="material-icons">work_outline</span>
            <span>INFOS</span>
          </div>
          <div className="form-section_content">
            <div className="row gapped">
              <NumberInput
                value={client.remise || 0}
                name={'remise'}
                label={'remise'}
                locked={!isOnEdition}
                size={5}
                resize={true}
                className="t-large light"
                prefix={'%'}
                onChange={onChange}
              />
            </div>
            {isOnEdition || client.note ? (
              <div>
                <label>NOTES</label>
                <TextArea
                  name={'note'}
                  value={client.note || ''}
                  className="full-width"
                  locked={!isOnEdition}
                  onChange={onChange}
                />
              </div>
            ) : null}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ClientForm;
