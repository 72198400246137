import React from 'react';
import { Ingredient } from '../../../_shared/models/ingredient';
import PinyinInput from './components/pinyin.input';
import CodeInput from './components/code.input';
import PriceSelector from './components/price.selector';

import './ingredientForm.css';
import ChineseInput from './components/chinese.input';
import LatinInput from './components/latin.input';
import { getAllCodePrix } from '../../../_shared/services/codeprix.service';
import { ingredientService } from '../../../_shared/services/ingredient.service';
import { DynamicTextGroup } from '../../../_shared/component/dynamicTextGroup/dynamicTextGroup';

export interface IFormFieldProps<T> {
  isExist?: boolean;
  isEditable?: boolean;
  onChange: any;
}

interface IngredientFormProps {
  ingredientSelected: Ingredient | null;
  isEditing: boolean;
  onChange: (value: Ingredient | null) => void;
  handleDelete: () => void;
}

interface IIngredientFormState {
  exists: any[];
  codesprix: any[];
}

export default class IngredientForm extends React.Component<IngredientFormProps, IIngredientFormState> {
  state: IIngredientFormState = {
    exists: [],
    codesprix: [],
  };

  checkableField = ['pinyin', 'code', 'chinois', 'synonymes'];

  componentDidMount(): void {
    getAllCodePrix().subscribe(
      (codesprix) => {
        this.setState({ codesprix: codesprix });
        if (
          this.props.isEditing &&
          this.props.ingredientSelected !== null &&
          (!this.props.ingredientSelected.codeprix || !this.props.ingredientSelected.codeprix.id)
        ) {
          this.props.onChange({
            ...this.props.ingredientSelected,
            ...{ codeprix: codesprix[0] },
          });
        }
      },
      (err) => console.log(err),
    );
  }

  componentDidUpdate() {
    if (
      this.props.isEditing &&
      this.props.ingredientSelected !== null &&
      (!this.props.ingredientSelected.codeprix || !this.props.ingredientSelected.codeprix.id)
    ) {
      this.props.onChange({
        ...this.props.ingredientSelected,
        ...{ codeprix: this.state.codesprix[0] },
      });
    }
  }

  private handleIngredientChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    // State update
    if (this.checkableField.findIndex((i) => i === name) > -1) {
      this.checkIfExist(name, value, () =>
        this.props.onChange({
          ...this.props.ingredientSelected,
          [name]: value,
        } as Ingredient),
      );
    } else {
      this.props.onChange({
        ...this.props.ingredientSelected,
        [name]: value,
      } as Ingredient);
    }
  };

  private checkIfExist(name: string, value: any, callback: Function): void {
    if (value === '' || value === null) {
      this.handleExists(name, false, null, null);
      this.handleExists(name, false, null, 'pinyin');
      this.handleExists(name, false, null, 'synonymes');
      callback();
    } else if (this.checkableField.findIndex((n) => n === name) >= 0 && this.props.ingredientSelected) {
      if (Array.isArray(value)) {
        this.props.ingredientSelected.synonymes.forEach((v, index) => {
          this.handleExists(name, false, index, null);
          this.handleExists(name, false, index, 'pinyin');
          this.handleExists(name, false, index, 'synonymes');
        });
        if (value.length <= 0) {
          callback();
        }
        value.map((v, index) => {
          if (this.props.ingredientSelected && name === 'synonymes') {
            if (v.toLowerCase() === this.props.ingredientSelected.pinyin.toLowerCase()) {
              this.handleExists('synonymes', true, index, 'pinyin');
            } else {
              this.handleExists('synonymes', false, index, 'pinyin');
            }
          }
          if (this.props.ingredientSelected && name === 'synonymes') {
            if (value.filter((val, ind) => index !== ind && val === v).length > 0) {
              this.handleExists('synonymes', true, index, 'synonymes');
            } else {
              this.handleExists('synonymes', false, index, 'synonymes');
            }
          }
          if (this.props.ingredientSelected && v !== this.props.ingredientSelected.pinyin) {
            this.handleExists('pinyin', false, null, 'synonymes');
          }

          ingredientService.getIsPropsExist(name, v).subscribe(
            (res) => {
              if (this.props.ingredientSelected && (!res.id || res.id !== this.props.ingredientSelected.id)) {
                this.handleExists(name, res.exists, index, null);
              }
            },
            (err) => console.log(err),
          );

          if (value.length - 1 === index) {
            callback();
          }
        });
      } else {
        this.handleExists(name, false, null, null);
        this.handleExists(name, false, null, 'pinyin');
        this.handleExists(name, false, null, 'synonymes');

        if (name === 'pinyin' && this.props.ingredientSelected) {
          this.state.exists
            .filter((exist) => exist.name === 'synonymes' && exist.local === 'pinyin')
            .forEach((synonyme, index) => {
              this.handleExists('synonymes', false, index, 'pinyin');
            });

          if (this.props.ingredientSelected.synonymes.filter((synonyme) => synonyme === value).length > 0) {
            this.handleExists(name, true, null, 'synonymes');
            callback();
          } else {
            this.handleExists(name, false, null, 'synonymes');
          }
        }

        ingredientService.getIsPropsExist(name, value).subscribe(
          (res) => {
            if (this.props.ingredientSelected && (!res.id || res.id !== this.props.ingredientSelected.id)) {
              this.handleExists(name, res.exists, null, null);
            }
          },
          (err) => console.log(err),
        );
        callback();
      }
    }
  }

  private handleExists(name: string, isExist: boolean, place: number | null, local: string | null) {
    if (isExist && this.state.exists.findIndex((e) => e.name === name && e.place === place && e.local === local) < 0) {
      this.setState({
        exists: [{ name: name, place: place, local: local }, ...this.state.exists],
      });
    }

    if (
      !isExist &&
      this.state.exists.findIndex((e) => e.name === name && e.place === place && e.local === local) >= 0
    ) {
      const exists: {
        name: string;
        place: number | null;
        local: string | null;
      }[] = this.state.exists;
      if (exists) {
        const index = exists.findIndex((e) => e.name === name && e.place === place && e.local === local);
        if (index >= 0) {
          exists.splice(index, 1);
          this.setState({ exists: exists });
        }
      }
    }
  }

  private isExist(name: string) {
    return this.state.exists.filter((e) => e.name === name);
  }

  render() {
    if (this.props.ingredientSelected) {
      return (
        <div className="form-content">
          <form
            className={'row gapped'}
            style={{
              backgroundColor: this.props.isEditing ? 'white' : 'transparent',
            }}
          >
            <CodeInput
              isExist={this.isExist('code').length > 0}
              isEditable={this.props.isEditing}
              onChange={this.handleIngredientChange}
              code={this.props.ingredientSelected.code || ''}
            />
            <PinyinInput
              isExist={this.isExist('pinyin').length > 0}
              isEditable={this.props.isEditing}
              onChange={this.handleIngredientChange}
              pinyin={this.props.ingredientSelected.pinyin || ''}
            />

            <DynamicTextGroup
              value={this.props.ingredientSelected.synonymes || []}
              name={'synonymes'}
              exists={this.isExist('synonymes')}
              isEditable={this.props.isEditing}
              onChange={(e: string[]) => this.handleIngredientChange(e)}
              className={'dynamicGroup'}
            />
            <LatinInput
              isEditable={this.props.isEditing}
              onChange={this.handleIngredientChange}
              latin={this.props.ingredientSelected.latin || ''}
            />
            <ChineseInput
              isExist={this.isExist('chinois').length > 0}
              isEditable={this.props.isEditing}
              onChange={this.handleIngredientChange}
              chinese={this.props.ingredientSelected.chinois || ''}
            />
            {this.state.codesprix.length > 0 ? (
              <PriceSelector
                codeprix={this.props.ingredientSelected.codeprix}
                codesprixList={this.state.codesprix}
                isEditable={this.props.isEditing}
                onChange={this.handleIngredientChange}
              />
            ) : (
              'Chargement'
            )}
          </form>
        </div>
      );
    } else {
      return null;
    }
  }
}
