import React from 'react';

interface IParentComponentProps {
  onBlur?: any;
  className?: any;
  children: any;
  reference?: any;
  style?: any;
}

export const ParentComponent: React.FC<IParentComponentProps> = ({
  onBlur,
  className,
  style,
  reference,
  children,
}: IParentComponentProps) => {
  const handleBlur = (e: any) => {
    const currentTarget = e.currentTarget;

    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        onBlur();
      }
    }, 0);
  };

  return (
    <div className={className || ''} ref={reference} style={style} onBlur={(e: any) => (onBlur ? handleBlur(e) : null)}>
      {children}
    </div>
  );
};

export default ParentComponent;
