import { useContext } from 'react';
import { Composition } from '../../models/composition';
import { CompositionEditorContext } from './compositionEditor.provider';

const useCompositionOnEdition = () => {
  const { composition, updateComposition } = useContext(CompositionEditorContext);

  return { composition: composition as Composition, updateComposition };
};

export default useCompositionOnEdition;
