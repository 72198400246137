import { NavLink } from 'react-router-dom';
import React from 'react';
import { NON_PAYEE_STATUS, R1_STATUS } from '../../../../_shared/config/constants';
import { SCAN_PATH } from '../../../../_shared/config/paths';

interface IOrdersTypeFilterPanel {
  disabled: boolean;
  status: string;
  onHandleStatus: (status: string[]) => void;
}

export const OrdersTypeFilterPanel: React.FC<IOrdersTypeFilterPanel> = ({
  disabled,
  status,
  onHandleStatus,
}: IOrdersTypeFilterPanel) => {
  return (
    <div className="crud-header row gapped p-medium">
      <div className="title">
        <span className="material-icons">format_list_bulleted</span>
        <span>COMMANDES</span>
      </div>
      <nav className="row gapped scrollable-x">
        <button
          disabled={disabled}
          className={`button-validate transparent rounded ${status === NON_PAYEE_STATUS ? 'active' : ''}`}
          onClick={() => onHandleStatus([NON_PAYEE_STATUS])}
        >
          <i className="material-icons">alarm</i> &nbsp; Non payées
        </button>
        <button
          disabled={disabled}
          className={`button-validate transparent  rounded ${status === R1_STATUS ? 'active' : ''}`}
          onClick={() => onHandleStatus([R1_STATUS])}
        >
          <i className="material-icons">error_outline</i> &nbsp; Relance 1
        </button>
        <button
          disabled={disabled}
          className={`button-grey transparent rounded ${!status ? 'active' : ''}`}
          onClick={() => onHandleStatus([])}
        >
          <i className="material-icons">history</i> &nbsp; Historique
        </button>
        <NavLink className="button-validate row" to={SCAN_PATH}>
          <i className="material-icons t-large">rss_feed</i>
          <div>Scanner des paiements</div>
        </NavLink>
      </nav>
    </div>
  );
};
