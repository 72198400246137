import React from 'react';
import { ENVOIE_STATUS } from '../../../../_shared/config/constants';

interface ISetToEnvoieButtonProps {
  onSetToEnvoie: any;
  minified?: boolean;
  disabled?: boolean;
}

export const SetToEnvoieButton: React.FC<ISetToEnvoieButtonProps> = (props: ISetToEnvoieButtonProps) => {
  return (
    <div>
      <button
        className={props.minified ? 'button-validate minified' : 'button-validate bordered'}
        disabled={props.disabled}
        onClick={() => props.onSetToEnvoie(ENVOIE_STATUS)}
        data-cy="SetToEnvoieButton"
      >
        <i className="material-icons">mail_outline</i>
        {props.minified ? null : <span>Valider pour expédition</span>}
      </button>
    </div>
  );
};
