import React, { useState } from 'react';
import { ORDERS_LIMIT } from '../../config/constants';
import NumberInput from '../numberInput';
import './ListPagination.css';

interface IListPaginationProps {
  total: number;
  limit: number;
  offset: number;
  setOffset: (offset: number) => void;
}

export const ListPagination: React.FC<IListPaginationProps> = (props: IListPaginationProps) => {
  return props.total > props.limit ? (
    <div className="br-top row center p-small gapped">
      {Array.from(Array(Math.floor(props.total / props.limit) + 1).keys()).map((n: number) => (
        <button
          key={n}
          className={props.offset === n * props.limit ? 't-green bold' : 't-grey'}
          disabled={n === props.offset}
          onClick={() => props.setOffset(n * props.limit)}
        >
          {n + 1}
        </button>
      ))}
    </div>
  ) : null;
};

const PaginationPanel: React.FC<PaginationPanelProps> = ({
  offset,
  totalItem,
  itemByPage,
  onPageSelect,
}: PaginationPanelProps) => {
  const pageMax = Math.ceil(totalItem / itemByPage);
  const currentPage = Math.ceil(offset / ORDERS_LIMIT) + 1;
  const visiblePage = 5;
  const marginPage = 2;

  const [pageSelected, setPageSelected] = useState(currentPage);
  const getPageElement = (index: number) => (
    <button
      key={index}
      type="button"
      className={index === currentPage ? 'active' : undefined}
      disabled={index === currentPage}
      onClick={() => onPageSelect(index)}
    >
      {index}
    </button>
  );

  const pagination = () => {
    const pages: JSX.Element[] = [];
    if (pageMax === 1) return [];
    if (pageMax <= visiblePage) {
      for (let index = 1; index <= pageMax; index++) {
        pages.push(getPageElement(index));
      }
    } else {
      let leftSide = visiblePage / 2;
      let rightSide = visiblePage - leftSide;

      if (currentPage > pageMax - visiblePage / 2) {
        rightSide = pageMax - currentPage;
        leftSide = visiblePage - rightSide;
      } else if (currentPage < visiblePage / 2) {
        leftSide = currentPage;
        rightSide = visiblePage - leftSide;
      }

      let index;
      let page;
      let breakView;
      const createPageView = (index: number) => getPageElement(index);

      for (index = 1; index <= pageMax; index++) {
        page = index;

        if (page <= marginPage) {
          pages.push(createPageView(index));
          continue;
        }

        if (page > pageMax - marginPage) {
          pages.push(createPageView(index));
          continue;
        }

        if (index >= currentPage - leftSide && index <= currentPage + rightSide) {
          pages.push(createPageView(index));
          continue;
        }

        if (pages[pages.length - 1] !== breakView) {
          breakView = <span key={'break-' + index}> ...</span>;
          pages.push(breakView);
        }
      }
    }

    return pages;
  };

  const onPageSelected = (page: number) => {
    if (page <= pageMax) setPageSelected(page);
  };

  return pageMax > 1 ? (
    <div className="pagination_panel column center">
      <div className="row">
        {currentPage > 1 ? (
          <button onClick={() => onPageSelect(currentPage - 1)}>
            <span className="material-icons">arrow_back</span>
          </button>
        ) : null}
        {pagination()}
        {currentPage < pageMax ? (
          <button onClick={() => onPageSelect(currentPage + 1)}>
            <span className="material-icons">arrow_forward</span>
          </button>
        ) : null}
      </div>

      <div className="row p-small ">
        <span className="t-small t-grey">Aller à la page</span>
        <NumberInput
          name={'page'}
          className="light r-align"
          size={pageMax.toString().length}
          value={pageSelected}
          onChange={(e: any) => onPageSelected(e.page)}
          onPressEnter={() => onPageSelect(pageSelected)}
        />
        <span className="p-small">/{pageMax}</span>
      </div>
    </div>
  ) : null;
};

interface PaginationPanelProps {
  onPageSelect: any;
  offset: number;
  itemByPage: number;
  totalItem: number;
}

export default PaginationPanel;
