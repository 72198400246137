import * as React from 'react';

interface IDynamicTextGroupProps {
  isEditable?: boolean;
  isAutofocus?: boolean;
  isRequired?: boolean;
  value: string[];
  name: string;
  onChange: any;
  className: string;
  exists: { name: string; place: number | null }[];
  labelHide?: boolean;
}

export class DynamicTextGroup extends React.Component<IDynamicTextGroupProps> {
  state = {
    line: [],
    activeLine: -1,
  };

  componentWillMount(): void {
    if (this.props.value.length > 0) {
      this.setState({
        line: this.props.value,
        activeLine: this.props.value.length,
      });
    }
  }

  setActif(index: number) {
    this.setState({ activeLine: index });
  }

  handleChange(e: any) {
    let lines = Object.assign([''], this.props.value);
    if (lines.length > 0 && e.target.value.length > 0 && e.target.value !== '') {
      lines[this.state.activeLine] = e.target.value.toLowerCase();
    } else {
      lines.splice(this.state.activeLine, 1);
    }
    this.props.onChange({
      target: { name: this.props.name, value: [...lines] },
    });
  }

  handleDelete(index: number) {
    let lines = Object.assign([''], this.props.value);
    lines.splice(index, 1);
    this.props.onChange({
      target: { name: this.props.name, value: [...lines] },
    });
  }

  isExist(place: number) {
    return this.props.exists.findIndex((e) => e.place === place) >= 0;
  }

  capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  render() {
    return (
      <div className={this.props.className}>
        {this.props.labelHide === undefined || this.props.labelHide === false ? (
          <label className={this.props.isEditable && this.props.isRequired ? 'required' : ''}>
            {this.props.name.toUpperCase()}
            <span style={{ color: 'red' }}>{this.props.exists.length > 0 ? 'Existe déjà' : ''}</span>
          </label>
        ) : (
          ''
        )}
        <div>
          <div className={'group'}>
            {this.props.value.map((v, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: !this.props.isEditable ? 'transparent' : '',
                }}
              >
                {this.props.isEditable && index === this.state.activeLine ? (
                  <input
                    autoFocus
                    key={index}
                    size={v.length}
                    style={{ color: this.isExist(index) ? 'indianred' : '' }}
                    disabled={!this.props.isEditable && this.state.activeLine !== index}
                    onChange={(e) => this.handleChange(e)}
                    placeholder={`${this.props.name} ${index}`}
                    value={this.props.value[index]}
                  />
                ) : (
                  <div
                    tabIndex={this.props.isEditable ? 0 : -1}
                    key={index}
                    className="dynamicGroup-text"
                    style={{ color: this.isExist(index) ? 'indianred' : '' }}
                    onClick={(e) => this.setActif(index)}
                    onFocus={(e) => this.setActif(index)}
                  >
                    {this.capitalize(v)}
                  </div>
                )}
                {this.props.isEditable ? (
                  <button
                    className="deleteButton"
                    tabIndex={-1}
                    type="button"
                    onClick={(e) => {
                      this.handleDelete(index);
                    }}
                  >
                    <i className="material-icons">clear</i>
                  </button>
                ) : (
                  ''
                )}
              </div>
            ))}
          </div>

          {this.props.isEditable ? (
            <input
              disabled={!this.props.isEditable && this.state.activeLine !== this.props.value.length}
              onClick={(e) => this.setActif(this.props.value.length)}
              onFocus={(e) => this.setActif(this.props.value.length)}
              onChange={(e) => this.handleChange(e)}
              className={'capitalize'}
              style={{ maxWidth: '90px' }}
              placeholder={`${this.props.name} ${this.props.value.length}`}
              value={this.props.value[this.props.value.length] || ''}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}
