import React, { useEffect, useReducer, useState } from 'react';
import 'moment/locale/fr';

import { BROUILLON_STATUS, DEPOT_STATUS, EXPEDIEE_STATUS } from '../../../../_shared/config/constants';

import Checkbox from '../../../../_shared/component/checkBox/checkbox';
import { DraftActionsPanel } from './draftActionsPanel/draftActions.Panel';
import { orderSelectionReducer } from '../../../../_shared/providers/order/orderSelectionReducer';
import { useHistory } from 'react-router-dom';
import { formatToDate, useEuroFormatter } from '../../../../_shared/services/formatter.service';
import { ShippingConfirmationModal } from '../../components/shippingConfirmationModal/shippingConfirmation.modal';
import { HeadersList } from '../../../../_shared/component/tableHeadersList/Headers.list';
import { DRAFTS_PATH, ON_EDITION_PATH } from '../../../../_shared/config/paths';

const orderListHeaders = (orderType: string) => [
  {
    title: 'code',
    name: 'code',
  },
  {
    title: 'client',
    name: 'client',
  },
  {
    title: 'praticien',
    name: 'praticien',
  },
  {
    title: orderType === BROUILLON_STATUS ? 'date de création' : 'date de validation',
    name: orderType === BROUILLON_STATUS ? 'date' : 'validationDate',
  },
  {
    title: 'prix',
    name: 'prixCalcul',
  },
  {
    title: 'commentaire',
    name: 'commentaire',
  },
];

interface IDraftsListProps {
  draftStatus: string | null | undefined;
  drafts: any[];
  sort: any;
  direction: 'asc' | 'desc';
  onPrint: (id: string, status: string) => void;
  onPrintMulti: (ids: string[], status: string) => void;
  onUpdateSort: (sort: string) => void;
  onUpdateDraftsStatus: (ids: string[], status: string) => void;
}

export const DraftsList: React.FC<IDraftsListProps> = (props: IDraftsListProps) => {
  const [showSendPanel, setShowSendPanel] = useState(false);
  const [showMultiSendPanel, setShowMultiSendPanel] = useState(false);
  const history = useHistory();

  const [ordersSelectedState, dispatchOrderSelected] = useReducer(orderSelectionReducer, {
    ordersSelected: [],
  });

  const redirectTo = (orderId: string, editStatus: string) => {
    history.push(`${editStatus === BROUILLON_STATUS ? ON_EDITION_PATH : DRAFTS_PATH}/${orderId}`);
  };

  const formatToEuro = useEuroFormatter();

  const drafts = props.drafts;
  const ordersSelected = ordersSelectedState.ordersSelected;

  useEffect(() => {
    dispatchOrderSelected({ type: 'remove_allOrders' });
  }, [drafts]);

  return (
    <>
      <ShippingConfirmationModal
        showModal={showMultiSendPanel}
        onHideModal={() => setShowMultiSendPanel(false)}
        onValidate={() => props.onUpdateDraftsStatus(ordersSelected, EXPEDIEE_STATUS)}
      />
      {props.draftStatus ? (
        <table className="list">
          <thead>
            <tr>
              <th className="fit-content">
                <div className="row">
                  <Checkbox
                    name={'all'}
                    value={ordersSelected.length > 0}
                    onChange={(e: any) =>
                      ordersSelected.length === drafts.length
                        ? dispatchOrderSelected({
                            type: 'remove_allOrders',
                          })
                        : dispatchOrderSelected({
                            type: 'add_orders',
                            payload: drafts.map((o: any) => o.id),
                          })
                    }
                  />
                  <DraftActionsPanel
                    editStatus={props.draftStatus}
                    updateStatus={(value: string) => props.onUpdateDraftsStatus(ordersSelected, value)}
                    onShipping={() => setShowSendPanel(true)}
                    printOrder={() =>
                      // in case depot status, we want to print bordereau here
                      props.onPrintMulti(ordersSelected, props.draftStatus != DEPOT_STATUS ? 'prepa' : 'bordereau')
                    }
                    disabled={ordersSelected.length <= 0}
                  />
                </div>
              </th>
              <HeadersList
                headersList={orderListHeaders(props.draftStatus)}
                sort={{ sort: props.sort, direction: props.direction }}
                onUpdateSort={props.onUpdateSort}
              />
            </tr>
          </thead>
          <tbody className="table-body">
            {props.drafts.map((order: any, index: number) => (
              <tr key={index}>
                <td className="fit-content">
                  <div className="row">
                    <Checkbox
                      name={'select'}
                      value={ordersSelected.findIndex((o: string) => o === order.id) >= 0}
                      onChange={(value: boolean) => {
                        dispatchOrderSelected({
                          type: value ? 'add_order' : 'remove_order',
                          payload: order.id,
                        });
                      }}
                    />
                    <DraftActionsPanel
                      editStatus={props.draftStatus as string}
                      updateStatus={(orderStatus: string) => props.onUpdateDraftsStatus([order.id], orderStatus)}
                      onShipping={() => setShowSendPanel(true)}
                      printOrder={() => props.onPrint(order.id, 'prepa')}
                      disabled={props.draftStatus == DEPOT_STATUS}
                    />
                    <ShippingConfirmationModal
                      showModal={showSendPanel}
                      onHideModal={() => setShowSendPanel(false)}
                      onValidate={() => props.onUpdateDraftsStatus([order.id], EXPEDIEE_STATUS)}
                    />
                  </div>
                </td>
                <td className="small" onClick={() => redirectTo(order.id, order.status)}>
                  {order.codeFull}
                </td>
                <td className="medium" onClick={() => redirectTo(order.id, order.status)}>
                  {order.client}
                </td>
                <td className="xsmall" onClick={() => redirectTo(order.id, order.status)}>
                  {order.praticienCode || '--'}
                </td>
                <td className="small no-wrap" onClick={() => redirectTo(order.id, order.status)}>
                  {formatToDate(props.draftStatus === BROUILLON_STATUS ? order.date : order.validationDate)}
                </td>
                <td className="xsmall r-align" onClick={() => redirectTo(order.id, order.status)}>
                  {formatToEuro(order.prixCalcul)}
                </td>
                <td className="large" onClick={() => redirectTo(order.id, order.status)}>
                  {order.commentaire || '--'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}
    </>
  );
};
