import React, { useContext } from 'react';

import { DraftEditorContext } from '../../../../../_shared/providers/draft/draftEditor.provider';
import { setSelected } from '../../../../../_shared/providers/draft/draftEditor.actions';

import { OrderStatusCard } from '../../../components/orderStatusCard/orderStatus.card';
import { ClientCard } from '../../../components/clientCard/client.card';
import { AdresseCard } from '../../../components/adresseCard/adresse.card';
import { CompositionList } from '../compositionList/composition.list';
import { DraftComment } from '../draftComment/draft.comment';
import DraftConfirmationPanel from '../draftConfirmationPanel/draftConfirmation.panel';
import PractitionerInfo from '../practitionerInfo/practitioner.info';

import { BROUILLON_STATUS } from '../../../../../_shared/config/constants';
import { PractitionerPicker } from '../practitionerPicker/practitioner.picker';
import { ClientPicker } from '../clientPicker/client.picker';
import { ShippingPanel } from '../shippingPanel/shipping.panel';
import DraftPricePanel from '../draftPricePanel/draftPrice.panel';
import { useRemise } from '../../../../../_shared/providers/draft/useRemise';
import PaymentPanel from '../paymentPanel/payment.panel';

export const DraftInfoPanel: React.FC<any> = () => {
  const { code, practitioner, dates, client, clientWarning, adresseSelected, selected, dispatch } =
    useContext(DraftEditorContext);

  const remise = useRemise();

  return (
    <div className="draft-info-panel p-medium">
      <div>
        <OrderStatusCard
          status={BROUILLON_STATUS}
          orderCode={code}
          shipmentType={''}
          dates={{
            createdAt: dates.createdAt,
          }}
          practitionerCode={practitioner ? practitioner.code : null}
        />
      </div>
      <PractitionerInfo />
      {selected === 'practitioner' ? <PractitionerPicker /> : null}
      <button
        className="selectable wrap l-align br-bottom p-small full-width start"
        tabIndex={0}
        onClick={() => {
          dispatch(setSelected('client'));
        }}
        data-cy="DraftInfoPanel-client-search"
      >
        <div className="full-width">
          {client ? (
            <>
              <div className={`row br-bottom full-width`}>
                <div className="circle p-medium column center t-grey b-lgrey bordered">
                  {clientWarning ? (
                    <i className="material-icons t-red">error</i>
                  ) : (
                    <i className="material-icons t-green">person</i>
                  )}
                </div>

                <div className="full-width">
                  <div className="light t-green">CLIENT</div>
                  <div className="row full-width spaced">
                    <ClientCard client={client} isRemised={remise > 0 && remise === client.remise} />
                    {clientWarning ? <i className="material-icons t-red">error</i> : null}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="row wrap">
              <div className="row">
                <div className="circle p-medium column center t-grey b-lgrey bordered">
                  <i className="material-icons " style={{ fontSize: '16px' }}>
                    person
                  </i>
                </div>
                <span className="t-green light">CLIENT</span>
              </div>
              <div className="row gapped t-medium">
                <div className="flex-text t-green">+</div>
                <div className="light t-grey">Ajouter un client à la commande</div>
              </div>
            </div>
          )}
          {client ? (
            <div className="rounded row full-width">
              <div className="circle p-medium column center t-grey b-lgrey bordered">
                <i className="material-icons t-green">local_shipping</i>
              </div>
              {client && client.adresses[adresseSelected] ? (
                <AdresseCard adresseSelected={client.adresses[adresseSelected]} />
              ) : (
                <div>Aucune adresse renseignée</div>
              )}
            </div>
          ) : null}
        </div>
      </button>
      {selected === 'client' ? <ClientPicker /> : null}
      <CompositionList />
      <DraftComment />
      <ShippingPanel />
      <PaymentPanel />
      <DraftPricePanel />
      <DraftConfirmationPanel />
    </div>
  );
};
