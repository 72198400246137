import React from 'react';
import { Client } from '../../../_shared/models/client';

interface IClientsListProps {
  isLoading: boolean;
  clients: Client[];
  handleSelectClient: any;
  handleOrder: any;
}

export const ClientsList: React.FC<IClientsListProps> = (props: IClientsListProps) => {
  return (
    <div className={'full-height full-width scrollable-y'}>
      <table className="table list">
        <thead className="table-head">
          <tr>
            <th onClick={() => props.handleOrder('num')}>N°</th>
            <th onClick={() => props.handleOrder('nom')}>NOM</th>
            <th onClick={() => props.handleOrder('prenom')}>PRENOM</th>
            <th>ADRESSE</th>
            <th onClick={() => props.handleOrder('cp')}>CP</th>
            <th onClick={() => props.handleOrder('ville')}>VILLE</th>
            <th onClick={() => props.handleOrder('pays')}>PAYS</th>
            <th>MOBILE</th>
            <th>FIXE</th>
          </tr>
        </thead>
        <tbody className="table-body">
          {props.clients.map((client: Client, index: number) => {
            const address = client.adresses.find((adress) => adress.default) || client.adresses[0];
            return (
              <tr key={index} onClick={() => props.handleSelectClient(client)}>
                <td className={'bold'}>{client.num}</td>
                <td className="upperCase">{client.nom}</td>
                <td>{client.prenom}</td>
                <td>{address.lines[0]}</td>
                <td>{address.cp}</td>
                <td>{address.ville}</td>
                <td>{address.pays}</td>
                <td>{client.mobile}</td>
                <td>{client.fixe}</td>
              </tr>
            );
          })}
          <tr />
        </tbody>
      </table>
    </div>
  );
};
