import React from 'react';

export const PractitionerSuggestion: React.FC<any> = ({ practitioner }: any) => {
  return (
    <div className="l-align">
      <div className="t-medium">
        <b>{practitioner.code}</b>
      </div>
      <div className="t-medium">
        {practitioner.nom} {practitioner.prenom}
      </div>
      <div className="t-medium">{practitioner.fixe}</div>
      <div className="t-medium">{practitioner.mobile}</div>
      <div className="t-medium">{practitioner.mail}</div>
    </div>
  );
};
