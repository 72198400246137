import React, { useContext, useEffect, useState } from 'react';
import { ServiceContext } from '../../../_core/services/service.context';
import { orderService } from '../../../_shared/services/order.service';
import moment from 'moment';
import { useEuroFormatter } from '../../../_shared/services/formatter.service';
import { HeadersList } from '../../../_shared/component/tableHeadersList/Headers.list';
import { ListPagination } from '../../../_shared/component/listPagination/ListPagination';
import { ORDERS_LIMIT } from '../../../_shared/config/constants';
import Row from '../../../_shared/component/row/row';
import { SpinLoader } from '../../../_shared/component/loader/loader';

const headersList = [
  {
    name: 'code',
    title: 'code',
  },
  {
    name: 'date',
    title: 'date',
  },
  {
    name: 'praticien',
    title: 'praticien',
  },
  {
    name: 'prix',
    title: 'prix',
  },
];

const useClientDrafts = (clientId: string) => {
  const { query } = useContext(ServiceContext);
  const [state, setState] = useState({
    drafts: [],
    total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [orderSort, setOrderSort] = useState({
    sort: 'date',
    direction: 'desc',
  });

  useEffect(() => {
    if (clientId && query) {
      setIsLoading(true);
      setState({
        drafts: [],
        total: 0,
      });
      query(
        orderService.getAllDraftsByStatus(
          { cid: clientId, editStatus: 'draft' },
          offset,
          orderSort.sort,
          orderSort.direction,
        ),
      )
        .subscribe(
          (res) => {
            setState({ drafts: res.drafts, total: res.total });
          },
          (err) => console.log(err),
        )
        .add(() => {
          setIsLoading(false);
        });
    }
  }, [clientId, orderSort, offset]);

  const updateSort = (sort: any) => {
    if (orderSort && orderSort.sort === sort) {
      setOrderSort({
        sort,
        direction: orderSort.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setOrderSort({ sort, direction: 'desc' });
    }
  };

  return {
    ...state,
    sort: orderSort,
    offset,
    setOffset,
    updateSort,
    isLoading,
  };
};

export const CLientDraftsList: React.FC<any> = ({ clientId, orderId, onSelect, onWarning }: any) => {
  const { drafts, isLoading, sort, offset, total, setOffset, updateSort } = useClientDrafts(clientId);

  const formatToEuro = useEuroFormatter();

  const filteredDrafts = orderId ? drafts.filter((d: any) => d.id !== orderId && d.editStatus !== 'archive') : drafts;

  useEffect(() => {
    if (onWarning !== undefined) onWarning(filteredDrafts && filteredDrafts.length > 0);
  }, [filteredDrafts]);

  return (
    <div className={'client-order-list'}>
      <div className="p-medium light t-green br-bottom row gapped">
        <span className="material-icons">cached</span>
        <span>COMMANDES EN COURS</span>
      </div>
      {filteredDrafts.length > 0 && !isLoading ? (
        <>
          <Row className="t-orange p-spaced rounded">
            <i className="material-icons">warning</i>
            <div>Commandes en préparation : {filteredDrafts.length}</div>
          </Row>
          <table className="br-top br-bottom">
            <thead>
              <tr>
                <HeadersList headersList={headersList} sort={sort} onUpdateSort={updateSort} />
              </tr>
            </thead>
            <tbody>
              {filteredDrafts.map((draft: any, index: number) => (
                <tr key={index} onClick={() => onSelect(draft.id)}>
                  <td>{draft.code}</td>
                  <td>{moment(draft.date).locale('fr').format('DD MMMM YYYY')}</td>
                  <td>{draft.praticienCode || '--'}</td>
                  <td>{formatToEuro(draft.prixCalcul)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <ListPagination total={total} limit={ORDERS_LIMIT} offset={offset} setOffset={setOffset} />
        </>
      ) : (
        <div className="row center p-medium full-width">
          {isLoading ? (
            <SpinLoader size={'3em'} thickness={'0.2em'} color={'#000000'} />
          ) : (
            <div className="t-grey">Aucune commande en cours</div>
          )}
        </div>
      )}
    </div>
  );
};
