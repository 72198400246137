import React from 'react';
import {
  BROUILLON_STATUS,
  DEPOT_STATUS,
  DEVIS_STATUS,
  ENVOIE_STATUS,
  VALIDEE_STATUS,
} from '../../../../_shared/config/constants';

interface IDraftsStatusPanel {
  disabled?: boolean;
  status?: string | null;
  onHandleStatus: any;
}

export const DraftsStatusPanel: React.FC<IDraftsStatusPanel> = ({
  disabled,
  status,
  onHandleStatus,
}: IDraftsStatusPanel) => {
  return (
    <div className="crud-header row gapped">
      <div className="title">
        <span className="material-icons">access_time</span>
        <span>EN COURS</span>
      </div>
      <nav className="row gapped">
        <button
          disabled={disabled}
          className={`button-validate transparent rounded ${status === BROUILLON_STATUS ? 'active' : ''}`}
          onClick={() => onHandleStatus(BROUILLON_STATUS)}
        >
          <i className="material-icons">create</i> &nbsp; Brouillons
        </button>
        <button
          disabled={disabled}
          className={`button-validate transparent rounded ${status === VALIDEE_STATUS ? 'active' : ''}`}
          onClick={() => onHandleStatus(VALIDEE_STATUS)}
        >
          <i className="material-icons">flip_camera_android</i> &nbsp; À préparer
        </button>
        <button
          disabled={disabled}
          className={`button-validate transparent rounded ${status === ENVOIE_STATUS ? 'active' : ''}`}
          onClick={() => onHandleStatus(ENVOIE_STATUS)}
        >
          <i className="material-icons">mail_outline</i> &nbsp; À expédier
        </button>
        <button
          disabled={disabled}
          className={`button-validate transparent rounded ${status === DEPOT_STATUS ? 'active' : ''}`}
          onClick={() => onHandleStatus(DEPOT_STATUS)}
        >
          <i className="material-icons">content_paste_go</i> &nbsp; À déposer
        </button>
        <button
          disabled={disabled}
          className={`button-validate transparent rounded ${status === DEVIS_STATUS ? 'active' : ''}`}
          onClick={() => onHandleStatus(DEVIS_STATUS)}
        >
          <i className="material-icons">list</i> &nbsp; Devis
        </button>
      </nav>
    </div>
  );
};
