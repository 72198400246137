import React, { useContext } from 'react';
import { DraftEditorContext, Paiement } from '../../../../../_shared/providers/draft/draftEditor.provider';
import { setCommentairePaiement, setPaiement } from '../../../../../_shared/providers/draft/draftEditor.actions';
import Row from '../../../../../_shared/component/row/row';
import NumberInput from '../../../../../_shared/component/numberInput';
import { usePrixTotal } from '../../../../../_shared/providers/draft/usePrixTotal';
import TextInput from '../../../../../_shared/component/textInput/textInput.component';

const PaymentPanel: React.FC = () => {
  const { paiement, commentairePaiement, dispatch } = useContext(DraftEditorContext);
  const { prixTotal } = usePrixTotal();

  const handlePaiementTypeChange = (paiementType: Paiement['type']) => {
    dispatch(
      setPaiement({
        type: paiementType === paiement?.type ? 'standard' : paiementType,
      }),
    );
  };

  const handleCommentChange = (value: { comment: string }) => {
    dispatch(setCommentairePaiement(value.comment));
  };

  return (
    <div className="payment-panel row wrap">
      <Row className="light">
        <div className="circle p-medium t-medium column center t-grey b-lgrey bordered">
          <i className="material-icons">point_of_sale</i>
        </div>
        <div>PAIEMENT</div>
      </Row>
      <div className="payment-panel_content row gapped p-medium">
        <div className={`row gapped ${paiement?.type === 'prepaye' ? 'active' : ''}`}>
          <button
            onClick={() => {
              handlePaiementTypeChange('prepaye');
            }}
            className="circle b-grey bordered p-small"
          >
            {paiement?.type === 'prepaye' ? <div className="circle b-green" /> : null}
          </button>
          <span>Prépayée</span>
        </div>
        <div className={`row gapped ${paiement?.type === 'praticien' ? 'active' : ''}`}>
          <button
            onClick={() => {
              handlePaiementTypeChange('praticien');
            }}
            className="circle b-grey bordered p-small"
          >
            {paiement?.type === 'praticien' ? <div className="circle b-green" /> : null}
          </button>
          <span>Praticien</span>
        </div>
        <div className={`row gapped ${paiement?.type === 'partiel' ? 'active' : ''}`}>
          <button
            className="circle b-grey bordered p-small"
            onClick={() => {
              handlePaiementTypeChange('partiel');
            }}
          >
            {paiement?.type === 'partiel' ? <div className="circle b-green" /> : null}
          </button>
          <span>Partiel</span>
          {paiement?.type === 'partiel' ? (
            <NumberInput
              name={'amount'}
              value={paiement?.amount || 0}
              onChange={(e: any) => {
                dispatch(setPaiement({ ...paiement, amount: e.amount }));
              }}
              className="t-grey bordered rounded t-large r-align"
              prefix="€"
              error={prixTotal < (paiement?.amount || 0) ? 'err' : null}
              size={4}
              resize={true}
            />
          ) : null}
        </div>
      </div>
      <TextInput
        value={commentairePaiement}
        name="comment"
        label="Commentaire de paiement"
        onChange={handleCommentChange}
        show={true}
        labelHide={true}
        isEditable={true}
        size={100}
        dataCy="PaymentPanel-comment"
      />
    </div>
  );
};

export default PaymentPanel;
