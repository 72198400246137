import React, { useEffect } from 'react';
import { OrderDetail } from './order.detail';
import { useOrderById } from '../../../_shared/providers/order/useOrderById';
import { useHistory } from 'react-router-dom';
import { OrdersTypeFilterPanel } from '../ordersPage/ordersTypeFilterPanel/ordersTypeFilter.panel';
import { ORDERS_PATH } from '../../../_shared/config/paths';

export const OrderPage: React.FC<any> = ({ match }: any) => {
  const {
    order,
    setOrderId,
    isLoading,
    isPaiementOrderLoading,
    updateStatus,
    updateComment,
    addPaiement,
    generatePaiementURL,
  } = useOrderById();

  const history = useHistory();

  useEffect(() => {
    if (match.params) setOrderId(match.params.id);
  }, []);

  return (
    <div className="orders-layout">
      <OrdersTypeFilterPanel
        status={order?.editStatus}
        onHandleStatus={(status: string[]) => history.push(`${ORDERS_PATH}?status=${status.join(',')}`)}
        disabled={false}
      />
      <div className="page order-page">
        <OrderDetail
          order={order}
          isLoading={isLoading}
          isPaiementOrderLoading={isPaiementOrderLoading}
          onCommentChange={updateComment}
          onPaymentCommentChange={(paymentComment: string) => updateComment(undefined, paymentComment)}
          onUpdateStatus={updateStatus}
          onAddPaiement={addPaiement}
          onGeneratePaiementOrder={generatePaiementURL}
        />
      </div>
    </div>
  );
};
