import React from 'react';

interface EmptyRowsProps {
  rowsNumber: number | null;
  startIndex: number;
  pattern: any;
}

const EmptyRows: React.FC<EmptyRowsProps> = ({ rowsNumber, startIndex, pattern }: EmptyRowsProps) => {
  return (
    <>
      {rowsNumber && rowsNumber > 0
        ? Array.from(Array(rowsNumber).keys()).map((index: number) => (
            <tr key={index}>
              <td className="locked t-grey center-align">{startIndex + index + 1}</td>
              <td className="locked" />
              <td className="locked" />
              {pattern.map((element: any, i: number) => (
                <td key={i} className={element.isEditable ? '' : 'locked'} />
              ))}
              <td />
              <td className="locked" />
              <td className="locked" />
              <td className="locked" />
            </tr>
          ))
        : null}
    </>
  );
};

export default EmptyRows;
