import { useContext, useEffect, useState } from 'react';
import { DraftEditorContext } from './draftEditor.provider';

export const usePrixTTC = () => {
  const { compositions } = useContext(DraftEditorContext);
  const [prixTTC, setPrixTTC] = useState(0);
  useEffect(() => {
    setPrixTTC(compositions.reduce((c, arr) => c + arr.prix, 0));
  }, [compositions]);
  return prixTTC;
};
