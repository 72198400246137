import { from, Observable, of, throwError } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { BASE_URL } from '../config/constants';

export function getAllCodePrix(): Observable<any> {
  return from(
    fetch(`${BASE_URL}/codeprix`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    }),
  ).pipe(
    flatMap((response: Response) => {
      if (response.ok) {
        return response.json();
      } else {
        return throwError({
          status: response.status,
          message: response.text(),
        });
      }
    }),
  );
}

class CodeprixService {
  fetchCodePrix = (codePrix: any) => {
    const body = {
      id: codePrix.id,
      code: codePrix.code,
      forme_prix: codePrix.forme_prix,
    };
    return from(
      fetch(`${BASE_URL}/codeprix${codePrix.id ? '/' + codePrix.id : ''}`, {
        method: codePrix.id ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(body),
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
  };

  deleteCodePrix = (id: string) =>
    from(
      fetch(`${BASE_URL}/codeprix/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
    ).pipe(
      flatMap((response: Response) => {
        if (response.ok) {
          return of('ok');
        } else {
          return throwError({
            status: response.status,
            message: response.text(),
          });
        }
      }),
    );
}

export const codePrixService = new CodeprixService();
