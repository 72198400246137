import React, { SyntheticEvent } from 'react';

interface IDateInputProps {
  value: any;
  name: string;
  label?: string;
  type?: string;
  size?: number;
  className?: string;
  labelHide?: boolean;
  number?: boolean;
  prefix?: string;
  isExist?: boolean;
  isEditable?: boolean;
  isAutofocus?: boolean;
  isRequired?: boolean;
  hiden?: boolean;
  show?: boolean;
  max?: string;
  min?: string;
  onChange: any;
  onCancel?: any;
}

const handleOnChange = (e: any) => {
  return {
    [e.target.name]: e.target.value,
  };
};

const DateInput: React.FC<IDateInputProps> = (props: IDateInputProps) => {
  return (
    <div className={`date-input ${props.className}`}>
      <label>{props.label ? props.label : props.name}</label>
      <div className={`date-input-row row bordered`}>
        <input
          type={'date'}
          value={props.value || ''}
          name={props.name}
          max={props.max ? props.max : ''}
          min={props.min ? props.min : ''}
          onChange={(e: SyntheticEvent) => props.onChange(handleOnChange(e))}
        />
        {props.onCancel && props.value ? (
          <button disabled={!props.value} onClick={() => props.onCancel()}>
            <span className="material-icons">close</span>
          </button>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default DateInput;
