import React from 'react';
import { Client } from '../../../../_shared/models/client';

interface IClientCardProps {
  client: Client | null;
  isRemised?: boolean;
  remise?: number;
}

export const ClientCard: React.FC<IClientCardProps> = ({ client, isRemised, remise }: IClientCardProps) => {
  const phoneNumber = client ? client.mobile || client.fixe || client.tel3 || client.tel4 : undefined;
  return (
    <>
      {client ? (
        <div className="client-card row spaced full-width">
          <div className=" column gapped">
            <div className="t-medium">{client.num}</div>
            <div className="t-medium bold">
              {client.prenom ? client.prenom.toUpperCase() : ''} {client.nom ? client.nom.toUpperCase() : ''}
            </div>
            <div className="row wrap t-grey">
              {client.mail ? (
                <div className="row gapped">
                  <span style={{ fontSize: '14px' }} className="material-icons">
                    alternate_email
                  </span>
                  <span className="t-medium">{client.mail}</span>
                </div>
              ) : null}
              {phoneNumber ? (
                <div className="row gapped">
                  <span style={{ fontSize: '14px' }} className="material-icons">
                    local_phone
                  </span>
                  <span className="t-medium">{phoneNumber}</span>
                </div>
              ) : null}
            </div>
          </div>
          {isRemised ? (
            <div className="row gapped t-green">
              <span className="material-icons t-large">local_offer</span>
              <span className="t-large">{remise || client.remise} %</span>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="row gapped">
          <div className="light t-medium  ">Aucun client sélectionné</div>
        </div>
      )}
    </>
  );
};
