import React from 'react';
import { BROUILLON_STATUS } from '../../../../_shared/config/constants';

interface ISetToEditionButtonProps {
  onSetToEdition: any;
  minified?: boolean;
  disabled?: boolean;
}

export const SetToEditionButton: React.FC<ISetToEditionButtonProps> = (props: ISetToEditionButtonProps) => {
  return (
    <div>
      <button
        className={props.minified ? 'button-lgrey minified' : 'button-lgrey'}
        disabled={props.disabled}
        onClick={() => props.onSetToEdition(BROUILLON_STATUS)}
      >
        <i className="material-icons">create</i>
        {props.minified ? '' : <span>Editer</span>}
      </button>
    </div>
  );
};
