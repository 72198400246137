import React, { useState } from 'react';
import NumberInput from '../../../../../../../_shared/component/numberInput';
import useCompositionOnEdition from '../../../../../../../_shared/providers/draft/useCompositionOnEdition';
import Row from '../../../../../../../_shared/component/row/row';

export const IngredientPricePanel: React.FC = () => {
  const [ingredientPrice, setIngredientPrice] = useState(undefined);
  const { composition, updateComposition } = useCompositionOnEdition();

  const updatePrices = () => {
    const updatedIngredients = composition.ingredients.map((i: any) =>
      i.codeprix?.code && i.codeprix.code == 'N' ? { ...i, prix: ingredientPrice } : i,
    );

    updateComposition({ ...composition, ingredients: updatedIngredients });
  };

  return (
    <div style={{ fontSize: '0.8em' }}>
      <label>PRIX CODE N</label>
      <Row gapped={true}>
        <NumberInput
          className={'calcInput'}
          resize={true}
          size={4}
          name={'ingredientPrice'}
          decimals={3}
          value={ingredientPrice}
          min={0}
          onChange={(p: any) => setIngredientPrice(p.ingredientPrice)}
        />
        <button onClick={() => updatePrices()}>
          <i className="material-icons t-medium t-lgrey">done</i>
        </button>
      </Row>
    </div>
  );
};
