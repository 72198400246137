import React from 'react';
import './App.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppLayout, AppProvider, AppRouter } from './_core/routing/router';

const App: React.FC = () => {
  return (
    <div className="App">
      <Router>
        <AppProvider>
          <AppLayout>
            <AppRouter />
          </AppLayout>
        </AppProvider>
      </Router>
    </div>
  );
};

export default App;
