import { useRef } from 'react';

export const useFocus = () => {
  const htmlElRef = useRef(null as any | null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.select();
  };

  return { htmlElRef, setFocus };
};
