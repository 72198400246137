import React, { forwardRef, Ref } from 'react';
import { Client } from '../../../_shared/models/client';
import useClients from '../../../_shared/providers/client/useClients';
import { ClientSuggestion } from '../../orders/draftEditorPage/components/clientPicker/clientSuggestion/client.suggestion';
import { SearchBar } from '../../../_shared/component/searchBar/searchBar';
import useOutsideClick from '../../../_shared/component/useClickOutside';
import ParentComponent from '../../../_shared/component/parentComponent/parent.component';

interface IClientSuggesterProps {
  clear?: boolean;
  onClientSelect: (client: Client) => void;
  ref: any;
}

const ClientSuggester: React.FC<IClientSuggesterProps> = forwardRef((props: IClientSuggesterProps, ref: Ref<any>) => {
  const { clients, searchField, setSearchField, isLoading } = useClients(false);

  useOutsideClick(ref, () => setSearchField(null));

  const selectClient = (client: Client) => {
    props.onClientSelect(client);
    if (props.clear) setSearchField('');
  };

  return (
    <ParentComponent onBlur={() => setSearchField(null)}>
      <div>
        <SearchBar
          filter={searchField || ''}
          placeholder={'Rechercher un client'}
          onChange={setSearchField}
          onFilterRemove={() => setSearchField(null)}
        />
      </div>
      {searchField && clients ? (
        <div className="relative b-white">
          <div className="absolute">
            <div className="b-white bordered rounded">
              {clients.length > 0
                ? clients.map((client: any, index: number) => (
                    <button
                      key={index}
                      className={`selectable p-medium full-width l-align${
                        index !== clients.length - 1 ? ' br-bottom' : ''
                      }`}
                      onClick={() => selectClient(client)}
                    >
                      <ClientSuggestion client={client} />
                    </button>
                  ))
                : isLoading
                ? null
                : 'Aucun client trouvé'}
            </div>
          </div>
        </div>
      ) : null}
    </ParentComponent>
  );
});

export default ClientSuggester;
