import { useContext, useEffect, useState } from 'react';
import { useRemise } from './useRemise';
import { usePrixTTC } from './usePrixTTC';
import { DraftEditorContext } from './draftEditor.provider';

export const usePrixTotal = () => {
  const { fraisPort } = useContext(DraftEditorContext);
  const remise = useRemise();
  const prixTTC = usePrixTTC();
  const [remiseInEuro, setRemiseInEuro] = useState(0);
  const [prixTotal, setPrixTotal] = useState(0);

  useEffect(() => {
    setRemiseInEuro(prixTTC * (remise / 100));
    setPrixTotal(prixTTC + fraisPort - (prixTTC * remise) / 100);
  }, [prixTTC, fraisPort, remise]);
  return { prixTTC, prixTotal, remise, remiseInEuro };
};
