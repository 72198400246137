import React from 'react';
import FormulePicker from '../formulePicker/formule.picker';
import ParserPicker from '../parserPicker/parser.picker';
import IngredientPicker from '../ingredientPicker/ingredient.picker';

interface ISearchRowProps {
  formeId: string;
  pickerType: string;
  ingredientsId: string[];
  parserSuggestions: any[];
  pattern: any[];
  onAddIngredient: any;
  onPickerTypeChange: any;
  onParserFocus: any;
}

function renderPickerPanel({
  pattern,
  pickerType,
  formeId,
  ingredientsId,
  onAddIngredient,
  parserSuggestions,
  onPickerTypeChange,
  onParserFocus,
}: ISearchRowProps) {
  switch (pickerType) {
    case 'ingredient':
      return (
        <IngredientPicker
          pattern={pattern}
          formeId={formeId}
          ingredientsId={ingredientsId}
          onAddIngredient={onAddIngredient}
          onPickerTypeChange={onPickerTypeChange}
        />
      );
    case 'formule':
      return (
        <FormulePicker
          pattern={pattern}
          formeId={formeId}
          ingredientIds={ingredientsId}
          onPickerTypeChange={onPickerTypeChange}
        />
      );
    case 'parser':
      return (
        <ParserPicker
          pattern={pattern}
          formeId={formeId}
          ingredientIds={ingredientsId}
          suggestions={parserSuggestions}
          onPickerTypeChange={onPickerTypeChange}
          onParserFocus={onParserFocus}
        />
      );
    default:
      throw new Error('Incorrect Picker Type');
  }
}

export const SearchRow: React.FC<ISearchRowProps> = (props: ISearchRowProps) => {
  return renderPickerPanel({ ...props });
};
