import useCompositionOnEdition from './useCompositionOnEdition';

export const useAddIngredients = () => {
  const { composition, updateComposition } = useCompositionOnEdition();

  return (ingredients: any[], poids: number) => {
    let currentIngredients = [...composition.ingredients];
    const poidsTotal = ingredients.reduce((c: number, a: any) => c + a.proportion, 0);

    const ings = ingredients.map((ingredient: any) => {
      ingredient.proportion = poidsTotal > 0 ? (ingredient.proportion * poids) / poidsTotal : ingredient.proportion;
      return ingredient;
    });

    ings
      .filter((i: any) => i.id && i.pinyin && i.proportion && i.proportion > 0)
      .map((e: any) => {
        const ingredientIndex: number = currentIngredients.findIndex((i: any) => i.id === e.id);
        if (ingredientIndex >= 0) {
          currentIngredients[ingredientIndex].proportion += Number(e.proportion);
        } else {
          currentIngredients = [...currentIngredients, ...[e]];
        }
      });

    updateComposition({
      ingredients: currentIngredients,
    });
  };
};
