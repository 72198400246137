interface IEnumerable {
  [key: string]: any;
}

const percentFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'percent',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const formatPercent = (percent: number) => (isNaN(percent) ? '--' : percentFormatter.format(percent));

const poidsFormatter = new Intl.NumberFormat('fr-FR', {
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
});

const formatPoids = (poids: number) =>
  // use formatter then cast back to number for later calculus
  isNaN(poids) ? '--' : poidsFormatter.format(poids) + '0 g';

const patternProp = {
  value: 'proportion',
  name: 'proportion',
  label: 'Proportion',
  isEditable: true,
};

const patternPoids = {
  value: 'poids',
  name: 'poids',
  formatter: formatPoids,
  label: 'Poids',
};

const patternPercent = {
  value: 'percent',
  name: 'percent',
  formatter: formatPercent,
  label: '%',
};

const patternPropPoids = {
  value: 'proportion',
  name: 'proportion',
  label: 'Poids',
  isEditable: true,
};

const standarPattern = [patternProp, patternPercent, patternPoids];

export const INGREDIENT_LIST_PATTERNS: IEnumerable = {
  PC: standarPattern,
  MO: standarPattern,
  GE: standarPattern,
  IF: standarPattern,
  CC: standarPattern,
  VR: standarPattern,
  VR8: standarPattern,
  DV: [patternPropPoids, patternPercent],
};
