import React from 'react';

interface ICrudButtonPanelProps {
  isOnEdition: boolean;
  onEdition: any;
  onValidate: any;
  onCancelEdition?: any;
}

export const CrudButtonPanel: React.FC<ICrudButtonPanelProps> = (props: ICrudButtonPanelProps) => {
  return props.isOnEdition ? (
    <div className="row gapped p-medium">
      {props.onCancelEdition ? (
        <button className="button-alert" onClick={props.onCancelEdition}>
          <i className="material-icons">undo</i>
          Annuler
        </button>
      ) : null}
      <button className="button-validate" onClick={props.onValidate}>
        <i className="material-icons">done</i>
        Sauvegarder
      </button>
    </div>
  ) : (
    <div className="row p-medium gapped">
      <button className="button-edit" onClick={props.onEdition}>
        <i className="material-icons">create</i>
        Modifier
      </button>
    </div>
  );
};
