import moment, { Moment } from 'moment';

const euroFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
});

export const useEuroFormatter = () => {
  const format = (value: any) => (isNaN(value) ? '---' : euroFormatter.format(value));
  return format;
};

const percentFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'percent',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const usePercentFormatter = () => {
  const format = (value: any) => (isNaN(value) ? '---' : percentFormatter.format(value));
  return format;
};

const poidsFormatter = new Intl.NumberFormat('fr-FR', {
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
});

export const usePoidsFormatter = () => {
  const format = (value: any) => (isNaN(value) ? '---' : poidsFormatter.format(value) + '0 g');
  return format;
};

const decimalFormater = new Intl.NumberFormat('fr-FR', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const useDecimalFormatter = () => {
  const format = (value: any) => decimalFormater.format(Number(value));
  return format;
};

export const formatToDate = (date: string | null | undefined) =>
  date ? moment(date).locale('fr').format('DD MMMM YYYY  |  HH:mm') : '--';

export const formatToDateFilter = (date: Moment) => date.format('YYYY-MM-DD');
