import React from 'react';
import { Ingredient } from '../../../_shared/models/ingredient';
import './ingredientsList.css';
import { SpinLoader } from '../../../_shared/component/loader/loader';

interface IIngredientListProps {
  isEditing: boolean;
  isLoading: boolean;
  ingredients: Ingredient[] | null;
  ingredientSelected: Ingredient | null;
  order: string | null;
  handleOrder: (order: string) => void;
  onSelect: (value: Ingredient) => void;
}

export class IngredientsList extends React.Component<IIngredientListProps> {
  render() {
    return (
      <div className={'full-height scrollable-y ' + (this.props.isEditing ? 'locked' : '')}>
        {!this.props.isLoading ? (
          <table className="table">
            <thead className="table-head">
              <tr>
                <th
                  className={this.props.order === 'code' ? 'orderned' : ''}
                  onClick={() => this.props.handleOrder('code')}
                >
                  CODE
                </th>
                <th
                  className={this.props.order === 'pinyin' ? 'orderned' : ''}
                  onClick={() => this.props.handleOrder('pinyin')}
                >
                  PINYIN
                </th>
                <th>SYNONYMES</th>
                <th
                  className={this.props.order === 'latin' ? 'orderned' : ''}
                  onClick={() => this.props.handleOrder('latin')}
                >
                  LATIN
                </th>
                <th
                  className={this.props.order === 'chinois' ? 'orderned' : ''}
                  onClick={() => this.props.handleOrder('chinois')}
                >
                  CHINOIS
                </th>
                <th>CODE PRIX</th>
                <th>REGLEMENTATION</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {this.props.ingredients ? (
                this.props.ingredients.map((ingredient: Ingredient, index) => (
                  <tr
                    key={index}
                    onClick={(e) => {
                      this.props.onSelect(ingredient);
                    }}
                  >
                    <td className="bold">{ingredient.code}</td>
                    <td className="firstUpper">{ingredient.pinyin}</td>
                    <td className="firstUpper">
                      {ingredient.synonymes.map((synonyme, index) => (
                        <span className="firstUpper" key={index}>{`${synonyme}${
                          ingredient.synonymes[index + 1] ? ', ' : ''
                        }`}</span>
                      ))}
                    </td>
                    <td> {ingredient.latin}</td>
                    <td>{ingredient.chinois}</td>
                    <td>{ingredient.codeprix ? ingredient.codeprix.code : '--'}</td>
                    <td>{ingredient.reglementation}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>Aucun ingredient n'a pour l'instant été créé</td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <div className="row center p-medium full-height full-width">
            <SpinLoader size={'8em'} thickness={'4px'} color={'dimgrey'} />
          </div>
        )}
      </div>
    );
  }
}
