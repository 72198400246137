import React, { useContext, useEffect, useState } from 'react';
import DateInput from '../../../_shared/component/dateInput/dateInput';
import moment from 'moment';
import { Enumerable, NON_PAYEE_STATUS, R1_STATUS, R2_STATUS } from '../../../_shared/config/constants';
import { TextArea } from '../../../_shared/component/textArea/textArea.component';
import { ServiceContext } from '../../../_core/services/service.context';
import { practitionerService } from '../../../_shared/services/practitioner.service';
import { orderService } from '../../../_shared/services/order.service';
import useDebounce from '../../../_shared/component/useDebounce';
import { SpinLoader } from '../../../_shared/component/loader/loader';

export const StatsPage: React.FC = () => {
  const [filters, setFilters] = useState({} as any);
  const [codes, setCodes] = useState('' as any);
  const [isLoading, setIsLoading] = useState(false);
  const [practitioners, setPractitioners] = useState({} as Enumerable<any>);
  const { query } = useContext(ServiceContext);

  const debounceCodes = useDebounce(codes, 400);

  const onFiltersChange = (changes: any) => {
    if (changes) {
      setFilters({ ...filters, ...changes });
    } else {
      setFilters({});
    }
  };

  const onParseCodes = (codes: string[]) => {
    setIsLoading(true);
    query(practitionerService.getPractitionersByCodes(codes))
      .subscribe(
        (res: any) => {
          setPractitioners(res);
        },
        (err: any) => console.log(err),
      )
      .add(() => setIsLoading(false));
  };

  const onExport = (statType: 'clients' | 'praticiens' | 'preparations' | undefined = undefined) => {
    const f = filters;

    if (statType == 'clients') {
      // for client stats, we only want unpaid orders
      f['status'] = [NON_PAYEE_STATUS, R1_STATUS, R2_STATUS];
    }

    query(
      orderService.exportStats({
        ...f,
        pid: Object.values(practitioners)
          .filter((v) => v.id)
          .map((p) => p.id),
        statType,
      }),
    ).subscribe(
      () => console.log('Export done'),
      (err: any) => console.log(err),
    );
  };

  useEffect(() => {
    if (debounceCodes) onParseCodes(debounceCodes.toUpperCase().replace(/\s+/g, '').split(','));
    else if (debounceCodes.replace(/\s+/g, '').split(',').length <= 0 || debounceCodes.length <= 0) {
      setPractitioners({});
    }
  }, [debounceCodes]);

  return (
    <div className="stats-page page b-lgrey column start center p-medium scrollable-y">
      <div className="bordered b-white rounded p-medium">
        <div className="row t-large light p-medium br-bottom">
          <span className="material-icons t-green">leaderboard</span> STATISTIQUES
        </div>
        <div className="row p-medium br-bottom wrap center b-grey">
          <div className="p-medium fitContent">
            <div className="t-large light">Plage de commande</div>
            <div className="row spaced gapped b-white bordered rounded p-medium">
              <DateInput
                value={filters.dateStart}
                label={'Date de commande min'}
                name={'dateStart'}
                className="p-spaced"
                onChange={onFiltersChange}
                max={filters.dateEnd || moment().format('YYYY-MM-DD')}
                onCancel={() => onFiltersChange({ dateStart: undefined })}
              />
              <DateInput
                value={filters.dateEnd}
                label={'Date de commande max'}
                name={'dateEnd'}
                className="p-spaced"
                onChange={onFiltersChange}
                min={filters.dateStart}
                max={moment().format('YYYY-MM-DD')}
                onCancel={() => onFiltersChange({ dateEnd: undefined })}
              />
            </div>
          </div>
          <div className="p-medium fitContent">
            <div className="t-large light">Plage de paiement</div>
            <div className="row spaced gapped b-white bordered rounded p-medium">
              <DateInput
                value={filters.pDateStart}
                label={'Date de paiement min'}
                name={'pDateStart'}
                className="p-spaced"
                onChange={onFiltersChange}
                max={filters.pDateEnd || moment().format('YYYY-MM-DD')}
                onCancel={() => onFiltersChange({ pDateStart: undefined })}
              />
              <DateInput
                value={filters.pDateEnd}
                label={'Date de paiement max'}
                name={'pDateEnd'}
                className="p-spaced"
                onChange={onFiltersChange}
                min={filters.pDateStart}
                max={moment().format('YYYY-MM-DD')}
                onCancel={() => onFiltersChange({ pDateEnd: undefined })}
              />
            </div>
          </div>
          <button className="circle-button b-grey delete-filters" type={'button'} onClick={() => onFiltersChange(null)}>
            <span className="material-icons">replay</span>
          </button>
        </div>
        <div className="column">
          <div>
            <label>Codes praticiens:</label> {isLoading ? <SpinLoader size={'1em'} thickness={'1px'} /> : null}
          </div>
          <TextArea name={'codes'} value={codes} onChange={({ codes }: any) => setCodes(codes)} minRows={4} />
          {codes ? (
            <div className="column fit-width gapped">
              <div className="light">Liste des correspondances:</div>
              {debounceCodes
                .replace(/\s+/g, '')
                .split(',')
                .filter((c: string) => c.length > 0)
                .map((code: string, index: number) => (
                  <div key={'code-' + index} className="p-small row gapped">
                    <div
                      className={`${
                        practitioners[code.toUpperCase()] && !practitioners[code.toUpperCase()].id ? 't-red' : ''
                      } bordered rounded bold p-spaced fit-width`}
                    >
                      {code.toUpperCase()}
                    </div>
                    {practitioners[code.toUpperCase()] && practitioners[code.toUpperCase()].id ? (
                      <div className="bordered rounded p-spaced fit-width">
                        {practitioners[code.toUpperCase()].nom} {practitioners[code.toUpperCase()].prenom}
                      </div>
                    ) : null}
                  </div>
                ))}
            </div>
          ) : null}
        </div>
        <div className="row p-medium">
          <button className="addButton full-width" type={'button'} onClick={() => onExport()}>
            <span className="material-icons">save_alt</span> Export stats
          </button>
        </div>
        <div className="row p-medium">
          <button className="addButton full-width" type={'button'} onClick={() => onExport('praticiens')}>
            <span className="material-icons">save_alt</span> Export praticiens
          </button>
        </div>
        <div className="row p-medium">
          <button className="addButton full-width" type={'button'} onClick={() => onExport('preparations')}>
            <span className="material-icons">save_alt</span> Export préparations
          </button>
        </div>
        <div className="row p-medium">
          <button className="addButton full-width" type={'button'} onClick={() => onExport('clients')}>
            <span className="material-icons">save_alt</span> Export clients
          </button>
        </div>
      </div>
    </div>
  );
};
