import React, { useState } from 'react';
import styles from './AddPointEventForm.module.scss';
import NumberInput from '../../../_shared/component/numberInput';

interface IAddPointEventForm {
  onSubmit: (amount: number) => void;
  className?: string;
}

type PointEventType = 'add' | 'remove';

/**
 * Form with one single number input. Emits only if the value is different from 0.
 * TODO: make it generic ?
 * @param onSubmit {Function} callback called when the user submit the form
 * @param className {string} custom className
 */
const AddPointEventForm: React.FC<IAddPointEventForm> = ({ onSubmit, className }: IAddPointEventForm) => {
  const [eventType, setEventType] = useState<PointEventType>();
  const [amount, setAmount] = useState<number>();

  const handleAmountChange = (change: { pointEventAmount: number }) => {
    const { pointEventAmount } = change;
    setAmount(pointEventAmount);
  };

  const handleSubmit = () => {
    if (amount && amount !== 0) {
      // Don't allow explicit negative values.
      const positiveAmount = Math.abs(amount);
      // If type is remove, submit the value as a negative.
      const finalAmount = eventType === 'remove' ? positiveAmount * -1 : positiveAmount;
      onSubmit(finalAmount);
      setEventType(undefined);
      setAmount(undefined);
    }
  };

  return (
    <div className={`${styles.AddPointEventForm} ${className}`}>
      <div className="p-2 t-black upperCase">Ajouter ou retirer des points</div>
      {eventType ? (
        // Form
        <div className="row">
          {/* Add or Remove icon */}
          {eventType === 'add' ? (
            <i className={`material-icons ${styles.add}`} style={{ fontSize: '1.5rem' }}>
              add
            </i>
          ) : (
            <i className={`material-icons ${styles.remove}`} style={{ fontSize: '1.5rem' }}>
              remove
            </i>
          )}
          {/* Amount input */}
          <NumberInput
            value={amount}
            placeholder="0"
            name="pointEventAmount"
            size={10}
            className={`${styles.AmountInput} ${styles[eventType]} t-grey light r-align t-large bordered rounded mr-4 p-2`}
            onChange={handleAmountChange}
            onPressEnter={handleSubmit}
            dataCy="AddPointEventForm-input"
            autofocus
          />
          {/* Validation */}
          <button
            type="button"
            className="button-validate circle"
            style={{ marginRight: '0.5rem' }}
            onClick={handleSubmit}
          >
            <i className="material-icons">check</i>
          </button>
          {/* Cancel */}
          <button type="button" className="button-lgrey circle" onClick={() => setEventType(undefined)}>
            <i className="material-icons">undo</i>
          </button>
        </div>
      ) : (
        // Select type
        <div className="row mt-2">
          {/* Add button */}
          <button type="button" className="button-edit mr-2" onClick={() => setEventType('add')}>
            <i className="material-icons">add</i>
            Ajouter
          </button>
          {/* Remove button */}
          <button type="button" className="button-alert" onClick={() => setEventType('remove')}>
            <i className="material-icons">remove</i>
            Retirer
          </button>
        </div>
      )}
    </div>
  );
};

export default AddPointEventForm;
