import { useContext, useState } from 'react';
import { Client } from '../../models/client';
import { ServiceContext } from '../../../_core/services/service.context';

import { clientService } from '../../services/client.service';

const useClientOnEdition = () => {
  const { query } = useContext(ServiceContext);

  const [state, setState] = useState<{
    client: Client | undefined;
    adresseSelected: number | undefined;
  }>();

  const [isLoading, setIsLoading] = useState(false);

  const setClientOnEdition = (client: Client | undefined, adresseSelected: number | undefined) => {
    setState({
      client,
      adresseSelected,
    });
  };

  const setAdresseOnEdition = (adresseSelected: number) => {
    setState({ client: state?.client, adresseSelected });
  };

  const setClientOnEditionAsNew = () => {
    setState({
      client: clientService.getNewClient(),
      adresseSelected: 0,
    });
  };

  const setAdresseOnEditionAsNew = () => {
    if (state?.client) {
      setState({
        client: {
          ...state.client,
          adresses: [...state.client.adresses, clientService.getNewAddress()],
        },
        adresseSelected: state.client.adresses.length,
      });
    }
  };

  const updateClientOnEdition = (updates: Partial<Client>) => {
    if (state?.client) {
      setState({ ...state, client: { ...state.client, ...updates } });
    } else {
      throw Error('No client on edition');
    }
  };

  const fetchClientOnEdition = (callback?: any) => {
    if (state?.client) {
      const isNew = !state.client.id;
      setIsLoading(true);
      query(isNew ? clientService.createClient(state.client) : clientService.updateClient(state.client), {
        notify: isNew ? 'Client créé' : 'Client mis à jour',
      })
        .subscribe(
          (newClient: Client) => {
            if (isNew) {
              setState({
                ...state,
                client: newClient,
              });
            }

            if (callback)
              isNew ? callback(newClient, state.adresseSelected) : callback(state.client, state.adresseSelected);
          },
          (err: any) => {
            console.log(err);
          },
        )
        .add(() => setIsLoading(false));
    } else {
      throw Error('No client on edition');
    }
  };

  const deleteClientOnEdition = (callback?: any) => {
    if (state && state.client) {
      if (state.client.id) {
        setIsLoading(true);
        query(clientService.deleteClient(state.client.id), {
          notify: `Client ${state.client.num} supprimé`,
        })
          .subscribe(
            () => {
              setState({
                client: undefined,
                adresseSelected: undefined,
              });
              if (callback) callback(null, null);
            },
            (err: any) => {
              console.log(err);
            },
          )
          .add(() => setIsLoading(false));
      } else {
        throw Error('Client have no ID');
      }
    } else {
      throw Error('No client on edition');
    }
  };

  return {
    clientOnEdition: state?.client,
    adresseOnEdition: state?.adresseSelected,
    isLoading,
    setClientOnEdition,
    setAdresseOnEdition,
    setClientOnEditionAsNew,
    setAdresseOnEditionAsNew,
    updateClientOnEdition,
    fetchClientOnEdition,
    deleteClientOnEdition,
  };
};

export default useClientOnEdition;
