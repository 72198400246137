import React from 'react';

import useCompositionOnEdition from '../../../../../../../_shared/providers/draft/useCompositionOnEdition';
import { TextArea } from '../../../../../../../_shared/component/textArea/textArea.component';

const CommentaireInput: React.FC = () => {
  const { composition, updateComposition } = useCompositionOnEdition();
  return (
    <div className="composition-commentaire-field column">
      <label className="upperCase t-small">Commentaire/Posologie de la préparation:</label>
      <TextArea
        name={'commentaire'}
        value={composition.commentaire}
        onChange={updateComposition}
        minRows={3}
        dataCy="CommentaireInput"
      />
    </div>
  );
};

export default CommentaireInput;
