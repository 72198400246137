import React from 'react';
import { Observable, Subject, Subscription } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { withRouter } from 'react-router-dom';
import { Alert, ServiceContext } from './service.context';
import { AuthContext } from '../routing/auth.context';
import Row from '../../_shared/component/row/row';

const TOAST_TIME = 3000;

class ServiceInterface extends React.Component<any, any> {
  static contextType = AuthContext;
  alerts$ = new Subject();
  alertsSubscription = new Subscription();

  state: { alerts: Alert[] } = {
    alerts: [],
  };

  componentDidMount(): void {
    this.alertsSubscription = this.alerts$.asObservable().subscribe((alert: any) => {
      this.setState({
        alerts: [...this.state.alerts, alert],
      });
    });
  }

  componentWillUnmount(): void {
    this.alertsSubscription.unsubscribe();
  }

  addAlert(alert: Alert) {
    this.alerts$.next(alert);
    if (!alert.permanent) setTimeout(() => this.removeAlert(alert), TOAST_TIME);
  }

  removeAlert(alert: any) {
    this.setState({ alerts: this.state.alerts.filter((x) => x !== alert) });
  }

  query = (query$: Observable<any>, options?: any) => {
    return query$.pipe(
      tap(() => (options && options.notify ? this.addAlert({ message: options.notify, type: 'valid' }) : '')),
      catchError(async (err) => {
        if (err.status === 401 || !err.status) {
          this.context.logout();
        } else {
          const message = await err.message;
          if (!options || !options.noAlert) this.addAlert({ message, type: 'error' });
          throw new Error(message);
        }
      }),
      first(),
    );
  };

  getAlert = (alert: Alert) => {
    const getContent = (alert: Alert) => {
      switch (alert.type) {
        case 'error':
          return (
            <>
              <span className="material-icons">block</span>
              <div>{alert.message}</div>
            </>
          );
        case 'valid':
          return (
            <>
              <span className="material-icons">done</span>
              <div>{alert.message}</div>
            </>
          );
        case 'info':
          return (
            <>
              <span className="material-icons">announcement</span>
              <div>{alert.message}</div>
            </>
          );
      }
    };

    return (
      <Row align="spaced" className={`alert-${alert.type} rounded`}>
        <div className="row gapped">{getContent(alert)}</div>
        {alert.component}
        {alert.permanent ? (
          <button className="button-white circle" onClick={() => this.removeAlert(alert)}>
            <span className="material-icons">close</span>
          </button>
        ) : null}
      </Row>
    );
  };

  render() {
    return (
      <>
        <div className="alert-list" style={{ position: 'absolute' }}>
          {this.state.alerts.map((alert: any, index: number) => (
            <React.Fragment key={index}>{this.getAlert(alert)}</React.Fragment>
          ))}
        </div>
        <ServiceContext.Provider
          value={{
            query: this.query,
            pushAlert: (alert: Alert) => this.addAlert(alert),
          }}
        >
          {this.props.children}
        </ServiceContext.Provider>
      </>
    );
  }
}

export const ServiceProvider = withRouter(ServiceInterface);
