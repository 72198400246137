import * as React from 'react';
import './textInput.scss';
import { forwardRef, useState } from 'react';

interface ITextInputProps {
  value: any;
  name: string;
  label?: string;
  type?: string;
  size?: number;
  maxlength?: number;
  className?: string;
  labelHide?: boolean;
  number?: boolean;
  decimals?: number;
  prefix?: string;
  isExist?: boolean;
  isEditable?: boolean;
  isAutofocus?: boolean;
  isRequired?: boolean;
  hiden?: boolean;
  show?: boolean;
  onChange: any;
  onCancel?: any;
  onPressEnter?: any;
  ref?: any;
  dataCy?: string;
}

const TextInput: React.FunctionComponent<ITextInputProps> = forwardRef((props: ITextInputProps, ref: any) => {
  const [selected, setSelected] = useState(false);
  const handleFocus = (event: any) => event.target.select();
  const handleOnChange = (e: any) => {
    let str = e.target.value;
    if (props.number) {
      const record = str.toString().charAt(str.length - (props.decimals ? 2 + props.decimals : 2));
      if (record === '.') {
        return;
      }
      str = str.length > 0 ? Number(str) : str;
    }
    props.onChange({ [props.name]: str });
  };

  if (!props.show && (props.hiden || (!props.isEditable && !props.value))) {
    return null;
  } else
    return (
      <div className={props.className}>
        {props.labelHide === undefined || props.labelHide === false ? (
          <label className={props.isEditable && props.isRequired ? 'required' : ''}>
            {props.label ? props.label.toUpperCase() : props.name.toUpperCase()}
            <span style={{ color: 'red' }}>{props.isExist ? 'Existe déjà' : null}</span>
          </label>
        ) : (
          ''
        )}
        <div
          className={`text-input-row row gapped
            ${selected ? 'selected' : ''} `}
        >
          <input
            className={`${props.isExist ? 'onError' : ''}`}
            autoFocus={props.isAutofocus}
            type={props.number ? 'number' : props.type || 'text'}
            name={props.name}
            size={props.size}
            disabled={!props.isEditable}
            value={props.value || ''}
            autoComplete="off"
            ref={ref}
            maxLength={props.maxlength}
            step={'0.01'}
            placeholder={props.label || props.name}
            onFocus={(event) => {
              handleFocus(event);
              setSelected(true);
            }}
            onBlur={() => setSelected(false)}
            onKeyDown={(e: any) => {
              if (props.onPressEnter && e.key === 'Enter') {
                props.onPressEnter();
              }
            }}
            onChange={(e) => handleOnChange(e)}
            data-cy={props.dataCy}
          />
          {props.prefix ? <div className="input-prefix">{props.prefix}</div> : null}
          {props.onCancel && props.value ? (
            <button tabIndex={1} disabled={!props.value} onClick={() => props.onCancel()}>
              <span className="material-icons t-grey">close</span>
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    );
});

export default TextInput;

interface ITUextInputProps {
  value: any;
  name: string;
  label?: string;
  placeholder?: string;
  size?: number;
  resize?: boolean;
  maxLength?: number;
  className?: string;
  type?: 'text' | 'password';
  prefix?: any;
  suffix?: any;
  error?: string;
  labelHide?: boolean;
  disabled?: boolean;
  autofocus?: boolean;
  required?: boolean;
  isHidden?: boolean;
  onChange: any;
  onCancel?: any;
  onPressEnter?: any;
  onFocusChange?: any;
  ref?: any;
  dataCy?: string;
}

export class TUextInput extends React.Component<ITUextInputProps, any> {
  state = {
    isEditing: false,
  };

  onChange(e: any) {
    this.props.onChange({ [this.props.name]: e.target.value });
  }

  setIsEditing(isEditing: boolean) {
    this.setState({ isEditing }, () => {
      if (this.props.onFocusChange) {
        this.props.onFocusChange(isEditing);
      }
    });
  }

  render() {
    const { isEditing } = this.state;
    const {
      name,
      label,
      value,
      placeholder,
      size,
      resize,
      disabled,
      autofocus,
      error,
      maxLength,
      className,
      type,
      labelHide,
      isHidden,
      suffix,
      prefix,
      required,
      onCancel,
      onPressEnter,
      dataCy,
    } = this.props;

    const errorMessage = error ? error : required && (!value || value.length <= 0) ? 'Requis' : '';

    const relativeSize =
      resize && value && value.length >= 0 ? (size ? (value.length > size ? value.length : size) : size) : size;

    return isHidden ? null : (
      <div className={`${className || ''} ${errorMessage ? 'error' : ''}  text-input `}>
        {!labelHide ? (
          <div className="row">
            <label>{label ? label.toUpperCase() : name.toUpperCase()}</label>
            <label className="t-red">{errorMessage}</label>
          </div>
        ) : null}
        <div
          className={`text-input_row ${suffix ? 'suffix' : ''} ${prefix ? 'prefix' : ''}`}
          style={{ width: !size ? '100%' : undefined }}
        >
          {suffix && !disabled ? <div className="text-input_suffix">{suffix}</div> : null}

          {isEditing ? (
            <input
              type={type || 'text'}
              name={name}
              defaultValue={value}
              disabled={disabled}
              size={resize ? relativeSize : size}
              placeholder={placeholder}
              maxLength={maxLength}
              autoComplete="off"
              onChange={(e: any) => this.onChange(e)}
              onBlur={() => {
                this.setIsEditing(false);
              }}
              style={{ width: !size ? '100%' : undefined }}
              onKeyDown={(e: any) => {
                if (onPressEnter && e.key === 'Enter') {
                  onPressEnter();
                }
              }}
              data-cy={dataCy}
            />
          ) : (
            <input
              type={'text' || type}
              name={name}
              value={value || ''}
              disabled={disabled}
              size={resize ? relativeSize : size}
              placeholder={placeholder}
              maxLength={maxLength}
              autoComplete="off"
              autoFocus={autofocus}
              style={{ width: !size ? '100%' : undefined }}
              onFocus={() => this.setIsEditing(true)}
              onClick={() => this.setIsEditing(true)}
              readOnly={true}
              data-cy={dataCy}
            />
          )}
          {prefix ? <div className="text-input_prefix">{prefix}</div> : null}
          {onCancel && value ? (
            <div>
              <button tabIndex={1} onClick={() => onCancel()}>
                <span className="material-icons t-grey">close</span>
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
