import React from 'react';
import Row from '../../../../../../_shared/component/row/row';

const PickerTypePanel: React.FC<any> = ({ pickerType, onPickerTypeChange }: any) => {
  return (
    <Row className="picker-type-panel">
      <button
        disabled={pickerType === 'ingredient'}
        className={`row gapped ${pickerType === 'ingredient' ? 't-green' : ''}`}
        onClick={() => onPickerTypeChange('ingredient')}
      >
        <span className="material-icons">spa</span>
        <label>Ingrédients</label>
      </button>
      <button
        disabled={pickerType === 'formule'}
        className={`row gapped ${pickerType === 'formule' ? 't-green' : ''}`}
        onClick={() => onPickerTypeChange('formule')}
      >
        <span className="material-icons">science</span>
        <label>Formules</label>
      </button>
      <button
        className={`row gapped ${pickerType === 'parser' ? 't-green' : ''}`}
        onClick={() => onPickerTypeChange('parser')}
        data-cy="PickerTypePanel-parser"
      >
        <span className="material-icons">notes</span>
        <label>Parser</label>
      </button>
    </Row>
  );
};

export default PickerTypePanel;
