import { Switch, Route } from 'react-router-dom';
import React from 'react';
import { ClientsPage } from './clients.page';
import { RedirectRoute } from '../../_core/routing/redirect.guard';
import { ClientPage } from './client.page';

export const ClientRouter: React.FC<any> = ({ match }: any) => {
  return (
    <Switch>
      <Route path={match.url} exact={true} component={ClientsPage} />
      <Route path={`${match.url}/:id`} exact={true} component={ClientPage} />
      <RedirectRoute path="*" redirection={'/clients'} />
    </Switch>
  );
};
