import React from 'react';
import { Switch } from 'react-router-dom';
import { DraftEditorProvider } from '../../_shared/providers/draft/draftEditor.provider';
import { ProtectedRoute } from '../../_core/routing/protected.guard';
import { DraftEditorPage } from './draftEditorPage/draftEditor.page';

export const OnEditionRouter: React.FC<any> = ({ match }: any) => {
  return (
    <Switch>
      <DraftEditorProvider>
        <ProtectedRoute path={match.url} exact={true} component={DraftEditorPage} />
        <ProtectedRoute path={`${match.url}/:id`} exact={true} component={DraftEditorPage} />
      </DraftEditorProvider>
    </Switch>
  );
};
