import React, { useEffect, useState } from 'react';
import {
  ARCHIVE_STATUS,
  BROUILLON_STATUS,
  DEVIS_STATUS,
  ENVOIE_STATUS,
  EXPEDIEE_STATUS,
  PAYEE_STATUS,
  NON_PAYEE_STATUS,
  R1_STATUS,
  R2_STATUS,
  VALIDEE_STATUS,
  TO_PRATICIEN,
  DEPOT_STATUS,
  COLISSIMO,
} from '../../../_shared/config/constants';
import { Redirect, useHistory } from 'react-router-dom';
import { usePrint } from '../../../_shared/providers/order/usePrint';
import { OrderCard } from '../components/orderCard/order.card';
import { OrderInfo } from '../components/orderInfo/order.info';
import { SpinLoader } from '../../../_shared/component/loader/loader';
import { SetToEditionButton } from './orderPanel/setToEdition.button';
import { SetToValideeButton } from './orderPanel/setToValidee.button';
import { SetToEnvoieButton } from './orderPanel/setToEnvoie.button';
import { SetToExpedieeButton } from './orderPanel/setToExpediee.button';
import { SetToPayeeButton } from './orderPanel/setToPayee.button';
import { useCopyOrder } from '../../../_shared/providers/order/useCopyOrder';
import { CopyOrderButton } from './orderPanel/copyOrder.button';
import { SetToR1Button } from './orderPanel/setToR1.button';
import { SetToR2Button } from './orderPanel/setToR2.button';
import { PrintR2Button } from './orderPanel/printR2.button';
import { PrintPanel, PRINT_ETIQUETTE, PRINT_FACTURE, PRINT_SHIPMENT } from '../components/printPanel/print.panel';
import { OrderComment } from '../components/orderComment/orderComment';
import { isDraftStatus } from '../../../_shared/services/order.service';
import { ShippingConfirmationModal } from '../components/shippingConfirmationModal/shippingConfirmation.modal';
import { ArchiveConfirmationModal } from '../components/archiveConfirmationModal/archiveConfirmation.modal';
import { CancelPaiementButton } from './orderPanel/cancelPaiement.button';
import { useQuery } from '../../../_core/services/service.context';
import { practitionerService } from '../../../_shared/services/practitioner.service';
import { Practitioner } from '../../../_shared/models/practitioner';
import { HistoryModal, PaiementModal } from './eventsPanel/events.panel';
import OrderEvent from '../../../_shared/models/event';
import Modal from '../../../_shared/component/modal/modal';
import { SetToSendPraticienButton } from './orderPanel/setToSendPractitioner.button';
import useCopyInClipboard from '../../../_shared/component/useCopyInClipboard';
import { checkMail } from '../../../_shared/utils';
import { ON_EDITION_PATH } from '../../../_shared/config/paths';
import { FactureModal } from './orderPanel/facture.modal';

const isOrderCommentEditable = (orderStatus: string) => {
  switch (orderStatus) {
    case R1_STATUS:
      return true;
    case R2_STATUS:
      return true;
    case NON_PAYEE_STATUS:
      return true;
    default:
      return false;
  }
};

interface IOrderDetailProps {
  order: any;
  isLoading: boolean;
  isPaiementOrderLoading?: boolean;
  onUpdateStatus?: any;
  onCommentChange?: any;
  onPaymentCommentChange?: any;
  onAddPaiement?: any;
  onGeneratePaiementOrder?: any;
}

export const OrderDetail: React.FC<IOrderDetailProps> = ({
  order,
  isLoading,
  isPaiementOrderLoading,
  onUpdateStatus,
  onCommentChange,
  onPaymentCommentChange,
  onAddPaiement,
  onGeneratePaiementOrder,
}: IOrderDetailProps) => {
  const [showSendPanel, setShowSendPanel] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showPaiementModal, setShowPaiementModal] = useState(false);
  const [copyModal, setCopyModal] = useState(false);

  const [factureModal, setFactureModal] = useState(false);

  const copyInCP = useCopyInClipboard();
  const history = useHistory();
  const print = usePrint();
  const copy = useCopyOrder();
  const query = useQuery();

  const copyCheck = () => {
    if (order.practitioner) {
      query(practitionerService.getPractitionerById(order.practitioner._id)).subscribe(
        (practitioner: Practitioner) => {
          if (practitioner.renouvellementRefuse) {
            setCopyModal(true);
          } else {
            copy(order.id);
          }
        },
        (error) => console.log(error),
      );
    } else {
      copy(order.id);
    }
  };

  const handlePrint = (status: string) => {
    if (status != PRINT_FACTURE) {
      return print(order.id, status);
    } else {
      setFactureModal(true);
    }
  };

  useEffect(() => {
    if (order && order.editStatus === BROUILLON_STATUS) history.push(`${ON_EDITION_PATH}/${order.id}`);
  }, [order]);

  return (
    <div className="full-height full-width relative b-grey">
      <Modal on={copyModal} onCancel={() => setCopyModal(false)}>
        <div className="column center p-small b-white gapped rounded">
          <div className="br-bottom t-grey t-large p-medium">Le praticien refuse les renouvellements.</div>
          <div className="t-grey light t-large ">Voulez-vous tout de même continuer ?</div>
          <div className="row gapped ">
            <button onClick={() => copy(order.id)} className="button-validate t-large">
              oui
            </button>
            <button onClick={() => setCopyModal(false)} className="button-alert t-large">
              non
            </button>
          </div>
        </div>
      </Modal>

      <FactureModal isDisplayed={factureModal} onHide={() => setFactureModal(false)} orderId={order?.id} />

      <HistoryModal
        events={order?.events}
        isDisplayed={showHistory}
        onHide={() => setShowHistory(false)}
        onAddPaiement={onAddPaiement}
      />
      <PaiementModal
        events={order?.events.filter((e: OrderEvent) => e.type === 'PAIEMENT')}
        isDisplayed={showPaiementModal && order.editStatus !== PAYEE_STATUS}
        onHide={() => setShowPaiementModal(false)}
        onAddPaiement={onAddPaiement}
        maxAmount={order?.reste}
      />
      <ShippingConfirmationModal
        showModal={showSendPanel}
        onHideModal={() => setShowSendPanel(false)}
        onValidate={() => onUpdateStatus(EXPEDIEE_STATUS)}
      />
      <ArchiveConfirmationModal
        orderCode={order?.code}
        isDisplayed={order && showArchiveModal}
        onHideModal={() => setShowArchiveModal(false)}
        onValidate={() => onUpdateStatus(ARCHIVE_STATUS)}
      />
      {isLoading ? (
        <div className={'full-height row center'}>
          <SpinLoader size={'14em'} thickness={'4px'} color={'grey'} />
        </div>
      ) : order ? (
        <OrderCard order={order} onRemoveOrder={() => setShowArchiveModal(true)}>
          <div className="card">
            <div className="row spaced br-bottom p-small">
              <div className="t-large light t-grey p-medium br-right">RÉSUMÉ</div>
              <div className="row p-small gapped">
                {isDraftStatus(order.editStatus) ? (
                  <SetToEditionButton disabled={false} onSetToEdition={onUpdateStatus} />
                ) : (
                  <CopyOrderButton minified={false} onCopyOrder={() => copyCheck()} />
                )}
                {order.events?.length ? (
                  <button className="button-lgrey t-grey light" onClick={() => setShowHistory(true)}>
                    <span className="material-icons">history</span>
                    <span>Historique</span>
                  </button>
                ) : null}
                {!isDraftStatus(order.editStatus) ? (
                  order.paiement.type === 'prepaye' || order.paiement.type === 'praticien' ? null : order.editStatus ===
                    PAYEE_STATUS ? null : (
                    <>
                      <button className="button-lgrey light" onClick={() => setShowPaiementModal(true)}>
                        <span className="material-icons">add_circle_outline</span>
                        <span>Paiements</span>
                      </button>
                    </>
                  )
                ) : null}
                {order.paiement.type === 'prepaye' || order.paiement.type === 'praticien' ? (
                  <div className="t-red">{order.paiement.type === 'prepaye' ? 'PRÉPAYÉE' : 'COMPTE PRATICIEN'}</div>
                ) : null}
              </div>
            </div>
            <div>
              <div className="column b-white p-medium">
                {isDraftStatus(order.editStatus) && order.practitioner && order.practitioner.prescription ? (
                  <div className="row gapped center t-red">
                    <span className="material-icons">system_update_alt</span>{' '}
                    <div>Joindre la prescription dans le colis du client.</div>
                  </div>
                ) : null}
                <div className="p-small">
                  <OrderComment
                    placeholder="Commentaire de commande"
                    commentaire={order.commentaire}
                    lockable={isOrderCommentEditable(order.editStatus)}
                    onChange={(comment) => onCommentChange(comment)}
                  />
                </div>
                <div className="p-small" style={{ paddingTop: '0' }}>
                  <OrderComment
                    label="Commentaire de paiement"
                    placeholder="Commentaire de paiment"
                    commentaire={order.commentairePaiement}
                    minRow={2}
                    lockable={isOrderCommentEditable(order.editStatus)}
                    onChange={(comment) => onPaymentCommentChange(comment)}
                  />
                </div>
                <OrderInfo order={order} />
                <div>
                  <OrderButtonPanel
                    editStatus={order.editStatus}
                    updateStatus={onUpdateStatus}
                    onShipping={() => setShowSendPanel(true)}
                    printOrder={handlePrint}
                    shipmentType={order.shipment ? order.shipment.type : 'colissimo'}
                    disabled={false}
                  />
                  <div>
                    {order.editStatus !== PAYEE_STATUS ? (
                      <div className="row gapped p-spaced">
                        <span className="material-icons t-large t-lgrey">credit_card</span>
                        <span className="t-lgrey">Lien de paiement</span>

                        {order.paiementURL ? (
                          <div className="p-medium rounded selectable" onClick={() => copyInCP(order.paiementURL)}>
                            <span className="t-grey light">{order.paiementURL}</span>
                          </div>
                        ) : (
                          <>
                            {checkMail(order.client?.mail) ? (
                              <button
                                className="button-lgrey"
                                disabled={isPaiementOrderLoading}
                                onClick={() => onGeneratePaiementOrder('MAIL')}
                              >
                                <span className="material-icons">mail_outline</span>
                                <span>MAIL</span>
                              </button>
                            ) : null}
                            <button
                              className="button-lgrey"
                              disabled={isPaiementOrderLoading}
                              onClick={() => onGeneratePaiementOrder('URL')}
                            >
                              <span className="material-icons">link</span>
                              <span>URL</span>
                            </button>
                          </>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OrderCard>
      ) : null}
    </div>
  );
};

interface IOrderButtonPanelProps {
  editStatus: string;
  shipmentType: string;
  updateStatus: any;
  onShipping: any;
  printOrder: any;
  disabled: boolean;
}

function renderPannelButton({
  editStatus,
  shipmentType,
  updateStatus,
  onShipping,
  printOrder,
  disabled,
}: IOrderButtonPanelProps) {
  switch (editStatus) {
    case VALIDEE_STATUS:
      return (
        <>
          <div className="row gapped spaced p-medium">
            <SetToEnvoieButton disabled={disabled} onSetToEnvoie={() => updateStatus(ENVOIE_STATUS)} />
          </div>
          <PrintPanel printTypes={['infos', 'latin']} onPrintOrder={printOrder} />
        </>
      );
    case ENVOIE_STATUS:
      return (
        <>
          <div className="row gapped spaced p-medium">
            <SetToExpedieeButton disabled={disabled} onSetToExpediee={onShipping} />
          </div>
          <PrintPanel printTypes={['infos', 'latin']} onPrintOrder={printOrder} />
        </>
      );
    case DEPOT_STATUS:
      // for depot status, we can  manually force expedition outside the bordereau generation in order
      // to manage DOM/TOM and foreign addresses
      return (
        <>
          <div className="row gapped spaced p-medium">
            <SetToExpedieeButton label="Déposer" disabled={disabled} onSetToExpediee={onShipping} />
          </div>
          <PrintPanel
            printTypes={[PRINT_FACTURE, PRINT_ETIQUETTE, shipmentType !== COLISSIMO ? '' : PRINT_SHIPMENT]}
            onPrintOrder={printOrder}
          />
        </>
      );
    case EXPEDIEE_STATUS:
      return <></>;
    case DEVIS_STATUS:
      return (
        <>
          <div className="row gapped spaced p-medium">
            <SetToValideeButton disabled={disabled} onSetToValidee={() => updateStatus(VALIDEE_STATUS)} />
          </div>
          <PrintPanel printTypes={['devis']} onPrintOrder={printOrder} />
        </>
      );
    case NON_PAYEE_STATUS:
      return (
        <>
          <div className="row gapped spaced p-medium">
            <SetToPayeeButton onSetToPayee={() => updateStatus(PAYEE_STATUS)} />
            <SetToSendPraticienButton onSetToSendPraticien={() => updateStatus(TO_PRATICIEN)} />
            <SetToR1Button onUpdateToR1={() => updateStatus(R1_STATUS)} />
          </div>
          <PrintPanel printTypes={[PRINT_FACTURE, PRINT_ETIQUETTE, "latin"]} onPrintOrder={printOrder} />
        </>
      );
    case R1_STATUS:
      return (
        <>
          <div className="row gapped p-medium">
            <SetToPayeeButton onSetToPayee={() => updateStatus(PAYEE_STATUS)} />
            <SetToSendPraticienButton onSetToSendPraticien={() => updateStatus(TO_PRATICIEN)} />
            <SetToR2Button onUpdateToR2={() => updateStatus(R2_STATUS)} />
          </div>
          <PrintPanel printTypes={[PRINT_FACTURE, PRINT_ETIQUETTE]} onPrintOrder={printOrder} />
        </>
      );
    case R2_STATUS:
      return (
        <>
          <div className="row gapped p-medium">
            <SetToPayeeButton onSetToPayee={() => updateStatus(PAYEE_STATUS)} />
            <SetToSendPraticienButton onSetToSendPraticien={() => updateStatus(TO_PRATICIEN)} />
            <PrintR2Button
              minified={false}
              onPrintR2={() => {
                printOrder(R2_STATUS);
              }}
            />
          </div>
          <PrintPanel printTypes={[PRINT_FACTURE, PRINT_ETIQUETTE]} onPrintOrder={printOrder} />
        </>
      );
    case PAYEE_STATUS:
      return (
        <>
          <div className="p-medium">
            <CancelPaiementButton onSetToNonPayee={updateStatus} />
          </div>
          <PrintPanel printTypes={[PRINT_FACTURE, PRINT_ETIQUETTE]} onPrintOrder={printOrder} />
        </>
      );
    case BROUILLON_STATUS:
      return null;
    case ARCHIVE_STATUS:
      return null;
    default:
      return <Redirect to="/commandes" />;
  }
}

const OrderButtonPanel: React.FC<IOrderButtonPanelProps> = (props: IOrderButtonPanelProps) => {
  return renderPannelButton(props);
};
