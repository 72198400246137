import React, { useContext, useEffect, useRef, useState } from 'react';
import { CompositionIngredientList } from '../compositionIngredientList/compositionIngredient.list';
import { AddCompositionPanel } from '../compositionPick/composition.picker';
import { CompositionProductList } from '../compositionProductList/compositionProducts.list';
import { CompositionHeader } from './compositionHeader/composition.header';
import { IngredientParser } from '../ingredientSelector/ingredientParser/ingredient.parser';
import { CSSTransition } from 'react-transition-group';
import { DraftEditorContext } from '../../../../../_shared/providers/draft/draftEditor.provider';
import useCompositionOnEdition from '../../../../../_shared/providers/draft/useCompositionOnEdition';

export const CompositionEditor: React.FC<any> = () => {
  const { compositionSelected } = useContext(DraftEditorContext);
  const { composition, updateComposition } = useCompositionOnEdition();
  const [pickerType, setPickerType] = useState('parser' as 'ingredient' | 'formule' | 'parser');
  const [showParser, setShowParser] = useState(false);
  const [parserResult, setParserResult] = useState([] as any[]);

  const parserAreaRef = useRef<HTMLDivElement>(null);

  const handlePickerType = (pickerType: 'ingredient' | 'formule' | 'parser') => {
    setShowParser(pickerType === 'parser');
    setPickerType(pickerType);
  };

  useEffect(() => {
    if (!composition || !composition.ingredients || !composition.ingredients.length) {
      handlePickerType('parser');
    } else {
      handlePickerType('ingredient');
    }
    setParserResult([]);
  }, [compositionSelected]);

  return composition ? (
    <div className="composition-editor-box">
      <div className="composition-editor">
        <div className="b-white">
          <CompositionHeader />
        </div>
        <div className="composition-editor-content relative">
          {composition.forme.code === 'LB' ? (
            <CompositionProductList forme={composition.forme} products={composition.ingredients} />
          ) : (
            <>
              <CSSTransition in={showParser} timeout={0} classNames="parser-transition" unmountOnExit>
                <div
                  style={{
                    position: 'absolute',
                    zIndex: 99,
                    width: '300px',
                    right: 0,
                    height: '100%',
                  }}
                >
                  <IngredientParser
                    formeId={composition.forme.id}
                    parserText={composition.parser || ''}
                    parserAreaRef={parserAreaRef}
                    onUpdateComposition={updateComposition}
                    onParserSuggestions={setParserResult}
                    onClose={() => setShowParser(false)}
                  />
                </div>
              </CSSTransition>
              <CompositionIngredientList
                forme={composition.forme}
                ingredients={composition.ingredients}
                pickerType={pickerType}
                ingredientSuggestions={parserResult}
                onChangePickerType={handlePickerType}
                onParserFocus={() => setShowParser(true)}
              />
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="b-lgrey bordered column center full-height">
      <div>Pour commencer veuillez</div>
      <div>ajouter une préparation</div>
      <div>
        <AddCompositionPanel />
      </div>
    </div>
  );
};
