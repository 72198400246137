import { useEffect } from 'react';

const useEscape = (callback: any) => {
  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.keyCode === 27) {
        callback();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [callback]);
};

export default useEscape;
