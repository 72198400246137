import React, { useRef } from 'react';
import useOutsideClick from '../useClickOutside';

const OutClickDiv: React.FC<any> = (props: any) => {
  const htmlRef = useRef(null);
  useOutsideClick(htmlRef, () => props.onClickOutside());
  return (
    <div {...props} ref={htmlRef}>
      {props.children}
    </div>
  );
};

export default OutClickDiv;
