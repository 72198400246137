import React from 'react';
import { withRouter } from 'react-router-dom';

import { authService } from '../services/auth.service';
import { IAuthContext, AuthContext } from './auth.context';
import { LoginPage } from '../login/login.page';

class AuthInterface extends React.Component<any, IAuthContext> {
  state: IAuthContext = {
    isAuth: false,
    isLoading: false,
    authError: null,
    login: (login: string, password: string) => this.login(login, password),
    logout: () => this.logout(),
  };

  UNSAFE_componentWillMount(): void {
    const token = this.getCookie('connect.sid');
    if (token) {
      this.setState({ isAuth: true });
    }
  }

  getCookie(cname: string) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  login = (login: string, password: string) => {
    this.setState({ isLoading: true });
    authService.login(login, password).subscribe(
      () => {
        this.setState({ isAuth: true, isLoading: false, authError: null });
        if (caches) {
          caches.keys().then(function (names) {
            for (const name of names) caches.delete(name);
          });
        }
        window.location.reload(true);
      },
      (err: any) => {
        if (err.status === 401) {
          this.setState({
            isLoading: false,
            isAuth: false,
            authError: 'Login ou mot de passe incorrect',
          });
        } else {
          this.setState({
            isLoading: false,
            isAuth: false,
            authError: 'Erreur du serveur',
          });
        }
      },
    );
  };

  logout = () => {
    this.setState({ isAuth: false });
    authService.logout().subscribe(
      () => {
        this.setState({
          isLoading: false,
          isAuth: false,
          authError: null,
        });
      },
      () => {
        this.setState({
          isLoading: false,
          authError: 'Erreur lors de la déconnexion',
        });
      },
    );
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          isAuth: this.state.isAuth,
          isLoading: this.state.isLoading,
          authError: this.state.authError,
          login: this.login,
          logout: this.logout,
        }}
      >
        {this.state.isAuth ? this.props.children : <LoginPage />}
      </AuthContext.Provider>
    );
  }
}

export const AuthProvider = withRouter(AuthInterface);
