import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthConsumer } from './auth.context';

export const RedirectRoute = ({ component, redirection, ...rest }: any) => (
  <AuthConsumer>
    {({ isAuth }) => (
      <Route render={() => (isAuth ? <Redirect to={redirection} /> : <Redirect to="/login" />)} {...rest} />
    )}
  </AuthConsumer>
);
