import React, { useContext } from 'react';

import Row from '../../../../../../../_shared/component/row/row';
import { TUextInput } from '../../../../../../../_shared/component/textInput/textInput.component';

import useCompositionOnEdition from '../../../../../../../_shared/providers/draft/useCompositionOnEdition';
import { DraftEditorContext } from '../../../../../../../_shared/providers/draft/draftEditor.provider';

const DestinaireInput: React.FC = () => {
  const { client } = useContext(DraftEditorContext);
  const { composition, updateComposition } = useCompositionOnEdition();

  const setClientAsDestinataire = () => {
    if (client) {
      updateComposition({ destinataire: `${client.prenom} ${client.nom}` });
    }
  };

  return (
    <Row className="destinataire-input">
      <TUextInput
        name={'destinataire'}
        value={composition.destinataire || ''}
        suffix={<span className="material-icons t-large t-lgrey">person</span>}
        className="p-small "
        size={40}
        resize={true}
        onChange={updateComposition}
        prefix={
          <button tabIndex={1} onClick={setClientAsDestinataire}>
            <span className="material-icons t-medium t-lgrey">replay</span>
          </button>
        }
      />
    </Row>
  );
};

export default DestinaireInput;
