import * as qs from 'query-string';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useQueryParams = (params: any, onInit: any) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const query = qs.parse(location.search, { arrayFormat: 'comma' });
    onInit(query);
  }, []);

  useEffect(() => {
    const { offset, filters, sort, direction } = params;
    history.replace({
      pathname: location.pathname,
      search: qs.stringify(
        {
          offset,
          sort,
          direction,
          ...filters,
        },
        { arrayFormat: 'comma' },
      ),
    });
  }, [params]);
};

export default useQueryParams;
