import React from 'react';
import './orderStatus.card.css';
import {
  BROUILLON_STATUS,
  DEPOT_STATUS,
  DEVIS_STATUS,
  ENVOIE_STATUS,
  EXPEDIEE_STATUS,
  NON_PAYEE_STATUS,
  PAYEE_STATUS,
  R1_STATUS,
  R2_STATUS,
  VALIDEE_STATUS,
} from '../../../../_shared/config/constants';
import moment from 'moment';

interface IOrderHeaderCard {
  status: string;
  orderCode: string;
  practitionerCode: string | null;
  dates?: any;
  shipmentType: string;
}

const formatDateLong = (date: any) =>
  moment(date || new Date())
    .locale('fr')
    .format('DD MMMM YYYY à HH:mm');

const formatDate = (date: any) =>
  moment(date || new Date())
    .locale('fr')
    .format('DD MMMM YYYY');

const orderStatusIcon = (status: string, dates: any) => {
  switch (status) {
    case BROUILLON_STATUS:
      return {
        color: '#568b6b',
        icon: 'edit',
        label: `Créée le ${formatDateLong(dates.createdAt)}`,
      };
    case DEVIS_STATUS:
      return {
        color: '#696969',
        icon: 'list',
        label: `Créée le ${formatDate(dates.createdAt)}`,
      };
    case VALIDEE_STATUS:
      return {
        color: '#568b6b',
        icon: 'flip_camera_android',
        label: `Validée le ${formatDate(dates.validationDate)}`,
      };
    case ENVOIE_STATUS:
      return {
        color: '#c5ae36',
        icon: 'mail_outline',
        label: `Validée le ${formatDate(dates.validationDate)}`,
      };
    case DEPOT_STATUS:
      return {
        color: '#c5ae36',
        icon: 'content_paste_go',
        label: `Validée le ${formatDate(dates.validationDate)}`,
      };
    case EXPEDIEE_STATUS:
      return {
        color: '#4985c5',
        icon: 'send',
        label: `Validée le ${formatDate(dates.validationDate)}`,
      };
    case NON_PAYEE_STATUS:
      return {
        color: '#9bb44a',
        icon: 'query_builder',
        label: `Envoyée le ${formatDate(dates.createdAt)}`,
      };
    case R1_STATUS:
      return {
        color: '#feb96f',
        icon: 'error_outline',
        label: `Relancée le ${formatDate(dates.relanceDate)}`,
      };
    case R2_STATUS:
      return {
        color: '#fe5f54',
        icon: 'warning',
        label: `Relancée le ${formatDate(dates.relanceDate)}`,
      };
    case PAYEE_STATUS:
      return {
        color: 'green',
        icon: 'done_outline',
        label: `Payée le ${formatDate(dates.paiementDate)}`,
      };
    default:
      return {
        color: 'lighgrey',
        icon: 'cancel',
      };
  }
};

export const OrderStatusCard: React.FC<IOrderHeaderCard> = ({
  status,
  orderCode,
  practitionerCode,
  dates,
  shipmentType,
}: IOrderHeaderCard) => {
  const icon = orderStatusIcon(status, dates);

  return (
    <div className="order-status-card">
      <div className="row">
        <div className="order-status-icon column center" style={{ backgroundColor: icon.color }}>
          <i className="material-icons">{icon.icon || 'edit'}</i>
        </div>
      </div>
      <div className="column p-spaced">
        <div className="light">COMMANDE</div>
        <div className="t-xlarge bold">N° &nbsp; {`${practitionerCode ? practitionerCode : 'RA'}${orderCode}`}</div>
        <div className="t-small">Status</div>
        <div className="bold">{status.toUpperCase()}</div>
        <div className="t-medium light">{icon.label}</div>
        <div className="light">{shipmentType.toUpperCase()}</div>
      </div>
    </div>
  );
};
