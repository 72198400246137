export const getAllPays = function () {
  return [
    'France',
    'Albanie',
    'Allemagne',
    'Andorre',
    'Autriche',
    'Belgique',
    'Biélorussie',
    'Bosnie-Herzégovine',
    'Bulgarie',
    'Chypre',
    'Croatie',
    'Danemark',
    'Espagne',
    'Estonie',
    'Finlande',
    'France',
    'Grèce',
    'Hongrie',
    'Irlande',
    'Islande',
    'Italie',
    'Kazakhstan',
    'Kosovo',
    'Lettonie',
    'Liechtenstein',
    'Lituanie',
    'Luxembourg',
    'Macédoine du Nord',
    'Malte',
    'Moldavie',
    'Monaco',
    'Monténégro',
    'Norvège',
    'Pays-Bas',
    'Pologne',
    'Portugal',
    'République tchèque',
    'Roumanie',
    'Royaume-Uni',
    'Russie',
    'Saint-Marin',
    'Serbie',
    'Slovaquie',
    'Slovénie',
    'Suède',
    'Suisse',
    'Turquie',
    'Ukraine',
    'Vatican',
  ];
};
