import * as React from 'react';
import { IFormFieldProps } from '../ingredientForm';

interface ILatinInputProps extends IFormFieldProps<string> {
  latin: string | undefined;
}

const LatinInput: React.FunctionComponent<ILatinInputProps> = (props: ILatinInputProps) => {
  return (
    <div>
      <label>LATIN </label>
      <input
        className={props.isExist ? 'onError' : ''}
        type="text"
        name="latin"
        disabled={!props.isEditable}
        value={props.latin}
        placeholder="Latin"
        autoComplete="off"
        onChange={(e) => props.onChange(e)}
      />
    </div>
  );
};

export default LatinInput;
