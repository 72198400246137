import React, { useState } from 'react';
import Checkbox from '../../../../_shared/component/checkBox/checkbox';
import Modal from '../../../../_shared/component/modal/modal';
import { TextArea } from '../../../../_shared/component/textArea/textArea.component';
import { usePrint } from '../../../../_shared/providers/order/usePrint';
import { PRINT_FACTURE } from '../../components/printPanel/print.panel';

interface IFactureModal {
  isDisplayed: boolean;
  onHide: any;
  orderId: string;
}

const FactureModal: React.FC<IFactureModal> = ({ isDisplayed, onHide, orderId }: IFactureModal) => {
  const [noTVA, setNoTVA] = useState(false);
  const [factureComment, setFactureComment] = useState('');

  const print = usePrint();

  return (
    <Modal on={isDisplayed} onCancel={onHide}>
      <div className="column p-small b-white gapped rounded print-facture-modal full-width p-medium">
        <div className="row gapped full-width ">
          <label className="t-large">Facture B2B (sans TVA)</label>
          <Checkbox
            name="printFacture"
            value={noTVA}
            onChange={(e: any) => {
              setNoTVA(e);
            }}
          />
        </div>
        <label className="t-large">Indications supplémentaires :</label>
        <TextArea
          className="full-width"
          name="factComment"
          value={factureComment}
          minRows={2}
          onChange={({ factComment }: any) => setFactureComment(factComment)}
        />
        <button
          onClick={() => {
            onHide();
            print(orderId, PRINT_FACTURE, noTVA, factureComment);
          }}
          className="button-validate t-large"
        >
          Générer la facture
        </button>
      </div>
    </Modal>
  );
};

export { FactureModal };
