import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { PractitionersPage } from './practitioners.page';

export const PractitionerRouter: React.FC<any> = ({ match }: any) => {
  return (
    <Switch>
      <Route path={match.url} exact component={PractitionersPage} />
    </Switch>
  );
};
