import { useContext, useEffect, useState } from 'react';
import { ingredientService } from '../../services/ingredient.service';
import { ServiceContext } from '../../../_core/services/service.context';

export const useIngredientSuggestions = (filter: string, formeId: string) => {
  const [suggestions, setSuggestions] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const { query } = useContext(ServiceContext);
  const updateSuggestions = (update: any, index: number) => {
    const suggests = [...suggestions];
    suggests[index].suggestion = { ...suggests[index].suggestion, ...update };
    setSuggestions(suggests);
  };

  useEffect(() => {
    if (filter.length > 0 && query) {
      setIsLoading(true);
      query(ingredientService.getSuggestions(filter, formeId))
        .subscribe(
          (res) => {
            if (res.length) {
              res.map((suggestion: any) => (suggestion.suggestion.proportion = 0));
            }
            setSuggestions([]);
            setSuggestions([...res]);
          },
          (err) => console.log(err),
        )
        .add(() => setIsLoading(false));
    } else {
      setSuggestions([]);
    }
  }, [filter]);

  return { suggestions, updateSuggestions, isLoading };
};
