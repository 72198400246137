import { from, Observable, throwError } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { BASE_URL } from '../config/constants';

export function getAllForme(): Observable<any> {
  return from(
    fetch(`${BASE_URL}/forme`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    }),
  ).pipe(
    flatMap((response: Response) => {
      if (response.ok) {
        return response.json();
      } else {
        return throwError({
          status: response.status,
          message: response.text(),
        });
      }
    }),
  );
}
