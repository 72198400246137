import React from 'react';
import { Client } from '../../../../../../_shared/models/client';

interface IClientSuggestion {
  client: Client;
}

export const ClientSuggestion: React.FC<IClientSuggestion> = ({ client }: IClientSuggestion) => {
  return (
    <div className="l-align t-medium">
      <div>{client.num}</div>
      <div>
        <b>
          {client.nom} {client.prenom}
        </b>
      </div>
      <div>{client.fixe}</div>
      <div>{client.mobile}</div>
      <div>{client.mail}</div>
    </div>
  );
};
