import React, { useContext, useState } from 'react';
import { ingredientService } from '../../../../../../_shared/services/ingredient.service';
import { ServiceContext } from '../../../../../../_core/services/service.context';
import { SpinLoader } from '../../../../../../_shared/component/loader/loader';
import { TextArea } from '../../../../../../_shared/component/textArea/textArea.component';
import Row from '../../../../../../_shared/component/row/row';
import useEscape from '../../../../../../_shared/component/useEscape';

interface IIngredientParserProps {
  formeId: string;
  parserText: string;
  parserAreaRef: any;
  onParserSuggestions: any;
  onUpdateComposition: any;
  onClose: any;
}

export const IngredientParser: React.FC<IIngredientParserProps> = (props: IIngredientParserProps) => {
  const { loading, parse } = useIngredientParser(props.formeId);
  const lines = (props.parserText || '').split('\n').reduce(
    (acc: any, current: string) => {
      if (current.trim().length <= 0) {
        acc.lines.push('-');
        return acc;
      } else {
        acc.lines.push(acc.i);
        acc.i += 1;
        return acc;
      }
    },
    { lines: [], i: 1 },
  ).lines;

  const parseText = () => {
    parse(props.parserText, false, (ingredients: any) => {
      props.onParserSuggestions(ingredients);
      props.onClose();
    });
  };

  useEscape(() => props.onClose());

  return (
    <form className="ingredients-parser rounded full-height">
      <Row className="t-large light b-grey br-bottom t-grey gapped" align="spaced">
        <Row>
          <span className="material-icons">notes</span>
          <div>PARSER DU TEXTE</div>
        </Row>
        <button type="button" className="material-icons b-grey" onClick={props.onClose}>
          close
        </button>
      </Row>
      <div className="parser-area p-medium" style={{ display: 'grid', gridTemplateColumns: '0fr 1fr' }}>
        <div className="p-small r-align parser-area_index">
          {lines.map((value: any, i: number) => (
            <div className="t-grey light" key={'parser-line-' + i}>
              {value}.
            </div>
          ))}
        </div>
        <TextArea
          name={'parser'}
          value={props.parserText}
          minRows={8}
          autofocus={true}
          onChange={(e: any) => {
            props.onUpdateComposition(e);
          }}
          reference={props.parserAreaRef}
          dataCy="IngredientParser-input"
        />
      </div>
      <div className="row gapped p-medium">
        <button
          className="addButton"
          type={'button'}
          disabled={!props.parserText || props.parserText.length <= 0}
          onClick={parseText}
          data-cy="IngredientParser-Parser"
        >
          Parser
        </button>
        {loading ? <SpinLoader size={'1em'} thickness={'1px'} /> : null}
      </div>
    </form>
  );
};

const useIngredientParser = (formeId: string) => {
  const { query } = useContext(ServiceContext);
  const [loading, setLoading] = useState(false);

  const parse = (parserText: string, isBullet: boolean, callback: any) => {
    setLoading(true);
    query(ingredientService.parse(parserText, isBullet, formeId))
      .subscribe(
        (suggestions: any[]) => {
          callback(suggestions);
        },
        (err) => console.log(err),
      )
      .add(() => setLoading(false));
  };

  return {
    loading,
    parse,
  };
};
