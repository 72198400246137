import * as React from 'react';
import { IFormFieldProps } from '../ingredientForm';

interface IPriceSelectorProps extends IFormFieldProps<any> {
  codesprixList: any[];
  codeprix: { code: string | null; id: string | null };
}

const PriceSelector: React.FunctionComponent<IPriceSelectorProps> = (props: IPriceSelectorProps) => {
  const { codesprixList = [], codeprix, onChange } = props;

  const handlePriceSelected = (event: any) => {
    const index = codesprixList.findIndex((p) => p.id === event.target.value);
    return {
      target: {
        name: 'codeprix',
        value: codesprixList[index],
      },
    };
  };

  const codeId = codeprix && codeprix.id ? codeprix.id : codesprixList[0].id;

  return (
    <div>
      <label>CODE PRIX</label>
      {props.codesprixList.length > 0 ? (
        <select
          value={codeId}
          disabled={!props.isEditable}
          onChange={(e: any) => {
            onChange(handlePriceSelected(e));
          }}
        >
          {codesprixList.map((price) => {
            return (
              <option key={price.id} value={price.id}>
                {price.code}
              </option>
            );
          })}
        </select>
      ) : (
        <div>chargement</div>
      )}
    </div>
  );
};

export default PriceSelector;
