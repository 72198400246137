import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { SearchBar } from '../../../_shared/component/searchBar/searchBar';
import { orderService } from '../../../_shared/services/order.service';
import { ServiceContext } from '../../../_core/services/service.context';
import { SetToPayeeButton } from '../orderPage/orderPanel/setToPayee.button';
import { PAYEE_STATUS } from '../../../_shared/config/constants';
import { OrderInfo } from '../components/orderInfo/order.info';
import { OrderCard } from '../components/orderCard/order.card';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { OrderComment } from '../components/orderComment/orderComment';
import useDebounce from '../../../_shared/component/useDebounce';
import { ORDERS_PATH } from '../../../_shared/config/paths';
import { OrdersTypeFilterPanel } from '../ordersPage/ordersTypeFilterPanel/ordersTypeFilter.panel';

export const PaiementScanPage: React.FC<any> = () => {
  const [filter, setFilter] = useState('');
  const [order, setOrder] = useState(null as any | null);
  const [isLoading, setIsLoading] = useState(false);
  const { query } = useContext(ServiceContext);
  const history = useHistory();
  const location = useLocation();

  const debouncedFilter = useDebounce(filter, 400);
  const inputRef = useRef(null as any);

  const focus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  };

  const updateStatus = (status: string) => {
    if (order.id) {
      query(orderService.updateOrderStatus([order.id], status)).subscribe(
        () => {
          setOrder({ ...order, editStatus: status });
          focus();
        },
        (err) => console.log(err),
      );
    }
  };

  const updateComment = (comment: string) => {
    if (order.id) {
      setIsLoading(true);
      query(orderService.updateOrderComment(order.id, comment), {
        notify: 'Commentaire mis à jour',
      })
        .subscribe(
          () => {
            setOrder({ ...order, commentaire: comment });
          },
          (err) => console.log(err),
        )
        .add(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    if (location.search.length > 0) setFilter(location.search.substr(1));
  }, []);

  useEffect(() => {
    if (debouncedFilter.length > 0) {
      setIsLoading(true);
      query(orderService.getOrderByNumber(debouncedFilter), { noAlert: true })
        .subscribe(
          (res) => {
            if (location.search !== `?${debouncedFilter}`)
              history.replace({
                search: `?${debouncedFilter}`,
              });
            setOrder(res);
          },
          () => {
            setOrder(null);
            history.replace({
              pathname: location.pathname,
            });
          },
        )
        .add(() => setIsLoading(false));
    } else {
      setOrder(null);
      history.replace({
        pathname: location.pathname,
      });
    }
  }, [debouncedFilter]);

  return (
    <div className="orders-layout">
      <OrdersTypeFilterPanel
        disabled={isLoading}
        onHandleStatus={(status: string[]) => history.push(`${ORDERS_PATH}?status=${status.join(',')}`)}
        status={''}
      />
      <div className="page b-lgrey">
        {debouncedFilter.length > 0 ? (
          order ? (
            <OrderCard order={order} />
          ) : (
            <div className="full-height b-grey column center">
              <div className="t-xlarge">
                <i className="material-icons t-xlarge t-red">error_outline</i>
              </div>
              <div>{"La commande n'existe pas"}</div>
            </div>
          )
        ) : (
          <div className="full-height b-grey column center">
            <div className="t-xlarge">
              <i className="material-icons t-xlarge t-green">rss_feed</i>
            </div>
            <div>Veuillez scanner ou rentrer un code</div>
          </div>
        )}
        <div
          style={{
            position: 'sticky',
            bottom: '2em',
            marginRight: '2em',
            zIndex: 9999,
          }}
          className="row center "
        >
          <div className="fit-width b-white bordered rounded column">
            {order ? (
              <div className="column gapped spaced p-small">
                <OrderComment
                  commentaire={order.commentaire}
                  lockable={order.status !== PAYEE_STATUS}
                  onChange={(comment) => updateComment(comment)}
                />
                <div className="b-grey">
                  <OrderInfo order={order} />
                </div>
                <div className="row end">
                  <Link className="row fit-width p-spaced t-grey" to={`${ORDERS_PATH}/${order.id}`}>
                    <span>Consulter la commande</span>
                    <span className="material-icons">search</span>
                  </Link>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className={'fit-width b-white p-medium gapped row'}>
              <SearchBar
                filter={filter}
                autofocus
                placeholder={'N° commande'}
                onChange={setFilter}
                classname={'t-large'}
                ref={inputRef}
                onFilterRemove={() => {
                  setFilter('');
                  focus();
                }}
              />
              <SetToPayeeButton
                disabled={!order || isLoading || order.editStatus === PAYEE_STATUS}
                onSetToPayee={() => updateStatus(PAYEE_STATUS)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
