import { useContext } from 'react';
import { ServiceContext } from '../../../_core/services/service.context';
import { orderService } from '../../services/order.service';
import { tap } from 'rxjs/operators';

export const usePrintMulti = () => {
  const { query } = useContext(ServiceContext);
  const printMulti = (ids: string[], status: string, callback?: any) => {
    if (query) {
      query(orderService.printMultiOrders(ids, status))
        .pipe(
          tap((res: any) => {
            const file = new File([res], 'commande.pdf', {
              type: 'application/pdf',
            });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }),
        )
        .subscribe(
          () => {
            console.log('done');
            callback && callback();
          },
          (err) => console.log(err),
        );
    }
  };

  return printMulti;
};
