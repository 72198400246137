import { PointEvent, Practitioner } from '../../../_shared/models/practitioner';
import React, { ReactElement, FC, useRef, useEffect, useState } from 'react';
import styles from './PointEventList.module.scss';
import PointRow from '../PointRow';
import AddPointEventForm from '../AddPointEventForm';

interface IPointEventList {
  points: Practitioner['points'];
  onNewPEventAdded?: (amount: number) => void;
  onPEventDeleted?: (pointEventId: string) => void;
  className?: string;
}

/**
 * Represents a list of point events.
 * Also display the order points on top, and the balance on bottom.
 * Could also display a form to create new point event if the callback onNewPEventAdded is provided.
 * @param points {Practitioner["points"]} the points to display
 * @param onNewPEventAdded {Function} callback called when a new point is added, with the amount as param
 * @param onPEventDeleted {Function} callback called when a point event is deleted, with its id as param
 * @param className {string} custom className
 */
const PointEventList: FC<IPointEventList> = ({
  points,
  onNewPEventAdded,
  onPEventDeleted,
  className,
}: IPointEventList): ReactElement => {
  const [confirmDeletion, setConfirmDeletion] = useState<string>();

  const pointEventListRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    pointEventListRef.current?.scrollTo(0, pointEventListRef.current?.scrollHeight);
  };

  const handleDeleteClicked = (pointEvent: PointEvent) => {
    setConfirmDeletion(pointEvent.id);
  };

  useEffect(() => {
    // Auto scroll to bottom
    scrollToBottom();
  });

  const pointEventElements =
    points?.events
      .sort((pointEventA, pointEventB) => (pointEventA.date < pointEventB.date ? -1 : 1))
      .map((pointEvent) => (
        <PointRow
          key={pointEvent.id}
          amount={pointEvent.amount}
          date={pointEvent.date}
          onDeletedClicked={() => handleDeleteClicked(pointEvent)}
          className="mb-2"
        >
          {/* Confirm Delete point event */}
          {confirmDeletion === pointEvent.id && (
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <span style={{ marginRight: 'auto' }}>Supprimer ces points ?</span>
              {/* Confirm button */}
              <button
                type="button"
                className="button-validate circle ml-3"
                onClick={() => onPEventDeleted && pointEvent.id && onPEventDeleted(pointEvent.id)}
              >
                <i className="material-icons">check</i>
              </button>
              {/* Cancel button */}
              <button type="button" className="button-lgrey circle" onClick={() => setConfirmDeletion(undefined)}>
                <i className="material-icons">undo</i>
              </button>
            </div>
          )}
        </PointRow>
      )) || [];

  return (
    <div className={`${className} ${styles.container} pl-5 mb-4 t-grey`} data-cy="PointEventList">
      <div className={`${styles.header} mb-2`}>
        <div className="upperCase p-2 t-black br-top">Historique</div>
        {/* Order points */}
        <PointRow type="other" icon="shopping_cart" amount={points?.orderPoints ?? 0}>
          {' '}
          pts commandes
        </PointRow>
      </div>
      {/* Point events */}
      <div className={styles.pointEventListWrapper} ref={pointEventListRef}>
        {pointEventElements.length > 0 && <div className={`${styles.pointEventList} column`}>{pointEventElements}</div>}
      </div>
      {/* Balance */}
      <div className={`${styles.footer} mb-2`}>
        <PointRow type="other" icon="trending_up" amount={points?.balance ?? 0}>
          {' '}
          balance
        </PointRow>
      </div>
      {/* Point event form */}
      {onNewPEventAdded && <AddPointEventForm onSubmit={onNewPEventAdded} className="mt-4 br-top" />}
    </div>
  );
};

export default PointEventList;
