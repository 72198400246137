import React from 'react';

import { IIngredientContext, IngredientContext } from './ingredient.context';
import { ServiceContext } from '../../../_core/services/service.context';
import { Ingredient } from '../../models/ingredient';
import { ingredientService } from '../../services/ingredient.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

export class IngredientProvider extends React.Component<any, IIngredientContext> {
  static contextType = ServiceContext;
  query: any = this.context.query;

  state: IIngredientContext = {
    ingredientSelected: null,
    ingredientOnEdition: null,
    ingredients: null,
    isLoading: false,
    filter: '',
    order: '',
    fillIngredients: () => this.fillIngredients(),
    setIngredientSelected: (ingredient: Ingredient | null) => this.setIngredientSelected(ingredient),
    setIngredientOnEdition: (ingredient: Ingredient | null) => this.setIngredientOnEdition(ingredient),
    setIngredientOnEditionAsNew: () => this.setIngredientSelectedAsNew(),
    fetchIngredientOnEdition: () => this.fetchIngredient(),
    deleteIngredient: () => this.deleteIngredient(),
    setFilter: (filter: any) => this.setFilter(filter),
    setOrder: () => '',
  };

  filters$: Subject<string> = new Subject<string>();
  filterSubscription: Subscription = new Subscription();

  componentDidMount(): void {
    this.filterSubscription = this.filters$
      .asObservable()
      .pipe(
        tap((filter: string) => this.setState({ filter })),
        distinctUntilChanged(),
        debounceTime(200),
        switchMap((filter: string) => {
          this.setState({ isLoading: true });
          if (filter.length > 0) {
            return this.context.query(ingredientService.getSuggestions(filter, undefined)).pipe(
              map((suggestions: any) => {
                return suggestions.map((suggestion: any) => suggestion.suggestion) || [];
              }),
            ) as Observable<Ingredient[]>;
          } else {
            return this.context.query(ingredientService.getAllIngredients()) as Observable<Ingredient[]>;
          }
        }),
      )
      .subscribe(
        (ingredients: Ingredient[]) => {
          this.setState({
            ingredients: ingredients,
            isLoading: false,
          });
        },
        (err: any) => {
          console.log(err);
          this.setState({ isLoading: false });
        },
      );
  }

  componentWillUnmount(): void {
    this.filterSubscription.unsubscribe();
  }

  /**
   * Fill the store with ingredients
   */
  fillIngredients() {
    this.setState({
      isLoading: true,
    });
    this.context
      .query(ingredientService.getAllIngredients())
      .subscribe(
        (ingredients: Ingredient[]) => {
          this.setState({
            ingredients: ingredients,
          });
        },
        (err: any) => {
          console.log(err);
        },
      )
      .add(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  /**
   * Create or Update ingredient
   *
   * @param ingredient
   */
  fetchIngredient(): void {
    const ingredient = JSON.parse(JSON.stringify(this.state.ingredientOnEdition));
    if (!ingredient) return;
    if (ingredient.id === null || ingredient.id === undefined) {
      this.context
        .query(ingredientService.createIngredient(ingredient), {
          notify: 'Ingredient créé',
        })
        .subscribe(
          (newIngredient: Ingredient) => {
            this.fillIngredients();
            this.setState({
              ingredientSelected: newIngredient,
              ingredientOnEdition: null,
            });
          },
          (err: string) => {
            console.log(err);
          },
        );
    } else {
      this.context
        .query(ingredientService.updateIngredient(ingredient), {
          notify: 'Ingredient modifié',
        })
        .subscribe(
          () => {
            if (
              this.state.ingredients &&
              this.state.ingredients.findIndex((i: Ingredient) => i.id === ingredient.id) >= 0
            ) {
              const index = this.state.ingredients.findIndex((i: Ingredient) => i.id === ingredient.id);
              const ingredients: Ingredient[] = JSON.parse(JSON.stringify(this.state.ingredients));
              ingredients[index] = ingredient;
              this.setState({
                ingredientSelected: ingredient,
                ingredientOnEdition: null,
                ingredients: ingredients,
              });
            } else {
              this.setState({
                ingredientSelected: ingredient,
                ingredientOnEdition: null,
              });
            }
          },
          (err: any) => {
            console.log(err);
          },
        );
    }
  }

  deleteIngredient() {
    const ingredient = this.state.ingredientSelected;
    if (ingredient && ingredient.id) {
      ingredientService.deleteIngredient(ingredient.id).subscribe(
        () => {
          const ingredients = this.state.ingredients;
          if (ingredients) {
            const index = ingredients.findIndex((i: Ingredient) => i.id === ingredient.id);
            ingredients.splice(index, 1);
            this.setState({
              ingredientSelected: null,
              ingredientOnEdition: null,
              ingredients: ingredients,
            });
          } else {
            this.setState({
              ingredientSelected: null,
              ingredientOnEdition: null,
            });
          }
        },
        (err) => console.log(err),
      );
    }
  }

  setIngredientSelected(ingredient: Ingredient | null) {
    if (
      !this.state.ingredientOnEdition ||
      (!ingredient && this.state.ingredientSelected && !this.state.ingredientSelected.id)
    ) {
      this.setState({
        ingredientSelected: JSON.parse(JSON.stringify(ingredient)),
        ingredientOnEdition: null,
      });
    }
  }

  setIngredientOnEdition(ingredient: Ingredient | null) {
    const ingredientOnEdition = JSON.parse(JSON.stringify(ingredient));
    this.setState({
      ingredientOnEdition: ingredientOnEdition,
    });
  }

  setOrder(order: string): void {
    this.setState({
      order: order,
    });
  }

  setFilter(filter: string): void {
    this.filters$.next(filter);
  }

  setIngredientSelectedAsNew() {
    if (!this.state.ingredientOnEdition) {
      const newIngredient: Ingredient = {
        code: '',
        pinyin: '',
        synonymes: [],
        latin: '',
        chinois: '',
        reglementation: 'normal',
        codeprix: {
          code: 'N',
          id: null,
        },
      };

      this.setState({
        ingredientOnEdition: newIngredient,
      });
    }
  }

  render() {
    return <IngredientContext.Provider value={this.state}>{this.props.children}</IngredientContext.Provider>;
  }
}
