import { useEffect, useState } from 'react';
import { useQuery } from '../../../_core/services/service.context';
import { usePrintMulti } from './useMultiPrint';
import { orderService } from '../../services/order.service';
import { R1_STATUS, R2_STATUS } from '../../config/constants';
import useListParams from '../useListParams';
import { OrderFilters } from '../../types/OrderFilter';

interface IOrdersState {
  orders: any[];
  total: number;
}

export const useOrders = () => {
  const query = useQuery();
  const printMulti = usePrintMulti();

  const [state, setState] = useState<IOrdersState>({
    orders: [],
    total: 0,
  });

  const [isLoading, setIsLoading] = useState(false);

  const { params, initParams, updateOffset, updateFilters, updateOrderSort } = useListParams<OrderFilters>({
    offset: 0,
    sort: 'date',
    direction: 'desc',
  });

  const init = (initialParams: any) => {
    const { sort, direction } = params;

    const parms = {
      sort: sort,
      direction: direction,
      offset: params.offset,
      filters: {},
    };

    const filters: { [key: string]: 'string' | 'array' } = {
      dateStart: 'string',
      dateEnd: 'string',
      pDateStart: 'string',
      pDateEnd: 'string',
      prixMin: 'string',
      prixMax: 'string',
      cid: 'array',
      pid: 'array',
      shipment: 'string',
      status: 'array',
      code: 'string',
    };

    Object.keys(filters).forEach((f: string) => {
      if (initialParams[f]) {
        if (filters[f] === 'array') {
          (parms.filters as any)[f] = Array.isArray(initialParams[f]) ? [...initialParams[f]] : [initialParams[f]];
        } else {
          (parms.filters as any)[f] = initialParams[f];
        }
      }
    });

    initParams(parms);
  };

  const getOrders = () => {
    if (params.filters) {
      setIsLoading(true);
      query(orderService.getAllOrder(params.filters, params.offset, params.sort, params.direction))
        .subscribe(
          (ordersState: any) => {
            setState(ordersState);
          },
          () =>
            setState({
              orders: [],
              total: 0,
            }),
        )
        .add(() => setIsLoading(false));
    }
  };

  const updateOrdersState = (ids: string[], orderStatus: string) => {
    if (query) {
      query(orderService.updateOrderStatus(ids, orderStatus)).subscribe(
        () => {
          if (orderStatus === R1_STATUS || orderStatus === R2_STATUS) {
            printMulti(ids, orderStatus);
          }
          getOrders();
        },
        (err) => console.log(err),
      );
    }
  };

  useEffect(getOrders, [params]);

  return {
    orders: state.orders,
    total: state.total,
    params,
    isLoading,
    init,
    updateOrderSort,
    updateOffset,
    updateFilters,
    updateOrdersState,
  };
};

export default useOrders;
