import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthConsumer } from './auth.context';

export const ProtectedRoute = ({ component: Component, props, ...rest }: any) => (
  <AuthConsumer>
    {({ isAuth }) => (
      <Route
        render={(props) =>
          isAuth ? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }
        {...rest}
      />
    )}
  </AuthConsumer>
);
