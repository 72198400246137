import * as React from 'react';
import { TUextInput } from '../../../../_shared/component/textInput/textInput.component';
import { Address } from '../../../../_shared/models/client';
import TextSelector from '../../../../_shared/component/textSelector/textSelector';
import { getAllPays } from '../../../../_shared/services/pays.service';
import Checkbox from '../../../../_shared/component/checkBox/checkbox';
import { ADDRESS_MAX_LENGTH } from '../../../../_shared/config/constants';
import { useContext, useRef, useState } from 'react';
import { ServiceContext } from '../../../../_core/services/service.context';
import { addressService } from '../../../../_shared/services/address.service';
import useOutsideClick from '../../../../_shared/component/useClickOutside';
import ParentComponent from '../../../../_shared/component/parentComponent/parent.component';
import Row from '../../../../_shared/component/row/row';

const VilleSuggestionsList = ({ villeSuggestions, onSelect }: any) => {
  return (
    <div className="relative full-width">
      <div className="absolute" style={{ minWidth: '100%' }}>
        {villeSuggestions.map((suggestion: any, index: number) => (
          <button
            key={`ville-suggestion-${index}`}
            type="button"
            className="b-white p-spaced selectable full-width l-align br-bottom no-wrap"
            onClick={() => onSelect(suggestion)}
          >
            {suggestion.cp} {suggestion.ville}
          </button>
        ))}
      </div>
    </div>
  );
};

export const AddressForm: React.FC<any> = ({ address, isEditing, onChange }: any) => {
  const { query } = useContext(ServiceContext);
  const [villeSuggestions, setVilleSuggestions] = useState([]);
  const [fieldSelected, setFieldSelected] = useState(null as string | null);

  const suggestionRef = useRef(null);
  useOutsideClick(suggestionRef, () => {
    setVilleSuggestions([]);
  });

  const handleLineChange = (value: any, lineIndex: number) => {
    const lines = [...address.lines];
    lines[lineIndex] = value;
    onChange({ lines });
  };

  const getLineError = (index: number, message?: string) => {
    return address.lines[index]
      ? address.lines[index].length >= ADDRESS_MAX_LENGTH
        ? message || 'Erreur'
        : undefined
      : undefined;
  };

  const getVilleSuggestions = (fieldName: string, searchField: string) => {
    if (address.pays && address.pays.toUpperCase() === 'FRANCE') {
      setFieldSelected(fieldName);
      query(addressService.getVillesSuggestions(searchField)).subscribe(
        (suggestions: any) => setVilleSuggestions(suggestions),
        (err) => console.log(err),
      );
    } else {
      setVilleSuggestions([]);
      setFieldSelected(null);
    }
  };

  const handleVilleChange = (name: string, value: string) => {
    if (value && value.length) {
      getVilleSuggestions(name, value);
    } else {
      setVilleSuggestions([]);
    }
    onChange({ [name]: value });
  };

  const handleVilleSuggestions = (suggestion: any) => {
    setVilleSuggestions([]);
    onChange({ ville: suggestion.ville, cp: suggestion.cp });
  };

  const handleBlur = () => {
    setFieldSelected(null);
    setVilleSuggestions([]);
  };

  return (
    <div className="address-form">
      {isEditing ? (
        <>
          <div className="row p-small">
            <TUextInput
              name={'label'}
              suffix={<span className="material-icons t-lgrey">location_on</span>}
              className="light capitalize"
              value={address.label}
              onChange={onChange}
              size={20}
              resize={true}
            />
          </div>

          <div className="address-form_lines column p-small">
            <TUextInput
              name={'line'}
              label={'Numéro et libellé de voie.'}
              suffix={<span className="t-lgrey">{1}</span>}
              value={address.lines[0]}
              className={'first'}
              disabled={!isEditing}
              labelHide={!isEditing}
              error={getLineError(0, "L'adresse doit être comprise entre 1 et 34 caractères")}
              isHidden={!address.lines[0] && !isEditing}
              onChange={(e: any) => handleLineChange(e.line, 0)}
            />
            <TUextInput
              name={'line'}
              value={address.lines[1]}
              label={'Suite adresse / Lieu dit ou autre mention.'}
              suffix={<span className="t-lgrey">2</span>}
              disabled={!isEditing}
              labelHide={!isEditing}
              isHidden={!address.lines[1] && !isEditing}
              error={getLineError(1, 'Doit être compris entre 1 et 34 caractères')}
              onChange={(e: any) => handleLineChange(e.line, 1)}
            />
            <TUextInput
              name={'line'}
              value={address.lines[2]}
              label={'Entrée, bâtiment, immeuble, résidence.'}
              suffix={<span className="t-lgrey">3</span>}
              disabled={!isEditing}
              labelHide={!isEditing}
              isHidden={!address.lines[2] && !isEditing}
              error={getLineError(2, 'Doit être compris entre 1 et 34 caractères')}
              onChange={(e: any) => handleLineChange(e.line, 2)}
            />
            <TUextInput
              value={address.lines[3] || ''}
              name={'line'}
              label={'Etage, couloir, escalier, appart.'}
              suffix={<span className="t-lgrey">4</span>}
              disabled={!isEditing}
              labelHide={!isEditing}
              isHidden={!address.lines[3] && !isEditing}
              error={getLineError(3, 'Doit être compris entre 1 et 34 caractères')}
              onChange={(e: any) => handleLineChange(e.line, 3)}
            />
          </div>
          <div className={isEditing ? 'row' : ''}>
            <ParentComponent reference={suggestionRef} onBlur={handleBlur}>
              <div className={'row '}>
                <div>
                  <TUextInput
                    value={address.cp}
                    name={'cp'}
                    labelHide={!isEditing}
                    disabled={!isEditing}
                    size={7}
                    resize={true}
                    onFocusChange={(e: boolean) => (e ? getVilleSuggestions('cp', address.cp) : null)}
                    onChange={(e: any) => handleVilleChange('cp', e.cp)}
                  />
                  {isEditing && villeSuggestions.length && fieldSelected === 'cp' ? (
                    <VilleSuggestionsList onSelect={handleVilleSuggestions} villeSuggestions={villeSuggestions} />
                  ) : null}
                </div>
                <div>
                  <TUextInput
                    value={address.ville}
                    name={'ville'}
                    className={'upperCase'}
                    labelHide={!isEditing}
                    disabled={!isEditing}
                    size={30}
                    onFocusChange={(e: boolean) => (e ? getVilleSuggestions('ville', address.ville) : null)}
                    onChange={(e: any) => handleVilleChange('ville', e.ville)}
                  />
                  {isEditing && villeSuggestions.length && fieldSelected === 'ville' ? (
                    <VilleSuggestionsList onSelect={handleVilleSuggestions} villeSuggestions={villeSuggestions} />
                  ) : null}
                </div>
              </div>
            </ParentComponent>
            <div>
              <TextSelector
                value={address.pays}
                name={'pays'}
                className={'selector full-width'}
                isEditable={isEditing}
                labelHide={!isEditing}
                defaultChoice={'France'}
                options={getAllPays()}
                onChange={(e: any) => onChange(e)}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="address-detail t-grey p-medium">
          <div className="address-detail_lines">
            {address.lines.map((l: any, index: number) =>
              l && l.length > 0 ? (
                <div key={index} className={`address-detail_line ${getLineError(index) ? 't-red' : ''}`}>
                  {l}
                </div>
              ) : null,
            )}
          </div>
          <div className="row">
            <span className="address-detail_zipcode"> {address.cp}</span>
            &nbsp;
            <span className="address-detail_city"> {address.ville}</span>
          </div>
          <div className="row">
            <span className="address-detail_country">{address.pays}</span>
          </div>
        </div>
      )}
    </div>
  );
};

interface IAddressTabsProps {
  addressSelected: number | undefined;
  addresses: Address[];
  isEditing: boolean;
  onAdresseSelect: any;
  onNewAddress: any;
  onChange: any;
}

const AddressTabs: React.FC<IAddressTabsProps> = ({
  addressSelected,
  addresses,
  isEditing,
  onAdresseSelect,
  onNewAddress,
  onChange,
}: IAddressTabsProps) => {
  const address = addresses[addressSelected || 0];

  const handleFavoriteChange = (index: number) => {
    const add: Address[] = [...addresses];
    add.forEach((p) => {
      p.default = false;
    });
    add[index].default = true;
    onChange(add);
  };

  const handleOnChange = (updates: Partial<Address>) => {
    const add: Address[] = [...addresses];
    add[addressSelected as number] = {
      ...add[addressSelected as number],
      ...updates,
    };
    onChange(add);
  };

  return (
    <div className="address-tab">
      <div className="address-tab_header">
        {addresses.map((address: Address, index: number) => (
          <button
            className={addressSelected === index ? 'tab active' : 'tab'}
            type="button"
            onClick={() => {
              onAdresseSelect(index);
            }}
            key={index}
            disabled={addressSelected === index}
          >
            {index + 1}
            {address.default ? <i className="material-icons">star</i> : ''}
          </button>
        ))}
        {isEditing && addresses.length < 5 ? (
          <button type={'button'} className={'tab newAddress-button'} onClick={() => onNewAddress()}>
            +
          </button>
        ) : null}
      </div>
      <div className="address-tab_body">
        {address ? (
          <>
            <AddressForm address={address} isEditing={isEditing} onChange={handleOnChange} />
            {isEditing ? (
              <Row className="p-small">
                <label>Adresse par défaut ?</label>
                <Checkbox
                  name={'default'}
                  value={address.default}
                  onChange={() => handleFavoriteChange(addressSelected as number)}
                />
              </Row>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default AddressTabs;
