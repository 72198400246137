import React from 'react';
import { ORDER_STATUS, STATUS_PROPS } from '../../../../_shared/config/constants';
import { OrderFilters } from '../../../../_shared/types/OrderFilter';

interface IOrdersStatusFiltersProps {
  statusFilters: string[];
  onFiltersChange: (filters: OrderFilters) => void;
}

const OrdersStatusFilters: React.FC<IOrdersStatusFiltersProps> = ({
  statusFilters,
  onFiltersChange,
}: IOrdersStatusFiltersProps) => {
  const handleFilterChange = (status: string) => {
    if (!statusFilters) return onFiltersChange({ status: [status] });
    const index = statusFilters.findIndex((s) => s === status);
    if (index < 0) onFiltersChange({ status: [...statusFilters, status] });
    else {
      const st = [...statusFilters];
      st.splice(index, 1);
      onFiltersChange({ status: st });
    }
  };

  return (
    <div className="row gapped p-medium">
      {Object.values(ORDER_STATUS).map((status: string, index: number) => {
        const statusProps = STATUS_PROPS[status];
        const isActive =
          !statusFilters || statusFilters.length <= 0 || statusFilters?.findIndex((s: string) => s === status) >= 0;
        return (
          <button
            className={`button-lgrey rounded bordered ${isActive ? 'take' : ''}`}
            key={'status-filter-' + index}
            style={{
              color: isActive ? statusProps.color : undefined,
              borderColor: isActive ? 'currentColor' : '',
            }}
            onClick={(event) => {
              event.currentTarget.blur();
              handleFilterChange(status);
            }}
          >
            <i className="material-icons">{statusProps.icon}</i>
            {statusProps.name}
          </button>
        );
      })}
      <button
        className="button-lgrey rounded"
        disabled={!statusFilters || statusFilters.length >= Object.values(ORDER_STATUS).length}
        onClick={(event) => {
          event.currentTarget.blur();
          onFiltersChange({ status: Object.values(ORDER_STATUS) });
        }}
      >
        Tous
      </button>
    </div>
  );
};

export default OrdersStatusFilters;
