import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DraftsList } from './draftList/drafts.list';
import { ORDERS_LIMIT } from '../../../_shared/config/constants';
import { usePrint } from '../../../_shared/providers/order/usePrint';
import { usePrintMulti } from '../../../_shared/providers/order/useMultiPrint';
import { useDrafts } from '../../../_shared/providers/order/useDrafts';
import PaginationPanel from '../../../_shared/component/listPagination/ListPagination';
import { SpinLoader } from '../../../_shared/component/loader/loader';
import * as qs from 'query-string';
import { DRAFTS_PATH } from '../../../_shared/config/paths';
import { DraftsStatusPanel } from './draftFilterPanel/draftsStatus.panel';
import DraftsFilters from '../components/draftsFilters/drafts.filters';

export const DraftsPage: React.FC<any> = () => {
  const print = usePrint();
  const printMulti = usePrintMulti();

  const {
    drafts,
    total,
    params,
    isLoading,
    init,
    updateOffset,
    updateOrderSort,
    updateDraftsStatus,
    updateFilters,
    removeFilters,
  } = useDrafts();

  const history = useHistory();
  const location = useLocation();
  const { offset, filters, sort, direction } = params;

  useEffect(() => {
    const query = qs.parse(location.search);
    init({ ...query });
  }, []);

  useEffect(() => {
    history.replace({
      pathname: `${DRAFTS_PATH}`,
      search: qs.stringify({
        offset,
        sort,
        direction,
        ...filters,
      }),
    });
  }, [params]);

  const handlePrintMulti = (ids: string[], status: string) => {
    printMulti(ids, status, () => history.go(0));
  };

  return (
    <div className="order-page page" style={{ display: 'grid', gridTemplateRows: '0fr auto' }}>
      {filters ? (
        <div>
          <DraftsStatusPanel status={filters.status} onHandleStatus={(status: string) => updateFilters({ status })} />
          <DraftsFilters filters={filters} onFiltersChange={updateFilters} onRemoveFilters={removeFilters} />
        </div>
      ) : null}

      <div className="full-height relative" style={{ display: 'grid', gridTemplateRows: 'auto 0fr' }}>
        <div className="full-height scrollable-y">
          {!isLoading && drafts.length <= 0 ? (
            <div className="column center full-height">
              <div>
                <span className="material-icons">list_alt</span>
              </div>
              <div>Aucune commande trouvée</div>
            </div>
          ) : (
            <DraftsList
              draftStatus={filters.status}
              drafts={drafts}
              sort={sort}
              direction={direction}
              onPrint={print}
              onPrintMulti={handlePrintMulti}
              onUpdateSort={updateOrderSort}
              onUpdateDraftsStatus={updateDraftsStatus}
            />
          )}
          {isLoading ? (
            <div className="absolute column center full-height full-width">
              <SpinLoader size={'20vmax'} thickness={'0.2rem'} color={'dimgrey'} />
            </div>
          ) : null}
        </div>
        <div className="sticky-bottom row center br-top b-white">
          <PaginationPanel
            onPageSelect={(page: any) => {
              updateOffset((page - 1) * ORDERS_LIMIT);
            }}
            offset={offset}
            itemByPage={ORDERS_LIMIT}
            totalItem={total}
          />
        </div>
      </div>
    </div>
  );
};
