import React from 'react';
import { IngredientListSuggester } from '../compositionIngredient.list';
import NumberInput from '../../../../../../_shared/component/numberInput';
import { Ingredient } from '../../../../../../_shared/models/ingredient';

interface SuggestionRow {
  ingredient: Ingredient;
  isValid: boolean;
  index: number;
  formeId: string;
  pattern: any;
  ingredientIds: string[];
  lineSelected: number | null;
  setLineSelected: any;
  replaceSuggestion: any;
  updateSuggestion: any;
  deleteSuggestion: any;
}

const SuggestionRow: React.FC<SuggestionRow> = ({
  ingredient,
  isValid,
  index,
  formeId,
  pattern,
  ingredientIds,
  lineSelected,
  setLineSelected,
  replaceSuggestion,
  updateSuggestion,
  deleteSuggestion,
}: SuggestionRow) => {
  return (
    <tr className={`bordered ${isValid ? '' : 't-red'}`}>
      <td className={`center-align ${isValid ? 't-green' : ''}`}>{index + 1}</td>
      {lineSelected === index ? (
        <td colSpan={2}>
          <IngredientListSuggester
            formeId={formeId}
            ingredients={ingredientIds}
            onSelect={(e: any) => {
              replaceSuggestion({ ...e, proportion: ingredient.proportion }, index);
              setLineSelected(null);
            }}
            onCancel={() => {
              setLineSelected(null);
            }}
          />
        </td>
      ) : (
        <>
          <td className="selectable" onClick={() => setLineSelected(index)}>
            {ingredient.code}
          </td>
          <td className="selectable" onClick={() => setLineSelected(index)}>
            {ingredient.pinyin}
          </td>
        </>
      )}
      <td>
        {isNaN(ingredient.proportion) ? (
          '--'
        ) : (
          <NumberInput
            name={'proportion'}
            value={ingredient.proportion}
            onChange={(e: any) => updateSuggestion(e.proportion, index)}
          />
        )}
      </td>
      <td colSpan={pattern.length + 4}>
        <button className="deleteButton" onClick={() => deleteSuggestion(index)}>
          <i className="material-icons t-medium t-grey">close</i>
        </button>
      </td>
    </tr>
  );
};

export default SuggestionRow;
