import React from 'react';
import useEscape from '../useEscape';
import OutClickDiv from '../outClickDiv/outclick.div';

interface IModalProps {
  on: boolean;
  onCancel?: any;
  children: JSX.Element | JSX.Element[];
  className?: string;
}

const Modal: React.FC<IModalProps> = (props: IModalProps) => {
  useEscape(props.onCancel);

  return props.on ? (
    <div className="modal absolute overlay full-width full-height column center">
      <OutClickDiv className="column gapped" onClickOutside={() => (props.onCancel ? props.onCancel() : null)}>
        <div className="row end">
          <button className="button-lgrey circle b-white" onClick={props.onCancel} data-cy="Modal-close">
            <span className="material-icons">close</span>
          </button>
        </div>
        <div className={`modal_content ${props.className}`}>{props.children}</div>
      </OutClickDiv>
    </div>
  ) : null;
};

export default Modal;
